import type { IonIcon } from '@ionic/react';
import type { TFunction } from 'i18next';
import { trashBin } from 'ionicons/icons';
import type { ComponentProps } from 'react';

import i18n from '../../i18n';
import toasters from '../UI/Toasts';

const iconProps = (icon?: ComponentProps<typeof IonIcon>['icon']) => ({
  icon: {
    slot: 'icon-only',
    color: 'medium',
    icon
  }
});

const deleteFileMessage = i18n.t('Are you sure you want to delete this file?');
const deleteAllVersionsMessage = i18n.t('This will delete all files and versions of this file. Are you sure you want to delete this file?');

const fileToasts = (type: 'error' | 'success', message: string | TFunction) => {
  return {
    createToast: () => {
      toasters.createToast({
        message,
        background: 'var(--ion-color-light)',
        icon: trashBin
      }, type);
    }
  };
};

export const alertOptions = (message: string, handler: () => void) => ({
  header: i18n.t('Delete file'),
  message,
  buttons: [
    {
      text: i18n.t('Cancel'),
      role: 'cancel',
    },
    {
      text: i18n.t('Delete'),
      handler,
    },
  ],
});

export const config = {
  iconProps,
  deleteFileMessage,
  deleteAllVersionsMessage,
  fileToasts,
  alertOptions
};
