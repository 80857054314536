import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';

import { networking } from '../api/networking';
import { setActivityCodes } from '../reducers/ActivityCodes';
import store from '../store';

const fetchActivityCodes = () => {
  networking.get('/api/v1/activity_codes?per_page=2000')
    .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.ActivityCode>>) => {
      const activityCodeData = response.data.data.records;
      store.dispatch(setActivityCodes(activityCodeData));
    })
    .catch((error: E2U.V1.Response.Error) => {
      Sentry.captureException(error);
    });
};

export default fetchActivityCodes;
