import type { DatetimeCustomEvent } from '@ionic/react';
import { IonCol, IonDatetime, IonDatetimeButton, IonGrid, IonIcon, IonItem, IonModal, IonRow } from '@ionic/react';
import { t } from 'i18next';
import { chevronDown } from 'ionicons/icons';
import React from 'react';

import styles from '../../styles/OnboardingStageTwo.module.scss';

interface RegisterExpirationDate {
  onChange: (value: string) => void
}

const DatePickContainer: React.FC<RegisterExpirationDate> = (props) => {
  const handleChange = (evt: DatetimeCustomEvent) => {
    const value = evt.target.value as string;
    props.onChange(value.split('T')[0]);
  };

  return (
    <div className={styles['datepick-container']}>
      <IonGrid>
        <IonRow className="ion-text-left">
          <IonCol size="12">
            <p className="ion-text-left ion-no-margin ion-margin-bottom" >
              {t('Certificate expiration date')}
            </p>
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-left ion-align-items-center">
          <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
          <IonIcon icon={chevronDown} size="small"></IonIcon>
        </IonRow>
      </IonGrid>

      <IonModal keepContentsMounted={true} className={styles['date-modal-container']}>
        <IonItem className={styles['calendar-container']} lines={'none'}>
          <IonDatetime
            id="datetime"
            presentation="date"
            showDefaultButtons={true}
            placeholder={'yyyy-mm-dd'}
            onIonChange={handleChange}
          />
        </IonItem>
      </IonModal>
    </div>
  );
};

export default DatePickContainer;
