import { headsetOutline, homeOutline, personOutline } from 'ionicons/icons';

import i18n from '../../i18n';

interface MenuListItems {
    itemIcon: string;
    directTo?: string;
    linkText: string;
  }

  interface Path {
    url: string;
    pattern: string[];
    tab: string;
    title: string;
  }

export const paths:Path[] = [
  {
    url: '/notice-board',
    pattern: ['/notice-board'],
    tab: 'notice-board',
    title: i18n.t('Notice board')
  },
  {
    url: '/project-tools',
    pattern: ['/project-tools'],
    tab: 'project-tools',
    title: i18n.t('Project tools')
  },
  {
    url: '/economy-tools',
    pattern: ['/economy-tools'],
    tab: 'economy-tools',
    title: i18n.t('Economy tools')
  }
];

export const MENU_LIST: MenuListItems[] = [
  {
    itemIcon: homeOutline,
    directTo: '/',
    linkText: i18n.t('Home')
  },
  {
    itemIcon: headsetOutline,
    directTo: '/support',
    linkText: i18n.t('Support')
  },
  {
    itemIcon: personOutline,
    directTo: '/settings',
    linkText: i18n.t('User settings')
  },

];
