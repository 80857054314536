import { IonicSlides } from '@ionic/react';
import React, { useState } from 'react';
import type {
  Swiper as SwiperInterface
} from 'swiper';
import { Swiper } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';

import {
  Keyboard,
  Pagination,
  Scrollbar,
  Virtual
} from 'swiper';

import './SwiperContent.module.scss';
import type { SwipingContainerInterface } from './SwiperInterfaces';
import { useAppSelector } from '../../hooks';

const SwiperContainer: React.FC<SwipingContainerInterface> = ({
  autoHeight,
  children,
  controller,
  grabCursor,
  slidesPerView,
  spaceBetween,
  speedOfSlide,
  touchMove,
}) => {
  const [swiper, setSwiper] = useState<SwiperInterface | undefined>();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  if (!controller) {
    return null;
  } else {
    controller.setSlidesPerView(slidesPerView ?? 1);
  }

  const onInitSwiper = (swiper: SwiperInterface) => {
    setSwiper(swiper);

    if (swiper) {
      controller.addSwiper(swiper);
    }
  };

  return (
    <Swiper
      allowTouchMove={touchMove ?? undefined}
      modules={[Keyboard, Pagination, Scrollbar, IonicSlides, Virtual]}
      {...isDesktop && {
        scrollbar: { draggable: false, dragSize: 200, hide: false },
        keyboard: true,
      }}
      onSwiper={onInitSwiper}
      speed={speedOfSlide || 200}
      slidesPerView={slidesPerView || 1}
      spaceBetween={spaceBetween}
      autoHeight={autoHeight || false}
      grabCursor={grabCursor || false}
      observer={true}
    >
      {children}
    </Swiper>
  );
};

export default SwiperContainer;
