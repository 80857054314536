import { IonCol, IonGrid, IonIcon, IonLabel, IonList, IonModal, IonRow, IonSelectOption, isPlatform } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { chevronDown, chevronUp, downloadSharp, shareSocialSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useStore } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import PrecalculationForm from './Form/PrecalculationForm';
import PrecalculationRows from './PreCalculationRows';
import styles from './Precalculations.module.scss';
import { networking } from '../../../../api/networking';
import FadeInContainer from '../../../../components/Animated/FadeInContainer';
import PieChart from '../../../../components/Graphs/PieChart';
import RelatedMultiSelectEdit from '../../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import SearchAndSortRow from '../../../../components/UI/SearchAndSort/SearchAndSortRow';
import SelectOptions from '../../../../components/UI/Select/SelectOption';
import { useAppSelector } from '../../../../hooks';
import useModal from '../../../../hooks/useModal';
import { setIsLoading } from '../../../../reducers/loading';
import { setPrecalculations, setSelectedPrecalculation } from '../../../../reducers/precalculations';
import formatNumber from '../../../../tools/formatNumber';
import shareUrl from '../../../../tools/shareUrl';

interface Lock {
  isLocked: boolean;
  isLockable: boolean;
}

const SummaryRows: React.FC<{
  value: E2U.V1.Models.Precalculation | number | string | undefined; label: string;
}> = ({ label, value }) => {
  return (
    <IonRow>
      <IonCol className='ion-text-left'>
        <IonLabel className='ion-text-left ion-no-margin' style={{ fontSize: '12px', fontWeight: 'bold' }}>
          {`${label}: `}
        </IonLabel>
        <IonLabel className='ion-no-margin'>
          {value}
        </IonLabel>
      </IonCol>
    </IonRow>
  );
};

const Precalculations: React.FC<Lock> = (props: Lock) => {
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('created_at');
  const store = useStore();
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const [filterParam, setFilterParam] = useState<null | string>(null);
  const [selectedPrecalculationId, setSelectedPrecalculationId] = useState<string | undefined>(undefined);
  const [selectedPrecalculationRow, setSelectedPrecalculationRow] = useState<Partial<E2U.V1.Models.PrecalculationRow>>({});
  const [selectedActivityCodes, setSelectedActivityCodes] = useState<E2U.V1.Models.ActivityCode[]>([]);
  const [showChart, setShowChart] = useState<boolean>(false);
  const precalculations = useAppSelector((state) => state.precalculations.precalculations);
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const isLoadingPrecalculations = useAppSelector((state) => state.loading.isLoading.precalculations);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const selectedPrecalculation = useAppSelector((state) => state.precalculations.selectedPrecalculation);

  const { t } = useTranslate();
  const history = useHistory();
  const location = useLocation();
  const { closeModal, isModalOpen, openModal } = useModal();
  const { handleSubmit } = useForm<FieldValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange'
  });

  const remainingTotal = {
    value: (selectedPrecalculation?.total ?? 0) - (selectedPrecalculation?.secured_costs ?? 0),
    color: filterParam === '1' ? 'var(--ion-color-medium)' : 'var(--ion-color-primary)',
    param: '0'
  };

  const securedTotal = {
    value: selectedPrecalculation?.secured_costs,
    color: filterParam === '0' ? 'var(--ion-color-medium)' : 'var(--ion-color-secondary)',
    param: '1'
  };

  const pieChartData = [
    {
      value: remainingTotal.value,
      name: 'Remaining',
      color: remainingTotal.color,
      onClick: () => setFilterParam(remainingTotal.param)
    },
    {
      value: securedTotal.value,
      name: 'Secured Costs',
      color: securedTotal.color,
      onClick: () => setFilterParam(securedTotal.param)
    }
  ];

  const submitToasters = {
    pending: t('Creating precalculation rows'),
    success: t('Successfully created precalculation rows'),
    error: t('Failed creating precalculation rows')
  };

  const selectedRevisionID = new URLSearchParams(location.search).get('revision');
  const shareURL = `${process.env.REACT_APP_SHARE_BASE_URL + location.pathname + '?revision=' + selectedRevisionID}`;

  const defaultSelectedOption = (
    selectedRevisionID ||
    selectedPrecalculationId ||
    (typeof precalculations !== 'undefined' && precalculations.length > 0
      ? precalculations[0].id
      : undefined)
  );

  const onModalDismiss = () => closeModal();
  const savePrecalculation = () => submitPrecalculation();

  const fetchPrecalculations = async () => {
    try {
      store.dispatch(setIsLoading({ name: 'precalculations', value: true }));
      const response = await toasters.promise(networking.get(`/api/v1/projects/${uuid}/precalculations?fields=name,id,iteration`), {
        error: t('Failed to load precalculations')
      });
      const { data } = response.data;
      const sortedRecords = data.records.sort((a: E2U.V1.Models.Precalculation, b: E2U.V1.Models.Precalculation) => {
        return b.is_draft ? 1 : (b.iteration ?? 0) - (a.iteration ?? 0);
      });
      store.dispatch(setPrecalculations(sortedRecords));
      if (typeof selectedRevisionID === 'undefined' && sortedRecords.length > 0) {
        setSelectedPrecalculationId(sortedRecords[0].id);
        return;
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      store.dispatch(setIsLoading({ name: 'precalculations', value: false }));
    }
  };

  const handleSelectedRevision = (id: string, e: React.MouseEvent<HTMLSelectElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setSelectedPrecalculationId(id);
    const params = new URLSearchParams({ revision: id });
    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  const handleExport = () => {
    networking.get(`/api/v1/precalculations/${defaultSelectedOption ?? selectedRevisionID}/share`)
      .then((response) => {
        shareUrl(
          `${t('Precalculation for project')} ${selectedPrecalculation?.name}.`,
          `${t('Here is the link to the precalculation for project')} ${selectedProject?.name}: ${isPlatform('ios') ? response.data.data.url : ''}`,
          `${response.data.data.url}`,
          t(`Let me know if there is something you are wondering about.`),
          'export'
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const handleShareRevision = () => {
    shareUrl(
      `${t('Share revision')} ${selectedPrecalculation?.name}.`,
      `${t('Here is the link for revision')}: ${isPlatform('ios') ? shareURL : ''}`,
      `${process.env.REACT_APP_SHARE_BASE_URL + location.pathname + '?revision=' + defaultSelectedOption ?? selectedRevisionID}`,
      t(`Let me know if there is something you are wondering about.`),
      'share'
    );
  };

  const handleShareAndExportOfPrecalculation = (handler: 'export' | 'share') => {
    if (handler === 'export') return handleExport();
    if (handler === 'share') return handleShareRevision();
  };

  const deletePrecalculationRow = () => {
    store.dispatch(setIsLoading({ name: 'precalculations', value: true }));
    toasters
      .promise(networking.delete(`/api/v1/precalculation_rows/${selectedPrecalculationRow.id}`), {
        success: 'Successfully deleted precalculation.',
        pending: 'Deleting precalculation',
        error: "Couldn't delete precalculation"
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'precalculations', value: false }));
        fetchPrecalculation();
        onModalDismiss();
      });
  };

  const submitPrecalculation = () => {
    toasters
      .promise(networking.post(`/api/v1/precalculations/${selectedPrecalculation?.id}/submit`), {
        pending: t('Submitting precalculation'),
        success: t('Successfully submitted precalculation'),
        error: t("Couldn't submit precalculation")
      })
      .finally(() => {
        store.dispatch(setSelectedPrecalculation);
        fetchPrecalculations();
      });
  };

  const openPrecalculationFormModal = (row: Partial<E2U.V1.Models.PrecalculationRow>) => {
    setSelectedPrecalculationRow(row);
    openModal();
  };

  const onRowSave = () => {
    fetchPrecalculation();
  };

  const { PreCalculationRowsComponentRender, fetchPrecalculation } =
    PrecalculationRows({
      selectedPrecalculationId: defaultSelectedOption,
      onRowClick: openPrecalculationFormModal,
      lockRows: props.isLocked,
      search,
      sortBy,
      filterParam
    });

  useEffect(() => {
    fetchPrecalculations();
    return () => {
      store.dispatch(setPrecalculations(undefined));
      store.dispatch(setSelectedPrecalculation(undefined));
    };
  }, [uuid]);

  useEffect(() => {
    const requestAttributes: any[] = [];
    const identifier: string | undefined = selectedPrecalculationId || defaultSelectedOption;

    if (!identifier || !selectedActivityCodes.length) {
      return;
    }

    selectedActivityCodes.forEach(activityCode => {
      requestAttributes.push({
        activity_code_id: activityCode.id,
        precalculation_id: identifier,
        cost_per_unit: 0,
        quantity: 0,
        description: null,
        unit: 'sqm',
        living_unit: 'sqm',
        secured_cost: false,
      });
    });

    const saveRequest = networking.post('/api/v1/precalculation_rows/bulk', requestAttributes);

    toasters
      .promise(saveRequest, submitToasters)
      .then(() => {
        fetchPrecalculation();
      })
      .catch(
        (error: E2U.V1.Response.Error<E2U.V1.Models.PrecalculationRow>) => {
          if (networking.isAxiosError(error)) {
            if (
              error &&
              error.response?.data &&
              error.response.data.message &&
              error.response.data.message === 'Validation Failed'
            ) {
              return;
            }
          }
          Sentry.captureException(error);
        }
      ).finally(() => {
        setSelectedActivityCodes([]);
      });
  }, [selectedActivityCodes, selectedPrecalculationId]);

  return (
    <div {...isDesktop && {
      className: styles['precalculations-accordion-container']
    }}>
      <div className='ion-padding' {...isDesktop && { className: styles['precalculations-desktop-wrapper'] }} >

        <IonGrid>
          <IonRow class="ion-justify-content-evenly ion-text-center" style={{ maxWidth: 500 }}>
            <IonCol
              size='6'
              sizeMd='4'
              sizeLg='12'
              className='ion-margin-top ion-text-left'>
              {isLoadingPrecalculations
                ? <React.Fragment />
                : (
                  <IonCol>
                    <SelectOptions
                      itemPadding='ion-no-padding'
                      selectedColour='var(--ion-color-dark)'
                      label={t('Select revision')}
                      options={typeof precalculations !== 'undefined' ? precalculations : []}
                      getId={(option) => option.id}
                      getName={(option) => option.name}
                      selectedOption={precalculations?.find((e) => e.id === defaultSelectedOption)}
                      setSelectedOption={(option, e) => handleSelectedRevision(option?.id ?? '', e as React.MouseEvent<HTMLSelectElement>)}
                    />
                  </IonCol>
                )
              }
              <IonGrid>
                <IonRow className='ion-justify-content-flex-start ion-no-margin ion-margin-bottom'>
                  <IonCol className='ion-text-left' size='4' sizeLg='12'>
                    <RelatedMultiSelectEdit
                      button={'solid'}
                      model={'activity_codes'}
                      onChange={(results: E2U.V1.Models.ActivityCode[]) => setSelectedActivityCodes(results)}
                      label={t('Select activity codes')}
                      value={[]}
                      displayFields={['code', 'name']}
                      modalTitle={t('Select activity codes')}
                      hideSelected={true}
                      ionButtonProps={{
                        color: 'primary'
                      }}
                      leftIcon={false}
                    />
                  </IonCol>
                  <IonCol className='ion-text-left' size='4' sizeLg='12'>
                    <BigUp.Buttons.Responsive
                      padding={20}
                      expand='block'
                      isDesktop={isDesktop}
                      color={isDesktop ? 'secondary' : 'none'}
                      title={t('Share')}
                      onClick={() => handleShareAndExportOfPrecalculation('share')}
                      icon={{ icon: shareSocialSharp, color: 'secondary' }}
                    />
                  </IonCol>
                  <IonCol className='ion-text-left' size='4' sizeLg='12'>
                    <BigUp.Buttons.Responsive
                      padding={20}
                      expand='block'
                      isDesktop={isDesktop}
                      color={isDesktop ? 'tertiary' : 'none'}
                      title={t('Export')}
                      onClick={() => handleShareAndExportOfPrecalculation('export')}
                      icon={{ icon: downloadSharp, color: 'tertiary' }}
                    />
                  </IonCol>
                </IonRow>
                {selectedPrecalculation && (
                  <IonRow className='ion-align-items-center'>
                    <IonCol>
                      <SummaryRows
                        value={formatNumber(selectedPrecalculation?.total ?? 0)}
                        label={t('Total')}
                      />
                      <SummaryRows
                        value={selectedPrecalculation?.secured_costs !== 0 ? formatNumber(selectedPrecalculation?.secured_costs ?? 0) : ''}
                        label={t('Secured')}
                      />
                      <SummaryRows
                        value={formatNumber((selectedPrecalculation?.total ?? 0) - (selectedPrecalculation?.secured_costs ?? 0))}
                        label={t('Remaining')}
                      />
                    </IonCol>

                    {isDesktop && (
                      <IonCol className='ion-text-end' size='3'>
                        <IonIcon
                          size='large'
                          {...showChart
                            ? { icon: chevronUp }
                            : { icon: chevronDown }
                          }
                          onClick={() => setShowChart(!showChart)}
                        ></IonIcon>
                      </IonCol>
                    )}
                  </IonRow>
                )}
              </IonGrid>
            </IonCol>

            <IonCol size='6'
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
              {isDesktop
                ? (<>
                  <FadeInContainer isVisible={showChart}>
                    {selectedPrecalculation && (
                      <React.Fragment>
                        <PieChart height={250} data={pieChartData} />
                      </React.Fragment>
                    )}
                  </FadeInContainer></>)
                : (
                  <>
                    {selectedPrecalculation && (
                      <React.Fragment>
                        <PieChart height={250} data={pieChartData} />
                      </React.Fragment>
                    )}
                  </>
                )}
            </IonCol>
          </IonRow>
          <IonRow className='ion-align-items-center ion-justify-content-center ion-margin-left ion-margin-right ion-margin-top' style={{ maxWidth: 500 }}>
            <IonCol size='12' className='ion-text-center'>
              <form onSubmit={handleSubmit(savePrecalculation)}>
                <BigUp.Buttons.Regular
                  expand='block'
                  color={'secondary'}
                  type="submit"
                  title={t('Create revision')}
                >
                </BigUp.Buttons.Regular>
              </form>
            </IonCol>

          </IonRow>
        </IonGrid>
        <div {...isDesktop && { style: { width: '60%' } }}>
          <div className='ion-padding-top ion-padding-end'>
            <SearchAndSortRow onSearch={setSearch} onSort={setSortBy} value={search}>
              <IonSelectOption value={'stamped_activity_code'}>{t('Activity Code')}</IonSelectOption>
              <IonSelectOption value={'stamped_activity_code_desc'}>{t('Activity Code (highest first)')}</IonSelectOption>
              <IonSelectOption value={'name'}>{t('Name (A-Z)')}</IonSelectOption>
              <IonSelectOption value={'name_desc'}>{t('Name (Z-A)')}</IonSelectOption>
              <IonSelectOption value={'created_at'}>{t('Created at (newest first)')}</IonSelectOption>
              <IonSelectOption value={'created_at_desc'}>{t('Created at (oldest first)')}</IonSelectOption>
              <IonSelectOption value={'updated_at'}>{t('Last modified')}</IonSelectOption>
              <IonSelectOption value={'updated_at_desc'}>{t('Last modified (most recent)')}</IonSelectOption>
            </SearchAndSortRow>
          </div>
          {isDesktop
            ? (
              <IonList>
                {<React.Fragment>{PreCalculationRowsComponentRender}</React.Fragment>}
              </IonList>
            )
            : (
              <IonList >
                {<React.Fragment>{PreCalculationRowsComponentRender}</React.Fragment>}
              </IonList>
            )
          }
        </div>
      </div>
      <IonModal
        className={styles['precalc-form-modal']}
        isOpen={isModalOpen}
        onIonModalDidDismiss={onModalDismiss}
      >
        <PrecalculationForm
          deletePrecalculationRow={deletePrecalculationRow}
          onDismiss={onModalDismiss}
          selectedRow={selectedPrecalculationRow}
          onRowSave={onRowSave}
        />
      </IonModal>
    </div>
  );
};
export default Precalculations;
