import { IonCheckbox, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonModal, IonRow, IonText } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import type { AccessRequestCertificate } from '@techlove/easy2use-typings/src/V1/Models/AccessRequestCertificate';
import { useTranslate } from '@tolgee/react';
import { arrowForward, document } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import CertificationFiles from './CertificationFiles';
import OnboardingFormSummaryCertificates from './OnboardingFormSummaryCertificates';
import OnboardingFormSummaryRows from './OnboardingFormSummaryRows';
import { networking } from '../../../../../../api/networking';
import StepButtons from '../../../../../../components/Toolbar/StepButtons';
import { useAppSelector } from '../../../../../../hooks';
import modalStyle from '../../../containers/styles/ModalContainers.module.scss';
import OnboardingHeader from '../../../header/FormHeaderTitle';
import OnboardingFinish from '../../../modals/OnboardingFinish';
import styles from '../../styles/OnboardingFormSummary.module.scss';

interface PersonOnboardingSummary {
  personalContactInfo: string;
  value: string | undefined;
}
interface CertificateSummary {
  workName: string;
  checked: boolean
}

const OnboardingFormSummary: React.FC = () => {
  const { t } = useTranslate();
  const { onboardingUuid } = useParams<{ onboardingUuid: string | undefined }>();

  const [temporaryModal, setTemporaryModal] = useState(false);
  const [allCertificates, setAllCertificates] = useState<Partial<E2U.V1.Models.AccessRequestCertificate>[]>([]);

  const onboardingStageOne: Partial<E2U.V1.Objects.ProjectAccessRequest> = useAppSelector(
    store => store.onboarding.onboardingStageOne
  );
  const onboardingStageTwo: Partial<E2U.V1.Objects.ProjectAccessRequest> = useAppSelector(
    store => store.onboarding.onboardingStageTwo
  );
  const onboardingStageThree: Partial<E2U.V1.Objects.ProjectAccessRequest> = useAppSelector(
    store => store.onboarding.onboardingStageThree
  );
  const projectFiles = useAppSelector(
    (state) => state.onboarding.projectFiles
  );

  const personInformationSummary: PersonOnboardingSummary[] = [
    { personalContactInfo: t('ID-number'), value: onboardingStageOne.personal_number },
    { personalContactInfo: t('First name'), value: onboardingStageOne.first_name },
    { personalContactInfo: t('Last name'), value: onboardingStageOne.last_name },
    { personalContactInfo: t('Company'), value: onboardingStageOne.company_name },
    { personalContactInfo: t('Org.nr'), value: onboardingStageOne.organization_number }
  ];

  const certificationSummary: CertificateSummary[] = [
    { workName: t('Hot work'), checked: onboardingStageTwo.hot_work! },
    { workName: t('Heavy lifting'), checked: onboardingStageTwo.heavy_lifting! },
    { workName: t('Work from lift'), checked: onboardingStageTwo.work_from_lift! }
  ];

  const toggle = () => {
    setTemporaryModal(!temporaryModal);
  };

  const mapCertificates = (certificates?: Partial<AccessRequestCertificate>[]) => {
    if (!certificates) return [];
    return certificates.map(d => {
      return {
        ...d,
        id: typeof d?.file_id !== 'undefined' ? d.file_id : d?.id
      };
    });
  };

  const formatFileIds = (data: Partial<E2U.V1.Objects.ProjectAccessRequest>) => {
    return {
      ...data,
      hot_work_certificates: mapCertificates(data.hot_work_certificates),
      work_from_lift_certificates: mapCertificates(data.work_from_lift_certificates),
      hot_works_certificates: mapCertificates(data.hot_work_certificates)
    };
  };

  const postOnboarding = () => {
    const stateData = {
      ...onboardingStageOne,
      ...formatFileIds(onboardingStageTwo),
      ...onboardingStageThree
    };

    networking.post(`/api/v1/projects/${onboardingUuid}/access_requests/submit`, stateData)
      .then(() => {
        toggle();
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
      });
  };

  useEffect(() => {
    setAllCertificates(
      [
        ...onboardingStageTwo.heavy_lifting_certificates ?? [],
        ...onboardingStageTwo.hot_work_certificates ?? [],
        ...onboardingStageTwo.work_from_lift_certificates ?? [],
        ...onboardingStageTwo.other_certificates ?? []
      ]
    );
  }, [
    onboardingStageTwo.heavy_lifting_certificates,
    onboardingStageTwo.hot_work_certificates,
    onboardingStageTwo.work_from_lift_certificates,
    onboardingStageTwo.other_certificates
  ]);

  return (
    <>
      <OnboardingHeader title={t('Control and confirm your details')} />
      <IonGrid className={styles['onboarding-summary-container']}>
        <IonRow >
          <IonCol size='10' className={styles['section-summary-title']}>
            <IonItem className='ion-no-padding'>
              <h6>{t('Personal and contact information')}</h6>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonList>
          <div className={styles['personal-information-list']}>
            {personInformationSummary.map((information, i) => {
              return (
                <OnboardingFormSummaryRows
                  key={i}
                  personalContactInfo={information.personalContactInfo}
                  value={information.value}
                />
              );
            })}
          </div>
        </IonList>

        <IonRow className="ion-text-left">
          <IonCol size='10' className={styles['section-summary-title']}>
            <IonItem className='ion-no-padding'>
              <h6>{t('Confirmation of educational certification')}</h6>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonList className={styles['certificate-section']}>
          {certificationSummary.map((certificate, i) => {
            return (
              <OnboardingFormSummaryCertificates
                key={i}
                checked={certificate.checked}
                workName={certificate.workName}
              />
            );
          })}

          <IonRow className="ion-text-left">
            <IonCol size='10' className={styles['section-summary-title']}>
              <IonItem className='ion-no-padding'>
                <h6>{t('Certificates')}</h6>
              </IonItem>
            </IonCol>
          </IonRow>

          {allCertificates.length > 0
            ? (allCertificates.map((certificate: any, index: number) => {
              return (certificate.filename || (certificate.file && certificate.file.name))
                ? <CertificationFiles key={index} filename={certificate.filename || certificate.file.name} />
                : null;
            }))
            : (
              <div className='ion-margin-bottom'>
                <IonText color='medium' className='ion-margin-bottom'>
                  <h3>{t('No uploaded certificates')}</h3>
                </IonText>
              </div>
            )
          }
        </IonList>

        <IonRow className="ion-text-left">
          <IonCol size='10' className={styles['section-summary-title']}>
            <IonItem className='ion-no-padding'>
              <h6>{t('Confirmation of understanding of the project files')}</h6>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonList>
          {projectFiles.map((e, i) => {
            return (
              <IonItem className='ion-no-padding' key={i}>
                <IonRow className='ion-align-items-center'>
                  <IonCol size='1'>
                    <IonIcon icon={document} size="large" color='success' />
                  </IonCol>
                  <IonCol size="10">
                    <IonLabel className='ion-text-left'>
                      {e.name}
                    </IonLabel>
                  </IonCol>
                  <IonCol size='1' className='ion-text-right'>
                    <IonCheckbox disabled={true} checked={true} />
                  </IonCol>
                </IonRow>
              </IonItem>
            );
          })}
        </IonList>
      </IonGrid>
      <StepButtons
        leftTitle={t('previous')}
        leftIonColor={'none'}
        leftOnClick={() => history.go(-1)}
        rightTitle={t('submit')}
        rightSubmit={'submit'}
        rightIonColor={'secondary'}
        rightOnClick={() => postOnboarding()}
        rightIcon={arrowForward}
      />

      <IonModal isOpen={temporaryModal} className={modalStyle['onboarding-finished-modal']}>
        <OnboardingFinish toggle={toggle} />
      </IonModal>
    </>
  );
};

export default OnboardingFormSummary;
