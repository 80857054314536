import { IonCol, IonRow } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { arrowForward } from 'ionicons/icons';
import React from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { FORM_VALUES } from './formlist';
import StepButtons from '../../../../../../components/Toolbar/StepButtons';
import { BigUp } from '../../../../../../components/UI';
import { useAppSelector } from '../../../../../../hooks';
import { setOnboardingStageOne } from '../../../../../../reducers/onboarding';
import store from '../../../../../../store';
import type { OnboardingInterface } from '../../../../interfaces/Onboarding.interface';
import OnboardingHeader from '../../../header/FormHeaderTitle';
import styles from '../../styles/OnboardingStageOne.module.scss';

const OnboardingStageOne: React.FC<OnboardingInterface> = (props) => {
  const { t } = useTranslate();
  const onboarding = useAppSelector(store => store.onboarding.onboardingStageOne);
  const user = useAppSelector(store => store.authentication.user);
  const history = useHistory();
  const nextStep = () => {
    history.push('./certificates');
  };

  const methods = useForm<FieldValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      personal_number: onboarding.personal_number,
      first_name: onboarding.first_name || user?.first_name || '',
      last_name: onboarding.last_name || user?.last_name || '',
      company_name: onboarding.company_name,
      organization_number: onboarding.organization_number,
    }
  });

  const submitForm: SubmitHandler<FieldValues> = (data: FieldValues) => {
    store.dispatch(setOnboardingStageOne(data));
  };

  return (
    <React.Fragment>
      <OnboardingHeader title={t('Personal and contact information')} />
      <div className={styles['onboarding-stage-one']}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submitForm)}>
            {FORM_VALUES.map((input, i) => {
              return (
                <React.Fragment key={i}>
                  <IonRow>
                    <IonCol size="12">
                      <BigUp.Input labelPlacement='stacked' {...input} />
                    </IonCol>
                  </IonRow>
                </React.Fragment>
              );
            })}

            <StepButtons
              leftTitle={t('cancel')}
              leftIonColor={'none'}
              leftOnClick={() => props.toggleOnboarding!()}
              rightTitle={t('next')}
              rightIonColor={'secondary'}
              rightDisabled={!methods.formState.isDirty && !methods.formState.isValid}
              rightOnClick={() => nextStep()}
              rightIcon={arrowForward}
              rightSubmit={'submit'}
            />
          </form>
        </FormProvider>
      </div>

    </React.Fragment>

  );
};

export default OnboardingStageOne;
