import { IonCol, IonGrid, IonIcon, IonItem, IonLoading, IonRow } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { chevronBack, mail } from 'ionicons/icons';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router';

import styles from './PasswordResetForm.module.scss';
import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';
import { regex } from '../../../regex/regex';
import store from '../../../store';

const PasswordResetForm: React.FC = () => {
  const { t } = useTranslate();
  const isResettingPassword: boolean = useAppSelector((state) => state.loading.isLoading.resettingPassword);
  const history = useHistory();
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange'
  });

  const handleFormSubmit: SubmitHandler<FieldValues> = (data) => {
    store.dispatch(setIsLoading({ name: 'resettingPassword', value: true }));
    networking.post('/api/v1/users/reset_password/request', data)
      .then(() => {
        toasters.createToast({
          message: t('If the email is registered, a reset link has been sent to your email.'),
          background: 'var(--ion-color-light)',
          icon: mail
        }, 'success');
      })
      .catch(() => {
        toasters.createToast({
          message: t('An error occurred, try again later.'),
          background: 'var(--ion-color-danger)',
        }, 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'resettingPassword',
          value: false
        }));
      });
  };

  return (
    <section className={styles['password-reset-form']}>
      {isResettingPassword
        ? <IonLoading isOpen={true} />
        : (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
              <IonGrid className='ion-padding'>
                <IonRow className='ion-margin-vertical'>
                  <IonCol>
                    <IonItem className='ion-no-padding' lines={'none'} type={'button'} onClick={() => history.push('/login')}>
                      <IonIcon className={'ion-no-margin'} aria-hidden="true" icon={chevronBack} slot="start" />
                      <BigUp.Label.Regular className={'ion-no-margin'} label={t('Back to login')} />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-align-items-center ion-margin ion-padding-top' >
                  <IonCol>
                    <BigUp.Input
                      register={'email'}
                      disabled={methods.formState.isSubmitting}
                      label={t('Email')}
                      placeholder={t('Enter email')}
                      name='email'
                      type='email'
                      validation={{
                        required: t('Email is required'),
                        pattern: {
                          value: regex.regex_email, message: t('Must match format')
                        }
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow className='ion-justify-content-center ion-align-items-center ion-margin'>
                  <IonCol size='12' className='ion-text-end'>
                    <BigUp.Buttons.Regular
                      title={t('Reset password')}
                      type={'submit'}
                      disabled={!methods.formState.isValid || methods.formState.isSubmitting}
                      color={'secondary'}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </FormProvider>
        )
      }
    </section>
  );
};

export default PasswordResetForm;
