import type { Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface WorkingEnvironmentReducer{
    workingEnvironmentItems:E2U.V1.Models.WorkingEnvironmentItem[] | any;
    isSelected:E2U.V1.Models.WorkingEnvironmentItem | any;
}

export const workingEnvironmentSlice = createSlice<WorkingEnvironmentReducer, SliceCaseReducers<WorkingEnvironmentReducer>, string>({
  name: 'workingEnvironment',
  initialState: {
    workingEnvironmentItems: [],
    isSelected: '',
  },
  reducers: {
    setWorkingEnvironment: (
      state:Draft<WorkingEnvironmentReducer>,
      action: PayloadAction<E2U.V1.Models.WorkingEnvironmentItem[]>
    ) => {
      state.workingEnvironmentItems = action.payload;
      state.isSelected = action.payload;
    }
  }
});

export const { setWorkingEnvironment } = workingEnvironmentSlice.actions;

export default workingEnvironmentSlice.reducer;
