import { IonContent, IonIcon } from '@ionic/react';
import { closeCircleSharp, warningSharp } from 'ionicons/icons';
import React from 'react';

import type { Props } from './Alarm';
import styles from './AlarmModalContent.module.scss';
import StepButtons from '../Toolbar/StepButtons';

const AlarmModalContent: React.FC<Props> = ({ alert, buttonLeft, buttonRight, colour, modal, texts }) => {
  return (
    <IonContent className={styles.alarmContentWrapper}>
      <IonIcon
        role="button"
        icon={closeCircleSharp}
        color={colour.iconCloseColour || 'light'}
        onClick={modal.modalDismiss}
        className={styles.closeIcon}
      />
      <div className={styles.alarmContainer}>
        <section className={styles.sectionTopContainer} style={{ background: colour.backgroundColour }}>
          <div className={styles.topContainerInner}>
            <IonIcon icon={alert?.alertIcon || warningSharp} color='light' />
            <h2 className="ion-no-margin">{texts.alertTitle}</h2>
            <h3 className="ion-no-margin">{texts.alertSubtitle}</h3>
          </div>
        </section>
        <section className={styles.sectionBottomContainer}>
          <h4>{texts.contentText}</h4>
        </section>
      </div>
      <section className={styles.bottomButtonsContainer}>
        <StepButtons
          rightButtonTextColour={colour.buttonRightColour}
          leftTitle='Cancel'
          leftIonColor={buttonLeft.leftIonColour}
          leftOnClick={modal.modalDismiss}
          rightTitle='Lock'
          rightIonColor={buttonRight.rightIonColour}
          {...buttonRight.shouldSubmit && {
            rightSubmit: 'submit'
          }}
          rightOnClick={buttonRight.shouldSubmit ? () => { } : buttonRight.rightOnClick}
        />
      </section>
    </IonContent>
  );
};
export default AlarmModalContent;
