import React from 'react';

import styles from './styles/Curve.module.scss';

const Curve: React.FC = (props) => {
  const { children } = props;

  return (
    <>
      <div id={styles['curve-box']}>
        <div id={styles['curve-top']} />
        {children}
      </div>
    </>
  );
};

export default Curve;
