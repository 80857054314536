import type {
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import {
  createSlice
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface EconomyMonitoringReducer {
    economyMonitoring: E2U.V1.Models.NotPosted | undefined;
    economyMonitoringRow: E2U.V1.Models.NotPostedRow | undefined;
    changeTile: E2U.V1.Models.Address | undefined;
    deviationTile: E2U.V1.Models.Address | undefined;
    standardTile: E2U.V1.Models.Address | undefined;
    selectedIndex: number;
  }

export const economyMonitoringSlice = createSlice<
  EconomyMonitoringReducer,
    SliceCaseReducers<EconomyMonitoringReducer>,
    string
  >({
    name: 'economyMonitoring',
    initialState: {
      economyMonitoring: undefined,
      economyMonitoringRow: undefined,
      changeTile: undefined,
      deviationTile: undefined,
      standardTile: undefined,
      selectedIndex: 0
    },

    reducers: {
      setEconomyMonitoring: (
        state: EconomyMonitoringReducer,
        action: PayloadAction<E2U.V1.Models.NotPosted | undefined>
      ) => {
        state.economyMonitoring = action.payload;
      }
    }
  });

export const {
  changeTile,
  deviationTile,
  economyMonitoring,
  economyMonitoringRows,
  setEconomyMonitoring,
  standardTile
} = economyMonitoringSlice.actions;

export default economyMonitoringSlice.reducer;
