
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonLoading,
  IonPage
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { appsOutline, arrowBack, pencil, trash, trashBin } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import Detail from './Detail';
import Edit from './Edit';
import { networking } from '../../../../../api/networking';
import PageMenuHeader from '../../../../../components/PageMenuHeader/PageMenuHeader';
import ReportsCard from '../../../../../components/ReportsCard';
import Toast from '../../../../../components/Toasts/Toast';
import { useAppSelector } from '../../../../../hooks';
import { setIsLoading } from '../../../../../reducers/loading';
import { setSelectedObservation } from '../../../../../reducers/reports';

const ObservationReport: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslate();
  const { observation_uuid } = useParams<{
    observation_uuid: string | undefined;
  }>();
  const store = useStore();
  const isLoadingObservation: boolean = useAppSelector(
    (state) => state.loading.isLoading.observation
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const fetchObservation = () => {
    if (typeof observation_uuid === 'undefined' || observation_uuid === '') {
      Toast(t('No projects found'), 'error');
      history.push('/projects');
      return;
    }
    store.dispatch(setIsLoading({ name: 'observation', value: true }));
    networking
      .get(`/api/v1/observation_reports/${observation_uuid}?with=files`)
      .then(
        (
          response: E2U.V1.Response.Success<E2U.V1.Models.ObservationReport>
        ) => {
          store.dispatch(setSelectedObservation(response.data.data));
        }
      )
      .catch((error: E2U.V1.Response.Error) => {
        console.warn('Error while loading observation', error);
        Toast(t('Observation not found'), 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'observation', value: false }));
      });
  };

  const onSave = () => {
    setIsEditing(false);
    fetchObservation();
  };

  useEffect(() => {
    fetchObservation();
    return () => {
      store.dispatch(setSelectedObservation(null));
    };
  }, []);
  return (
    <IonPage>
      <PageMenuHeader />

      <IonContent>
        {isLoadingObservation
          ? (
            <IonLoading isOpen />
          )
          : isEditing
            ? (
              <ReportsCard
                title={t('Observation edit')}
                iconRight={trash}
                iconLeft={arrowBack}
              >
                <Edit onSave={onSave}></Edit>
              </ReportsCard>
            )
            : (
              <ReportsCard
                title={t('Observation detail')}
                iconRight={trash}
                iconLeft={arrowBack}
              >
                <Detail
                  onUpdateObservation={fetchObservation}
                  onOpenEdit={() => {
                    setIsEditing(true);
                  }}
                />
              </ReportsCard>
            )}

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon icon={appsOutline}></IonIcon>
          </IonFabButton>
          <IonFabList side="start">
            <IonFabButton
              color="warning"
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <IonIcon icon={pencil}></IonIcon>
            </IonFabButton>
            <IonFabButton color="danger">
              <IonIcon icon={trashBin}></IonIcon>
            </IonFabButton>
          </IonFabList>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default ObservationReport;
