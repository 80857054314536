import { useTranslate } from '@tolgee/react';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';

import { useAppSelector } from '../../hooks';
import AppLayout from '../../layout/AppLayout';
import MenuLessAppLayout from '../../layout/MenuLessAppLayout';

const ClickupForm: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslate();

  const user = useAppSelector((state) => state.authentication.user);
  const Wrapper = useMemo(() => {
    return (user && user.id) ? AppLayout : MenuLessAppLayout;
  }, [user]);

  return (
    <Wrapper title={t('Report error')}>
      <iframe src={`${process.env.REACT_APP_CLICKUP_REPORT_FORM}`} style={{ width: '100%', height: '90vh', border: 'none' }} />
    </Wrapper>
  );
};

export default ClickupForm;
