import { IonCol, IonContent, IonGrid, IonItem, IonLabel, IonPopover, IonRow } from '@ionic/react';
import classNames from 'classnames';
import React, { useEffect } from 'react';

import styles from './CashFlowRows.module.scss';
import { useAppSelector } from '../../../../../hooks';
import formatNumber from '../../../../../tools/formatNumber';

interface DifferenceProps {
  label: string;
  value: number;
  difference: number | null;
  id: 'project-management' | 'net-cost' | 'fee' | 'total' | '';
  refetch?: () => void;
}

const DifferenceBadge: React.FC<DifferenceProps> = (props) => {
  const [triggerHandler, setTriggerHandler] = React.useState<boolean | string>(false);
  const [value, setValue] = React.useState<string>('');
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);

  const triggerPopover = (id: string) => {
    setTriggerHandler(prevHandler => prevHandler === id ? false : id);
  };
  const roundedDifference = Math.round(parseInt(props.difference?.toString() ?? '0'));

  const updateValue = () => {
    if (props.difference === null) {
      return;
    }
    const formatDifference = formatNumber(props.difference);

    const differenceMap = new Map([
      [roundedDifference > 0, isDesktop ? `${formatDifference}` : '+'],
      [roundedDifference < 0, isDesktop ? `${formatDifference}` : '-'],
      [roundedDifference === 0, isDesktop ? `0` : '='],
    ]);
    setValue(differenceMap.get(true) ?? '');
  };

  const badgeColumnSize = () => {
    if (props.difference !== null && isDesktop) {
      return '8';
    } else {
      return '10';
    }
  };

  useEffect(() => {
    updateValue();
  }, [props.difference, isDesktop]);

  return (
    <React.Fragment>
      <IonLabel className='ion-no-margin'>
        {props.label}
      </IonLabel>
      <IonItem className='ion-no-padding'>
        <IonGrid>
          <IonRow className='ion-align-items-center ion-justify-content-between'>
            <IonCol size={badgeColumnSize()} className='ion-text-left'>
              <p className='ion-no-margin ion-text-left'>
                {formatNumber(props.value)}
              </p>
            </IonCol>
            {props.difference !== null && (
              <IonCol
                size={isDesktop ? '4' : '2'}
                className={classNames(['ion-text-left', styles.difference])}
                style={{
                  cursor: 'pointer',
                  backgroundColor: roundedDifference === 0 || null
                    ? 'var(--ion-color-success-tint)'
                    : 'var(--ion-color-danger-tint)',
                }}
              >
                <p
                  role='button'
                  {...value !== '=' && { onClick: () => triggerPopover(props.id) }}
                  {...props.difference !== null && { id: props.id }}
                  className={'ion-no-margin'}>
                  <b>{value}</b>
                </p>

                {props.difference !== null && (
                  <IonPopover trigger={props.id} isOpen={triggerHandler === props.id} onDidDismiss={() => setTriggerHandler(false)}>
                    <IonContent>
                      <p className='ion-text-center'>
                        {formatNumber(props.difference)}
                      </p>
                    </IonContent>
                  </IonPopover>
                )
                }
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonItem>
    </React.Fragment>
  );
};

export default DifferenceBadge;
