import { useState } from 'react';

export interface UseToggleHook {
  isToggled: boolean;
  toggleState: () => void;
  toggleFalse: () => void;
  toggleTrue: () => void;
  id: string;
  defaultState?: boolean | undefined
}

const useToggle = (id: string, defaultState?: boolean | undefined): UseToggleHook => {
  const [isToggled, setIsToggled] = useState(defaultState || false);

  const toggleTrue = () => {
    setIsToggled(true);
  };

  const toggleFalse = () => {
    setIsToggled(false);
  };

  const toggleState = () => {
    setIsToggled(prevState => !prevState);
  };

  return {
    defaultState,
    isToggled,
    toggleTrue,
    toggleFalse,
    toggleState,
    id
  };
};

export default useToggle;
