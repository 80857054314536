import type { Swiper as SwiperInterface } from 'swiper';

class SwiperController {
  protected totalIndexes = 0;
  protected currentIndex = 0;
  protected slidesPerView = 0;
  protected swipers: SwiperInterface[] = [];
  protected currentIndexHooks: ((currentIndex: number) => void)[] = [];
  protected inProgressSwipers: SwiperInterface[] = [];

  constructor (totalIndexes = 0) {
    this.setTotalIndexes(totalIndexes);
  }

  setSlidesPerView (slidesPerView: number) {
    this.slidesPerView = slidesPerView;
  }

  getSlidesPerView ():number {
    return this.slidesPerView;
  }

  setTotalIndexes (totalIndexes: number) {
    this.totalIndexes = totalIndexes;
  }

  getTotalIndexes () {
    return this.totalIndexes;
  }

  onSlideChange (swiper: SwiperInterface) {
    this.goTo(swiper.activeIndex);
  }

  addSwiper (swiper: SwiperInterface) {
    swiper.on('activeIndexChange', (swiper) => {
      this.onSlideChange(swiper);
    });
    swiper.on('beforeSlideChangeStart', () => {
      this.beforeSlide(swiper);
    });
    swiper.on('transitionEnd', () => {
      this.afterSlide(swiper);
    });

    this.swipers.push(swiper);
    this.updateSliders();
  }

  beforeSlide (swiper: SwiperInterface) {
    this.inProgressSwipers.push(swiper);
  }

  afterSlide (swiper: SwiperInterface) {
    const index = this.inProgressSwipers.findIndex(
      (existingSwiper: SwiperInterface) => {
        return swiper === existingSwiper;
      }
    );

    this.inProgressSwipers.splice(index, 1);
  }

  isSliding (swiper: SwiperInterface) {
    const index = this.inProgressSwipers.findIndex(
      (existingSwiper: SwiperInterface) => {
        return swiper === existingSwiper;
      }
    );
    return index !== -1;
  }

  removeSwiper (swiper: SwiperInterface) {
    const index = this.swipers.findIndex((existingSwiper: SwiperInterface) => {
      return swiper === existingSwiper;
    });

    this.swipers.splice(index, 1);
  }

  updateSlider (slider: SwiperInterface) {
    if (!this.isSliding(slider) && !slider.destroyed) {
      slider.slideTo(this.currentIndex);
    }

    this.updateHooks();
  }

  recalcSlides () {
    this.swipers.map((swiper: SwiperInterface) => {
      return (
        this.recalcSlidesForSwiper(swiper)
      );
    });
  }

  recalcSlidesForSwiper (swiper: SwiperInterface) {
    swiper.update();
  }

  updateSliders () {
    this.swipers.map((swiper: SwiperInterface) => {
      return this.updateSlider(swiper);
    });
  }

  addIndexHook (hook: (index: number) => void) {
    this.currentIndexHooks.push(hook);
  }

  updateHooks () {
    this.currentIndexHooks.map((hook) => {
      return hook(this.getCurrentIndex());
    });
  }

  nextSlide () {
    this.currentIndex++;
    this.updateSliders();
  }

  previousSlide () {
    this.currentIndex--;
    this.updateSliders();
  }

  isAtStart () {
    return this.currentIndex === 0;
  }

  isAtEnd () {
    return Math.max(...this.swipers.map((swiper: SwiperInterface) => {
      return (swiper.isEnd ? 1 : 0);
    })
    );
  }

  slidesIsAtEnd (slides: number) {
    return this.currentIndex === this.totalIndexes - slides + 1;
  }

  goTo (index: number) {
    this.currentIndex = index;
    this.updateSliders();
  }

  goToStart () {
    this.goTo(0);
  }

  goToEnd () {
    this.goTo(this.getTotalIndexes());
  }

  getCurrentIndex () {
    return this.currentIndex;
  }
}

export default SwiperController;
