// import type { ToastOptions } from 'react-toastify';
import { IonIcon } from '@ionic/react';
import type { ToastOptions } from 'react-toastify';
import { toast } from 'react-toastify';

import type { PromiseMessages } from './ToastsInterface';
import type { IonicThemeColours } from '../UI/variables';

const toastConfig: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

const toasters = {
  promise: (promise: Promise<any>, messages: PromiseMessages
  ): Promise<any> => {
    return toast.promise(promise, messages, toastConfig);
  },

  success: (message: string, icon?: HTMLIonIconElement['icon'], color?: IonicThemeColours) => {
    toast.success(`${message}`, {
      ...toastConfig,
      icon: icon && <IonIcon icon={icon} color={color} />
    });
  },

  error: (message: string, icon?: HTMLIonIconElement['icon'], color?: IonicThemeColours) => {
    toast.error(`${message}`, {
      ...toastConfig,
      icon: icon && <IonIcon icon={icon} color={color} />
    });
  },

  info: (message: string, icon?: HTMLIonIconElement['icon'], color?: IonicThemeColours) => {
    toast.info(`${message}`, {
      ...toastConfig,
      icon: icon && <IonIcon icon={icon} color={color} />
    });
  },
  warning: (message: string, icon?: HTMLIonIconElement['icon'], color?: IonicThemeColours) => {
    toast.warning(`${message}`, {
      ...toastConfig,
      icon: icon && <IonIcon icon={icon} color={color} />
    });
  },
};

export default toasters;
