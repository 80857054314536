import { createAnimation } from '@ionic/react';
import { useRef } from 'react';

const useCustomModalAnimations = () => {
  const modal = useRef<HTMLIonModalElement>(null);

  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const modalWrapper = root?.querySelector('.modal-wrapper');
    const modalBackdrop = root?.querySelector('ion-backdrop');

    const keyframes = [
      { offset: 0, opacity: '.1', marginLeft: '-15px' },
      { offset: 1, opacity: '1', margin: '0' }
    ];

    if (root && modalWrapper && modalBackdrop) {
      const backdropAnimation = createAnimation()
        .addElement(modalBackdrop)
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = createAnimation()
        .addElement(modalWrapper)
        .keyframes(keyframes);

      return createAnimation()
        .addElement(baseEl)
        .easing('ease-in-out')
        .duration(400)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    }
    return createAnimation();
  };

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction('reverse');
  };

  const dismiss = () => {
    modal.current?.dismiss();
  };

  return {
    modal,
    enterAnimation,
    leaveAnimation,
    dismiss
  };
};

export default useCustomModalAnimations;
