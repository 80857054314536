import { Capacitor } from '@capacitor/core';
import type { ActionPerformed, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import { IonAlert } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import React, { useEffect, useState } from 'react';

import { networking } from './api/networking';
import { useAppSelector } from './hooks';
import { setCountPollerInterval, setIsUpdatingCount, setShouldUpdateCount } from './reducers/authentication';
import storage from './storage';
import store from './store';

const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

interface ContainerProps {
}

const NotificationListener: React.FC<ContainerProps> = (props) => {
  const [showNetworkErrorAlert, setShowNetworkErrorAlert] = useState<boolean>(false);
  const [allowNotifications, setAllowNotifications] = useState<boolean>(false);
  const request_notifications = useAppSelector(store => store.notification.request_notifications);
  const shouldUpdateCount = useAppSelector(state => state.authentication.should_update_count);
  const countPollerInterval = useAppSelector(state => state.authentication.count_poller_interval);
  const isUpdatingCount = useAppSelector(state => state.authentication.is_updating_count);
  const { t } = useTranslate();

  const getUnreadCount = (forced = false) => {
    if (isUpdatingCount && !forced) {
      return;
    }
    if (typeof countPollerInterval !== 'undefined') {
      clearTimeout(countPollerInterval);
      store.dispatch(setCountPollerInterval(undefined));
    }
    store.dispatch(setIsUpdatingCount(true));
    /* Get the unread count networking.get('/user/unread').then((response: GetUserUnreadCount) => {
            store.dispatch(setUnreadCount(response.data.count));
        }).finally(() => {
            store.dispatch(setIsUpdatingCount(false));
            let pollerTimeout = setTimeout(() => getUnreadCount(), 7500);
            store.dispatch(setCountPollerInterval(pollerTimeout));
        }) */
  };

  useEffect(() => {
    if (shouldUpdateCount) {
      getUnreadCount(true);
      store.dispatch(setShouldUpdateCount(false));
    }
  }, [shouldUpdateCount]);

  const requestPermission = () => {
    console.log('Push notification permissions requested');
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive) {
        if (permission.receive === 'granted') {
          setUpNotifications();
        }
      } else {
        setAllowNotifications(true);
      }
    });
  };

  const setUpNotifications = () => {
    console.log('Setup push notifications');
    PushNotifications.register();
    PushNotifications.addListener('registration', (token: Token) => {
      storage.set('push_token', token.value);
      networking.post('/api/v1/me/device_tokens/register', {
        type: 'apn',
        token: token.value
      })
        .then((response) => {
          console.log('RESPONSE: ', response);
        })
        .catch((error) => {
          console.log('ERROR: ', error);
        });
    });
    // Some issue with your setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('registrationError', error);
    });
    // When message is received
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.log('pushNotificationReceived', notification);
    });
    // When notification is clicked
    PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
      console.log('pushNotificationssActionPerformed', action);
    });
  };

  const checkNotifications = (initial = false) => {
    if (isPushNotificationsAvailable && PushNotifications) {
      // registering for push notifications
      PushNotifications.checkPermissions().then((PermissionStatus) => {
        if (PermissionStatus.receive === 'granted') {
          setUpNotifications();
        } else if (PermissionStatus.receive === 'prompt') {
          if (!initial) requestPermission();
        }
        console.log('PermisssionStatuss', PermissionStatus);
      });
    }
  };
  console.log('checkNotificaftsions', isPushNotificationsAvailable, PushNotifications);

  useEffect(() => {
    if (PushNotifications && request_notifications) {
      checkNotifications(true);
    }
  }, []);

  useEffect(() => {
    // setUpNotifications();
    // checkNotifications(true);
    console.log('TOKEN', storage.get('push_token'));
    getUnreadCount();
    return () => {
      if (typeof countPollerInterval !== 'undefined') {
        clearTimeout(countPollerInterval);
        store.dispatch(setCountPollerInterval(undefined));
      }
    };
  }, []);

  return (
    <React.Fragment>
      <IonAlert
        isOpen={showNetworkErrorAlert}
        onDidDismiss={() => setShowNetworkErrorAlert(false)}
        header={t('An error occured')}
        subHeader={t('Check your internet connection')}
        message={('This could be due to a network error. Please try again later.')}
        buttons={[t('OK')]}
      />
      <IonAlert
        isOpen={allowNotifications}
        header={t('Notifications')}
        message={t('In order to use all functionality, you need to allow notifications')}
        buttons={[
          {
            text: t('Cancel'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => setAllowNotifications(false)
          },
          {
            text: t('Approve'),
            handler: () => requestPermission()
          }
        ]}
      />
    </React.Fragment>
  );
};

export default NotificationListener;
