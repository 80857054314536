import { IonBreadcrumbs } from '@ionic/react';

const BreadcrumbsWrapper: React.FC = ({ children }) => {
  return (
    <IonBreadcrumbs className=' ion-no-margin ion-padding-top ion-no-padding' >
      {children}
    </IonBreadcrumbs>
  );
};

export default BreadcrumbsWrapper;
