import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface ReportsReducer {
  selectedObservation: E2U.V1.Models.ObservationReport | undefined;
  selectedIncident: E2U.V1.Models.IncidentReport | undefined;
  observations: E2U.V1.Models.ObservationReport[] | undefined;
  incidents: E2U.V1.Models.IncidentReport[] | undefined;
}

export const reportsSlice = createSlice<ReportsReducer,
  SliceCaseReducers<ReportsReducer>,
  string>({
    name: 'project',
    initialState: {
      selectedObservation: undefined,
      selectedIncident: undefined,
      observations: undefined,
      incidents: undefined
    },
    reducers: {
      setSelectedObservation: (
        state: Draft<ReportsReducer>,
        action: PayloadAction<E2U.V1.Models.ObservationReport | undefined>
      ) => {
        state.selectedObservation = action.payload;
      },
      setSelectedIncident: (
        state: Draft<ReportsReducer>,
        action: PayloadAction<E2U.V1.Models.IncidentReport | undefined>
      ) => {
        state.selectedIncident = action.payload;
      },
      setObservations: (
        state: Draft<ReportsReducer>,
        action: PayloadAction<E2U.V1.Models.ObservationReport[] | undefined>
      ) => {
        state.observations = action.payload;
      },
      setIncidents: (
        state: Draft<ReportsReducer>,
        action: PayloadAction<E2U.V1.Models.IncidentReport[] | undefined>
      ) => {
        state.incidents = action.payload;
      }
    }
  });

export const {
  setIncidents,
  setObservations,
  setSelectedIncident,
  setSelectedObservation
} = reportsSlice.actions;

export default reportsSlice.reducer;
