import { IonCol, IonGrid, IonInput, IonItem, IonRow } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { useFormContext } from 'react-hook-form';

import InputLabel from '../../../components/UI/InputLabel';
import { ValidationBadge } from '../../../components/UI/Inputs/ValidationBadge';
import { useAppSelector } from '../../../hooks';

const TeamInputs: React.FC = () => {
  const { t } = useTranslate();
  const methods = useFormContext();
  const submitting = methods.formState.isSubmitting;
  const team = useAppSelector(state => state.team.selectedTeam);

  const validation = {
    required: { value: true, message: t('Team name is required') },
    maxLength: { value: 50, message: t('Team name can\'t be longer than 50 characters') },
    minLength: { value: 3, message: t('Team name must be at least 3 characters') },
  };

  return (
    <IonGrid className='ion-no-padding'>
      <IonRow>
        <IonCol>
          <IonItem className='ion-no-padding'>
            <InputLabel stacked >{t('Team name')}</InputLabel>
            <IonInput
              type={'text'}
              disabled={submitting}
              {...team && { value: team.name }}
              placeholder={t('Enter name of team')}
              {...methods.register('name', { ...validation })}
            />
          </IonItem>
          {methods.formState.errors.name && (
            <ValidationBadge marginLeft>
              {methods.formState.errors.name?.message}
            </ValidationBadge>
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default TeamInputs;
