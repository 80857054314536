import { IonDatetime, IonDatetimeButton, IonItem, IonModal } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import { findDefaultDates } from './defaults';
import { type DateIDs, type DateTimeContentProps, type DatetimeRegisterReturn } from './form.interface';
import InputLabel from '../../../components/UI/InputLabel';

const NoticePublishHandling: React.FC = () => {
  const { t } = useTranslate();
  return (
    <>
      <div className='ion-margin-bottom'>
        <InputLabel margin={'ion-no-margin'} stacked>{t('Publish date')}</InputLabel>
        <IonItem className='ion-no-padding' lines='none'>
          <IonDatetimeButton slot='start' datetime="notice-publish" className='ion-margin-top' />
        </IonItem>
      </div>
      <div className='ion-margin-bottom'>
        <InputLabel margin={'ion-no-margin'} stacked>{t('Unpublish date')}</InputLabel>
        <IonItem className='ion-no-padding' lines='none'>

          <IonDatetimeButton datetime="notice-unpublish" className='ion-margin-top' />
        </IonItem>
      </div>
      <IonModal keepContentsMounted={true}>
        <DateTimeContent id='notice-publish' dateRegister='publish_at' />
      </IonModal>

      <IonModal keepContentsMounted={true}>
        <DateTimeContent id='notice-unpublish' dateRegister='expire_at' />
      </IonModal>
    </>
  );
};

const DateTimeContent: React.FC<DateTimeContentProps> = ({ dateRegister, id }) => {
  const { t } = useTranslate();
  const methods = useFormContext();

  function registerDatetime(register: UseFormRegisterReturn) {
    return register as DatetimeRegisterReturn;
  }

  const findCurrentYear = () => {
    const year = new Date().getFullYear();
    return new Date(year + 10, 11, 31);
  };

  return (
    <IonDatetime
      showDefaultButtons
      doneText={t('OK')}
      cancelText={t('Cancel')}
      presentation='time-date'
      min={new Date().toISOString()}
      max={findCurrentYear().toISOString()}
      value={findDefaultDates(id as DateIDs)}
      id={id}
      {...registerDatetime(methods.register(dateRegister))}
    />
  );
};

export default NoticePublishHandling;
