import { IonIcon, IonItem, IonText } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';

import type { AccordionItemInterface } from './Accordion';

const AccordionHeader: React.FC<AccordionItemInterface> = ({
  children,
  hasNoPadding,
  subpanelActiveColour = 'var(--ion-color-primary)',
  subpanelIcon,
  subpanelIconEnd,
  subpanelTitle
}) => {
  return (
    <IonItem
      slot='header'
      color={'none'}
      className={classNames('accordion-header-component', hasNoPadding && 'ion-no-padding')}
    >
      {subpanelIcon === ''
        ? <React.Fragment />
        : <IonIcon
          style={{
            color: 'DimGray',
            '--accordion-icon-active': subpanelActiveColour
          }}
          className='accordion-header-icon'
          icon={subpanelIcon}
        />
      }
      {subpanelIcon === ''
        ? <p>{subpanelTitle}</p>
        : <IonText>
          <p>{subpanelTitle}</p>
        </IonText>
      }

      <React.Fragment>
        {subpanelIconEnd}
        {children}
      </React.Fragment>
    </IonItem>
  );
};

export default AccordionHeader;
