// Matches a password with at least one digit, one lowercase letter, one uppercase letter, and one special character
const regex_password = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\da-zA-Z]).*)$/;

// Matches a name allowing letters, spaces, and a wide range of Unicode characters
const regex_name = /^[a-zA-Z \u00C0-\u1FFF\u2800-\uFFFD]+$/;

// Matches a company name allowing letters, numbers, spaces, and a wide range of Unicode characters
const regex_company = /^[a-zA-Z0-9 \u00C0-\u1FFF\u2800-\uFFFD]+$/;

// Matches a numeric value, including decimal numbers
const regex_numeric = /^[0-9.]*$/;

// Matches an email address
const regex_email = /\S+@\S+\.\S+/;

// Matches a string consisting only of alphabetical characters
const regex_alphabetical = /^(?!\s*$)[\p{L}\s-]+$/u;

// Matches a URL
const regex_url = /^(https?):\/\/[^\s/$.?#].[^\s]*$/;

// Matches a username with alphanumeric characters, underscores, and hyphens, between 3 and 16 characters long
const regex_username = /^[a-zA-Z0-9_-]{3,16}$/;

// Matches a date in the format YYYY-MM-DD
const regex_date = /^\d{4}-\d{2}-\d{2}$/;

// Matches a time in the format HH:MM (24-hour format)
const regex_time = /^([01]\d|2[0-3]):([0-5]\d)$/;

// Matches a hexadecimal color code
const regex_hex_color = /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;

// Matches an alphanumeric string
const regex_alphanumeric = /^[a-zA-Z0-9]+$/;

// Matches a credit card number (basic validation)
const regex_credit_card = /^\d{13,16}$/;

// Matches a phone number allowing digits and spaces, with optional leading plus sign
const regex_phone = /^(\+|)[\d ]+$/;

// Matches if a string has at least one lowercase letter
const regex_hasLowerCase = /[a-zåäö]/;

// Matches if a string has at least one digit
const regex_hasNumber = /[0-9]/;

// Matches if a string has at least one uppercase letter
const regex_hasUpperCase = /[A-ZÅÄÖ]/;

// Matches if a string is alphabetical, including Swedish characters
const regex_isAlphabetical = /^[a-zA-ZåäöÅÄÖ]$/;

// Matches a Swedish name allowing letters, hyphens, dots, and spaces
const regex_swedish_name = /^([a-zA-ZåäöÅÄÖ\-\.]+)([a-zA-ZåäöÅÄÖ\-\. ]*)$/;

// Matches an international name allowing a wide range of characters from different languages and scripts
const regex_international_name = /^([a-zA-ZåäöÅÄÖ\-\. \u00C0-\u1FFF\u2800-\uFFFD]+)$/;

export const regex = {
  regex_password,
  regex_name,
  regex_company,
  regex_numeric,
  regex_email,
  regex_alphabetical,
  regex_phone,
  regex_hasLowerCase,
  regex_hasNumber,
  regex_hasUpperCase,
  regex_isAlphabetical,
  regex_swedish_name,
  regex_international_name,
  regex_url,
  regex_username,
  regex_date,
  regex_time,
  regex_hex_color,
  regex_alphanumeric,
  regex_credit_card
};
