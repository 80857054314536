import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';

import { INVITATION_INPUTS } from './config';
import { BigUp } from '../../../components/UI';

const TeamInviteInputs: React.FC = () => {
  return (
    <IonGrid className='ion-margin-top ion-no-padding'>
      {INVITATION_INPUTS.map((input, index) => {
        return (
          <IonRow key={index}>
            <IonCol>
              <BigUp.Input {...input} />
            </IonCol>
          </IonRow>
        );
      })}
    </IonGrid>
  );
};

export default TeamInviteInputs;
