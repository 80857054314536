import { IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPopover, IonRow, IonText } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import type { TFunction } from 'i18next';
import { checkmark, pencil } from 'ionicons/icons';
import { DateTime } from 'luxon';
import type { ComponentProps } from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import styles from './Notice.module.scss';
import { networking } from '../../api/networking';
import DesktopWrapper from '../../components/DesktopWrapper';
import { BigUp } from '../../components/UI';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import toasters from '../../components/UI/Toasts';
import { useAppSelector } from '../../hooks';
import { useBreadcrumbConfig } from '../../hooks/useBreadcrumbConfig';
import { findNoticeLevel } from '../NoticeBoard/config';
import { getSelectedMethodsLabels } from '../NoticeBoard/Form/defaults';

const Notice: React.FC = () => {
  const [notice, setNotice] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const { t } = useTranslate();
  const { notice_uuid } = useParams<{ notice_uuid: string | undefined }>();
  const breadcrumbs = useBreadcrumbConfig();
  const project = useAppSelector(state => state.project.selectedProject);
  const user = useAppSelector(state => state.authentication.user);

  const checkIfUserIsAuthor = !!(notice && user?.id === notice.created_by_id);
  const checkIfNoticeIsAcknowledged = notice && notice?.status_for_user === 'acknowledged';
  const searchParams = new URLSearchParams();
  searchParams.append('with[]', 'teams');
  searchParams.append('with[]', 'users');

  const getSelectedNotice = async () => {
    if (!notice_uuid) return;
    try {
      const response = await networking.get(`/api/v1/notices/${notice_uuid}?${searchParams}`);
      setNotice(response.data.data);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const redirectToEdit = () => {
    history.push(`/notice-board/${project?.id}/${notice_uuid}/add`);
  };

  const acknowledgeNotice = () => {
    setLoading(true);
    networking.post(`/api/v1/notices/${notice_uuid}/acknowledge`)
      .then(() => {
        toasters.createToast({
          message: t('Notice acknowledged'),
          background: 'var(--ion-color-light)',
        }, 'success');
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        getSelectedNotice();
        setLoading(false);
      });
  };

  const handleDelete = async () => {
    try {
      toasters.promiseToast(networking.delete(`/api/v1/notices/${notice_uuid}`), {
        success: { message: t('Notice deleted'), background: 'var(--ion-color-light)' },
        error: { message: t('Could not delete notice'), background: 'var(--ion-color-light)' },
        pending: { message: t('Deleting notice'), background: 'var(--ion-color-light)' }
      })
        .then(() => {
          history.push(`/notice-board/${project?.id}`);
        });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const checkTeamAndUserLength = (teams: E2U.V1.Models.Team[], users: E2U.V1.Models.User[]) => {
    return teams?.length === 0 && users?.length === 0;
  };

  useEffect(() => {
    getSelectedNotice();
  }, [notice_uuid]);

  return (
    <DesktopWrapper width='var(--ion-desktop-mid-width)'>
      <Breadcrumbs data={breadcrumbs.noticeDetailedBreadcrumbs} />
      {checkIfUserIsAuthor && (
        <IonGrid className='ion-padding'>
          <IonRow className='ion-align-items-center ion-justify-content-between'>
            <IonCol size='4' className='ion-text-start'>
              <BigUp.Buttons.Regular onClick={handleDelete} title={t('Delete')} color={'danger'} />
            </IonCol>
            <IonCol size='4' className='ion-text-end'>
              <BigUp.Buttons.Regular
                icon={{
                  icon: pencil,
                  slot: 'end'
                }}
                onClick={redirectToEdit} title={t('Edit')} />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}

      <IonList className='ion-padding-vertical'>
        <IonItem lines='none'>
          <IonText>
            <IonLabel className={`${styles.dateCol} ion-no-margin`}>
              {DateTime.fromISO(notice?.publish_at).toLocaleString(DateTime.DATE_FULL)} - {DateTime.fromISO(notice?.expire_at).toLocaleString(DateTime.DATE_FULL)}
            </IonLabel>
          </IonText>
          <IonIcon id="level-information" slot='end' className='ion-margin-end' size='large'{...findNoticeLevel(notice?.level)} />
          <IonPopover trigger="level-information" side="top" alignment="center">
            <IonContent class="ion-padding">{t('Notice level: {{level}}', { level: notice?.level })}</IonContent>
          </IonPopover>
        </IonItem>
        <NoticeItem label={t('Subject')}><span>{notice?.subject}</span></NoticeItem>
        <NoticeItem label={t('Comment')}><span>{notice?.message}</span></NoticeItem>
        <NoticeItem label={t('Affected teams')} lines='full'>
          {notice?.teams.map((team: E2U.V1.Models.Team) => <BigUp.Label.Regular key={team.id} label={team.name} />)}
          {notice?.teams.length === 0 && <BigUp.Label.Regular className='ion-no-margin' label={t('None')} />}
        </NoticeItem>
        <NoticeItem label={t('Affected users')} lines='full'>
          {notice?.users.map((user: E2U.V1.Models.User) => <BigUp.Label.Regular key={user.id} label={user.first_name} />)}
          {notice?.users.length === 0 && <BigUp.Label.Regular className='ion-no-margin' label={t('None')} />}
        </NoticeItem>

      </IonList>
      <IonGrid className='ion-padding-end ion-margin-end'>
        <IonRow className='ion-align-items-center ion-justify-content-between'>
          <IonCol size='7'>
            {checkTeamAndUserLength(notice?.teams, notice?.users) &&
              <>
                <IonItem lines='none'>
                  <IonText>
                    <IonLabel className={`${styles.dateCol} ion-no-margin`}>
                      {t('Notifications sent via')}
                    </IonLabel>
                  </IonText>
                </IonItem>
                <IonList>
                  {getSelectedMethodsLabels(notice?.notification_methods).map(({ icon, label }) => {
                    return (
                      <IonItem lines='none' key={label}>
                        <IonIcon icon={icon}></IonIcon>
                        <IonLabel>{label}</IonLabel>
                      </IonItem>
                    );
                  })}
                </IonList>
              </>
            }
          </IonCol>
        </IonRow>

      </IonGrid>
      <IonGrid className='ion-padding'>
        <IonRow>
          <IonCol size='12' className='ion-text-right'>
            <BigUp.Buttons.Regular
              {...checkIfNoticeIsAcknowledged && {
                disabled: true,
                icon: {
                  icon: checkmark,
                  slot: 'start',
                }
              }}
              onClick={() => acknowledgeNotice()}
              color={'success'}
              title={checkIfNoticeIsAcknowledged ? t('Acknowledged') : t('Acknowledge')}
            />
          </IonCol>
        </IonRow>
      </IonGrid>

    </DesktopWrapper>
  );
};

interface NoticeItemProps extends ComponentProps<typeof IonItem> {
  label: TFunction | string;
  children: React.ReactNode;
}
const NoticeItem: React.FC<NoticeItemProps> = ({ children, label, ...props }) => {
  return (
    <IonItem {...props} className='ion-margin-bottom'>
      <IonText className={styles.noticeTextContainer}>
        <BigUp.Label.Thick label={label} />
        {children}
      </IonText>
    </IonItem>
  );
};

export default Notice;
