import { IonCol, IonGrid, IonItem, IonList, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useMemo } from 'react';

import BigUp from '../../../components/UI';
import FilterChips from '../../../components/UI/Chips/FilterChips';

interface TagListProps {
  tags: E2U.V1.Models.Tag[];
}

const TagList: React.FC<TagListProps> = (props) => {
  const { tags } = props;

  const tagsPerCategory = useMemo(() => {
    return tags.reduce((acc, tag) => {
      if (!tag.category) {
        return acc;
      }
      if (!acc[tag.category_id]) {
        acc[tag.category_id] = {
          ...tag.category,
          tags: [],
        };
      }
      acc[tag.category_id].tags?.push(tag);
      return acc;
    }, {} as Record<string, E2U.V1.Models.TagCategory>);
  }, [tags]);

  return (
    <IonList>
      <IonGrid>
        {Object.keys(tagsPerCategory).map((categoryId, index) => (
          <>
            <IonRow>
              <IonCol>
                <BigUp.Label.Thick label={tagsPerCategory[categoryId].name} />
              </IonCol>
            </IonRow>
            <IonRow className='ion-align-items-center ion-justify-content-between' key={index}>
              <IonCol size='12'>
                <IonItem key={index} className='ion-no-padding ion-align-items-center ion-justify-content-between'>
                  <FilterChips
                    data={tagsPerCategory[categoryId].tags || []}
                    renderChipLabel={(item) => item.name}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
          </>
        ))}
      </IonGrid>
    </IonList>
  );
};

export default TagList;
