import { IonContent } from '@ionic/react';

const PaddedModalContent: React.FC = ({ children }) => {
  return (
    <IonContent className="ion-padding">
      {children}
    </IonContent>
  );
};

export default PaddedModalContent;
