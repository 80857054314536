
import { ionicColours } from '../../variables';

const ItemBorderContainer: React.FC = ({ children }) => {
  return (

    <div
      className="ion-no-padding ion-margin-top"
      style={{ borderLeft: `4px solid ${ionicColours.secondary}`, borderRadius: '2px', maxWidth: '500px', width: '100%' }}>
      {children}
    </div>
  );
};

export default ItemBorderContainer;
