import type { Messages } from './bankid.interfaces';

const BankIdMessages: React.FC<Messages> = ({ messages }) => {
  return (
    <div style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <h3 className="ion-text-center">
        {messages}
      </h3>
    </div>
  );
};

export default BankIdMessages;
