import { useState } from 'react';

import { type DefaultValues, type Permissions, defaultValues } from '../components/UI/Permissions/config';

const usePermissionSelect = () => {
  const [selectedPermissions, setSelectedPermissions] = useState<DefaultValues>(defaultValues);

  const handlePermissionSelection = (value: Permissions, category: string) => {
    const selectedValue = value === ('' || null || 'none') ? null : value;
    setSelectedPermissions(prevState => ({
      ...prevState,
      [category]: selectedValue
    }));
  };

  return { selectedPermissions, handlePermissionSelection, setSelectedPermissions };
};

export default usePermissionSelect;
