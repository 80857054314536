import { IonItem, IonTextarea } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useFormContext } from 'react-hook-form';

import type { TextareaProps } from './interface';
import { ValidationBadge } from '../Inputs/ValidationBadge';

const Textarea: React.FC<TextareaProps<E2U.V1.Models.User | string>> = (props) => {
  const methods = useFormContext();
  const label = props.label || '';
  return (
    <>
      <IonItem {...props.itemProps}>
        <IonTextarea
          {...props}
          label={label}
          labelPlacement={props.labelPlacement || 'stacked'}
          autoCapitalize='sentences'
          aria-required
          aria-aria-valuemax={props.maxlength}
          aria-aria-valuemin={props.minlength}
          placeholder={props.placeholder}
          {...methods.register(props.register, {
            ...props.validation,
          })}
        />
      </IonItem>
      <div className='ion-no-margin' style={{ height: 20, paddingTop: 5, display: 'flex' }}>
        {methods.formState.errors[props.register] && (
          <ValidationBadge >
            {methods.formState.errors[props.register]?.message}
          </ValidationBadge>
        )}
      </div>
    </>
  );
};

export default Textarea;
