import { IonLabel, isPlatform } from '@ionic/react';
const InputLabel: React.FC<{ children: React.ReactNode, stacked?: boolean, margin?: any, fontSize?: '8px' | '16px' | '20px' | 'var(--ion-label-size-medium)' }> = ({ children, fontSize, margin, stacked }) => {
  const label_styles = {
    fontWeight: 800,
    letterSpacing: '1px',
    fontSize: isPlatform('android') ? fontSize : '16px',
    marginBottom: '8px',
  };
  return (

    <IonLabel
      {...(stacked && { position: 'stacked' })}
      className={margin || 'ion-margin-left'}
      color={'medium'}
      style={{ ...label_styles }}>
      {children}
    </IonLabel>
  );
};

export default InputLabel;
