import { IonHeader, IonToolbar, useIonRouter } from '@ionic/react';
import { t } from 'i18next';
import React from 'react';

import DesktopWrapper from '../../../components/DesktopWrapper';
import { ionicColours } from '../../../components/UI/variables';
import ProjectForm from '../ProjectForm';

const ProjectCreate: React.FC = () => {
  const router = useIonRouter();

  return (
    <React.Fragment>
      <DesktopWrapper width={'800px'}>
        <IonHeader className={'ion-no-border ion-margin-top  ion-margin-start'}>
          <IonToolbar className='ion-no-padding'>
            <div className='ion-text-left' style={{ borderLeft: `8px solid ${ionicColours.secondary}` }}>
              <h1 className='ios' style={{ paddingLeft: '10px' }}>
                {t('Create new project')}
              </h1>
            </div>
          </IonToolbar>
        </IonHeader>
        <ProjectForm newProject onCancel={() => router.goBack()} />
      </DesktopWrapper>
    </React.Fragment >
  );
};

export default ProjectCreate;
