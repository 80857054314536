import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useState } from 'react';
import { useParams } from 'react-router';

import { networking } from '../api/networking';

const useFlashNotice = () => {
  // TODO: Fetch flash notice and implement this throughout the app (if there is a flash notice for the project).
  const [flashNotice, setFlashNotice] = useState<any>('');
  const [dummy, setDummy] = useState<boolean>(false); // TODO: Remove this dummy state and implement it using response from server.

  const closeDummy = () => {
    setDummy(false);
    // Handle this using redux instead of local state to be able to open it throughout the app.
  };

  const { uuid } = useParams<{ uuid: string }>();

  const getFlashNotice = () => {
    networking.get(`/api/v1/projects/${uuid}`) // TODO: Get flash notice from the project.
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Project>) => {
        setFlashNotice(response);
      }
      ).catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      });
  };
  return { flashNotice, getFlashNotice, closeDummy, dummy, setDummy };
};
export default useFlashNotice;
