import { IonList } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { calculator, calendarClear, clipboardSharp, cube, fileTrayFull, gitNetwork, logoEuro, medical, statsChart } from 'ionicons/icons';
import React, { useEffect, useMemo } from 'react';
import { useStore } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router';

import MonitoringReport from './Pages/MonitoringReport';
import CashFlow from './Subpanels/CashFlow/CashFlow';
import Change from './Subpanels/Changes/Change';
import Changes from './Subpanels/Changes/Changes';
import CreateChange from './Subpanels/Changes/CreateChange';
import EditChange from './Subpanels/Changes/EditChange';
import CreateDeviation from './Subpanels/Deviations/CreateDeviation';
import Deviation from './Subpanels/Deviations/Deviation';
import Deviations from './Subpanels/Deviations/Deviations';
import EditDeviation from './Subpanels/Deviations/EditDeviation';
import EconomyMonitoring from './Subpanels/EconomyMonitoring/EconomyMonitoring';
import EconomyReport from './Subpanels/EconomyReports/EconomyReport';
import PaymentSchedule from './Subpanels/PaymentSchedule/PaymentSchedule';
import Precalculations from './Subpanels/Precalculations/Precalculations';
import ProductionBudget from './Subpanels/ProductionBudget/ProductionBudget';
import ProjectInformation from './Subpanels/ProjectInformation/ProjectInformation';
import { networking } from '../../api/networking';
import Panel from '../../components/Panels/Panel';
import Toast from '../../components/Toasts/Toast';
import toasters from '../../components/Toasts/Toasts';
import ProgressBar from '../../components/UI/Loaders/ProgressBar';
import type { ReturnInterfaceExtraInfo } from '../../components/UI/Return/ReturnChevronText';
import SplitpaneContent from '../../components/UI/SplitPane/SplitPaneContent';
import { matchUuid } from '../../constants/matchUuid';
import { useAppSelector } from '../../hooks';
import { setIsLoading } from '../../reducers/loading';
import { setSelectedProject } from '../../reducers/project';
import { setAll, setIsLockable } from '../../reducers/subpanelLock';
import type { SubpanelItem } from '../DesktopCards';

const EconomyPage: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  const { t } = useTranslate();
  const location = useLocation<ReturnInterfaceExtraInfo>();
  const project: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.project.selectedProject);
  const isLoadingEconomy: boolean = useAppSelector((state) => state.loading.isLoading.selectedProject);
  const subpanelsLockAll = useAppSelector((state) => state.lockSubpanels.isLocked);
  const subpanelLockable = useAppSelector((state) => state.lockSubpanels.isLockable);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const projectId = useMemo(() => project?.id ?? '', [project]);

  const fetchProject = () => {
    if (
      typeof project === 'undefined' ||
      typeof project?.id === 'undefined'
    ) {
      Toast(t('Project not found'), 'error');
      history.push('/economy-tools');
      return;
    }
    store.dispatch(setIsLoading({ name: 'economy', value: true }));

    toasters
      .promise(networking.get(`/api/v1/projects/${project?.id}`), {
        error: t('Error while loading projects')
      })
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Project>) => {
        store.dispatch(setSelectedProject(response.data.data));
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'economy', value: false }));
      });
  };

  useEffect(() => {
    store.dispatch(setAll(project?.locked));
    store.dispatch(setIsLockable(project?.locked));
  }, [project]);

  const ECONOMY_TOOLS_ACCORDION_LIST: SubpanelItem[] = [
    {
      subpanelTitle: t('Project information'),
      subpanelIcon: clipboardSharp,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${project?.id}/project-information`,
      component: <ProjectInformation
        fetchProject={fetchProject}
        project={project}
        isLocked={subpanelsLockAll.projectInformation}
        isLockable={subpanelLockable}
      />,
      value: 'project-information',
      locked: subpanelsLockAll.projectInformation
    },
    {
      subpanelTitle: t('Precalculations'),
      subpanelIcon: calculator,
      subpanelActiveColour: 'var(--ion-color-danger)',
      subpanelDisabled: false,
      route: `${project?.id}/precalculations`,
      component: <Precalculations
        isLocked={subpanelsLockAll.precalculations}
        isLockable={subpanelLockable} />,
      value: 'precalculations',
      locked: subpanelsLockAll.precalculations
    },
    {
      subpanelTitle: t('Production budget'),
      subpanelIcon: statsChart,
      subpanelActiveColour: 'rgb(246, 154, 235)',
      subpanelDisabled: false,
      route: `${project?.id}/production-budget`,
      component: <ProductionBudget isLocked={subpanelsLockAll.productionBudgets} fetchProject={fetchProject} />,
      value: 'production-budget',
      locked: subpanelsLockAll.productionBudgets
    },
  ];

  const ONLY_ON_LOCK: SubpanelItem[] = [
    {
      subpanelTitle: t('Cash flow'),
      subpanelIcon: gitNetwork,
      subpanelActiveColour: 'lightgreen',
      subpanelDisabled: false,
      route: `${project?.id}/cash-flow`,
      component: <CashFlow onCalculated={fetchProject} />,
      value: 'cash-flow'
    },
    {
      subpanelTitle: t('Payment schedule'),
      subpanelIcon: calendarClear,
      subpanelActiveColour: 'rgb(74, 237, 237)',
      subpanelDisabled: false,
      route: `${project?.id}/payment-schedule`,
      value: 'payment-schedule'
    },
    {
      subpanelTitle: t('Economy monitoring'),
      subpanelIcon: fileTrayFull,
      subpanelActiveColour: 'var(--ion-color-success)',
      subpanelDisabled: false,
      route: `${project?.id}/economy-monitoring`,
      value: 'economy-monitoring'
    },
    {
      subpanelTitle: t('Economy reports'),
      subpanelIcon: logoEuro,
      subpanelActiveColour: 'var(--ion-color-secondary)',
      subpanelDisabled: false,
      route: `${project?.id}/economy-reports`,
      value: 'economy-reports'
    },
    {
      subpanelTitle: t('Deviations'),
      subpanelIcon: medical,
      subpanelActiveColour: 'var(--ion-color-danger)',
      subpanelDisabled: false,
      route: `${project?.id}/deviations`,
      value: 'deviations'
    },
    {
      subpanelTitle: t('Changes'),
      subpanelIcon: cube,
      subpanelActiveColour: 'var(--ion-color-medium)',
      subpanelDisabled: false,
      route: `${project?.id}/changes`,
      value: 'changes'
    },
  ];

  const renderTools: SubpanelItem[] = [
    ...ECONOMY_TOOLS_ACCORDION_LIST,
    ...(subpanelsLockAll.subpanelsOnlyOnLock ? ONLY_ON_LOCK : []),
  ];

  const mobilePanels = (
    <IonList className='ion-padding-end ion-margin-top'>
      {location.pathname === `/economy-tools/${projectId}` && (
        renderTools.map((item, index) => (
          <Panel
            key={index}
            icon={item.subpanelIcon}
            onClick={() => history.push(item.route ?? '')}
            panelTitle={item.subpanelTitle}
            iconColour={'#02724d'}
            hasInnerIcon={true}
          />
        ))
      )}
    </IonList>
  );

  return (
    <React.Fragment>
      {(isLoadingEconomy || typeof projectId === 'undefined')
        ? <ProgressBar />
        : (!isDesktop) && mobilePanels
      }

      <Route path={`/economy-tools/:uuid(${matchUuid})/project-information/`}>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <ProjectInformation fetchProject={fetchProject} project={project} isLocked={subpanelsLockAll.projectInformation} isLockable={subpanelLockable} />
        </SplitpaneContent>
      </Route>

      <Route path={`/economy-tools/:uuid(${matchUuid})/precalculations/`}>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <Precalculations isLocked={subpanelsLockAll.precalculations} isLockable={subpanelLockable} />
        </SplitpaneContent>

      </Route>

      <Route path={`/economy-tools/:uuid(${matchUuid})/production-budget/`}>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <ProductionBudget isLocked={subpanelsLockAll.productionBudgets} fetchProject={fetchProject} />
        </SplitpaneContent>

      </Route>

      <Route path={`/economy-tools/:uuid(${matchUuid})/cash-flow/`}>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <CashFlow onCalculated={fetchProject} />
        </SplitpaneContent>
      </Route>

      <Route path={`/economy-tools/:uuid(${matchUuid})/payment-schedule/`}>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <PaymentSchedule />
        </SplitpaneContent>
      </Route>

      <Route path={`/economy-tools/:uuid(${matchUuid})/economy-monitoring/`}>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <EconomyMonitoring />
        </SplitpaneContent>
      </Route>

      <Route path={`/economy-tools/:uuid(${matchUuid})/monitoring/:monitoring_uuid(${matchUuid})`}>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <MonitoringReport />
        </SplitpaneContent>
      </Route>

      <Route path={`/economy-tools/:uuid(${matchUuid})/economy-reports/`}>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <EconomyReport />
        </SplitpaneContent>
      </Route>

      {/* DEVIATIONS */}
      <Route path={`/economy-tools/:uuid(${matchUuid})/deviations/:deviation_uuid(${matchUuid})/edit`} exact>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <EditDeviation />
        </SplitpaneContent>
      </Route>
      <Route path={`/economy-tools/:uuid(${matchUuid})/deviations/:deviation_uuid(${matchUuid})`} exact>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <Deviation />
        </SplitpaneContent>
      </Route>
      <Route path={`/economy-tools/:uuid(${matchUuid})/deviations/create`} exact>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <CreateDeviation />
        </SplitpaneContent>
      </Route>
      <Route path={`/economy-tools/:uuid(${matchUuid})/deviations/`} exact>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <Deviations />
        </SplitpaneContent>
      </Route>
      {/* CHANGES */}
      <Route path={`/economy-tools/:uuid(${matchUuid})/changes/:change_uuid(${matchUuid})/edit`} exact>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <EditChange />
        </SplitpaneContent>
      </Route>
      <Route path={`/economy-tools/:uuid(${matchUuid})/changes/:change_uuid(${matchUuid})`} exact>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <Change />
        </SplitpaneContent>
      </Route>
      <Route path={`/economy-tools/:uuid(${matchUuid})/changes/create`} exact>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <CreateChange />
        </SplitpaneContent>
      </Route>
      <Route path={`/economy-tools/:uuid(${matchUuid})/changes/`} exact>
        <SplitpaneContent baseURL='economy-tools' uuid={projectId} menuItems={renderTools}>
          <Changes />
        </SplitpaneContent>
      </Route>

    </React.Fragment>
  );
};
export default EconomyPage;
