import { IonTitle } from '@ionic/react';

import type { TitleProps } from './interface';

const Title: React.FC<TitleProps> = ({ children, label, ...props }) => {
  return (
    <IonTitle
      {...props}
      {...props.style
        ? { style: props.style }
        : { style: { fontWeight: '800', fontSize: '1.5rem' } }
      }
      className={props.className || 'ion-no-padding'}
      size='large'>{label || children}</IonTitle>
  );
};

export default Title;
