import { IonInput } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useFormContext } from 'react-hook-form';

import type { InputProps } from './Input.interface';
import { ValidationBadge } from './ValidationBadge';

const Input: React.FC<InputProps<E2U.V1.Models.User | string>> = (props) => {
  const methods = useFormContext();
  const label = props.label || '';

  return (
    <>
      <IonInput
        style={{ borderBottom: '1px solid var(--ion-color-primary)' }}
        label={label}
        labelPlacement={props.labelPlacement || 'stacked'}
        className={'ion-margin-left'}
        autoCapitalize='sentences'
        clearInput={false}
        type={props.type}
        inputmode={props.inputMode}
        placeholder={props.placeholder}
        debounce={300}
        onIonInput={(e) => {
          methods.setValue(props.register, e.detail.value);
          methods.trigger(props.register);
        }}
        helperText={props.helperText && props.helperText}
        {...props}
        {...props.register && methods.register(props.register, {
          ...props.validation,
        })}

      />
      {methods.formState.errors[props.register] && (
        <div className='ion-no-margin' style={{ height: 20, paddingTop: 5, display: 'flex' }}>
          <ValidationBadge>
            {methods.formState.errors[props.register]?.message}
          </ValidationBadge>
        </div>
      )}
    </>
  );
};

export default Input;
