import BankIdButton from './BankIDButton';
import BankIdIcon from './BankIDIcon';
import BankIdLogin from './BankIDLogin';
import BankIdMessages from './BankIDMessages';
import BankIdQrCode from './BankIDQrCode';
import BankIdSignForm from './BankIDSign';

const BankID = {
  BankIdQrCode,
  BankIdButton,
  BankIdIcon,
  BankIdMessages,
  BankIdLogin,
  BankIdSignForm
};

export default BankID;
