import { IonCol, IonGrid, IonLabel, IonList, IonModal, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { constructSharp, documentsSharp, fileTrayFullSharp, keySharp, map, paperPlane, peopleSharp } from 'ionicons/icons';
import React from 'react';
import { Route, useHistory, useLocation, useParams } from 'react-router';

import ControlOfExecution from './ControlOfExecution';
import ControlOfExecutionDetailed from './ControlOfExecution/ControlOfExecutionDetailed';
import PendingSiteAccessRequest from './PendingSiteAccessRequests';
import ProjectDocuments from './ProjectDocuments/ProjectDocuments';
import RejectedSiteAccessRequest from './RejectedSiteAccessRequest';
import SiteAccessRequests from './SiteAccessRequests';
import UnsubmittedSiteAccessRequest from './UnsubmittedSiteAccessRequest';
import WorkingEnviroment from './WorkingEnviroment';
import Panel from '../../../components/Panels/Panel';
import ProgressBar from '../../../components/UI/Loaders/ProgressBar';
import type { ReturnInterfaceExtraInfo } from '../../../components/UI/Return/ReturnChevronText';
import SplitpaneContent from '../../../components/UI/SplitPane/SplitPaneContent';
import { matchUuid } from '../../../constants/matchUuid';
import { useAppSelector } from '../../../hooks';
import useCustomModalAnimations from '../../../hooks/useCustomModalAnimation';
import useModal from '../../../hooks/useModal';
import usePermissionHandler from '../../../hooks/usePermissions';
import { setSelectedProject } from '../../../reducers/project';
import store from '../../../store';
import type { SubpanelItem } from '../../DesktopCards';
import DocumentPage from '../../Document';
import CategoryPage from '../../Document/Category';
import EditDocumentTypePage from '../../Document/Category/EditDocumentTypePage';
import DocumentsEditPage from '../../Document/DocumentEditPage';
import modalStyles from '../../Onboarding/Components/containers/styles/ModalContainers.module.scss';
import OnboardingStart from '../../Onboarding/Components/modals/OnboardingStart';
import TagPage from '../../Tags/TagPage';
import TagsPage from '../../Tags/TagsPage';
import Teams from '../../Teams';
import TeamInvite from '../../Teams/Invite/TeamInvite';
import Team from '../../Teams/Team/Team';
import TeamForm from '../../Teams/TeamForm/TeamForm';
import WorksitePage from '../../Worksite';
import WorkSiteFormPage from '../../Worksite/form';
import LayerUpload from '../../Worksite/form/LayerUpload/LayerUpload';
import WorksiteMapPage from '../../Worksite/map';

const ProjectPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation<ReturnInterfaceExtraInfo>();
  const { t } = useTranslate();
  const { uuid } = useParams<{ uuid: string | undefined, worksite_id: string | undefined }>();
  const { closeModal, isModalOpen, openModal } = useModal();
  const { dismiss, enterAnimation, leaveAnimation, modal } = useCustomModalAnimations();

  const projects = useAppSelector((state) => state.project.projects);
  const selected: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.onboarding.selectedProject);
  const project: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.project.selectedProject);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const isLoadingProject: boolean = useAppSelector((state) => state.loading.isLoading.project);
  const permission = usePermissionHandler();
  const selectedProject = selected || project;

  const PROJECT_TOOLS_ACCORDION_LIST: SubpanelItem[] = [
    {
      subpanelTitle: t('Control of execution'),
      subpanelIcon: keySharp,
      subpanelActiveColour: 'var(--ion-color-primary)',
      subpanelDisabled: false,
      route: `${uuid}/control-of-execution`,
      value: 'control-of-execution',
      only_routing: false
    },
    {
      subpanelTitle: t('Working enviroment'),
      subpanelIcon: constructSharp,
      subpanelActiveColour: 'var(--ion-color-warning)',
      subpanelDisabled: false,
      route: `${uuid}/working-environment`,
      value: 'working-environment',
      only_routing: false
    },
    {
      subpanelTitle: t('Site Access Requests'),
      subpanelIcon: keySharp,
      subpanelActiveColour: 'var(--ion-color-success)',
      subpanelDisabled: false,
      route: `${uuid}/site-access-requests`,
      value: 'site-access-requests',
      only_routing: false
    },
    {
      subpanelTitle: t('Documents'),
      subpanelIcon: documentsSharp,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${uuid}/documents`,
      value: 'documents',
      only_routing: false
    },
    {
      subpanelTitle: t('Worksites'),
      subpanelIcon: documentsSharp,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${uuid}/worksites`,
      value: 'worksites',
      only_routing: false
    },
    {
      subpanelTitle: t('Map'),
      subpanelIcon: map,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${uuid}/map/:worksite_layer_id?`,
      value: 'map/:worksite_layer_id?',
      only_routing: true
    }
  ];

  const PROJECT_TOOLS_LIST_ADMIN = [
    {
      subpanelTitle: t('Tags'),
      subpanelIcon: paperPlane,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${uuid}/tags`,
      value: 'tags',
      only_admin: permission.checkPermission('project_tools_access', 'admin'),
      only_routing: false
    },
    {
      subpanelTitle: t('Document categories'),
      subpanelIcon: fileTrayFullSharp,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${uuid}/document-categories`,
      value: 'document-categories',
      only_admin: permission.checkPermission('project_tools_access', 'admin'),
      only_routing: false
    },
    {
      subpanelTitle: t('Teams'),
      subpanelIcon: peopleSharp,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${uuid}/teams`,
      value: 'teams',
      only_admin: permission.checkPermission('project_tools_access', 'admin'),
      only_routing: false
    },

  ];

  const toggleStart = () => {
    const project = projects?.find((project) => project.id === uuid);
    store.dispatch(setSelectedProject(project));
    openModal();
  };

  const renderTools: SubpanelItem[] = [
    ...PROJECT_TOOLS_ACCORDION_LIST,
    ...PROJECT_TOOLS_LIST_ADMIN.filter((item) => item.only_admin)
  ];

  const mobilePanels = (
    <IonList className='ion-padding-end ion-margin-top'>
      {location.pathname === `/project-tools/${uuid}` && (
        renderTools.filter((i) => !i.only_routing).map((item, index) => (
          <React.Fragment key={index}>
            <Panel
              icon={item.subpanelIcon}
              onClick={() => history.push(item.route ?? '')}
              panelTitle={item.subpanelTitle}
              iconColour={'var(--ion-color-primary-shade)'}
              hasInnerIcon={true}
            />
          </React.Fragment>
        ))
      )}
    </IonList>
  );

  return (
    <React.Fragment>
      {(isLoadingProject || typeof selectedProject === 'undefined')
        ? <ProgressBar />
        : (
          <React.Fragment>
            {(!isDesktop || !renderTools.length) && mobilePanels}
            {location.pathname === `/project-tools/${uuid}` && selectedProject.has_site_access === false && (
              <IonGrid className='ion-padding'>
                <IonRow className='ion-justify-content-center ion-align-items-center'>
                  <IonCol sizeMd='6' >
                    <IonLabel color={'medium'} className={'ion-no-margin'} style={{ fontSize: 15, fontWeight: 800 }}>
                      <RejectedSiteAccessRequest onClick={toggleStart} />
                      <UnsubmittedSiteAccessRequest onClick={toggleStart} />
                      <PendingSiteAccessRequest />
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>

            )}

          </React.Fragment>
        )}
      {/* CONTROL OF EXECUTION */}
      <Route path={`/project-tools/:uuid(${matchUuid})/control-of-execution/:coe_id(${matchUuid})`}>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <ControlOfExecutionDetailed />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/control-of-execution/`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <ControlOfExecution />
        </SplitpaneContent>
      </Route>
      {/* WORKING ENVIRONMENT */}
      <Route path={`/project-tools/:uuid(${matchUuid})/working-environment/`}>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <WorkingEnviroment />
        </SplitpaneContent>
      </Route>
      {/* SITE ACCESS REQUESTS */}
      <Route path={`/project-tools/:uuid(${matchUuid})/site-access-requests/`}>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <SiteAccessRequests />
        </SplitpaneContent>
      </Route>
      {/* DOCUMENTS */}
      <Route path={`/project-tools/:uuid(${matchUuid})/documents/:documentUuid(${matchUuid})/edit`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <DocumentsEditPage />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/documents/:documentUuid(${matchUuid})`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <DocumentPage />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/documents/`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <ProjectDocuments />
        </SplitpaneContent>
      </Route>
      {/* WORKSITES */}
      <Route path={`/project-tools/:uuid(${matchUuid})/worksites/:worksite_id(${matchUuid}/upload)`} >
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <LayerUpload />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/worksites/:worksite_id(${matchUuid}|add)`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>

          <WorkSiteFormPage />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/worksites`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <WorksitePage />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/map/:worksite_layer_id?`}>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <WorksiteMapPage />
        </SplitpaneContent>
      </Route>
      {/* TAGS */}
      <Route path={`/project-tools/:uuid(${matchUuid})/tags/:tag_category_id(${matchUuid})`}>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <TagPage />
        </SplitpaneContent>
      </Route>
      <Route path={`/project-tools/:uuid(${matchUuid})/tags/`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <TagsPage />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/document-categories/:category_id(${matchUuid})/`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <EditDocumentTypePage />
        </SplitpaneContent>
      </Route>
      {/* CATEGORIES */}
      <Route path={`/project-tools/:uuid(${matchUuid})/document-categories/`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <CategoryPage />
        </SplitpaneContent>
      </Route>
      {/* TEAM */}
      <Route path={`/project-tools/:uuid(${matchUuid})/team/:team_id(${matchUuid})`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <Team />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/team/:team_id(${matchUuid})/invite`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <TeamInvite />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/team/:team_id(${matchUuid})/edit`} exact>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <TeamForm />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/team/create`} exact >
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <TeamForm />
        </SplitpaneContent>
      </Route>

      <Route path={`/project-tools/:uuid(${matchUuid})/teams/`}>
        <SplitpaneContent baseURL='project-tools' uuid={uuid ?? ''} menuItems={renderTools}>
          <Teams />
        </SplitpaneContent>
      </Route>

      <IonModal
        isOpen={isModalOpen}
        onIonModalDidDismiss={() => closeModal()}
        ref={modal}
        className={modalStyles['onboarding-start-modal']}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
      >
        <OnboardingStart dismiss={dismiss} />
      </IonModal>

    </React.Fragment>
  );
};

export default ProjectPage;
