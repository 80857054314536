import { useTranslate } from '@tolgee/react';
import { useState } from 'react';

import { notice_default } from './defaults';
import { BigUp } from '../../../components/UI';
import type { SelectOption } from '../../../components/UI/Select/interface';

const NoticeAccessFilter: React.FC = () => {
  const { t } = useTranslate();
  const [selectAccessfilter, setSelectAccessfilter] = useState<SelectOption['id']>('onsite');

  const notice_filter_access = {
    data: notice_default.access_filter_data,
    selected: selectAccessfilter,
    handleSelection: (value: SelectOption | string) => setSelectAccessfilter(value as SelectOption['id']),
    label: t('Who is affected?'),
    register: 'project_access_filter',
    validation: undefined,
    itemProps: { className: 'ion-no-padding ion-margin-bottom' },
  };

  return <BigUp.Select {...notice_filter_access} />;
};

export default NoticeAccessFilter;
