import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonText
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { arrowForward, camera, document } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useStore } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';

import styles from './ObservationReports.module.scss';
import { networking } from '../../../../api/networking';
import Toast from '../../../../components/Toasts/Toast';
import { useAppSelector } from '../../../../hooks';
import { setIsLoading } from '../../../../reducers/loading';
import { setObservationReports } from '../../../../reducers/project';
import { setObservations } from '../../../../reducers/reports';

const ObservationReports: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslate();
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const store = useStore();
  const observations: E2U.V1.Models.ObservationReport[] | undefined =
    useAppSelector((state) => state.reports.observations);
  const fetchObservations = (pageNumber = 0, perPage = 0) => {
    store.dispatch(setIsLoading({ name: 'projects', value: true }));
    networking
      .get(`/api/v1/projects/${uuid}/observation_reports?with=files`)
      .then(
        (
          response: E2U.V1.Response.Success<
            E2U.V1.Objects.PaginatedData<E2U.V1.Models.ObservationReport>
          >
        ) => {
          store.dispatch(setObservations(response.data.data.records));
        }
      )
      .catch((response: E2U.V1.Response.Error) => {
        console.warn('E2U Errors', response);
        Toast(t('Failed to load observations'), 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'projects', value: false }));
      });
  };

  const getImageCount = (observation: E2U.V1.Models.ObservationReport) => {
    return observation.files
      ? observation.files.filter((doc) => {
        return doc.type === 'image';
      }).length
      : 0;
  };
  const getFileCount = (observation: E2U.V1.Models.ObservationReport) => {
    return observation.files
      ? observation.files.filter((doc) => {
        return doc.type !== 'image';
      }).length
      : 0;
  };

  const showReport = (observationId?: string) => {
    history.push(`${match.url}/observations/${observationId}`);
  };

  useEffect(() => {
    fetchObservations();
    return () => {
      setObservationReports(undefined);
    };
  }, []);

  return (
    <React.Fragment>
      {observations
        ? (
          observations.map((observation, i) => {
            return (
              <IonCard className={styles['report-list-card']} key={i}>
                <IonCardContent
                  onClick={() => showReport(observation.id)}
                  className={styles['ion-list-card-content']}
                >
                  <IonGrid>
                    <IonRow className={'ion-justify-content-between'}>
                      <IonCol size="7">
                        <IonItem lines="none">
                          <IonLabel className={styles['observation-name']}>
                            {observation.name}
                          </IonLabel>
                        </IonItem>
                      </IonCol>

                      <IonCol size={'true'}>
                        <IonText>{getImageCount(observation)}</IonText>
                        <IonIcon
                          className={styles['report-icons']}
                          icon={camera}
                        ></IonIcon>
                        <IonText>{getFileCount(observation)}</IonText>
                        <IonIcon
                          className={styles['report-icons']}
                          icon={document}
                        />
                        <IonIcon
                          className={classNames(
                            styles['report-icons'],
                            styles['open-icon']
                          )}
                          icon={arrowForward}
                        ></IonIcon>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            );
          })
        )
        : (
          <IonLabel className={classNames(['ion-text-center'], ['empty-list'])}>
            {t('No observation reports')}
          </IonLabel>
        )}
    </React.Fragment>
  );
};

export default ObservationReports;
