import { IonText } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';

import styles from '../form/styles/OnboardingFormSummary.module.scss';
interface HeaderProps {
  title: string;

}
const OnboardingHeader: React.FC<HeaderProps> = (props) => {
  return (
    <>
      <div id="scroll-to-top"></div>
      <IonText className={classNames('ion-text-center', styles['summary-title'])} >
        <h3>{props.title}</h3>
      </IonText>
    </>
  );
};

export default OnboardingHeader;
