import type { E2U } from '@techlove/easy2use-typings';

import i18n from '../../../i18n';
import type { ModalInterface } from '../modals/Modal';

export interface PermissionsModalProps {
  modal: ModalInterface;
  data: E2U.V1.Models.User;
}

type PermissionId = `permissions_${string}`;

export type Permissions = 'read' | 'write' | 'admin' | 'none' |null;

export interface Permission {
  id: PermissionId;
  value: Permissions;
  label?: string;
}

export interface PermissionsSelectionProps<T> {
  data: T[];
  handleSelection: any;
  selected: any;
  title: string;
  placeholder?: string;
}

export type DefaultValues = {
  project_tools: Permissions, economy_tools: Permissions
}
export const defaultValues: DefaultValues = {
  project_tools: 'none', economy_tools: 'none'
};

export const permissions:Permission[] = [
  { id: 'permissions_read', value: 'read', label: i18n.t('Read') },
  { id: 'permissions_write', value: 'write', label: i18n.t('Write') },
  { id: 'permissions_admin', value: 'admin', label: i18n.t('Admin') },
  { id: 'permissions_none', value: 'none', label: i18n.t('None') },
];
