import { IonLabel } from '@ionic/react';
import { t } from 'i18next';

import styles from './ColourDescription.module.scss';
import type { ColourVariables } from './variables';

interface ColourDescriptionInterface {
  colour: ColourVariables;
  label: string;
}
interface LegendData {
  data: ColourDescriptionInterface[];
}

const ColourDescription: React.FC<LegendData> = (props) => {
  return (
    <div>
      <div className={styles['colour-description-container']}>
        {props.data.map((item, index) => (
          <div key={index} className={styles['inner-container']}>
            <div className={styles['inner-content']} style={{ background: item.colour }} />
            <IonLabel color={'dark'} className='ion-no-margin'>{item.label}</IonLabel>
          </div>
        ))}

      </div>
    </div>
  );
};
export default ColourDescription;
