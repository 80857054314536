import { IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import type { CSSProperties } from 'react';
import type { ToastOptions } from 'react-toastify';

import styles from './styles';
import type { IonicThemeColours } from '../variables';

const CloseButton = () => {
  return (
    <div style={styles.closeButton}>
      <IonIcon icon={close} />
    </div>
  );
};

export const ToastIcon = (icon?: string, color?: IonicThemeColours) => (
  <IonIcon size="large" icon={icon} color={color} />
);

export const getDefaultToastConfig = (
  background?: CSSProperties['background'],
  textColour?: CSSProperties['color']
): ToastOptions => (
  {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    closeButton: <CloseButton />,
    draggable: true,
    bodyStyle: styles.body,
    style: { ...styles.content, background, color: textColour, },
  }
);
