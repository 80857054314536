import { IonCheckbox, IonCol, IonItem, IonLabel, IonRow } from '@ionic/react';
import { t } from 'i18next';
import React from 'react';

interface CertificateState {
  checked: boolean;
  workName: string;
}
const OnboardingFormSummaryCertificates: React.FC<CertificateState> = (props) => {
  return (
    <React.Fragment>
      <IonItem className='ion-no-padding'>
        <IonRow className="ion-justify-content-evenly ion-align-items-center ">
          <IonCol size="11">
            <IonLabel className="ion-text-left">
              {t(props.workName)}
            </IonLabel>
          </IonCol>
          <IonCol size='1' className={'ion-text-right'}>
            <IonCheckbox disabled={true} checked={props.checked} />
          </IonCol>
        </IonRow>
      </IonItem>
    </React.Fragment>
  );
};

export default OnboardingFormSummaryCertificates;
