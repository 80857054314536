
import { ionicColours } from '../../../components/UI/variables';
import i18n from '../../../i18n';

const getPropertiesByType: any = (type: string) => {
  const document_base_resource = `App\\Models\\`;
  switch (type) {
    case `${document_base_resource}File`:
      return {
        docColour: ionicColours.danger,
        categoryName: i18n.t('File'),
        route: `/project-tools/{project_id}/documents/{document_id}`,
        file: true,
        resourceType: `${document_base_resource}File`,
        state: {}
      };
    case `${document_base_resource}Documents\\Document`:
      return {
        docColour: ionicColours.danger,
        categoryName: i18n.t('Generic Document'),
        route: `/project-tools/{project_id}/documents/{document_id}`,
        file: true,
        resourceType: `${document_base_resource}Documents\\Document`,
        state: {}
      };
    case `${document_base_resource}ControlOfExecution`:
      return {
        docColour: ionicColours.secondary,
        categoryName: i18n.t('Control of execution'),
        resourceType: `${document_base_resource}ControlOfExecution`,
        route: `/project-tools/{project_id}/control-of-execution/{resource_id}`,
        file: true,
        state: { subpanel: 'control-of-execution' }
      };
    case `${document_base_resource}WorkingEnvironment`:
      return {
        docColour: ionicColours.warning,
        categoryName: i18n.t('Working environment'),
        resourceType: `${document_base_resource}WorkingEnvironment`,
        route: `/project-tools/reports/working_environment/{resource_id}`,
        state: {}
      };
    case `${document_base_resource}WorkingEnvironmentEntryReport`:
      return {
        docColour: ionicColours.medium,
        categoryName: i18n.t('Working environment entry report'),
        resourceType: `${document_base_resource}WorkingEnvironmentEntryReport`,
        route: `/project-tools/reports/working_environment_entry/{resource_id}`,
        state: {
        }
      };
    case `${document_base_resource}ObservationReport`:
      return {
        docColour: ionicColours.success,
        categoryName: i18n.t('Observation report'),
        resourceType: `${document_base_resource}ObservationReport`,
        route: `/project-tools/{project_id}/reports/observations/{resource_id}`,
        state: {}
      };
    case `${document_base_resource}IncidentReport`:
      return {
        docColour: ionicColours.mediumShade,
        categoryName: i18n.t('Incident report'),
        resourceType: `${document_base_resource}IncidentReport`,
        route: `/project-tools/{project_id}/reports/incidents/{resource_id}`,
        state: {}
      };
    case `${document_base_resource}SupplierInvoice`:
      return {
        docColour: ionicColours.secondary,
        categoryName: i18n.t('Supplier invoices'),
        resourceType: `${document_base_resource}SupplierInvoice`,
        route: `/project-tools/{project_id}/documents/{document_id}`,
        file: true,
        state: {}
      };
    case `${document_base_resource}EconomyMonitoring`:
      return {
        docColour: ionicColours.medium,
        categoryName: i18n.t('Economy monitoring'),
        resourceType: `${document_base_resource}EconomyMonitoring`,
        route: `/economy-tools/{project_id}/monitoring/{resource_id}`,
        state: {}
      };
    case `${document_base_resource}CustomerInvoice`:
      return {
        docColour: ionicColours.success,
        categoryName: i18n.t('Customer invoices'),
        resourceType: `${document_base_resource}CustomerInvoice`,
        route: `/project-tools/{project_id}/documents/{document_id}`,
        file: true,
        state: {}
      };
    case `${document_base_resource}Precalculation`:
      return {
        docColour: ionicColours.mediumShade,
        categoryName: i18n.t('Precalculations'),
        resourceType: `${document_base_resource}Precalculation`,
        route: `/economy-tools/{project_id}/precalculations?revision={resource_id}`,
        state: {
          subpanel: 'precalculations',
        }
      };
    case `${document_base_resource}EconomyDeviation`:
      return {
        docColour: ionicColours.success,
        categoryName: i18n.t('Deviations'),
        resourceType: `${document_base_resource}EconomyDeviation`,
        route: `/economy-tools/{project_id}/deviations/{resource_id}`,
        state: {}
      };
    case `${document_base_resource}EconomyChange`:
      return {
        docColour: ionicColours.danger,
        categoryName: i18n.t('Change'),
        resourceType: `${document_base_resource}EconomyChange`,
        route: `/economy-tools/{project_id}/changes/{resource_id}`,
        state: {}
      };
    default:
      return {
        docColour: ionicColours.primary,
        categoryName: type,
        state: {}
      };
  }
};

export default getPropertiesByType;
