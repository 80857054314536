import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';

export interface NavigationReducer {
  currentTab: string | undefined;
}

export const navigationSlice = createSlice<NavigationReducer,
  SliceCaseReducers<NavigationReducer>,
  string>({
    name: 'navigation',
    initialState: {
      currentTab: undefined
    },
    reducers: {
      setCurrentTab: (
        state: Draft<NavigationReducer>,
        action: PayloadAction<string>
      ) => {
        state.currentTab = action.payload;
      }
    }
  });

export const { setCurrentTab } = navigationSlice.actions;

export default navigationSlice.reducer;
