import type { TFunction } from 'i18next';

import toasters from '../../components/UI/Toasts';
import type { ToastTypes } from '../../components/UI/Toasts/interface';
import type { ColourVariables } from '../../components/UI/variables';

const Toast = (
  message: TFunction | string,
  type: ToastTypes = 'success',
  background: ColourVariables = 'var(--ion-color-light)'
) => {
  toasters.createToast({
    message,
    background: background || 'var(--ion-color-light)',
  }, type);
};

export default Toast;
