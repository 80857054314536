import { IonCol, IonIcon, IonRow } from '@ionic/react';
import classNames from 'classnames';
import { documentAttach } from 'ionicons/icons';
import React from 'react';

import styles from '../../styles/OnboardingFormSummary.module.scss';

interface CertDocInterface {
  filename: string
}

const CertificationFiles: React.FC<CertDocInterface> = (props) => {
  return (
    <React.Fragment>
      <IonRow className={classNames('ion-align-items-center ion-text-left', styles['file-row'])}>
        <IonCol size='1'>
          <IonIcon icon={documentAttach} size="large" />
        </IonCol>
        <IonCol size="11" className='ion-text-left'>
          <p>{props.filename}</p>
        </IonCol>
      </IonRow>
    </React.Fragment>
  );
};

export default CertificationFiles;
