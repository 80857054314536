import { IonCol, IonContent, IonFooter, IonGrid, IonItem, IonLabel, IonRow, IonToolbar, useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import type { AccessRequestCertificate } from '@techlove/easy2use-typings/src/V1/Models/AccessRequestCertificate';
import { useTranslate } from '@tolgee/react';
import { documentAttach, image } from 'ionicons/icons';
import fileDownload from 'js-file-download';
import React, { useState } from 'react';

import { networking } from '../../../../api/networking';
import toasters from '../../../../components/Toasts/Toasts';
import ButtonCTA from '../../../../components/UI/Buttons/CTA/ButtonCTA';
import ItemShadowNoIcons from '../../../../components/UI/Items/components/ItemShadowNoIcons';
import HeaderBorderLeft from '../../../../components/UI/modals/HeaderBorderLeft';
import { ionicColours } from '../../../../components/UI/variables';
import { useAppSelector } from '../../../../hooks';
import { setIsLoading } from '../../../../reducers/loading';
import { setSelectedProject } from '../../../../reducers/onboarding';
import store from '../../../../store';

interface CertificateListProps {
  certificates: AccessRequestCertificate[] | undefined;
  handleDownloadFile: (file: E2U.V1.Models.File) => void;
  title: string | undefined;
}
interface RequestDetails {
  details: E2U.V1.Models.ProjectAccessRequest
  detailToggle: () => void
}
const AccessRequestDetails: React.FC<RequestDetails> = ({ detailToggle, details }) => {
  const [message, setMessage] = useState<string>('');
  const [presentAlert] = useIonAlert();

  const { t } = useTranslate();

  const projects = useAppSelector(
    (state) => state.project.selectedProject
  );
  const handleDownloadFile = (file: E2U.V1.Models.File) => {
    if (typeof file.download_url === 'undefined' || typeof file === 'undefined' || typeof file.path === 'undefined') return;
    store.dispatch(setIsLoading({ key: 'downloading_file', value: true }));

    toasters.promise(
      networking.get(
        file.download_url,
        { responseType: 'blob' }
      ),
      {
        pending: t('Downloading file'),
        success: t('Downloaded Document'),
        error: t('Error downloading file')
      }
    ).then((response: any) => {
      fileDownload(response.data, file.path);
    }).finally(() => {
      store.dispatch(setIsLoading({ key: 'downloading_file', value: false }));
    });
  };

  const handleAccessRequest = (
    handler: string,
    message: string | undefined = undefined
  ) => {
    const actionString = handler === 'approve' ? 'Approving' : 'Rejecting';
    const completionString = handler === 'approve' ? 'Approved' : 'Rejected';
    toasters.promise(
      networking.post(`/api/v1/project_access_requests/${details.id}/${handler}`,
        message ? { message } : {}
      ),
      {
        pending: t(`${actionString} request`),
        success: t(`Successfully ${completionString} Request`),
        error: t(`Error ${actionString} request`)
      }
    )
      .then(() => {
        detailToggle();
      });
  };

  const rejectRequest = (message: string) => {
    handleAccessRequest('reject', message);
    detailToggle();
    store.dispatch(setSelectedProject(projects));
  };

  const acceptRequest = () => {
    handleAccessRequest('approve');
    detailToggle();
  };

  const acceptAlert = () => {
    presentAlert({
      header: t('Are you sure you want to acceps this request?'),
      buttons: [
        {
          text: t('No'),
          role: 'cancel',
        },
        {
          text: t('Yes'),
          role: 'confirm',
          handler: () => {
            acceptRequest();
          }
        }
      ],
    });
  };

  const rejectionAlert = () => {
    presentAlert({
      header: t('Are you sure you want to reject this request?'),
      buttons: [
        {
          text: t('No'),
          role: 'cancel',
        },
        {
          text: t('Yes'),
          role: 'confirm',
          handler: (values: {
            message: string
          }) => {
            rejectRequest(values.message);
          }
        }
      ],
      inputs: [
        {
          placeholder: t('Message'),
          type: 'textarea',
          value: setMessage(message),
          name: 'message'
        },

      ]
    });
  };

  const combinedCertificates = new Map<string, AccessRequestCertificate[] | undefined>();
  if (details.heavy_lifting) {
    combinedCertificates.set(t('Heavy lifting'), details.heavy_lifting_certificates);
  }
  if (details.hot_work) {
    combinedCertificates.set(t('Hot work'), details.hot_work_certificates);
  }
  if (details.work_from_lift) {
    combinedCertificates.set(t('Work from lift'), details.work_from_lift_certificates);
  }
  if (details.other_certificates) {
    combinedCertificates.set(t('Work at height'), details.other_certificates);
  }

  /* const combinedCertificates = [
    {
      label: t('Heavy lifting'),
      value: details.heavy_lifting_certificates
    }

  ]; */

  interface CertificateListProps {
    certificates: AccessRequestCertificate[] | undefined[];
    handleDownloadFile: (file: E2U.V1.Models.File) => void;
  }

  return (
    <React.Fragment>
      <HeaderBorderLeft clickHandler={detailToggle} title={t('Access request')} borderColour={ionicColours.success} />
      <IonContent className='ion-padding'>

        <IonItem className='ion-no-padding' lines='none'>
          <h2>{t('Personal information')}</h2>
        </IonItem>

        <IonItem className='ion-no-padding ion-margin-bottom'>
          <IonLabel className='ion-no-margin'>
            <h3>{t('Date of birth:')}</h3>
            <p>{details.personal_information?.personal_number}</p>
          </IonLabel>
        </IonItem>
        <IonGrid>
          <IonRow>
            <IonCol size='6'>
              <IonItem className='ion-no-padding ion-margin-bottom'>
                <IonLabel className='ion-no-margin'>
                  <h3>{t('Firstname')}:</h3>
                  <p>{details.personal_information?.first_name}</p>
                </IonLabel>
              </IonItem>
            </IonCol>
            <IonCol size='6'>
              <IonItem className='ion-no-padding ion-margin-bottom'>
                <IonLabel className='ion-no-margin'>
                  <h3>{t('Lastname')}:</h3>
                  <p >{details.personal_information?.last_name}</p>
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonItem className='ion-no-padding' lines='none'>
          <h2>{t('Company information')}</h2>
        </IonItem>
        <IonGrid>
          <IonRow>
            <IonCol size='6'>
              <IonItem className='ion-no-padding ion-margin-bottom'>
                <IonLabel className='ion-no-margin'>
                  <h3>{t('Company')}:</h3>
                  <p>{details.personal_information?.company_name}</p>
                </IonLabel>
              </IonItem>
            </IonCol>
            <IonCol size='6'>
              <IonItem className='ion-no-padding ion-margin-bottom'>
                <IonLabel className='ion-no-margin'>
                  <h3>{t('Org.nr')}:</h3>
                  <p >{details.personal_information?.organization_number}</p>
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonItem className='ion-no-padding' lines='none'>
          <h2>{t('Certificates and work')}</h2>
        </IonItem>
        <IonItem className='ion-no-padding ion-margin-bottom'>
          <IonLabel className='ion-no-margin'>
            <h3>{t('Heavy lifting:')}</h3>
            <p>{details.heavy_lifting ? t('Yes') : t('No')}</p>
          </IonLabel>
        </IonItem>

        <IonItem className='ion-no-padding ion-margin-bottom'>
          <IonLabel className='ion-no-margin'>
            <h3>{t('Hot work:')}</h3>
            <p>{details.hot_work ? t('Yes') : t('No')}</p>
          </IonLabel>
        </IonItem>

        <IonItem className='ion-no-padding ion-margin-bottom'>
          <IonLabel className='ion-no-margin'>
            <h3>{t('Work from lift:')}</h3>
            <p>{details.heavy_lifting ? t('Yes') : t('No')}</p>
          </IonLabel>
        </IonItem>

        <IonItem className='ion-no-padding' lines='none'>
          <h2>{t('Files')}</h2>
        </IonItem>
        <CertificateList certificates={details.work_from_lift_certificates} handleDownloadFile={handleDownloadFile} title={t('Work From Lift Certificates')} />
        <CertificateList certificates={details.heavy_lifting_certificates} handleDownloadFile={handleDownloadFile} title={t('Heavy Lifting Certificates')} />
        <CertificateList certificates={details.hot_work_certificates} handleDownloadFile={handleDownloadFile} title={t('Hot Work Certificates')} />
        <CertificateList certificates={details.other_certificates} handleDownloadFile={handleDownloadFile} title={t('Other Certificates')} />
      </IonContent>
      <IonFooter className='ion-no-border ion-margin-bottom ion-margin-top' color='primary'>
        <IonToolbar color={'none'}>
          <IonGrid>
            <IonRow className='ion-justify-content-evenly'>
              <IonCol size='6' className='ion-text-center'>
                <ButtonCTA disabled={(details as unknown as { status: string }).status === 'rejected'} clickHandler={rejectionAlert} content='reject' backgroundColour={ionicColours.danger} />
              </IonCol>
              <IonCol size='6' className='ion-text-center'>
                <ButtonCTA disabled={(details as unknown as { status: string }).status === 'approved'} clickHandler={acceptAlert} content='accept' backgroundColour={ionicColours.success} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </React.Fragment>
  );
};

export const CertificateList: React.FC<CertificateListProps> = ({ certificates, handleDownloadFile, title }) => {
  const { t } = useTranslate();
  if (!certificates) return null;
  return (
    <>
      {certificates.length > 0 && (
        <>
          <h4 style={{ color: 'var(--ion-color-medium-tint)', fontWeight: 600, opacity: '0.7', textDecoration: 'underline' }}>{title}</h4>
          {certificates.map((e: any, i: number) => {
            return (
              <>
                <ItemShadowNoIcons
                  key={i}
                  hasIconLeft={true}
                  iconLeft={e.file.type === 'image' ? image : documentAttach}
                  label={e.file.type === 'image' ? t('Image') : t('File')}
                  description={e.file.name}
                  subLabel={''}
                  hasBorder={false}
                  onItemClick={() => handleDownloadFile(e.file)}
                  backgroundLeft={e.file.type === 'image' ? `${e.file.export_url}?base64=true` : undefined}
                >
                  <form method="get" action={e.file.download_url}>
                    <button type="submit">
                      {e.file?.name}
                    </button>
                    <p onClick={() => handleDownloadFile(e.file)}></p>
                  </form>
                </ItemShadowNoIcons></>
            );
          })}
        </>

      )}
    </>
  );
};

export default AccessRequestDetails;
