import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { informationCircleSharp } from 'ionicons/icons';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import PasswordChange from './PasswordChange';
import UpdateUser from './UpdateUser';
import styles from './UserSettingsStyle.module.scss';
import ListTitle from '../../../../components/UI/Titles/ListTitle';
import { useAppSelector } from '../../../../hooks';

const UserSettingsForm: React.FC = () => {
  const { t } = useTranslate();
  const user = useAppSelector(state => state.authentication);

  const userSettings = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: user.user
  });

  const passwordUpdate = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      password_confirm: ''
    }
  });
  const location = useLocation();

  return (
    <div className={styles['user-settings-form-wrapper']}>
      <FormProvider {...userSettings}>
        <UpdateUser />
      </FormProvider>
      <FormProvider {...passwordUpdate}>
        <PasswordChange />
      </FormProvider>
      <div className={styles['user-setting-form-container']}>
        <IonGrid className='ion-margin-start ion-margin-end'>
          <IonRow className='ion-justify-content-center'>
            <IonCol size='12' className='ion-margin-bottom'>
              <ListTitle label={t('Need support?')} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <IonItem className='ion-no-padding ion-no-margin' detail={true}>
                <IonIcon icon={informationCircleSharp} size='large' color='medium' />
                <a href={`/support`} style={{ textDecoration: 'none' }}>
                  <IonLabel className='ion-no-margin ion-margin-start ' color={'dark'}>
                    <h2>{t('Go to our support page')}</h2>
                  </IonLabel>
                </a>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default UserSettingsForm;
