import { IonCol, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import React, { useEffect } from 'react';
import { useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { networking } from '../../../api/networking';
import Toast from '../../../components/Toasts/Toast';
import { setSelectedFile } from '../../../reducers/file';
import { setIsLoading } from '../../../reducers/loading';
import DocumentForm from '../DocumentForm';
import styles from '../DocumentMetadataForm/DocumentMetadataForm.module.scss';

const DocumentsEditPage: React.FC = (props) => {
  const store = useStore();
  const history = useHistory();
  const { documentUuid } = useParams<{ documentUuid: string | undefined }>();
  const { t } = useTranslate();

  const fetchSelectedDocument = () => {
    if (typeof documentUuid === 'undefined' || documentUuid === '') {
      return;
    }
    store.dispatch(setIsLoading({ name: 'document', value: true }));
    const searchParams = new URLSearchParams();
    searchParams.append('with[]', 'files');
    networking
      .get(`/api/v1/documents/${documentUuid}?${searchParams.toString()}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Document>) => {
        store.dispatch(setSelectedFile(response.data.data));
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
        Toast(t('Document not found'), 'error');
        if (history.length) {
          history.goBack();
        } else {
          history.push('/project-tools');
        }
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'document', value: false }));
      });
  };

  useEffect(() => {
    fetchSelectedDocument();
    return () => {
      store.dispatch(setSelectedFile(undefined));
    };
  }, []);

  useEffect(() => fetchSelectedDocument(), [documentUuid]);

  return (
    <section className={styles['file-form']}>
      <IonRow className={'ion-padding'}>
        <IonCol size={'12'}>
          <IonRow>
            <IonCol size={'12'}>
              <DocumentForm />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </section>
  );
};

export default DocumentsEditPage;
