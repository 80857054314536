import { createSlice } from '@reduxjs/toolkit';
import type { Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface SubpanelList {
  projectInformation: boolean;
  precalculations: boolean;
  productionBudgets: boolean;
  subpanelsOnlyOnLock: boolean;
}

export interface SubpanelLockReducer {
  isLocked: SubpanelList;
  isLockable: boolean;
  softUnlock: boolean;
}

export const subpanelLockSlice = createSlice<
  SubpanelLockReducer,
  SliceCaseReducers<SubpanelLockReducer>,
  string
>({
  name: 'subpanelLock',
  initialState: {
    isLocked: {
      projectInformation: false,
      precalculations: false,
      productionBudgets: false,
      subpanelsOnlyOnLock: false
    },
    isLockable: false,
    softUnlock: false
  },
  reducers: {
    setIsLocked: (
      state: Draft<SubpanelLockReducer>,
      action: PayloadAction<{ name: keyof SubpanelList; value: boolean }>
    ) => {
      state.isLocked[action.payload.name] = action.payload.value;
    },

    setAll: (
      state: Draft<SubpanelLockReducer>,
      action: PayloadAction<boolean>
    ) => {
      (Object.keys(state.isLocked) as (keyof SubpanelList)[]).map((key) => {
        return (state.isLocked[key] = action.payload);
      });
    },

    setIsLockable: (
      state: Draft<SubpanelLockReducer>,
      action: PayloadAction<boolean>
    ) => {
      state.isLockable = action.payload;
    },
    softUnlock: (
      state: Draft<SubpanelLockReducer>,
      action: PayloadAction<{ name: keyof SubpanelList; value: boolean }>
    ) => {
      state.isLocked[action.payload.name] = action.payload.value;
    }
  }
});

export const { setAll, setIsLockable, setIsLocked, softUnlock } = subpanelLockSlice.actions;

export default subpanelLockSlice.reducer;
