
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonModal, IonRow, } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { arrowBack, arrowForward, locationOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { networking } from '../../../../api/networking';
import EmptyList from '../../../../components/UI/EmptyList';
import { matchOnboarding } from '../../../../constants/onboarding';
import { useAppSelector } from '../../../../hooks';
import useCustomModalAnimations from '../../../../hooks/useCustomModalAnimation';
import useModal from '../../../../hooks/useModal';
import usePermissionHandler from '../../../../hooks/usePermissions';
import { setSelectedProject } from '../../../../reducers/onboarding';
import { reloadProjects } from '../../../../reducers/project';
import store from '../../../../store';
import modalStyles from '../../Components/containers/styles/ModalContainers.module.scss';
import SiteAccessButton from '../buttons/SiteAccessButton';
import Modal from '../modals/Modal';
import NoAccessModal from '../modals/NoAccessModal';
import OnboardingStart from '../modals/OnboardingStart';
import styles from '../modals/styles/PersonalOverview.module.scss';

interface PersonalOverviewContentProps {
  toggle?: () => void;
}

const PersonalOverviewContent: React.FC<PersonalOverviewContentProps> = (props) => {
  const [hasAccess, setHasAccess] = useState(false);
  const [clockedTime, setClockedTime] = useState<string>('');
  const { closeModal, isModalOpen, openModal } = useModal();
  const { dismiss, enterAnimation, leaveAnimation, modal } = useCustomModalAnimations();
  const { checkPermission } = usePermissionHandler();
  const { uuid } = useParams<{ uuid: string }>();
  const history = useHistory();
  const { t } = useTranslate();

  const projects: E2U.V1.Models.Project[] | undefined = useAppSelector(state => state.project.projects);

  const checkin = (project: E2U.V1.Models.Project) => {
    if (!project.has_site_access) {
      setHasAccess(!hasAccess);
    }
    networking.post(`/api/v1/projects/${project.id}/${!project.checked_in ? 'checkin' : 'checkout'}`)
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(reloadProjects(undefined));
      });
  };

  const accessModalController = () => {
    setHasAccess(!hasAccess);
  };

  const toggleStart = (project: E2U.V1.Models.Project) => {
    store.dispatch(setSelectedProject(project));
    openModal();
  };

  const handleMapRedirect = (project: E2U.V1.Models.Project) => {
    store.dispatch(setSelectedProject(project));
    history.push(`/project-tools/${project.id}/worksites`);
    props.toggle && props.toggle();
  };

  useEffect(() => {
    setClockedTime(clockedTime);
    return () => {
      store.dispatch(setSelectedProject(undefined));
    };
  }, [clockedTime]);

  useEffect(() => {
    if (!history.location.pathname.match(new RegExp(matchOnboarding))) {
      dismiss();
    }
  }, [history.location.pathname]);

  const permissionsForProjects = () => {
    return projects?.filter((project) => {
      return checkPermission('project_tools_access', 'read', project);
    });
  };

  return (
    <>
      <IonContent
        className={classNames(
          styles['ion-padding'],
          styles['personaloverview-content-container']
        )}
      >
        {projects && projects.length === 0 && (
          <EmptyList title={t('No projects available')} message={t('Contact your supervisor to be added to a project or create one.')} />
        )}
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonList
                className={styles['project-checkin-container']}>
                {permissionsForProjects()?.map((project) => {
                  return (
                    <div key={project.id} className='ion-margin-bottom'>
                      <IonLabel slot="stacked">{project.name}</IonLabel>
                      <IonItem color='none' lines='full'>
                        <IonButtons className='ion-no-margin'>
                          <div>
                            <IonButton
                              shape="round"
                              fill="solid"
                              onClick={() => checkin(project)}
                              {...(!project.checked_in) ? { color: 'danger' } : { color: 'success' }}
                            >
                              <IonIcon
                                {...(!project.checked_in
                                  ? { size: 'large', icon: arrowForward }
                                  : { size: 'large', icon: arrowBack }
                                )}
                              />
                            </IonButton>

                            <p className='ion-no-margin'>
                              {project.checked_in ? t('Check out') : t('Check in')}
                            </p>
                          </div>
                          <div>
                            <IonButton shape="round" color="none" fill="clear" onClick={() => handleMapRedirect(project)}>
                              <IonIcon size="large" icon={locationOutline} />
                            </IonButton>
                            <p className='ion-no-margin'>{t('Map')}</p>
                          </div>
                          <div>
                            <SiteAccessButton project={project} onClick={toggleStart} />
                          </div>
                        </IonButtons>
                      </IonItem>
                    </div>
                  );
                })}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonModal
        isOpen={isModalOpen}
        onIonModalDidDismiss={() => closeModal()}
        ref={modal}
        className={modalStyles['onboarding-start-modal']}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
      >
        <OnboardingStart dismiss={dismiss} />
      </IonModal>

      <Modal
        toggleOnClick={accessModalController}
        state={hasAccess}
        title={''}
        modalContainerClassName={'access-info-modal'}
      >
        <NoAccessModal onClose={accessModalController} />
      </Modal>

    </>
  );
};

export default PersonalOverviewContent;
