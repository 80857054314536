import { IonIcon } from '@ionic/react';

interface IconTypes {
  link: string
  icon: string
  width: any
  isActive: boolean
}

const IconComponent: React.FC<IconTypes> = ({
  icon,
  isActive,
  link,
  width
}) => {
  const color = isActive ? 'var(--ion-color-primary)' : '#AAAAAA';
  return (
    <a href={link}>
      <IonIcon
        slot="start"
        icon={icon}
        style={{ fontSize: width, color }}
      />
    </a>
  );
};

export default IconComponent;
