import { IonContent, IonModal } from '@ionic/react';

import styles from './BottomSheet.module.scss';
import type BottomSheetProps from './interface';

const BottomSheet: React.FC<BottomSheetProps> = (props, { bottombottomSheetRef, contentProps }) => {
  return (
    <IonModal style={{ '--height': props.sheetHeight || '500px' }}
      {...props}
      ref={bottombottomSheetRef}
      trigger={props.bottomSheetTrigger}
      initialBreakpoint={props.initialBreakpoint || 1} breakpoints={props.breakpoints || [0, 1]}
    >
      <IonContent {...contentProps} className={styles.bottomSheetContent}>
        {props.children}
      </IonContent>
    </IonModal>
  );
};
export default BottomSheet;
