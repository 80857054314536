import { IonCol, IonGrid, IonRow } from '@ionic/react';

import SearchbarUnderlined from './Search/SearchbarUnderlined';
import type { SearchAndSortInterface } from './SearchAndSort';
import SortByDropdown from './Sort/SortByDropdown';

const SearchAndSortRow: React.FC<SearchAndSortInterface> = ({ children, debounceInterval, horizontal, onSearch, onSort, placeholder, value }) => {
  return (
    <IonGrid >
      <IonRow className='ion-align-items-end ion-justify-content-between ion-margin-bottom' style={{ maxWidth: '600px' }}>
        <IonCol size={horizontal ? '12' : '5.7'} sizeMd={horizontal ? '12' : '5.3'} className='ion-no-padding ion-no-margin'>
          <SearchbarUnderlined onSearch={onSearch} value={value} />
        </IonCol>
        <IonCol size={horizontal ? '12' : '5.7'} sizeMd={horizontal ? '12' : '5.3'} className='ion-no-padding'>
          <SortByDropdown onSort={onSort} >
            {children}
          </SortByDropdown>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default SearchAndSortRow;
