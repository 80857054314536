import * as Sentry from '@sentry/capacitor';
import { useState } from 'react';

const useRefresh = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleRefresh = async () => {
    try {
      window.location.reload();
      setIsLoading(true);
    } catch (error) {
      Sentry.captureException(error);
      setIsLoading(false);
    }
  };

  return { isLoading, handleRefresh };
};

export default useRefresh;
