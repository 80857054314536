import { IonCol, IonGrid, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { newspaper } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

import type { FormLayoutProps } from './form.interface';
import NoticeAccessFilter from './NoticeAccessFilter';
import EntitySelect from './NoticeEntitySelect';
import NoticeLevel from './NoticeLevel';
import NoticeMessage from './NoticeMessage';
import NoticeNotifications from './NoticeNotifications';
import NoticePublishHandling from './NoticePublishHandling';
import NoticeSubject from './NoticeSubject';
import { networking } from '../../../api/networking';
import DesktopWrapper from '../../../components/DesktopWrapper';
import BigUp from '../../../components/UI';
import Breadcrumbs from '../../../components/UI/Breadcrumbs/Breadcrumbs';
import ListTitle from '../../../components/UI/Titles/ListTitle';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';
import { useBreadcrumbConfig } from '../../../hooks/useBreadcrumbConfig';

const NoticeFormPage: React.FC = () => {
  const { t } = useTranslate();
  const history = useHistory();
  const breadcrumbs = useBreadcrumbConfig();
  const { notice_uuid } = useParams<{ notice_uuid: string | undefined }>();
  const [notice, setNotice] = useState<E2U.V1.Models.Notice | undefined>(undefined);
  const project = useAppSelector(state => state.project.selectedProject);
  const selectedProjectID = useAppSelector(state => state.project.selectedProject?.id);

  const getSelectedNotice = async () => {
    if (!notice_uuid) return;
    try {
      networking.get(`/api/v1/notices/${notice_uuid}?with=teams`).then(
        (response: E2U.V1.Response.Success<E2U.V1.Models.Notice>) => {
          setNotice(response.data.data as E2U.V1.Models.Notice); // TODO: Fix me when typings package is sorted
        }
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const methods = useForm<any>({
    mode: 'onTouched',
    defaultValues: {
      subject: '',
      publish_at: '',
      level: 'info',
      message: '',
      expire_at: '',
      project_access_filter: 'onsite', // TODO: Handle this
      notification_methods: 0,
      teams: [],
      users: []
    }
  });

  const handleTeamSubmit = (teams: E2U.V1.Models.Team[], noticeId: E2U.V1.Models.Notice['id']) => {
    return Promise.all(
      teams.map((team: E2U.V1.Models.Team) =>
        networking.post(`/api/v1/notices/${noticeId}/teams/${team}`)
      )
    );
  };

  const handleUserSubmit = (users: E2U.V1.Models.User[], noticeId: E2U.V1.Models.Notice['id']) => {
    return Promise.all(
      users.map((user: any) =>
        networking.post(`/api/v1/notices/${noticeId}/users/${user}`)
      )
    );
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
    const teams = methods.getValues('teams') as E2U.V1.Models.Team[];
    const users = methods.getValues('users') as E2U.V1.Models.User[];

    try {
      if (notice_uuid) {
        await networking.put(`/api/v1/notices/${notice_uuid}`, { ...data })
          .then((response: E2U.V1.Response.Success<E2U.V1.Models.Notice>) => {
            Promise.all([
              handleTeamSubmit(teams, notice_uuid),
              handleUserSubmit(users, notice_uuid)
            ]);
            history.push(`/notice-board/${selectedProjectID}/${notice_uuid}`);
            toasters.createToast({
              message: t('Notice {{notice}} updated', { notice: response.data.data.subject }),
              background: 'var(--ion-color-light)',
              icon: newspaper
            }, 'success');
          });
      } else {
        await networking.post(`/api/v1/notices`, {
          ...data,
          project_id: selectedProjectID,
        })
          .then((response: E2U.V1.Response.Success<E2U.V1.Models.Notice>) => {
            const newNoticeId = response.data.data.id;
            Promise.all([
              handleTeamSubmit(teams, newNoticeId),
              handleUserSubmit(users, newNoticeId)
            ]);
            methods.reset();
            toasters.createToast({
              message: t('Successfully created {{notice}}', { notice: response.data.data.subject }),
              background: 'var(--ion-color-light)',
              icon: newspaper
            }, 'success');
          });
      }
    } catch (error: any | E2U.V1.Response.Error<E2U.V1.Models.Notice>) {
      const errorData = error.response?.data.data;
      if (errorData) {
        Object.entries(errorData).forEach(([fieldName, fieldErrors]) => {
          const errorMessage = (fieldErrors as string[])[0];
          methods.setError(fieldName as any, {
            type: 'server',
            message: errorMessage,
          });
        });
      }
      Sentry.captureException(error);
      toasters.createToast({
        message: notice_uuid
          ? t('Notice could not be updated')
          : t('Notice could not be posted'),
        background: 'var(--ion-color-light)',
      }, 'error');
    }
  };

  const listTitle = notice_uuid ? t('Edit notice') : t('Create notice');

  const handleNoticeChange = (notice: E2U.V1.Models.Notice) => {
    methods.setValue('subject', notice.subject);
    methods.setValue('level', notice.level ?? '');
    methods.setValue('message', notice.message);
    methods.setValue('publish_at', notice?.publish_at);
    methods.setValue('expire_at', notice.expire_at);
    methods.setValue('project_access_filter', notice.project_access_filter);
    methods.setValue('notification_methods', notice.notification_methods);
    methods.setValue('teams', notice.teams);
    methods.setValue('users', notice.users);
  };

  useEffect(() => {
    if (notice) {
      handleNoticeChange(notice);
    }
  }, [notice]);

  useEffect(() => {
    if (typeof notice === 'undefined' && notice_uuid) {
      getSelectedNotice();
    }
  }, [notice_uuid, selectedProjectID]);

  useEffect(() => {
    if (notice) {
      handleNoticeChange(notice);
    }
  }, []);

  return (
    <DesktopWrapper width='var(--ion-desktop-mid-width)' alignment={'center'}>
      <Breadcrumbs data={breadcrumbs.noticeFormBreadcrumbs} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <IonGrid className='ion-padding'>
            <h1>{notice?.subject}</h1>
            <LayoutRowCol row={{ className: 'ion-margin-bottom' }}>
              <ListTitle label={listTitle} />
            </LayoutRowCol>
            <LayoutRowCol><NoticeSubject /></LayoutRowCol>
            <LayoutRowCol><NoticeMessage /></LayoutRowCol>
            <LayoutRowCol><NoticeLevel /></LayoutRowCol>
            <LayoutRowCol><NoticeAccessFilter></NoticeAccessFilter></LayoutRowCol>
            <IonRow className='ion-justify-content-between ion-no-padding ion-padding-top'>
              <IonCol size='6' className='ion-text-center'>
                <EntitySelect model="teams" label={t('Select teams')} modalTitle={t('Select teams')} />
              </IonCol>
              <IonCol size='6' className='ion-text-center'>
                <EntitySelect model="users" label={t('Select users')} modalTitle={t('Select users')} />
              </IonCol>
            </IonRow>
            <LayoutRowCol><NoticePublishHandling /></LayoutRowCol>
            <LayoutRowCol><NoticeNotifications /></LayoutRowCol>
            <IonGrid className='ion-padding-horizontal'>
              <IonRow className='ion-justify-content-between'>
                <IonCol size='4' className='ion-text-left'>
                  <BigUp.Buttons.Regular
                    expand='block'
                    color={'light'}
                    title={t('Cancel')}
                    onClick={() => history.goBack()}
                  />
                </IonCol>
                <IonCol size='4' className='ion-text-right'>
                  <BigUp.Buttons.Regular
                    expand='block'
                    color={'secondary'}
                    title={t('Save')}
                    type='submit'
                    disabled={!methods.formState.isValid || methods.formState.isSubmitting}
                  />
                </IonCol>
              </IonRow>

            </IonGrid>
          </IonGrid>
        </form>
      </FormProvider>
    </DesktopWrapper>
  );
};

const LayoutRowCol: React.FC<FormLayoutProps> = ({ children, col, row }) => {
  return (
    <IonRow {...row}>
      <IonCol {...col}>
        {children}
      </IonCol>
    </IonRow>
  );
};

export default NoticeFormPage;
