import { IonItem, IonSelect } from '@ionic/react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import styles from './SortByDropdown.module.scss';
import type { DropDownSort } from '../SearchAndSort';

const SortByDropdown: React.FC<DropDownSort> = ({ children, onSort }) => {
  const [sortBy, setSortBy] = useState<string>('created_at');

  useEffect(() => {
    onSort(sortBy);
  }, [sortBy]);

  return (
    <React.Fragment>
      <IonItem className={classNames(styles['sort-by-dropdown'], 'ion-no-padding')}>
        <IonSelect
          placeholder={'Sort by'}
          className='ion-padding-top'
          onIonChange={(event) => setSortBy(event.detail.value ?? '')}
        >
          {children}
        </IonSelect>
      </IonItem>
    </React.Fragment>
  );
};

export default SortByDropdown;
