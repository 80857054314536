import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import AmendmentForm from '../../../../components/Page/Economy/AmendmentForm';
import ProgressBar from '../../../../components/UI/Loaders/ProgressBar';
import useFetchData from '../../../../hooks/useFetch';

const EditDeviation = () => {
  const { deviation_uuid } = useParams<{ deviation_uuid: string | undefined }>();
  const [deviation, setDeviation] = useState<E2U.V1.Models.EconomyDeviation>();
  const { fetchData, isLoading } = useFetchData<E2U.V1.Models.EconomyDeviation, any>({
    initialData: null
  });

  const params = new URLSearchParams();
  params.append('with[]', 'rows');
  params.append('with[]', 'files');

  const getRoute = `/api/v1/economy_deviations/${deviation_uuid}?${params.toString()}`;

  const onThen = (response: E2U.V1.Response.Success<E2U.V1.Models.EconomyDeviation>) => {
    setDeviation(response.data.data);
  };

  const onCatch = (error: E2U.V1.Response.Error<E2U.V1.Models.EconomyDeviation>) => {
    Sentry.captureException(error);
  };

  useEffect(() => {
    if (deviation_uuid) {
      fetchData(getRoute, onThen, onCatch);
    }
  }, [deviation_uuid]);

  return (
    <React.Fragment>
      {isLoading
        ? <ProgressBar />
        : <AmendmentForm type="deviations" amendment={deviation} />
      }
    </React.Fragment>
  );
};

export default EditDeviation;
