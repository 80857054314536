import type {
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import {
  createSlice
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface EconomyReportReducer {
  economyReport: E2U.V1.Models.EconomyMonitoring | undefined;
  economyReportRows: E2U.V1.Models.EconomyMonitoringRow | undefined;
  changeTile: E2U.V1.Models.Address | undefined;
  deviationTile: E2U.V1.Models.Address | undefined;
  standardTile: E2U.V1.Models.Address | undefined;
  selectedIndex: number;
}

export const economyReportSlice = createSlice<
  EconomyReportReducer,
  SliceCaseReducers<EconomyReportReducer>,
  string
>({
  name: 'economyReport',
  initialState: {
    economyReport: undefined,
    economyReportRows: undefined,
    changeTile: undefined,
    deviationTile: undefined,
    standardTile: undefined,
    selectedIndex: 0
  },

  reducers: {
    setEconomyReport: (
      state: EconomyReportReducer,
      action: PayloadAction<E2U.V1.Models.EconomyMonitoring | undefined>
    ) => {
      state.economyReport = action.payload;
    }
  }
});

export const {
  changeTile,
  deviationTile,
  economyReport,
  economyReportRows,
  setEconomyReport,
  standardTile
} = economyReportSlice.actions;

export default economyReportSlice.reducer;
