import { IonLabel } from '@ionic/react';
import classNames from 'classnames';

import styles from './Tiles.module.scss';
import type { StandardTileProps } from './TilesTypings';
import formatNumber from '../../../../tools/formatNumber';

const StandardTile: React.FC<StandardTileProps> = ({ background, field, label, row, whiteSpace }) => {
  return (
    <div className={classNames([styles.tile])} style={{ ...background ? { backgroundColor: background } : {}, whiteSpace: whiteSpace || 'nowrap' }}>
      <div className={styles.container}>
        <IonLabel className={`${classNames(styles.title)} ion-text-wrap`} >{label}</IonLabel>
        <p className={classNames(styles.value)}>
          {row ? (!row[field] ? '-' : formatNumber(row[field] as number)) : ''}
        </p>
      </div>
    </div>
  );
};
export default StandardTile;
