import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonText
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { arrowForward, camera, document } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useStore } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';

import styles from './IncidentReports.module.scss';
import { networking } from '../../../../api/networking';
import Toast from '../../../../components/Toasts/Toast';
import { useAppSelector } from '../../../../hooks';
import { setIsLoading } from '../../../../reducers/loading';
import { setIncidentReports } from '../../../../reducers/project';
import { setIncidents } from '../../../../reducers/reports';

const IncidentReports: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslate();
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const store = useStore();
  const incidents: E2U.V1.Models.IncidentReport[] | undefined = useAppSelector(
    (state) => state.reports.incidents
  );
  const fetchIncidents = () => {
    store.dispatch(setIsLoading({ name: 'projects', value: true }));
    networking
      .get(`/api/v1/projects/${uuid}/incident_reports?with=files`)
      .then(
        (
          response: E2U.V1.Response.Success<
            E2U.V1.Objects.PaginatedData<E2U.V1.Models.IncidentReport>
          >
        ) => {
          console.log('response.data.data.records', response.data.data.records);
          store.dispatch(setIncidents(response.data.data.records));
        }
      )
      .catch((response: E2U.V1.Response.Error) => {
        console.warn('E2U Errors', response);
        Toast(t('Failed to load incidents'), 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'projects', value: false }));
      });
  };

  const getImageCount = (incident: E2U.V1.Models.IncidentReport) => {
    return incident.files
      ? incident.files.filter((doc) => {
        return doc.type === 'image';
      }).length
      : 0;
  };
  const getFileCount = (incident: E2U.V1.Models.IncidentReport) => {
    return incident.files
      ? incident.files.filter((doc) => {
        return doc.type !== 'image';
      }).length
      : 0;
  };

  const showReport = (incidentId?: string) => {
    history.push(`${match.url}/incidents/${incidentId}`);
  };

  useEffect(() => {
    fetchIncidents();
    return () => {
      setIncidentReports(undefined);
    };
  }, []);

  return (
    <>
      {incidents
        ? (
          incidents.map((incident, i) => {
            return (
              <IonCard className={styles['report-list-card']} key={i}>
                <IonCardContent
                  onClick={() => showReport(incident.id)}
                  className={styles['ion-list-card-content']}
                >
                  <IonGrid>
                    <IonRow className={'ion-justify-content-between'}>
                      <IonCol size="7">
                        <IonItem lines="none">
                          <IonLabel className={styles['incident-name']}>
                            {incident.name}
                          </IonLabel>
                        </IonItem>
                      </IonCol>

                      <IonCol size={'true'}>
                        <IonText>{getImageCount(incident)}</IonText>
                        <IonIcon
                          className={styles['report-icons']}
                          icon={camera}
                        ></IonIcon>
                        <IonText>{getFileCount(incident)}</IonText>
                        <IonIcon
                          className={styles['report-icons']}
                          icon={document}
                        />
                        <IonIcon
                          className={classNames(
                            styles['report-icons'],
                            styles['open-icon']
                          )}
                          icon={arrowForward}
                        ></IonIcon>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            );
          })
        )
        : (
          <IonCard>
            <IonCardContent>{t('No incident reports')}</IonCardContent>
          </IonCard>
        )}
    </>
  );
};

export default IncidentReports;
