
import { BigUp } from '../../../components/UI';
import type { InputProps } from '../../../components/UI/Inputs/Input.interface';
import i18n from '../../../i18n';

const input_validate: InputProps<any>['validation'] = {
  required: i18n.t('A subject is required') as any,
  maxLength: { value: 50, message: i18n.t('Subject can\'t be longer than 50'), },
  minLength: { value: 1, message: i18n.t('Subject can\'t be 0'), },
};

const subject_input: InputProps<any> = {
  label: i18n.t('Subject'),
  type: 'text',
  inputMode: 'text',
  placeholder: i18n.t('Subject'),
  register: 'subject',
  validation: input_validate,
};

const NoticeSubject: React.FC = () => {
  return <BigUp.Input {...subject_input} />;
};

export default NoticeSubject;
