import { IonLoading } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Redirect, Route, matchPath, useHistory } from 'react-router';

import { networking } from '../api/networking';
import { useAppSelector } from '../hooks';
import { setProfilePicture, setShouldRedirect, setUser } from '../reducers/authentication';
import { setIsLoading } from '../reducers/loading';
import storage from '../storage';

const ProtectedRoute: React.FC<any> = (props) => {
  const store = useStore();
  const { t } = useTranslate();
  const history = useHistory();
  const [silentLoading, setSilentLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const user = useAppSelector(state => state.authentication.user);

  const handlePathMatching = () => {
    return (
      !matchPath(location.pathname, '/login') &&
      !matchPath(location.pathname, '/reset') &&
      !matchPath(location.pathname, '/join')
    );
  };

  const fetchUser = () => {
    storage
      .get('access_token')
      .then((access_token: string) => {
        if (access_token) {
          networking
            .get('/api/v1/me', {})
            .then((result: E2U.V1.Response.Success<E2U.V1.Models.User>) => {
              store.dispatch(setUser(result.data.data));
              updateProfilePicture(result.data.data);
              if (matchPath(location.pathname, '/login')) {
                history.push('/');
              }
              setLoading(false);
            })
            .catch((error) => {
              Sentry.captureException(error);
              if (handlePathMatching()) {
                store.dispatch(setUser(null));
                setLoading(false);
              }
            });
        } else {
          handleLoggedOutUser();
        }
      })
      .catch(() => {
        handleLoggedOutUser();
      });
  };

  useEffect(() => {
    setSilentLoading(false);
    if (!user) {
      setLoading(true);
      fetchUser();
    } else {
      setLoading(false);
    }
    return () => {
      setLoading(false);
    };
  }, []);

  const handleLoggedOutUser = () => {
    setLoading(false);
    store.dispatch(setIsLoading({ name: 'user', value: false }));
    if (
      handlePathMatching()
    ) {
      store.dispatch(setShouldRedirect(true));
    }
  };

  const updateProfilePicture = (user: E2U.V1.Models.User) => {
    if (user.profile_picture_url) {
      axios.get(`${user.profile_picture_url}?base64=true`, {
        headers: {
          Accept: '*/*'
        }
      })
        .then((res) => {
          store.dispatch(setProfilePicture(`data:${res.headers['content-type']};base64,${res.data}`));
        });
    }
  };

  return (<>
    {
      (silentLoading || loading)
        ? <></>
        : (
          !user
            ? <Redirect to="/login" />
            : <Route {...props} />
        )
    }
    <IonLoading isOpen={loading} />
  </>);
};

export default ProtectedRoute;
