import { IonLabel } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import React from 'react';

import styles from './ActivityCodeTile.module.scss';
import { useAppSelector } from '../../../hooks';

interface ActivityTile {
  activityCode?: E2U.V1.Models.ActivityCode | undefined;
  label?: string | undefined
  description?: string | undefined;
  onClick?: () => void;
  summary?: string;
  minHeight?: string;
  name?: string;
}

const ActivityCodeTile: React.FC<ActivityTile> = ({
  activityCode,
  description,
  label,
  minHeight,
  name,
  onClick,
  summary
}) => {
  const { t } = useTranslate();

  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  return (
    <React.Fragment>
      {isDesktop
        ? (
          <div
            className={styles['act-code-tile-container-row']}
            style={minHeight
              ? { minHeight }
              : {}}>
            <div className={`${styles['name-box']} ${!onClick ? styles['no-pointer'] : ''}`} onClick={onClick ? () => { onClick(); } : undefined}>
              <div className={styles['act-code']}>
                {activityCode && <IonLabel position="stacked">{t('Act.code')} | {activityCode?.code}</IonLabel>}
                {label && <IonLabel position="stacked">{label}</IonLabel>}
              </div>
              <p className='ion-no-margin'>
                <span >
                  {name ?? `${t('Act.code')} | ${activityCode?.name}`}
                </span>
                <span className='ion-no-margin'>
                  {description}
                </span>
              </p>
            </div>
          </div>
        )
        : (
          <div className={styles['act-code-tile-container-col']} style={minHeight ? { minHeight } : {}}>
            <div className={styles['act-code']}>
              {activityCode && <p>{t('Act.code')} | {activityCode?.code}</p>}
              {label && <p>{label}</p>}
            </div>
            <div className={styles['name-box']} onClick={onClick ? () => onClick() : undefined}>
              {name
                ? <p>{name}</p>
                : (activityCode && <p>{activityCode?.name}</p>)}
              {description && <p>{description}</p>}
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default ActivityCodeTile;
