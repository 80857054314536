import { IonIcon } from '@ionic/react';
import { lockClosed, lockOpen } from 'ionicons/icons';
import React from 'react';

import type { LockIcon as LockIconInterface } from './Icons';
import styles from './Icons.module.scss';

const LockIcon: React.FC<LockIconInterface> = ({ isLocked, onClick }) => {
  return (
    <React.Fragment>
      {isLocked
        ? (
          <IonIcon
            icon={lockClosed}
            color="danger"
            className={styles['lock-animation']}
            onClick={onClick}
          />
        )
        : (
          <IonIcon
            icon={lockOpen}
            color="success"
            onClick={onClick}
          />
        )}
    </React.Fragment>
  );
};

export default LockIcon;
