import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface DocumentReducer {
  showAll: boolean;
  documentTypes: {
    [key: string]: {
      class: string;
      count: string;
      color?: string;
      id?: string;
      name?: string;
    }
  },
  currentDocumentPage: {
    [type: string]: number
  }
  totalDocumentPages: {
    [type: string]: number
  },
  paginatedDocuments: {
    [type: string]: {
      [page: number]: E2U.V1.Models.Document[]
    }
  },
  loadingTypes: {
    [type: string]: boolean|undefined
  }
}

export const documentSlice = createSlice<DocumentReducer,
  SliceCaseReducers<DocumentReducer>,
  string>({
    name: 'document',
    initialState: {
      showAll: true,
      documentTypes: {},
      currentDocumentPage: {},
      totalDocumentPages: {},
      paginatedDocuments: {},
      loadingTypes: {}
    },
    reducers: {
      setShowAll: (
        state: Draft<DocumentReducer>,
        action: PayloadAction<boolean>
      ) => {
        state.showAll = action.payload;
      },
      setDocumentTypes: (
        state: Draft<DocumentReducer>,
        action: PayloadAction<{
        [key: string]: {
          class: string;
          count: string;
        }
      }>
      ) => {
        state.documentTypes = action.payload;
      },
      setCurrentDocumentPage: (
        state: Draft<DocumentReducer>,
        action: PayloadAction<{ type: string, page: number }>
      ) => {
        state.currentDocumentPage[action.payload.type] = action.payload.page;
      },
      setTotalDocumentPages: (
        state: Draft<DocumentReducer>,
        action: PayloadAction<{ type: string, amount: number }>
      ) => {
        state.totalDocumentPages[action.payload.type] = action.payload.amount;
      },
      setPaginatedDocuments: (
        state: Draft<DocumentReducer>,
        action: PayloadAction<{
        type: string;
        page: number;
        total: number;
        documents: E2U.V1.Models.Document[]
      }>
      ) => {
        state.paginatedDocuments[action.payload.type] = {
          ...state.paginatedDocuments[action.payload.type],
          [action.payload.page]: action.payload.documents
        };
        state.totalDocumentPages[action.payload.type] = action.payload.total;
        state.currentDocumentPage[action.payload.type] = action.payload.page;
      },
      setLoadingTypes: (
        state: Draft<DocumentReducer>,
        action: PayloadAction<{
        type: string;
        loading: boolean;
      }>
      ) => {
        state.loadingTypes[action.payload.type] = action.payload.loading;
      }
    }
  });

export const {
  setCurrentDocumentPage,
  setDocumentTypes,
  setLoadingTypes,
  setPaginatedDocuments,
  setShowAll,
  setTotalDocumentPages
} = documentSlice.actions;

export default documentSlice.reducer;
