import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { chevronDown, chevronUp } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';

import { scrollToSection } from '../../tools/scrollToSection';

interface PaginateDataInterface {
  currentPage: number;
  totalPages: number | undefined;
  filteredPagination?: number | undefined;
  pageStepper: (step: number) => void;
  scrollToId?: string;
}

const PaginateData: React.FC<PaginateDataInterface> = ({ currentPage, filteredPagination, pageStepper, scrollToId, totalPages }) => {
  const { t } = useTranslate();
  const useFilteredPagination = filteredPagination !== undefined;
  const total = useFilteredPagination ? filteredPagination : totalPages;
  const prevTotal = useRef(total).current;

  const handlePaginationReset = () => {
    if (total === 1) {
      pageStepper(-currentPage + 1);
    }
    if (!scrollToId) {
      return;
    }
    scrollToSection(scrollToId);
  };

  useEffect(() => {
    if (total !== prevTotal) {
      handlePaginationReset();
    }
  }, [total]);

  return (
    <React.Fragment>
      <IonGrid className='ion-padding-bottom' style={{ marginBottom: 50 }}>
        <IonRow className='ion-align-items-center ion-justify-content-center'>
          <IonCol size='4' className='ion-text-center'>
            <IonButton
              fill='clear'
              {...currentPage > 1
                ? { disabled: false, color: 'tertiary', onClick: (e) => { pageStepper(-1); e.preventDefault(); } }
                : { disabled: false, color: 'medium' }
              }
            >
              <IonIcon icon={chevronUp} />
            </IonButton>
          </IonCol>
          <IonCol size='4' className='ion-text-center'>
            <p>{currentPage} {t('of')} {total === 1 ? 1 : totalPages}</p>
          </IonCol>
          <IonCol size='4' className='ion-text-center'>
            <IonButton fill='clear'
              {...currentPage !== total
                ? { disabled: false, color: 'tertiary', onClick: (e) => { pageStepper(+1); e.preventDefault(); } }
                : { disabled: false, color: 'medium' }
              }
            >
              <IonIcon icon={chevronDown} />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </React.Fragment>
  );
};

export default PaginateData;
