import { IonButton } from '@ionic/react';

import styles from './ButtonComponent.module.scss';

interface ButtonType {
  text: string
  link: string
  outline: boolean
  type: any
}

const ButtonComponent: React.FC<ButtonType> = ({
  link,
  outline,
  text,
  type
}) => {
  return (
    <IonButton
      fill={outline ? 'outline' : 'solid'}
      routerLink={link}
      type={type}
      className={styles['small-button']}
      shape="round"
      expand="block"
      color="primary"
    >
      {text}
    </IonButton>
  );
};

export default ButtonComponent;
