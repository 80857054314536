import {
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow
} from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import type { typeHeader } from './ReportsCardHeader';
import styles from './ReportsCardHeader.module.scss';

const ReportsCardHeader: React.FC<typeHeader> = ({
  iconLeft,
  iconRight,
  title
}) => {
  const history = useHistory();

  return (
    <>
      <IonCardHeader className={styles['card-header']}>
        <IonGrid>
          <IonRow>
            <IonCol size="11">
              <IonIcon icon={iconLeft} onClick={() => history.goBack()}></IonIcon>
              <IonCardTitle className={styles['card-title']}>
                {title}
              </IonCardTitle>
            </IonCol>
            <IonCol size="1" className="ion-justify-content-end">
              <IonIcon icon={iconRight}></IonIcon>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardHeader>
    </>
  );
};
export default ReportsCardHeader;
