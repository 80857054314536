export type ColourVariables =
  'var(--ion-color-primary)' |
  'var(--ion-color-secondary)' |
  'var(--ion-color-tertiary)' |
  'var(--ion-color-success)' |
  'var(--ion-color-warning)' |
  'var(--ion-color-danger)' |
  'var(--ion-color-dark)' |
  'var(--ion-color-medium)' |
  'var(--ion-color-medium-shade)' |
  'var(--ion-color-light)' |
  'none';

export type IonicThemeColours =
  'primary' |
  'secondary' |
  'tertiary' |
  'success' |
  'warning' |
  'danger' |
  'dark' |
  'medium' |
  'mediumShade' |
  'light' |
  'none';

export type IonButtonCSSVariables = {
    '--padding-start'?: number | string;
    '--padding-end'?: number | string;
    '--padding-top'?: number | string;
    '--padding-bottom'?: number | string;
    '--font-size'?: number | string;
    '--background'?: ColourVariables;
    '--background-activated'?: ColourVariables;
    '--background-activated-opacity'?: string;
    '--background-focused'?: ColourVariables;
    '--background-focused-opacity'?: string;
    '--background-hover'?: ColourVariables;
    '--background-hover-opacity'?: string;
    '--border-color'?: ColourVariables;
    '--border-radius'?: string;
    '--border-style'?: string;
    '--border-width'?: string;
    '--box-shadow'?: string;
    '--color'?: ColourVariables;
    '--color-activated'?: ColourVariables;
    '--color-focused'?: ColourVariables;
    '--color-hover'?: ColourVariables;
    '--opacity'?: string;
    '--ripple-color'?: ColourVariables;
    '--transition'?: string;
  };
export type IonicColSize =
  '1' |
  '2' |
  '3' |
  '4' |
  '5' |
  '6' |
  '7' |
  '8' |
  '9' |
  '10' |
  '11' |
  '12' |
  undefined;

type CustomBorder = Record<string, `4px solid ${ColourVariables}`>

export const ionicColours: Record<IonicThemeColours, ColourVariables> = {
  primary: 'var(--ion-color-primary)',
  secondary: 'var(--ion-color-secondary)',
  tertiary: 'var(--ion-color-tertiary)',
  success: 'var(--ion-color-success)',
  warning: 'var(--ion-color-warning)',
  danger: 'var(--ion-color-danger)',
  dark: 'var(--ion-color-dark)',
  medium: 'var(--ion-color-medium)',
  mediumShade: 'var(--ion-color-medium-shade)',
  light: 'var(--ion-color-light)',
  none: 'none'
};

export const customBorder: CustomBorder = {
  borderColourPrimary: `4px solid ${ionicColours.primary}`,
  borderColourSecondary: `4px solid ${ionicColours.secondary}`,
  borderColourTertiary: `4px solid ${ionicColours.tertiary}`,
  borderColourWarning: `4px solid ${ionicColours.warning}`,
  borderColourDark: `4px solid ${ionicColours.dark}`,
  borderColourLight: `4px solid ${ionicColours.light}`,
  borderColourGreen: `4px solid ${ionicColours.success}`,
  borderColourGray: `4px solid ${ionicColours.medium}`,
  borderCoulorRed: `4px solid ${ionicColours.danger}`
};
