import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { useFormContext } from 'react-hook-form';

import type { SelectOption, SelectProps } from './interface';
import { capitalize } from '../../../tools/capitalizeString';

const Select: React.FC<SelectProps<SelectOption>> = (props) => {
  const { t } = useTranslate();
  const methods = useFormContext();
  return (
    <IonItem {...props.itemProps}>
      <IonSelect
        label={props.label}
        labelPlacement={props.labelPlacement || 'stacked'}
        interface={props.interface || 'alert'}
        placeholder={props.placeholder || ''}
        value={props.selected}
        {...methods.register(props.register)}
        onIonChange={(e) => props.handleSelection(e.detail.value)}
      >
        {props.data.map((section, index) => {
          const value = section.value === null ? t('None') : section.value;
          return (
            <IonSelectOption key={index} value={section.id}>
              {capitalize(value)}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    </IonItem>
  );
};

export default Select;
