import React from 'react';
import { useParams } from 'react-router';

import DocumentsListTable from '../../../Document/DocumentsListTable';

const ProjectDocuments: React.FC = () => {
  const { uuid } = useParams<{ uuid: string | undefined }>();

  return (
    <React.Fragment>
      <div className='ion-padding'>
        <DocumentsListTable />
      </div>
    </React.Fragment>
  );
};

export default ProjectDocuments;
