import { Camera, CameraResultType, CameraSource, type Photo } from '@capacitor/camera';
import { useTranslate } from '@tolgee/react';
import { camera } from 'ionicons/icons';

import BigUp from '../../../../../components/UI';
import { useAppSelector } from '../../../../../hooks';

interface TakePhotoButtonProps {
  onTakePhotoClick: (photos: Photo[]) => void;
  disabled?: boolean;
  expand?: 'block' | 'full' | undefined;
}

const TakePhotoButton: React.FC<TakePhotoButtonProps> = ({ disabled = false, expand, onTakePhotoClick }) => {
  const { t } = useTranslate();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const takePhoto = async () => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
        quality: 100,
      });
      onTakePhotoClick([photo]);
    } catch (error) {
      return (
        console.log(error)
      );
    }
  };

  return (
    <BigUp.Buttons.Responsive
      expand={expand || undefined}
      disabled={disabled}
      title={t('Take photo')}
      isDesktop={isDesktop}
      color={isDesktop ? 'secondary' : 'light'}
      icon={{
        icon: camera,
        color: 'secondary',
        size: 'large'
      }}
      onClick={takePhoto}
    />
  );
};
export default TakePhotoButton;
