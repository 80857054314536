import { IonCol, IonGrid, IonItem, IonLabel, IonRow, IonText } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { pencil, shareSocialSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import styles from './styles.module.scss';
import { networking } from '../../../../api/networking';
import FileButtons from '../../../../components/FileList/FileButtons';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import DeleteRecord from '../../../../components/UI/DeleteRecord';
import { useAppSelector } from '../../../../hooks';
import useAlert from '../../../../hooks/useAlert';
import formatNumber from '../../../../tools/formatNumber';
import { downloadAndShareFile } from '../../../../tools/shareAndDownloadFile';
import shareUrl from '../../../../tools/shareUrl';
import ActivityCodeTile from '../ActivityCodeTile';

const Change: React.FC = () => {
  const [change, setChange] = useState<E2U.V1.Models.EconomyChange>();
  const { change_uuid, uuid } = useParams<{ uuid: string | undefined, change_uuid: string | undefined }>();
  const history = useHistory();
  const { t } = useTranslate();
  const { alert } = useAlert();
  const activityCodes = useAppSelector((state) => state.activityCodes.activityCodes);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const editChange = (id: string | undefined) => {
    history.push(`/economy-tools/${uuid}/changes/${id}/edit`);
  };

  const fetchChange = () => {
    const searchParameters = new URLSearchParams();
    searchParameters.append('with[]', 'rows');
    searchParameters.append('with[]', 'files');
    networking.get(`/api/v1/economy_changes/${change_uuid}?${searchParameters.toString()}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.EconomyChange>) => {
        setChange(response.data.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const deleteChange = (id: string | undefined) => {
    toasters.promise(networking.delete(`/api/v1/economy_changes/${id}`), {
      success: t('Change deleted'),
      error: t('Could not delete change'),
    })
      .finally(() => {
        fetchChange();
        history.goBack();
      });
  };

  const confirmDeleteChange = () => {
    alert(
      t(`Deleting change: ${change?.name}`),
      t(`Are you sure you want to delete change: ${change?.name}?`),
      () => deleteChange(change?.id),
      t('Cancel'),
      t('Delete')
    );
  };

  const shareChange = () => {
    shareUrl(
      undefined,
      `${t('Here is the link to the economy change, ')} ${change?.name}: `,
      `${process.env.REACT_APP_SHARE_BASE_URL}/economy-tools/${uuid}/change/${change_uuid}`,
      '',
      'share'
    );
  };
  /*   const downloadAndShareFile = (file: E2U.V1.Models.File, action: 'share' | 'download') => {
      if (action === 'download' && file) {
        if (file.id === undefined) {
          const downloadLink = document.createElement('a');
          downloadLink.download = file.name;
          downloadLink.click();
          return;
        }

        networking.get(`/api/v1/files/${file.id}/share`)
          .then((response) => {
            window.location.assign(response.data.data.url);
          });
      } else if (action === 'share' && file) {
        shareUrl(
          undefined,
          `${t('Here is the link to the file, ')} ${file.name}: `,
          `${process.env.REACT_APP_SHARE_BASE_URL}/economy-tools/${uuid}/file/${file.id}`,
          '',
          'share'
        );
      } else {
        toasters.error(t('Could not perform action'));
      }
    }; */
  useEffect(() => {
    fetchChange();
  }, []);

  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={styles.changePageContainer} style={{
          ...isDesktop && {
            display: 'flex',
            flexDirection: 'row'
          }
        }}>
          <div style={{
            ...isDesktop && { width: '70%' }
          }}>
            <IonGrid>
              <IonRow
                className={classNames([
                  styles.changePageHeaderContainer,
                  'ion-padding ion-no-margin ion-justify-content-center ion-align-items-center'
                ])}
              >
                <IonCol size='6' className={styles.nameContainer}>
                  {(typeof change?.name !== 'undefined') &&
                    <h3 className="ion-no-margin">{change.name}</h3>
                  }
                </IonCol>
                <IonCol size='3' className='ion-text-right'>
                  <BigUp.Buttons.Responsive
                    padding={24}
                    expand={'block'}
                    isDesktop={isDesktop}
                    color={isDesktop ? 'secondary' : 'none'}
                    onClick={() => shareChange()}
                    icon={{ icon: shareSocialSharp, color: 'secondary', size: 'large' }}
                    title={t('Share')}
                  />
                </IonCol>
                <IonCol size={'3'} className='ion-text-left'>
                  <BigUp.Buttons.Responsive
                    padding={24}
                    expand={'block'}
                    isDesktop={isDesktop}
                    color={isDesktop ? 'primary' : 'none'}
                    onClick={() => editChange(change?.id)}
                    icon={{ icon: pencil, color: 'primary', size: 'large' }}
                    title={t('Edit')}
                  />
                </IonCol>
              </IonRow>

            </IonGrid>

            <IonGrid className={classNames([styles.changeRowsContainer, 'ion-padding-start'])}>
              {change?.rows.map((row: E2U.V1.Models.EconomyChangeRow, i) => {
                const activityCode = activityCodes.find(code => code.id === row.activity_code_id);
                console.log(activityCode);
                return (
                  <React.Fragment key={i}>
                    <IonRow className="ion-justify-content-center ion-align-items-center">
                      <IonCol size={'5'}>
                        <ActivityCodeTile
                          activityCode={activityCode}
                          name={''}
                          summary={row.name}
                          description={''}
                        />
                      </IonCol>
                      <IonCol size="3" className="ion-text-center">
                        <IonLabel style={{ fontSize: '12px' }} className="ion-no-margin" color={'medium'}>{t('Cost/unit')}</IonLabel>
                        <p className='ion-no-margin'>
                          {row.cost_per_unit > 1000
                            ? formatNumber(row.cost_per_unit)
                            : row.cost_per_unit
                          }
                        </p>
                      </IonCol>
                      <IonCol size="2"
                        className="ion-text-center">
                        <IonLabel style={{ fontSize: '12px' }} className="ion-no-margin" color={'medium'}>{t('Quantity')}</IonLabel>
                        <p className='ion-no-margin'>
                          {row.quantity > 1000
                            ? formatNumber(row.quantity)
                            : row.quantity
                          }
                        </p>
                      </IonCol>
                      <IonCol size="2" className="ion-text-center">
                        <IonLabel style={{ fontSize: '12px' }} className="ion-no-margin" color={'medium'}>{t('Unit')}</IonLabel>
                        <p className='ion-no-margin'>{row.unit}</p>
                      </IonCol>
                    </IonRow>

                    <React.Fragment>
                      {row.description && (
                        <React.Fragment>
                          <BigUp.Label.Thick label={t('Description')} className='ion-no-margin' />
                          <IonRow>
                            <IonCol>
                              <IonText
                                style={{
                                  backgroundColor: '#f2f2f2',
                                  minHeight: '40px',
                                  paddingTop: '7px',
                                  paddingLeft: '5px'
                                }}
                              >
                                <span style={{ fontSize: '16px' }}>
                                  {row.description}
                                </span>
                              </IonText>
                            </IonCol>
                          </IonRow>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  </React.Fragment>
                );
              })}
            </IonGrid>
          </div>
          <IonGrid className={'ion-padding-horizontal'}>
            <IonRow>
              <BigUp.Label.Thick label={t('Files')} className='ion-no-margin' />
            </IonRow>
            {change?.files?.map((file) => {
              return (
                <IonRow key={file.file_hash}>
                  <IonCol>
                    <IonItem className={'ion-no-padding'}>
                      <IonLabel className={'ion-no-margin ion-margin-vertical'}>
                        {file.name}
                      </IonLabel>
                      <FileButtons
                        file={file}
                        edit={false}
                        downloadFile={() => downloadAndShareFile(file, 'download', uuid)}
                        shareFile={() => downloadAndShareFile(file, 'share', uuid)}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              );
            })}
          </IonGrid>
          <DeleteRecord
            label={t('Do you want to delete this change?')}
            record={change?.id as string}
            onDelete={confirmDeleteChange}
          />
        </div>
      </div>
    </React.Fragment >
  );
};

export default Change;
