import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';

import type { PanelProps } from './interface';

const Panel: React.FC<PanelProps> = (props) => {
  return (
    <IonItem style={{ cursor: 'pointer' }} type="button" className='ion-no-margin ion-margin-top' onClick={props.onClick}>
      <IonIcon className='ion-no-margin ion-margin-end' size='medium' icon={props.icon} slot="start" style={{ color: `${props.iconColour}` }} />
      <IonLabel style={{ fontSize: '18px ' }} className="ion-no-margin">
        {props.panelTitle}
      </IonLabel>
      <IonIcon icon={chevronForward} slot="end" color='dark' />
    </IonItem>
  );
};

export default Panel;
