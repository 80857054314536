import { IonCol, IonRow } from '@ionic/react';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';

import BigUp from '../../../components/UI';

const WorksiteTextarea: React.FC = () => {
  const methods = useFormContext();
  return (
    <IonRow>
      <IonCol>
        <BigUp.Textarea
          label={t('Description')}
          placeholder={t('Enter description')}
          register="description"
          inputMode='text'
          autoCapitalize='sentences'
          itemProps={{ className: 'ion-no-padding ion-margin-bottom' }}
          validation={{ required: t('Description is required') }}
          disabled={methods.formState.isSubmitting}
        />
      </IonCol>
    </IonRow>
  );
};

export default WorksiteTextarea;
