import { IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import classNames from 'classnames';
import { camera, document } from 'ionicons/icons';
import React from 'react';

import type { ItemContentHeaderInterface } from '../Items';
import itemStyles from '../Items.module.scss';

const ItemContentHeader: React.FC<ItemContentHeaderInterface> = ({ childLabel, childSubLabel, childText, fileCount, photoCount }) => {
  return (
    <React.Fragment>
      <IonGrid className={classNames(itemStyles['item-content-header-wrapper'])} >
        <IonRow>
          <IonCol size="8" className={itemStyles['label-container']}>
            <IonLabel className={classNames(itemStyles['child-label'], 'ion-text-nowrap')}>
              {childLabel}
            </IonLabel>
            <IonLabel className={classNames(itemStyles['child-text'], 'ion-no-margin')}>
              {childText}
            </IonLabel>
          </IonCol>
          <IonCol size='4' className='ion-text-left'>
            <section className={itemStyles['item-content-icon-container']}>
              <div>
                {photoCount}
                <IonIcon icon={camera} size={'default'} />
              </div>
              <div>
                {fileCount}
                <IonIcon icon={document} size={'default'} />
              </div>
            </section>
          </IonCol>
          <IonCol size='12'>
            <IonLabel
              className={itemStyles['child-sublabel']}>
              {childSubLabel}
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>

    </React.Fragment>
  );
};

export default ItemContentHeader;
