import { useControls } from 'react-zoom-pan-pinch';

import type { PannableControlProps } from './interface';

const Controls: React.FC<PannableControlProps> = ({ control }) => {
  const { resetTransform, zoomIn, zoomOut } = useControls();

  return (
    <div className='tools'>
      <button onClick={() => zoomIn()}>ZOOM IN</button>
      <button onClick={() => zoomOut}>ZOOM OUT</button>
      <button onClick={() => resetTransform()}>RESET</button>
    </div>
  );
};

export default Controls;
