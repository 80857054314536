
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';

import SwiperController from '../../../../../components/Swiper/SwiperController';
import { useAppSelector } from '../../../../../hooks';
import MonitoringSwiper from '../../../MonitoringSwiper';

interface EconomyMonitoringRowProps {
  swiperController: SwiperController | undefined;
  economyMonitoring: E2U.V1.Models.NotPosted | undefined;
  fetchEconomyMonitoring: () => void;
  rows: E2U.V1.Models.NotPostedRow[];
}

const EconomyMonitoringRows: React.FC<EconomyMonitoringRowProps> = (props) => {
  const [swiperController, setSwiperController] = useState<SwiperController>(
    new SwiperController()
  );
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);

  useEffect(() => {
    setSwiperController(swiperController);
  }, []);
  const [currentIndex, setCurrentIndex] = useState<number>(swiperController.getCurrentIndex());
  const [compLoaded, setCompLoaded] = useState<boolean>(true);

  const setCurrentIndexWrapper = (prop: any) => {
    if (compLoaded) {
      setCurrentIndex(prop);
    }
  };

  swiperController?.addIndexHook(setCurrentIndexWrapper);

  const onUpdate = () => {
    if (props.fetchEconomyMonitoring) {
      props.fetchEconomyMonitoring();
    }
  };

  const nextIdx = () => {
    if (isDesktop) {
      swiperController?.goTo(props.rows.length);
      return;
    }
    swiperController?.nextSlide();
  };

  const prevIdx = () => {
    if (isDesktop) {
      swiperController?.goToStart();
      return;
    }
    swiperController?.previousSlide();
  };

  useEffect(() => {
    setCompLoaded(true);
    return () => setCompLoaded(false);
  }, []);

  useEffect(() => {
    setSwiperController(swiperController);
  }, []);
  return (
    <>
      <MonitoringSwiper
        canAdd={true}
        rows={props.rows}
        swiperController={swiperController}
        economyMonitoring={props.economyMonitoring}
        fetchEconomyMonitoring={props.fetchEconomyMonitoring}
      />
    </>
  );
};
export default EconomyMonitoringRows;
