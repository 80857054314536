import type { E2U } from '@techlove/easy2use-typings';
import { useState } from 'react';

import { networking } from '../api/networking';

interface UseFetchDataProps<T, K> {
  initialData: T | K | null;
}

const useFetchData = <T, K>({ initialData }: UseFetchDataProps<T, K>) => {
  const [data, setData] = useState<T | K | null>(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async (
    url: string,
    onThen?: (response: E2U.V1.Response.Success<T | K>) => void,
    onCatch?: (error: E2U.V1.Response.Error<T>) => void,
    onFinally?: () => void
  ) => {
    setIsLoading(true);

    try {
      const response: E2U.V1.Response.Success<T> = await networking.get(url);

      setData(response.data.data);
      if (onThen) {
        onThen(response);
      }
    } catch (error) {
      setError(null);
      if (onCatch) {
        onCatch(error as any);
      }
    } finally {
      setIsLoading(false);
      if (onFinally) {
        onFinally();
      }
    }
  };

  return { data, isLoading, error, fetchData };
};

export default useFetchData;
