import { IonInput, IonItem, IonLabel, IonNote } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import type InputProps from './Input.interface';

const InputFloatingLabel: React.FC<InputProps> = (props) => {
  const { t } = useTranslate();

  const {
    formState: { errors },
    register,
    watch
  } = useFormContext();

  return (
    <React.Fragment>
      <IonItem className='ion-no-margin ion-no-padding'>
        <IonLabel className='ion-no-margin'
          {...props.labelProperty
            ? ({ position: 'floating' })
            : ({ position: 'stacked' })
          }
        >
          {props.label}
        </IonLabel>

        <IonInput
          {...register(props.id, {
            validate: props.validate,
            required: props.required,
            maxLength: props.maxLength,
            minLength: props.minLength,
            pattern: {
              value: props.pattern,
              message: props.validationMessage as string
            }
          })}
          inputmode={props.inputMode}
          autoCapitalize={'sentences'}
          value={watch(props.id)}
          name={props.id}
          placeholder={props.inputPlaceholder}
          type={props.inputType}
        />

        {errors[props.id] && (
          <IonNote slot="end" color="danger">
            {errors[props.id]?.type === 'required' && t('Required')}
            {errors[props.id]?.type === 'minLength' && t('to short')}
            {errors[props.id]?.type === 'maxLength' && t('to long')}
            {errors[props.id]?.type === 'pattern' ? errors[props.id]?.message : null}
            {errors[props.id]?.type === 'validate' && props.validationMessage}
          </IonNote>
        )}
      </IonItem>
    </React.Fragment>);
};

export default InputFloatingLabel;
