import { animated, useSpring } from '@react-spring/web';

interface FadeInContainerProps {
  isVisible: boolean;
  children?: React.ReactNode;
}
const FadeInContainer: React.FC<FadeInContainerProps> = (props) => {
  const animationProps = useSpring({
    opacity: props.isVisible ? 1 : 0,
    from: { opacity: 0 },
    config: { duration: 500 }
  });

  const loaderStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <>
      {!props.isVisible
        ? (
          <></>
        )
        : <animated.div style={animationProps}>{props.children}</animated.div>
      }
    </>

  );
};

export default FadeInContainer;
