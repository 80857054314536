import type { PredefinedColors } from '@ionic/core';
import { checkmark, close, help } from 'ionicons/icons';

import SiteAccessRequestStatus from '../constants/enums/SiteAccessRequestStatus';

export type icon = string;
export type PossibleColors = PredefinedColors | 'none';

export interface SiteAccessIndicator {
  icon: icon;
  color: PossibleColors;
}

const getSiteAccessStatusIndicator = (status: SiteAccessRequestStatus): SiteAccessIndicator => {
  let icon: string;
  let color: PredefinedColors | 'none';
  switch (status) {
    case SiteAccessRequestStatus.APPROVED:
      icon = checkmark;
      color = 'success';
      break;
    case SiteAccessRequestStatus.PENDING:
      icon = help;
      color = 'warning';
      break;
    case SiteAccessRequestStatus.REJECTED:
      icon = close;
      color = 'danger';
      break;
    default:
      icon = close;
      color = 'none';
  }

  return {
    icon,
    color
  };
};

export default getSiteAccessStatusIndicator;
