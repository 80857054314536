
import { IonButton, IonLabel } from '@ionic/react';

import type { ResponsiveButtonProps } from './buttons.interface';
import { useAppSelector } from '../../../../hooks';

const ButtonSolid: React.FC<ResponsiveButtonProps> = ({ desktopButton, ...props }) => {
  return (
    <IonButton {...desktopButton?.button}{...desktopButton?.ionicButton} {...props}>
      {desktopButton?.children && desktopButton?.children}
      {props.children && props.children}
    </IonButton>
  );
};

const ButtonRound: React.FC<ResponsiveButtonProps> = ({ mobileButton, ...props }) => {
  return (
    <>
      {mobileButton?.label && <IonLabel className='ion-no-margin'>{mobileButton.label}</IonLabel>}
      <IonButton
        mode='md'
        {...props}
        style={{
          ...mobileButton?.style,
          width: mobileButton?.style?.width || '40px',
          height: mobileButton?.style?.height || '40px',
          '--padding-start': '6px',
          '--padding-end': '6px',
          minHeight: '40px',
          minWidth: '40px',
          borderRadius: '50%',
        }}
        {...mobileButton?.button}
        {...mobileButton?.ionicButton}>
        {
          mobileButton?.children &&
          mobileButton?.children
        }
        {
          props.children &&
          props.children
        }
      </IonButton>
    </>
  );
};

const ButtonResponsive: React.FC<ResponsiveButtonProps> = (props) => {
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);

  const mobileButton = () => {
    return <ButtonRound {...props} />;
  };

  const desktopButton = () => {
    return <ButtonSolid {...props} />;
  };

  return isDesktop ? desktopButton() : mobileButton();
};

export default ButtonResponsive;
