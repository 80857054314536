import { IonCol, IonGrid, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import type { FieldValues } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import type { InputProps } from '../../../components/UI/Inputs/Input.interface';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import type { AddTagCategoryProps } from '../interfaces';
import Toast from '../Toast';

const AddTagCategory: React.FC<AddTagCategoryProps> = ({ getTagCategories }) => {
  const { t } = useTranslate();
  const { uuid } = useParams<{ uuid: string }>();
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      name: ''
    }
  });

  const inputProps: InputProps<E2U.V1.Models.TagCategory> = {
    label: t('Add new'),
    placeholder: t('Add new tag category'),
    register: 'name',
    validation: {
      required: t('Tag category is required'),
      min: { value: 3, message: t('Name is too short') },
      max: { value: 20, message: t('Name is too long') }
    }
  };

  const addNewTagCategory = (data: FieldValues) => {
    store.dispatch(setIsLoading({ name: 'documentTypes', value: true }));
    networking.post(`/api/v1/tag_categories`, data)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.TagCategory>) => {
        networking.post(`/api/v1/tag_categories/${response.data.data.id}/projects/${uuid}`, data)
          .then(() => {
            getTagCategories();
            methods.reset();
          })
          .catch((error) => {
            Toast(t('Could not get tag categories'), 'error');
            Sentry.captureException(error);
          })
          .finally(() => {
            store.dispatch(setIsLoading({ name: 'documentTypes', value: false }));
          });
      });
  };

  return (
    <IonGrid>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(addNewTagCategory)}>
          <IonRow className='ion-margin-bottom ion-align-items-end ion-justify-content-between'>
            <IonCol size='8'>
              <BigUp.Input {...inputProps} />
            </IonCol>
            <IonCol size='3' className='ion-margin-end'>
              <BigUp.Buttons.Regular
                expand='block'
                disabled={!methods.formState.isValid || methods.formState.isSubmitting}
                title={t('Save')}
                color={'primary'}
                type='submit'
              />
            </IonCol>
          </IonRow>
        </form>
      </FormProvider>
    </IonGrid>
  );
};

export default AddTagCategory;
