import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';

interface ColumnProps {
  component: React.ReactElement;
  rows: E2U.V1.Models.EconomyMonitoringRow[] | undefined;
}

const Column: React.FC<ColumnProps> = (props) => {
  return (
    <React.Fragment>
      {props.rows?.map((row, i) => {
        const displayComponent: any = React.cloneElement(props.component, {
          row
        });

        return <React.Fragment key={i}>{displayComponent}</React.Fragment>;
      })}
    </React.Fragment>
  );
};

export default Column;
