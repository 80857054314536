import { IonContent } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';

import Curve from './CurveTop';
import styles from './styles/ModalFormContainer.module.scss';
import type { OnboardingInterface } from '../../interfaces/Onboarding.interface';

const ModalFormContainer: React.FC<OnboardingInterface> = (props) => {
  const { children } = props;

  return (
    <Curve>
      <IonContent className={classNames('ion-padding', styles['onboarding-form-content-container'])}>
        <div className={styles['fade-in-form']}>
          {children}
        </div>
      </IonContent>
    </Curve>
  );
};
export default ModalFormContainer;
