import type { Draft, SliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit/src';

export interface AccordionItemOpen {
    active: boolean;
    index: number;
}

export const accordionItemOpenSlice = createSlice<AccordionItemOpen, SliceCaseReducers<AccordionItemOpen>>({
  name: 'accordionItemOpen',
  initialState: {
    active: false,
    index: 0
  },
  reducers: {
    setActive: (
      state:Draft <AccordionItemOpen>,
      action: PayloadAction<AccordionItemOpen>
    ) => {
      state.active = action.payload.active;
      state.index = action.payload.index;
    }
  }
});

export const { setActive } = accordionItemOpenSlice.actions;

export default accordionItemOpenSlice.reducer;
