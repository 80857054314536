import { IonCol, IonGrid, IonRow, useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { networking } from '../../../api/networking';
import DesktopWrapper from '../../../components/DesktopWrapper';
import PaginateData from '../../../components/Pagination/PaginationData';
import Toast from '../../../components/Toasts/Toast';
import toasters from '../../../components/Toasts/Toasts';
import BigUp from '../../../components/UI';
import { useAppSelector } from '../../../hooks';
import { useBreadcrumbConfig } from '../../../hooks/useBreadcrumbConfig';
import { scrollToSection } from '../../../tools/scrollToSection';
import shareUrl from '../../../tools/shareUrl';
import MonitoringSwiper from '../MonitoringSwiper';

const MonitoringReport: React.FC = () => {
  const [monitoringReport, setMonitoringReport] = useState<E2U.V1.Models.EconomyMonitoringRow[]>([]);
  const [selectedMonitoring, setSelectedMonitoring] = useState<E2U.V1.Models.EconomyMonitoring & { document_name?: string, document_description?: string }>();
  const [perPage, setPerPage] = useState<number>(15);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>();
  const scrollToId = 'monitoring-report-top';
  const handlePagination = (step: number) => {
    setCurrentPage(currentPage + step);
    scrollToSection(scrollToId);
  };

  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const { monitoring_uuid, uuid } = useParams<{ uuid: string | undefined, monitoring_uuid: string | undefined }>();
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const { t } = useTranslate();

  const fetchMonitoringReport = () => {
    if (typeof monitoring_uuid === 'undefined' || monitoring_uuid === '') {
      Toast(t('No monitoring found'), 'error');
      history.push('/economy-tools');
      return;
    }
    Promise.all([
      networking.get(`/api/v1/economy_monitorings/${monitoring_uuid}/rows?per_page=${perPage}&page=${currentPage}`),
      networking.get(`/api/v1/economy_monitorings/${monitoring_uuid}`),
    ])
      .then(([resMonitoringReport, resSelectedMonitoring]) => {
        setMonitoringReport(resMonitoringReport.data.data.records);
        setSelectedMonitoring(resSelectedMonitoring.data.data);
        setTotalPages(resMonitoringReport.data.data.total_pages);
      });
  };

  const deleteHandler = (id: string | undefined) => {
    presentAlert({
      header: t('Are you sure you want to delete this report?'),
      message: selectedMonitoring?.document_name,
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel'
        },
        {
          text: t('Delete'),
          role: 'confirm',
          handler: () => deleteRow(id)
        },
      ],
    });
  };

  const deleteRow = (id: string | undefined) => {
    toasters
      .promise(
        networking.delete(
          `/api/v1/economy_monitorings/${id}`
        ),
        {
          success: t('Successfully deleted report.'),
          pending: t('Deleting report...'),
          error: t("Couldn't delete report")
        }
      )
      .finally(() => history.goBack());
  };

  useEffect(() => {
    fetchMonitoringReport();
  }, [
    monitoring_uuid,
    perPage,
    currentPage
  ]);
  const breadcrumbs = useBreadcrumbConfig();

  return (
    <React.Fragment>
      <DesktopWrapper>
        <IonGrid id={'monitoring-report-top'}>
          <IonRow
            className={'ion-padding ion-justify-content-space-end ion-align-items-center'}
            style={{
              ...isDesktop && { width: '100%' },
              borderRadius: '8px',
              marginBottom: '16px',
              margin: '8px'
            }}
          >
            <IonCol size='6' sizeMd='6' sizeSm='6' sizeXs='12' style={{ paddingLeft: '5px', borderRadius: '8px' }}>
              {(typeof selectedMonitoring?.document_name !== 'undefined') && <h3 className="ion-no-margin">
                {selectedMonitoring?.document_name}
              </h3>}
            </IonCol>
            <IonCol size='3' sizeMd='3' sizeSm='3' sizeXs='6'>
              <BigUp.Buttons.Regular
                expand='block'
                color='secondary'
                title={t('Share')}
                onClick={() => shareUrl(
                  undefined,
                  `${t('Here is the link to monitoring')} ${selectedMonitoring?.document_name}: `,
                  `${process.env.REACT_APP_SHARE_BASE_URL}/${uuid}/monitoring/${monitoring_uuid}`,
                  `${t('Let me know if there is something you are wondering about.')}`,
                  'share'
                )}
              />
            </IonCol>

            <IonCol size={'3'} sizeSm='3' sizeXs='6' >
              <BigUp.Buttons.Regular
                expand='block'
                color='danger'
                title={t('Delete')}
                onClick={() => deleteHandler(selectedMonitoring?.id)}
              />
            </IonCol>

          </IonRow>
          <div className='ion-padding-start'>
            <MonitoringSwiper
              canAdd={false}
              rows={monitoringReport}
              swiperController={undefined}
              economyMonitoring={selectedMonitoring}
              fetchEconomyMonitoring={fetchMonitoringReport}
            />
          </div>
          <PaginateData
            currentPage={currentPage}
            pageStepper={handlePagination}
            totalPages={totalPages}
          />
        </IonGrid>
      </DesktopWrapper>
    </React.Fragment>
  );
};
export default MonitoringReport;
