import { IonContent, IonPage } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';

import appStyles from '../App.module.scss';
import DesktopWrapper from '../components/DesktopWrapper';
import HamburgerMenu from '../components/Menu/HamburgerMenu';
import PageMenuHeader from '../components/PageMenuHeader/PageMenuHeader';
import { useAppSelector } from '../hooks';

const AppLayout: React.FC = ({ children }) => {
  const menu_id = 'main-content';
  const history = useHistory();
  const location = useLocation();

  const extractIdFromRoute = (): string => {
    const match = matchPath<{ id: string }>(location.pathname, '/:tab/:id');
    return match?.params.id || '';
  };

  const generateRouteWithId = (route: string, id: string): string => {
    const currentId = extractIdFromRoute();
    return `${route}${id ? `/${id}` : currentId ? `/${currentId}` : ''}`;
  };

  //  Get prefered mode from the user's device on load
  /*
  const toggleDarkTheme = (shouldAdd: boolean) => {
    document.body.classList.toggle('dark', shouldAdd);
  };

  const initializeDarkTheme = (isDark: boolean) => {
    toggleDarkTheme(isDark);
  };
  useEffect(() => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    initializeDarkTheme(prefersDark.matches);
    prefersDark.addEventListener('change', (mediaQuery) => initializeDarkTheme(mediaQuery.matches));
  }, []);
  */

  const shouldHideMenu = useAppSelector(state => state.project.shouldHideMenu);

  return (
    <React.Fragment>
      <HamburgerMenu menuID={menu_id} />
      <IonPage id={menu_id}>
        {!shouldHideMenu && <PageMenuHeader />}
        <IonContent id={appStyles['fade-in-page']} className={appStyles['remove-scroll']} fullscreen>
          <DesktopWrapper alignment={'center'} width={'600px'}>
            {children}
          </DesktopWrapper>
        </IonContent>
        {/* TODO: KEEP FOR THE FUTURE */}
        {/*
        <IonTabBar slot="bottom">
          <IonTabButton tab="notice-board" onClick={() => history.push(generateRouteWithId('/notice-board', extractIdFromRoute()))}>
            <IonIcon icon={homeSharp}
              color={
                matchPath(location.pathname, { path: '/notice-board', exact: false }) ? 'dark' : 'medium'
              }
            />
          </IonTabButton>
          <IonTabButton tab="project-tools" onClick={() => history.push(generateRouteWithId('/project-tools', extractIdFromRoute()))}>
            <IonIcon icon={hammerSharp}
              color={
                matchPath(location.pathname, { path: '/project-tools', exact: false }) ? 'dark' : 'medium'
              }
            />
          </IonTabButton>
          <IonTabButton tab="economy-tools" onClick={() => history.push(generateRouteWithId('/economy-tools', extractIdFromRoute()))}>
            <IonIcon icon={logoEuro} color={
              matchPath(location.pathname, { path: '/economy-tools', exact: false }) ? 'dark' : 'medium'
            } />
          </IonTabButton>
          <IonTabButton tab="economy-tools" onClick={() => history.push(generateRouteWithId('/settings', extractIdFromRoute()))}>
            <IonIcon icon={settingsSharp} color={
              matchPath(location.pathname, { path: '/settings', exact: false }) ? 'dark' : 'medium'
            } />
          </IonTabButton>
        </IonTabBar> */}
      </IonPage>
    </React.Fragment>
  );
};

export default AppLayout;
