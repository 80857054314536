import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

import {
  DEFAULT_VALUES_STAGE_ONE,
  DEFAULT_VALUES_STAGE_THREE,
  DEFAULT_VALUES_STAGE_TWO
} from '../pages/Onboarding/interfaces/Form.interface';

export interface OnboardingReducer {
  shouldSubmit: boolean,
  selectedProject: E2U.V1.Models.Project | undefined;
  onboardingStageOne: Partial<E2U.V1.Objects.ProjectAccessRequest>;
  onboardingStageTwo: Partial<E2U.V1.Objects.ProjectAccessRequest>;
  onboardingStageThree: Partial<E2U.V1.Objects.ProjectAccessRequest>;
  projectFiles: E2U.V1.Models.ProjectAccessRequirement[];

}

export const OnboardingSlice = createSlice<
  OnboardingReducer,
  SliceCaseReducers<OnboardingReducer>,
  string
>({
  name: 'onboarding',
  initialState: {
    shouldSubmit: false,
    selectedProject: undefined,
    onboardingStageOne: DEFAULT_VALUES_STAGE_ONE,
    onboardingStageTwo: DEFAULT_VALUES_STAGE_TWO,
    onboardingStageThree: DEFAULT_VALUES_STAGE_THREE,
    projectFiles: []
  } as OnboardingReducer,

  reducers: {
    setSelectedProject: (
      state: Draft<OnboardingReducer>,
      action: PayloadAction<E2U.V1.Models.Project | undefined>
    ) => {
      state.selectedProject = action.payload;
    },
    resetAllStages: (
      state: Draft<OnboardingReducer>,
      action: PayloadAction<undefined>
    ) => {
      state.onboardingStageOne = Object.assign(Object.assign({}, state.onboardingStageOne), DEFAULT_VALUES_STAGE_ONE);
      state.onboardingStageTwo = Object.assign(Object.assign({}, state.onboardingStageTwo), DEFAULT_VALUES_STAGE_TWO);
      state.onboardingStageThree = Object.assign(Object.assign({}, state.onboardingStageThree), DEFAULT_VALUES_STAGE_THREE);
    },
    setOnboardingStageOne: (
      state: Draft<OnboardingReducer>,
      action: PayloadAction<Partial<E2U.V1.Objects.ProjectAccessRequest>>
    ) => {
      state.onboardingStageOne = Object.assign(Object.assign({}, state.onboardingStageOne), action.payload);
    },

    setOnboardingStageTwo: (
      state: Draft<OnboardingReducer>,
      action: PayloadAction<Partial<E2U.V1.Objects.ProjectAccessRequest>>
    ) => {
      state.onboardingStageTwo = Object.assign(Object.assign({}, state.onboardingStageTwo), action.payload);
    },

    setOnboardingStageThree: (
      state: Draft<OnboardingReducer>,
      action: PayloadAction<E2U.V1.Objects.ProjectAccessRequest>
    ) => {
      state.onboardingStageThree = Object.assign(Object.assign({}, state.onboardingStageThree), action.payload);
    },
    setProjectFiles: (
      state: Draft<OnboardingReducer>,
      action: PayloadAction<E2U.V1.Models.ProjectAccessRequirement[]>
    ) => {
      state.projectFiles = action.payload;
    },
    setShouldSubmit: (
      state: Draft<OnboardingReducer>,
      action: PayloadAction<boolean>
    ) => {
      state.shouldSubmit = action.payload;
    }
  }
});

export const {
  resetAllStages,
  setOnboardingStageOne,
  setOnboardingStageThree,
  setOnboardingStageTwo,
  setProjectFiles,
  setSelectedProject,
  setShouldSubmit
} = OnboardingSlice.actions;

export default OnboardingSlice.reducer;
