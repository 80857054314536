import { IonCheckbox } from '@ionic/react';
import { useFormContext } from 'react-hook-form';

import type { CheckboxLabelProps, CheckboxProps } from './interface';
const Checkbox: React.FC<CheckboxProps<CheckboxLabelProps>> = (props) => {
  const methods = useFormContext();

  return (
    <IonCheckbox
      labelPlacement={props.labelPlacement || 'end'}
      value={props.value}
      onIonChange={(e) => props.handleCheckbox(e.detail.checked)}
      {...props}
    >
      {props.labelProps?.label}
      {props.children}
    </IonCheckbox>
  );
};

export default Checkbox;
