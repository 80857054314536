import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonInput, IonItem, IonLabel,
  IonLoading
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router';

import styles from './CreateUserFromInviteForm.module.scss';
import { networking } from '../../../api/networking';
import Toast from '../../../components/Toasts/Toast';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';
import { regex } from '../../../regex/regex';

const CreateUserFromInviteForm: React.FC = () => {
  const store = useStore();
  const { t } = useTranslate();
  const invite: E2U.V1.Models.Invite | undefined = useAppSelector((state) => state.invite.invite);
  const isAcceptingInvite: boolean = useAppSelector((state) => state.loading.isLoading.acceptingInvite);
  const [validationErrors, setValidationErrors] = useState<{ [field: string]: string[] }>({});
  const { formState: { errors }, handleSubmit, register, setValue, watch } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange'
  });
  const history = useHistory();
  const passwordConfirmation = watch('password', '');

  const handleUpdateEmail = () => {
    if (typeof invite?.email !== 'undefined') {
      setValue('email', invite.email);
    }
  };

  useEffect(() => handleUpdateEmail(), [invite]);

  const handleFormSubmit: SubmitHandler<FieldValues> = (data) => {
    store.dispatch(setIsLoading({
      name: 'acceptingInvite',
      value: true
    }));
    networking.post(`/api/v1/invite/${invite?.token}/user`, data)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Invite>) => {
        Toast(t('. Please login using the credentials supplied'), 'success');
        history.push('/login');
      })
      .catch((error: E2U.V1.Response.Error) => {
        if (!networking.isAxiosError(error)) {
          console.log('Error', error);
        } else {
          if (error && error.response && error.response.data && error.response.data.message && error.response.data.message === 'Validation failed') {
            setValidationErrors(error.response.data.data);
          }
        }
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'acceptingInvite',
          value: false
        }));
      });
  };

  return (
    <section className={styles['create-user-from-invite-form']}>
      {isAcceptingInvite
        ? <IonLoading isOpen={true} />
        : <>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <IonCard>
              <IonCardContent>
                <IonCardHeader>
                  <IonCardTitle>{t('Invitation')}</IonCardTitle>
                </IonCardHeader>
                <IonItem>
                  <IonLabel>{t('Fill in your details below to join')} {invite?.team?.name}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>{t('First name')}</IonLabel>
                  <IonInput
                    {...register('first_name', { required: true, maxLength: 100 })}
                    placeholder={t('Enter first name')}
                    type={'text'}
                    name={'first_name'}
                  />
                  {errors.first_name && <IonBadge color="danger">{errors.first_name.type === 'required' ? t('First name is required') : t('First name is too long')}</IonBadge>}
                  {validationErrors && validationErrors.first_name && <IonBadge color="danger">{validationErrors.first_name.join('. ')}</IonBadge>}
                </IonItem>
                <IonItem>
                  <IonLabel>{t('Last name')}</IonLabel>
                  <IonInput
                    {...register('last_name', { required: true, maxLength: 100 })}
                    placeholder={t('Enter last name')}
                    type={'text'}
                    name={'last_name'}
                  />
                  {errors.last_name && <IonBadge color="danger">{errors.last_name.type === 'required' ? t('Last name is required') : t('Last name is too long')}</IonBadge>}
                  {validationErrors && validationErrors.last_name && <IonBadge color="danger">{validationErrors.last_name.join('. ')}</IonBadge>}
                </IonItem>
                <IonItem>
                  <IonLabel>{t('Email')}</IonLabel>
                  <IonInput
                    {...register('email', {
                      required: true,
                      maxLength: 100,
                      pattern: regex.regex_email
                    })}
                    placeholder={t('Enter email')}
                  />
                  {errors.email && <IonBadge color="danger">{errors.email.type === 'pattern' ? t('Email is of wrongful format') : errors.email.type === 'required' ? t('Email is required') : errors.email.type === 'email' ? t('Email format is faulty') : t('Password is too long')}</IonBadge>}
                  {validationErrors && validationErrors.email && <IonBadge color="danger">{validationErrors.email.join('. ')}</IonBadge>}
                </IonItem>
                <IonItem>
                  <IonLabel>{t('Password')}</IonLabel>
                  <IonInput
                    {...register('password', {
                      required: true,
                      maxLength: 100,
                      minLength: 8,
                      pattern: {
                        value: /(?=.*\d)(?=.*[a-ö])(?=.*[A-Ö])(?=.*[@$!%*#?&])/,
                        message: t('Password needs at least one small character, one large character, one digit and one special character (any of @$!%*#?&)')
                      }
                    })}
                    placeholder={t('Enter password')}
                    type={'password'}
                    name={'password'}
                  />
                  {errors.password && <IonBadge color="danger">{errors.password.type === 'required' ? t('Password is required') : errors.password.type === 'minLength' ? t('Password needs to be at least eight characters') : errors.password.type === 'pattern' ? errors.password.message : t('Password is too long')}</IonBadge>}
                  {validationErrors && validationErrors.password && <IonBadge color="danger">{validationErrors.password.join('. ')}</IonBadge>}
                </IonItem>
                <IonItem>
                  <IonLabel>{t('Confirm password')}</IonLabel>
                  <IonInput
                    {...register('password_confirm', {
                      required: true,
                      maxLength: 100,
                      minLength: 8,
                      pattern: {
                        value: /(?=.*\d)(?=.*[a-ö])(?=.*[A-Ö])(?=.*[@$!%*#?&-_])/,
                        message: t('Password needs at least one small character, one large character, one digit and one special character (any of @$!%*#?&)')
                      },
                      validate: (value: string) => {
                        if (value !== passwordConfirmation) {
                          return t('The passwords doesn\'t match');
                        }
                      }
                    })}
                    placeholder={t('Enter confirmation of password')}
                    type={'password'}
                    name={'password_confirm'}
                  />
                  {errors.password_confirm &&
                    <IonBadge color="danger">{errors.password_confirm.type === 'required'
                      ? t('Password confirmation is required')
                      : (errors.password_confirm.type === 'minLength')
                        ? (t('Password confirmation needs to be at least eight characters'))
                        : (errors.password_confirm.type === 'pattern')
                          ? (errors.password_confirm.message)
                          : (errors.password_confirm.type === 'validate')
                            ? (t('Password confirmation is not equal'))
                            : (t('Password confirmation is too long'))}</IonBadge>}
                  {validationErrors && validationErrors.password_confirm && <IonBadge color="danger">{validationErrors.password_confirm.join('. ')}</IonBadge>}
                </IonItem>
                <IonButton
                  type={'submit'}
                >
                  {t('Register')}
                </IonButton>
              </IonCardContent>
            </IonCard>
          </form>
        </>}
    </section>
  );
};

export default CreateUserFromInviteForm;
