import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import classNames from 'classnames';
import { close } from 'ionicons/icons';

import headerStyles from './Modals.module.scss';

interface ModalHeader {
  title: string;
  clickHandler: (item: undefined) => void | undefined;
  iconColour?: string;
  borderColour?: string
  disabled?: boolean;
}

const HeaderBorderLeft: React.FC<ModalHeader> = ({ borderColour, clickHandler, disabled, iconColour, title }) => {
  return (
    <IonHeader className={classNames('ion-no-border ion-padding-top ion-padding-bottom', headerStyles['record-view-header'])} >
      <IonToolbar>
        <IonTitle className='ion-text-left ion-no-padding'>
          <h1
            className='ios'
            style={{
              borderLeft: `8px solid ${borderColour}` || '8px solid var(--ion-color-tertiary)'
            }}>
            {title}
          </h1>
        </IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={() => clickHandler(undefined)} disabled={disabled}>
            <IonIcon
              icon={close}
              color={iconColour || 'dark'}
              size='large'
            />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default HeaderBorderLeft;
