import {
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonTextarea,
  IonToggle
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { t } from 'i18next';
import { FormEventHandler, useEffect, useRef, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import styles from './ObservationForm.module.scss';
import type { ObservationFormProps } from './ObservationFormTypes';
import { networking } from '../../../../../api/networking';
import ButtonWide from '../../../../../components/ButtonWide';
import CardListComponent from '../../../../../components/CardListComponent';
import DateTimePicker from '../../../../../components/DateTimePicker';
import Toast from '../../../../../components/Toasts/Toast';
import { useAppSelector } from '../../../../../hooks';
import { setIsLoading } from '../../../../../reducers/loading';

const ObservationForm: React.FC<ObservationFormProps> = (props) => {
  const store = useStore();
  const history = useHistory();
  const { t } = useTranslate();
  const observation: E2U.V1.Models.ObservationReport | undefined =
    useAppSelector((state) => state.reports.selectedObservation);

  const isLoadingObservation: boolean = useAppSelector(
    (state) => state.loading.isLoading.observation
  );
  const isCreatingObservation: boolean = useAppSelector(
    (state) => state.loading.isLoading.creatingObservation
  );
  const [validationError, setValidationError] = useState<{
    [field: string]: string[];
  }>({});
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange'
  });

  const [observationName, setObservationName] = useState<string>();
  const [observationDescription, setObservationDescription] =
    useState<string>();
  const [reportComment, setReportComment] = useState<string>();

  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (
      typeof observation !== 'undefined' &&
      typeof props.parent_id === 'undefined'
    ) {
      Object.entries(observation).forEach(([key, value]) =>
        setValue(key, value)
      );
    }
  }, [observation]);

  const handleFormSubmit: SubmitHandler<FieldValues> = (data) => {
    store.dispatch(setIsLoading({ name: 'creatingObservation', value: true }));
    const postData: FieldValues = { ...data };
    if (typeof props.parent_id !== 'undefined') {
      postData.parent_id = props.parent_id;
    }
    if (postData.parent_id === null) {
      delete postData.parent_id;
    }

    const request =
      !props.parent_id && observation && typeof observation.id !== 'undefined'
        ? networking.put(
          `/api/v1/observation_reports/${observation?.id}`,
          postData
        )
        : networking.post('/api/v1/observation_reports', postData);
    request
      .then(
        (
          response: E2U.V1.Response.Success<E2U.V1.Models.ObservationReport>
        ) => {
          Toast(
            !props.parent_id &&
              observation &&
              typeof observation.id !== 'undefined'
              ? t('Observation updated successfully')
              : t('Observation created successfully'),
            'success'
          );
          history.push(`/projects/${response.data.data.id}`);
        }
      )
      .catch(
        (error: E2U.V1.Response.Error<E2U.V1.Models.ObservationReport>) => {
          if (!networking.isAxiosError(error)) {
            Toast(
              !props.parent_id &&
                observation &&
                typeof observation.id !== 'undefined'
                ? t('Failed to update observation')
                : t('Could not create a new observation'),
              'error'
            );
          } else {
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message &&
              error.response.data.message === 'Validation failed'
            ) {
              setValidationError(error.response.data.data);
            }
            Toast(
              !props.parent_id &&
                observation &&
                typeof observation.id !== 'undefined'
                ? t('Failed to update observation')
                : t('Could not add a new observation'),
              'error'
            );
          }
        }
      )
      .finally(() => {
        store.dispatch(
          setIsLoading({ name: 'creatingObservation', value: false })
        );
      });
  };

  return (
    <>
      {isLoadingObservation || isCreatingObservation
        ? (
          <IonLoading isOpen={true} />
        )
        : (
          <IonList>
            <IonItem className={styles['input-style']}>
              {!props.parent_id &&
                observation &&
                typeof observation.id !== 'undefined'
                ? t('Edit observation title')
                : t('Create observation title')}
            </IonItem>

            <IonItem className={styles['input-style']}>
              <IonInput
                className="ion-no-padding ion-margin-top"
                {...register('name', { required: true, maxLength: 1000 })}
                placeholder={t('Name placeholder...')}
                type={'text'}
              ></IonInput>
            </IonItem>

            <IonItem className={styles['input-style']}>
              <IonInput
                className="ion-no-padding ion-margin-top"
                {...register('description', { required: true, maxLength: 1000 })}
                placeholder={t('Description placeholder...')}
                type={'text'}
              ></IonInput>
            </IonItem>

            <IonItem lines="none">
              <DateTimePicker title={t('Time of incident')} />
            </IonItem>

            <IonLabel>{t('Does this affect the schedule?')}</IonLabel>
            <IonItem color="white" lines="none">
              <IonToggle {...register('effects_schedule')} />
              <IonLabel slot="end">{t('Yes / No')}</IonLabel>
            </IonItem>

            <IonItem lines="none">
              <CardListComponent title={t('Affected individuals')} />
            </IonItem>

            <IonItem lines="none">
              <div className={styles['lable-style']}>
                <IonLabel className={styles['']}>{t('Report')}</IonLabel>
                <IonTextarea
                  clearOnEdit={true}
                  rows={6}
                  cols={20}
                  placeholder={t('Enter any notes here...')}
                  value={reportComment}
                  onIonChange={(e) => setReportComment(e.detail.value!)}
                ></IonTextarea>
              </div>
            </IonItem>

            <ButtonWide title={t('Submit')}></ButtonWide>
          </IonList>
        )}
    </>
  );
};

export default ObservationForm;
