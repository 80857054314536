import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent, IonCardHeader, IonCardTitle,
  IonInput, IonItem, IonLabel,
  IonLoading
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router';

import styles from './ConsumePasswordResetForm.module.scss';
import { networking } from '../../../api/networking';
import Toast from '../../../components/Toasts/Toast';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';

const ConsumePasswordResetForm: React.FC = () => {
  const store = useStore();
  const { t } = useTranslate();
  const passwordReset: E2U.V1.Models.PasswordReset | undefined = useAppSelector((state) => state.authentication.passwordReset);
  const isChangingPassword: boolean = useAppSelector((state) => state.loading.isLoading.changingPassword);
  const [validationErrors, setValidationErrors] = useState<{ [field: string]: string[] }>({});
  const { formState: { errors }, handleSubmit, register, setValue, watch } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange'
  });
  const passwordConfirmation = watch('password', '');
  const history = useHistory();

  const handleFormSubmit: SubmitHandler<FieldValues> = (data) => {
    store.dispatch(setIsLoading({
      name: 'changingPassword',
      value: true
    }));
    networking.post(`/api/v1/users/reset_password/${passwordReset?.token}/reset`, data)
      .then((response: E2U.V1.Response.Success<any>) => { // todo: Fix typing
        history.push('/login');
      })
      .catch((error: E2U.V1.Response.Error) => {
        if (error && error.response && error.response.data && error.response.data.message && error.response.data.message === 'Validation failed') {
          setValidationErrors(error.response.data.data);
        }
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'changingPassword',
          value: false
        }));
      });
  };

  return (
    <section className={styles['consume-password-reset-form']}>
      {isChangingPassword
        ? <IonLoading isOpen={true} />
        : <>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <IonCard>
              <IonCardContent>
                <IonCardHeader>
                  <IonCardTitle>{t('Reset password')}</IonCardTitle>
                </IonCardHeader>
                <IonItem>
                  <IonLabel>{t('Password')}</IonLabel>
                  <IonInput
                    {...register('password', {
                      required: true,
                      maxLength: 100,
                      minLength: 8,
                      pattern: {
                        value: /(?=.*\d)(?=.*[a-ö])(?=.*[A-Ö])(?=.*[@$!%*#?&])/,
                        message: t('Password needs at least one small character, one large character, one digit and one special character (any of @$!%*#?&)')
                      }
                    })}
                    placeholder={t('Enter password')}
                    type={'password'}
                    name={'password'}
                  />
                  {errors.password && <IonBadge color="danger">{errors.password.type === 'required' ? t('Password is required') : errors.password.type === 'minLength' ? t('Password needs to be at least eight characters') : errors.password.type === 'pattern' ? errors.password.message : t('Password is too long')}</IonBadge>}
                  {validationErrors && validationErrors.password && <IonBadge color="danger">{validationErrors.password.join('. ')}</IonBadge>}
                </IonItem>
                <IonItem>
                  <IonLabel>{t('Confirm password')}</IonLabel>
                  <IonInput
                    {...register('password_confirm', {
                      required: true,
                      maxLength: 100,
                      minLength: 8,
                      pattern: {
                        value: /(?=.*\d)(?=.*[a-ö])(?=.*[A-Ö])(?=.*[@$!%*#?&-_])/,
                        message: t('Password needs at least one small character, one large character, one digit and one special character (any of @$!%*#?&)')
                      },
                      validate: (value: string) => {
                        if (value !== passwordConfirmation) {
                          return t('The passwords doesn\'t match');
                        }
                      }
                    })}
                    placeholder={t('Enter confirmation of password')}
                    type={'password'}
                    name={'password_confirm'}
                  />
                  {errors.password_confirm && <IonBadge color="danger">{errors.password_confirm.type === 'required' ? t('Password confirmation is required') : errors.password_confirm.type === 'minLength' ? t('Password confirmation needs to be at least eight characters') : errors.password_confirm.type === 'pattern' ? errors.password_confirm.message : errors.password_confirm.type === 'validate' ? t('Password confirmation is not equal') : t('Password confirmation is too long')}</IonBadge>}
                  {validationErrors && validationErrors.password_confirm && <IonBadge color="danger">{validationErrors.password_confirm.join('. ')}</IonBadge>}
                </IonItem>
                <IonButton
                  type={'submit'}
                >
                  {t('Create')}
                </IonButton>
              </IonCardContent>
            </IonCard>
          </form>
        </>}
    </section>
  );
};

export default ConsumePasswordResetForm;
