import type { E2U } from '@techlove/easy2use-typings';

export interface FormFunctionality {
  nextStep?: () => void;
  previousStep?: () => void;
  handleInputData?: () => void;
}

export const DEFAULT_VALUES_STAGE_ONE: Partial<E2U.V1.Objects.ProjectAccessRequest> = {
  personal_number: '',
  first_name: '',
  last_name: '',
  company_name: '',
  organization_number: ''
};

export const DEFAULT_VALUES_STAGE_TWO: Partial<E2U.V1.Objects.ProjectAccessRequest> = {
  hot_work: false,
  hot_work_certificates: [],
  work_from_lift: false,
  work_from_lift_certificates: [],
  heavy_lifting: false,
  heavy_lifting_certificates: [],
  other_certificates: [],
  confirm_stage_two: false
};

export const DEFAULT_VALUES_STAGE_THREE: Partial<E2U.V1.Objects.ProjectAccessRequest > = {
  project_files: {}
};
