import { IonLabel } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';
import React from 'react';

import SiteAccessRequestStatus from '../../../constants/enums/SiteAccessRequestStatus';
import { useAppSelector } from '../../../hooks';

const PendingSiteAccessRequest: React.FC = () => {
  const selectedProject: E2U.V1.Models.Project | undefined = useAppSelector((state) => {
    return state.project.selectedProject;
  }
  );
  return (
    <React.Fragment>
      {selectedProject?.site_access_status === SiteAccessRequestStatus.PENDING && (
        <React.Fragment>
          <IonLabel color={'warning'} className={'ion-no-margin'} style={{ fontSize: 15, fontWeight: 800 }}>
            {t('Your request is waiting for approval')}
          </IonLabel>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PendingSiteAccessRequest;
