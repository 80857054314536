import React from 'react';

import styles from './HeaderBackground.module.scss';

interface HeaderBackgroundProps {
  title: string;
  subtitle: string;
}

const HeaderBackground: React.FC<HeaderBackgroundProps> = ({ subtitle, title }) => {
  return (
    <div className={styles['registration-header-container']}>
      <div style={{ maxWidth: 500 }}>
        <h2>{title}</h2>
        <h5>{subtitle}</h5>
      </div>
    </div>
  );
};

export default HeaderBackground;
