import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';
import React from 'react';

import ButtonCTA from '../../../components/UI/Buttons/CTA/ButtonCTA';
import { ionicColours } from '../../../components/UI/variables';
import SiteAccessRequestStatus from '../../../constants/enums/SiteAccessRequestStatus';
import { useAppSelector } from '../../../hooks';

interface SiteAccessToggleProps {
  onClick: () => void;
}
const UnsubmittedSiteAccessRequest: React.FC<SiteAccessToggleProps> = ({ onClick }) => {
  const selectedProject: E2U.V1.Models.Project | undefined = useAppSelector((state) => {
    return state.project.selectedProject;
  });

  return (
    <React.Fragment>
      {selectedProject?.has_site_access === false && selectedProject.site_access_status === SiteAccessRequestStatus.UNSUBMITTED && (
        <React.Fragment>
          <IonGrid>
            <IonRow>
              <IonCol size='12'>
                <IonLabel color={'tertiary'} className={'ion-no-margin'} style={{ fontSize: 15, fontWeight: 600 }}>
                  {`${t('You currently do not have site access for project: ')}`}
                </IonLabel>
                <IonLabel color={'danger'} className='ion-no-margin'>
                  {selectedProject.name}
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonRow>
                <IonCol className='ion-text-right ion-margin-top'>
                  <ButtonCTA
                    backgroundColour={ionicColours.secondary}
                    clickHandler={onClick}
                    content={t('Request access')}
                  />
                </IonCol>
              </IonRow>
            </IonRow>

          </IonGrid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default UnsubmittedSiteAccessRequest;
