
import type { BankIdHandlerProps } from './bankid.interfaces';
import BankIdHandler from './BankIDHandler';

const BankIdSign: React.FC<BankIdHandlerProps> = ({ error, handleBankIdCompleted, type = 'sign' }) => {
  return (
    <BankIdHandler
      type={type}
      error={error}
      handleBankIdCompleted={handleBankIdCompleted}
    />
  );
};

export default BankIdSign;
