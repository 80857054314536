import {
  IonContent,
  IonPage
} from '@ionic/react';
import React from 'react';

import DocumentForm from '../DocumentForm';

const UploadDocumentPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <DocumentForm />
      </IonContent>
    </IonPage>
  );
};

export default UploadDocumentPage;
