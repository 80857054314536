import React from 'react';

import type { BankIdButtonProps } from './bankid.interfaces';
import BigUp from '..';
import type { ButtonProps } from '../Buttons/interface';

type ButtonTypes = BankIdButtonProps & ButtonProps;

const BankIdButton: React.FC<ButtonTypes> = ({ children, onClick, ...props }) => {
  return (
    <BigUp.Buttons.Regular
      style={{ '--background': 'var(--ion-color-bankid)' }}
      {...props}
      onClick={onClick}
    >
      {children}
    </BigUp.Buttons.Regular>
  );
};

export default BankIdButton;
