import { useIonAlert } from '@ionic/react';

const useAlert = () => {
  const [presentAlert] = useIonAlert();
  const alert = (header: string, message: string, handler: (id: string) => void, cancel: string, confirm: string) => {
    presentAlert({
      header,
      message,
      buttons: [
        cancel,
        {
          text: confirm,
          handler
        }
      ],
    });
  };

  return { alert };
};

export default useAlert;
