import { IonCol, IonGrid, IonItem, IonRow } from '@ionic/react';
import { type ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';

import { notificationKeyValues } from './defaults';
import { NotificationMethod } from './form.interface';
import { BigUp } from '../../../components/UI';
import InputLabel from '../../../components/UI/InputLabel';
import i18n from '../../../i18n';

const itemProps: ComponentProps<typeof IonItem> = {
  className: 'ion-no-padding',
  style: { marginBottom: 2 },
  lines: 'none'
};

const NoticeNotifications: React.FC = () => {
  const methods = useFormContext();

  const checkboxHandler = (checked: boolean, key: keyof typeof NotificationMethod) => {
    const methodValue = NotificationMethod[key];
    const currentMethods = methods.getValues('notification_methods') || 0;

    let newMethods = 0;

    if (checked) {
      newMethods = currentMethods | methodValue;
    } else {
      newMethods = currentMethods & ~methodValue;
    }
    methods.setValue('notification_methods', newMethods);
  };

  return (
    <>
      <InputLabel margin={'ion-no-margin'} stacked>{i18n.t('Notification types')}</InputLabel>
      <IonGrid>

        {notificationKeyValues.map((notificationType) => (
          <IonRow key={notificationType.key}><IonCol>
            <IonItem className='ion-no-padding'>
              <BigUp.Checkbox
                key={notificationType.key}
                labelPlacement='start'
                checked={(methods.getValues('notification_methods') & NotificationMethod[notificationType.key]) !== 0}
                handleCheckbox={(checked) => checkboxHandler(checked, notificationType.key)}
              >
                {notificationType.label}
              </BigUp.Checkbox>
            </IonItem>
          </IonCol>
          </IonRow>
        ))}

      </IonGrid>

    </>
  );
};

export default NoticeNotifications;
