import { IonCol, IonContent, IonFabButton, IonGrid, IonIcon, IonItem, IonModal, IonRow, IonText } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { t } from 'i18next';
import { chatboxEllipsesOutline, checkmark, close } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import styles from './WorkingEnvironment.module.scss';
import WorkingEnvironmentEntryReport from './WorkingEnvironmentEntry/WorkingEnvironmentEntryReport';
import { networking } from '../../../../api/networking';
import HeaderBorderLeft from '../../../../components/UI/modals/HeaderBorderLeft';
import { ionicColours } from '../../../../components/UI/variables';
import { useAppSelector } from '../../../../hooks';
import { setWorkingEnvironmentEntry } from '../../../../reducers/workingEnvironmentEntryReducer';
import store from '../../../../store';

enum Status {
  completed = 'completed',
  faulty = 'faulty',
  pending = 'pending',
  deny = 'deny',
  approve = 'approve'
}

const WorkingEnviromentReport: React.FC = () => {
  const { entry_uuid, working_environment_entry_uuid, working_environment_uuid } = useParams<{
    working_environment_uuid: string | undefined;
    working_environment_entry_uuid: string | undefined;
    entry_uuid: string | undefined;
  }>();
  const [workingEnvironment, setWorkingEnvironment] = useState<E2U.V1.Models.WorkingEnvironment | undefined>();
  const [environmentCategories, setEnvironmentCategories] = useState<E2U.V1.Models.WorkingEnvironmentCategory[] | undefined>();
  const [reportModalOpen, setReportModalOpen] = useState<boolean>(false);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const history = useHistory();

  const getCategory = () => {
    networking.get(`/api/v1/working_environment_categories`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.WorkingEnvironmentCategory>>) => {
        setEnvironmentCategories(response.data.data.records);
      });
  };

  const getWorkingEnvironment = (uuid: string) => {
    networking.get(`/api/v1/working_environments/${uuid}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.WorkingEnvironment>) => {
        setWorkingEnvironment(response.data.data);
      });
    getCategory();
  };

  const redirectIfEntryUuid = () => {
    if (working_environment_entry_uuid) {
      networking.get(`/api/v1/working_environment_entry_reports/${working_environment_entry_uuid}?with[]=workingEnvironmentEntry`)
        .then((response: E2U.V1.Response.Success<E2U.V1.Models.WorkingEnvironmentEntryReport>) => {
          if (!response.data.data.working_environment_entry) {
            return;
          }
          history.push(`/project-tools/reports/working_environment/${response.data.data.working_environment_entry.environment_id}/${response.data.data.entry_id}`);
        });
    }
  };

  const openAppropriateEntryModal = () => {
    if (entry_uuid && !reportModalOpen) {
      workingEnvironment?.entries_by_category && Object.keys(workingEnvironment?.entries_by_category).forEach((categoryId) => {
        const findCategoryId = workingEnvironment?.entries_by_category[categoryId];
        if (!findCategoryId) {
          return;
        }
        findCategoryId.forEach((entry) => {
          if (entry.id === entry_uuid) {
            setSelectedReport(entry);
          }
        });
      });
    }
  };

  const setSelectedReport = (entry: E2U.V1.Models.WorkingEnvironmentEntry | undefined) => {
    setReportModalOpen(!reportModalOpen);
    store.dispatch(setWorkingEnvironmentEntry(entry));
  };

  const closeAndRedirect = (route = `/project-tools/${selectedProject?.id}/documents`) => {
    setReportModalOpen(false);
    if (location.pathname === `/project-tools/reports/working_environment/${working_environment_uuid}/${entry_uuid}`) {
      history.push(route);
    }
  };

  useEffect(() => {
    if (working_environment_entry_uuid) {
      redirectIfEntryUuid();
      return;
    }
    if (!workingEnvironment && working_environment_uuid) {
      getWorkingEnvironment(working_environment_uuid);
      return;
    }
    openAppropriateEntryModal();
  }, [working_environment_entry_uuid, working_environment_uuid, entry_uuid, workingEnvironment]);

  return (
    <React.Fragment>
      <IonGrid className={'ion-padding'}>
        <IonRow className='ion-align-items-center ion-justify-content-between ion-margin-bottom'>
          <IonCol size='5' color='danger'>
            <IonItem className='ion-no-padding'>
              <IonText>
                <h3>
                  {workingEnvironment?.date && DateTime.fromISO(workingEnvironment?.date).toFormat('yyyy-MM-dd')}
                </h3>
              </IonText>
            </IonItem>
          </IonCol>
        </IonRow>

        {(workingEnvironment && workingEnvironment.entries_by_category) &&
          Object.keys(workingEnvironment?.entries_by_category).map((categoryId, index) => {
            const findCategoryId = workingEnvironment?.entries_by_category[categoryId];

            const findEnvironmentCategory = environmentCategories?.find((category) => {
              return (category.id === categoryId);
            });

            return (
              <React.Fragment key={index}>
                <IonRow
                  className='ion-justify-between ion-align-items-center ion-padding-right ion-margin-top'
                  style={{ backgroundColor: ionicColours.light, fontSize: '16px' }}>
                  <IonCol className='ion-margin'>
                    {`${findEnvironmentCategory?.code} ${findEnvironmentCategory?.name}`}
                  </IonCol>
                </IonRow>

                {findCategoryId.map((entry: any, idx: number) => {
                  const itemId = entry.item.id;
                  const entryStatusComplete = entry.status === Status.completed;
                  const entryStatusFaulty = entry.status === Status.faulty;
                  return (
                    <IonRow
                      key={idx}
                      className='ion-align-items-center ion-justify-content-between'
                      style={{ borderBottom: '1px solid var(--ion-color-medium)' }}
                    >
                      <IonCol size='5' className='ion-padding'>
                        <p className='ion-no-margin'>
                          {entry.item.name}
                        </p>
                      </IonCol>
                      <IonRow className='ion-justify-content-end'>
                        <IonCol className='ion-no-margin ion-margin-end ion-padding-vertical'>
                          {(entry.reports && entry.reports.length > 0) &&
                            <IonFabButton
                              size='small'
                              color={'primary'}
                              className={'ion-no-margin'}
                              onClick={() => setSelectedReport(entry)}
                            >
                              <IonIcon
                                icon={chatboxEllipsesOutline}
                                {...itemId &&
                                  (entryStatusComplete || entryStatusFaulty)
                                  ? { color: 'light' }
                                  : { color: 'medium' }
                                } />
                            </IonFabButton>
                          }
                        </IonCol>

                        <IonCol className='ion-margin-end' onClick={(e) => e.stopPropagation()}>
                          <IonIcon
                            icon={(itemId && entryStatusFaulty) ? close : checkmark}
                            size={'large'}
                            className={classNames(styles['working-environment-vam'], 'ion-padding-vertical')}
                            {...itemId && entryStatusComplete ? { color: 'light' } : { color: 'medium' }}
                            {
                              ...itemId &&
                              entryStatusComplete
                                ? { color: 'success' }
                                : { color: 'danger' }
                            }
                          />
                        </IonCol>
                      </IonRow>
                    </IonRow>
                  );
                })}
              </React.Fragment>
            );
          })
        }
      </IonGrid>

      <IonModal
        isOpen={reportModalOpen}
        onIonModalDidDismiss={() => setReportModalOpen(false)}
        className={styles['create-list-modal-container']}
      >
        <HeaderBorderLeft
          borderColour={ionicColours.tertiary}
          title={t('Report')}
          clickHandler={closeAndRedirect}
        />
        <IonContent className='ion-padding'>
          <WorkingEnvironmentEntryReport closeModal={closeAndRedirect} readonly={true} />
        </IonContent>
      </IonModal>
    </React.Fragment >
  );
};
export default WorkingEnviromentReport;
