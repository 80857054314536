import { IonContent, IonFooter, IonList, IonModal, IonText, IonToolbar } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';
import type { FieldValues, SubmitHandler } from 'react-hook-form';

import type { Permissions, PermissionsModalProps } from './config';
import { permissions } from './config';
import styles from './Permissions.module.scss';
import PermissionSelection from './PermissionSelection';
import { BigUp } from '..';
import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import usePermissionSelect from '../../../hooks/usePermissionSelect';
import StepButtons from '../../Toolbar/StepButtons';
import HeaderBorderLeft from '../modals/HeaderBorderLeft';
import { ionicColours } from '../variables';

export type ProjectWithPermissions = E2U.V1.Models.Project & {
  project_tools_access: Permissions;
  economy_tools_access: Permissions;
};
const PermissionsModal: React.FC<PermissionsModalProps> = (props) => {
  const { handlePermissionSelection, selectedPermissions, setSelectedPermissions } = usePermissionSelect();
  const team = useAppSelector(state => state.team.selectedTeam);
  const user = useAppSelector(state => state.authentication.user);

  const project = useAppSelector((state) => state.project.selectedProject as ProjectWithPermissions);
  const submitUpdatedPermissions: SubmitHandler<FieldValues> = async () => {
    try {
      await networking.put(`/api/v1/teams/${team?.id}/users/${props?.data?.id}`, {
        project_tools_access: selectedPermissions.project_tools ?? 'none',
        economy_tools_access: selectedPermissions.economy_tools ?? 'none'
      });
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setSelectedPermissions({
        project_tools: project?.project_tools_access || 'none',
        economy_tools: project?.economy_tools_access || 'none'
      });
      props.modal.modalDidDismiss();
    }
  };
  return (
    <IonModal
      className={styles.permissionsModal}
      isOpen={props.modal.modalIsOpen}
      onDidDismiss={() => props.modal.modalDidDismiss()}
    >
      <HeaderBorderLeft
        borderColour={ionicColours.secondary}
        title={`${t('Permissions')}`}
        clickHandler={() => props.modal.modalDidDismiss()}
      />
      <IonContent className="ion-padding" scrollY={false}>
        <div className={'ion-margin-start'}>
          <IonText color={'dark'}>
            <BigUp.Label.Thick label={`${t('Select permissions for')}:`} />
            <p className={'ion-no-margin'} style={{ fontWeight: 600 }}>
              {props?.data?.first_name} {props?.data?.last_name}
            </p>
          </IonText>
        </div>
        <IonList className='ion-margin'>
          <PermissionSelection
            data={permissions}
            handleSelection={(value: Permissions) => handlePermissionSelection(value, 'project_tools')}
            selected={selectedPermissions.project_tools}
            title={t('Project tools')}

          // TODO: Add this when available from server
          // value={props?.data?.pivot?.project_tools_access || null}
          />
          <PermissionSelection
            data={permissions}
            handleSelection={(value: Permissions) => handlePermissionSelection(value, 'economy_tools')}
            selected={selectedPermissions.economy_tools}
            title={t('Economy tools')}

          />
        </IonList>
        <IonFooter className='ion-no-border'>
          <IonToolbar>
            <StepButtons
              rightOnClick={(submitUpdatedPermissions) as any}
              leftOnClick={() => props.modal.modalDidDismiss()}
              rightDisabled={false}
              rightSubmit={'submit'}
              rightTitle={t('Save')}
              leftTitle={t('Cancel')}
              rightIonColor='secondary'
              leftIonColor='none'
            />
          </IonToolbar>
        </IonFooter>
        {props.children}
      </IonContent>
    </IonModal>
  );
};

export default PermissionsModal;
