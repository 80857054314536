import { IonButton, IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTextarea } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

import styles from './Tiles.module.scss';
import type { DeviationProps } from './TilesTypings';
import { setActivityCodes } from '../../../../reducers/ActivityCodes';
import store from '../../../../store';
import formatNumber from '../../../../tools/formatNumber';
import type { AmmendmentData } from '../../Subpanels/EconomyMonitoring/EconomyMonitoringRows/add/FormTypings';

const DeviationsTile: React.FC<DeviationProps> = ({ clickable, row }) => {
  const [hasDeviations, setHasDeviations] = useState<boolean>(false);
  const [addDeviations, setAddDeviations] = useState<boolean>(false);
  const { uuid } = useParams<{
    uuid: string | undefined
  }>();
  const history = useHistory();
  const { t } = useTranslate();

  const methods = useForm<AmmendmentData>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const [validationErrors, setValidationErrors] = useState<{
    [field: string]: string[];
  }>({});

  useEffect(() => {
    if (row && row.deviations_total) {
      setHasDeviations(true);
    } else {
      setHasDeviations(false);
    }
  }, [row]);

  return (
    <React.Fragment>
      <div className={styles.tile}>
        <div className={classNames(styles.container, styles.isDeviation)}>
          <IonLabel className={classNames(styles.title, (
            hasDeviations
              ? styles['has-deviations']
              : ''))}>
            {t('Deviations')}
          </IonLabel>
          {(row) && <p
            className={classNames(styles.value)}
            style={{
              opacity: 0.7,
              width: '100%',
              textAlign: 'center',
              textTransform: 'uppercase',
              fontWeight: 800,
              borderRadius: '8px',
              cursor: 'pointer',
              ...(row.deviations_total === 0)
                ? {
                  background: 'none ',
                  color: 'var(--ion-color-dark)',
                }
                : {
                  color: 'var(--ion-color-light)',
                  background: 'var(--ion-color-primary)',
                }
            }}
            {...clickable === true && {
              onClick: () => {
                store.dispatch(setActivityCodes([row.activity_code]));
                history.push(`/economy-tools/${uuid}/deviations/create`);
              }
            }}>
            {
              row.deviations_total === 0
                ? clickable
                  ? '+ ' + t('add')
                  : row.deviations_total
                : formatNumber(row.deviations_total)
            }
          </p>}
        </div>
      </div>

      <IonModal
        className={styles['add-change-or-deviation-modal']}
        isOpen={addDeviations}
        onDidDismiss={() => {
          setAddDeviations(false);
        }}
      >
        <IonContent className='ion-padding'>
          <h1>{t('Deviations')}</h1>
          <IonGrid >
            <IonRow className='ion-justify-items-start ion-align-items-start'>
              <IonCol size='12' className='ion-margin-top'>
                <IonItem className='ion-no-padding'>
                  <IonLabel position="stacked" className='ion-no-margin'>
                    {t('Quantity')}:
                  </IonLabel>
                  <IonInput
                    {
                      ...methods.register('quantity', {
                        required: true
                      })
                    }
                    type='number'
                    inputmode='numeric'
                    placeholder="Enter quantity" />
                </IonItem>
              </IonCol>
              <IonCol size='12' className='ion-margin-top'>
                <IonItem className='ion-no-padding'>
                  <IonLabel position="stacked" className='ion-no-margin'>
                    {t('Unit')}:
                  </IonLabel>
                  <IonInput
                    {
                      ...methods.register('unit', {
                        required: true
                      })
                    }
                    type='text'
                    placeholder="Enter unit" />
                </IonItem>
              </IonCol>
              <IonCol size='12' className='ion-margin-top'>
                <IonItem className='ion-no-padding' counter>
                  <IonLabel position="stacked" className='ion-no-margin'>
                    {t('Cost per unit')}:
                  </IonLabel>
                  <IonTextarea
                    {
                      ...methods.register('cost_per_unit', {
                        required: true
                      })
                    }
                    autoGrow
                    placeholder={t('cost per unit')}
                    maxlength={140} />
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid className='ion-margin-bottom'>
            <IonRow className='ion-justify-content-center ion-align-items-center ion-margin-top'>
              <IonCol size='6' className='ion-text-center'>
                <IonButton color={'medium'}>{t('cancel')}</IonButton>
              </IonCol>
              <IonCol size='6' className='ion-text-center'>
                <IonButton
                  type='submit'
                  disabled={
                    !methods.formState.isDirty &&
                    !methods.formState.isValid
                  }
                >
                  {t('submit')}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>

    </React.Fragment>
  );
};

export default DeviationsTile;
