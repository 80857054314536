import React from 'react';
import { Redirect } from 'react-router';

import { useAppSelector } from '../../hooks';

const RedirectHandler: React.FC = () => {
  const shouldRedirect = useAppSelector((store) => store.authentication.shouldRedirect);
  return (
    <React.Fragment>
      {shouldRedirect
        ? (
          <Redirect push from="/" to="/login"></Redirect>
        )
        : <React.Fragment />}
    </React.Fragment>
  );
};

export default RedirectHandler;
