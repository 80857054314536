import { useTranslate } from '@tolgee/react';
import React, { useEffect } from 'react';

import ProjectListPage from './ProjectListPage';
import { reloadProjects } from '../../reducers/project';
import store from '../../store';

const ProjectsPage: React.FC = () => {
  const { t } = useTranslate();

  useEffect(() => {
    store.dispatch(reloadProjects(undefined));
  }, []);

  return (
    <React.Fragment>
      <ProjectListPage title={t('Project Tools')} description={t('Select a project to manage.')} />
    </React.Fragment>
  );
};

export default ProjectsPage;
