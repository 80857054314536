import {
  IonDatetime,
  IonDatetimeButton,
  IonLabel,
  IonModal
} from '@ionic/react';

import styles from './DateTimePicker.module.scss';
import type { TitleType } from './DateTimePickerTypes';

const DateTimePicker: React.FC<TitleType> = ({ title }) => {
  return (
    <section>
      <div className={styles['date-label']}>
        <IonLabel>{title}</IonLabel>
      </div>

      <IonDatetimeButton datetime="datetime"></IonDatetimeButton>

      <IonModal keepContentsMounted={true}>
        <IonDatetime id="datetime"></IonDatetime>
      </IonModal>
    </section>
  );
};

export default DateTimePicker;
