import { IonButton, IonIcon, useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { close } from 'ionicons/icons';
import { useEffect, useState } from 'react';

import SiteAccessRequestStatus from '../../../../constants/enums/SiteAccessRequestStatus';
import type { SiteAccessIndicator } from '../../../../tools/getSiteAccessIndicator';
import getSiteAccessStatusIndicator from '../../../../tools/getSiteAccessIndicator';

interface SiteAccessButtonInterface {
  project: E2U.V1.Models.Project;
  onClick: (project: E2U.V1.Models.Project) => void;
}

const SiteAccessButton: React.FC<SiteAccessButtonInterface> = ({ onClick, project }) => {
  const { t } = useTranslate();
  const [presentAlert] = useIonAlert();
  const [siteAccessIndicator, setSiteAccessIndicator] = useState<SiteAccessIndicator>({
    icon: close,
    color: 'none'
  });

  const workoutDisplay = () => {
    setSiteAccessIndicator(
      getSiteAccessStatusIndicator(project.site_access_status as SiteAccessRequestStatus)
    );
  };

  const handleClick = () => {
    if (project.site_access_status === SiteAccessRequestStatus.REJECTED) {
      return presentAlert({
        header: t('Your request was denied'),
        message: project.site_access_status_message,
        subHeader: t('Reason:'),
        buttons: [
          {
            text: t('Cancel'),
            role: 'cancel',
          },
          {
            text: t('Ok'),
            role: 'confirm',
            handler: () => {
              onClick(project);
            }
          }
        ],
      });
    }

    onClick(project);
  };

  useEffect(() => {
    workoutDisplay();
  }, [project]);

  return (
    <>
      <IonButton
        shape="round"
        fill="solid"
        color={siteAccessIndicator.color}
        disabled={project.has_site_access}
        onClick={handleClick}
      >
        <IonIcon size="large" icon={siteAccessIndicator.icon} />
      </IonButton>

      <p className='ion-no-margin'>{t('Access')}</p>
    </>
  );
};

export default SiteAccessButton;
