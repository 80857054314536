import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useLocation } from 'react-router';

import BirthdatePicker from './BirthdatePicker';
import registration_inputs from './config';
import type { RegistrationInputsProps } from './types';
import BigUp from '../../components/UI';

const RegistrationInputs: React.FC<RegistrationInputsProps> = ({ isBankId, onKeyUp }) => {
  const location = useLocation();

  return (
    <IonGrid>
      {registration_inputs.name_inputs.map((input, index) => {
        return (
          <IonRow key={input.id || index}>
            <IonCol>
              <BigUp.Input onKeyUp={onKeyUp} enterkeyhint='next' itemProps={{ lines: 'none' }} {...input} />
            </IonCol>
          </IonRow>
        );
      })}
      <IonRow>
        <IonCol>
          {isBankId || location.pathname !== '/registration'
            ? <BigUp.Input
              onKeyUp={onKeyUp}
              enterkeyhint='next'
              itemProps={{ lines: 'none' }}
              {...registration_inputs.swedish_personal_number} />
            : <BirthdatePicker />
          }
        </IonCol>
      </IonRow>
      {registration_inputs.contact_inputs.map((input, index) => {
        return (
          <IonRow key={index}>
            <IonCol>
              <BigUp.Input
                onKeyUp={onKeyUp}
                enterkeyhint='next'
                itemProps={{ lines: 'none' }}
                {...input}
              />
            </IonCol>
          </IonRow>
        );
      })}
    </IonGrid>
  );
};

export default RegistrationInputs;
