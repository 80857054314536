import { IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { chevronDown, chevronUp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';

import styles from './PrecalculationRows.module.scss';
import { networking } from '../../../../api/networking';
import FadeInContainer from '../../../../components/Animated/FadeInContainer';
import PaginateData from '../../../../components/Pagination/PaginationData';
import SkeletonTextThreeLines from '../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import toasters from '../../../../components/Toasts/Toasts';
import Easy2Use from '../../../../components/UI';
import { handleEmptyListStates } from '../../../../components/UI/EmptyList';
import { emptyListMessages } from '../../../../constants/messages';
import { useAppSelector } from '../../../../hooks';
import { setIsLoading } from '../../../../reducers/loading';
import { setSelectedPrecalculation } from '../../../../reducers/precalculations';
import store from '../../../../store';
import formatNumber from '../../../../tools/formatNumber';
import { buildQuery } from '../../../../tools/queryBuilder';
import { scrollToSection } from '../../../../tools/scrollToSection';
import ActivityCodeTile from '../ActivityCodeTile';

interface Precalculation {
  selectedPrecalculationId: string | undefined;
  onRowClick: (row: E2U.V1.Models.PrecalculationRow) => void;
  lockRows: boolean;
  search: string;
  sortBy: string;
  filterParam: string | null;
}

type PrecalcuationRowsComponent<props> = (props: props) => {
  PreCalculationRowsComponentRender: JSX.Element;
  fetchPrecalculation: () => void;
};

interface PaginationLabelInterface {
  currentPage: number;
  filteredPagination?: number | undefined;
  totalPages: number | undefined;
}

export const PaginationLabel: React.FC<PaginationLabelInterface> = ({ currentPage, filteredPagination, totalPages }) => {
  const { t } = useTranslate();
  return (
    <IonLabel style={{ margin: '0', textAlign: 'left' }}>
      {t('Page')} {currentPage === 0 ? 1 : currentPage} {t('of')}{' '}
      {filteredPagination !== undefined && filteredPagination !== 0 ? filteredPagination : totalPages}
    </IonLabel>
  );
};

const PrecalculationRows: PrecalcuationRowsComponent<Precalculation> = (props) => {
  const [precalcalculationRows, setPrecalculationRows] = useState<E2U.V1.Models.PrecalculationRow[]>([]);
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showFullDescription, setShowFullDescription] = useState<number | null>(null);
  const [error, setError] = useState<string | undefined>();
  const { t } = useTranslate();

  const selectedPrecalculation = useAppSelector((state) => state.precalculations.selectedPrecalculation);
  const isLoadingPrecalculation = useAppSelector((state) => state.loading.isLoading.precalculation);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const scrollToId = 'precalculations-rows-top';

  const fetchPrecalculation = () => {
    if (!props.selectedPrecalculationId) {
      return;
    }
    store.dispatch(setIsLoading({ name: 'precalculation', value: true }));

    toasters.promise(networking.get(`api/v1/precalculations/${props.selectedPrecalculationId}?with=rows`), {
      error: t('Failed to load precalculation rows')
    })
      .then(
        (response: E2U.V1.Response.Success<E2U.V1.Models.Precalculation>) => {
          store.dispatch(setSelectedPrecalculation(response.data.data));
        }
      )
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
        setError(error.message);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'precalculation', value: false }));
      });
  };

  const fetchPrecalculationRow = (page?: number) => {
    if (!props.selectedPrecalculationId) {
      return;
    }
    store.dispatch(setIsLoading({ name: 'precalculation', value: true }));

    if (typeof page !== 'undefined') {
      setCurrentPage(page);
    }

    let filterDirection = 'asc';
    let filterSortBy: string = props.sortBy;

    if (filterSortBy.endsWith('_desc')) {
      /**
       * Ideally, this would be separate parameter, but that would almost certainly
       * require UI changes (to allow for separate sort by and direction).
       */

      filterDirection = 'desc';
      filterSortBy = filterSortBy.replace('_desc', '');
    }

    const query: string = buildQuery(
      props.search,
      filterSortBy,
      filterDirection,
      (typeof page !== 'undefined' ? page : currentPage),
      10
    );

    toasters
      .promise(networking.get(`api/v1/precalculations/${props.selectedPrecalculationId}/rows?filters=[{"field":"secured_cost","value":${props.filterParam}}]&${query}`
      ), {
        error: t('Failed to load precalculation rows')
      }
      )
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.PrecalculationRow>>) => {
        setPrecalculationRows(response.data.data.records);
        setTotalPages(response.data.data.total_pages);
        setCurrentPage(response.data.data.current_page);
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'precalculation', value: false }));
      });
  };

  const pageStepper = (step: number) => {
    setCurrentPage(currentPage + step);
    scrollToSection(scrollToId);
  };

  const toggleDescription = (i: number) => {
    setShowFullDescription(prev => (prev === i ? null : i));
  };

  useEffect(() => {
    fetchPrecalculation();
    fetchPrecalculationRow();
  }, [props.selectedPrecalculationId]);

  useEffect(() => {
    fetchPrecalculationRow(1);
  }, [props.search, props.sortBy, props.filterParam]);

  useEffect(() => {
    fetchPrecalculationRow();
  }, [currentPage, selectedPrecalculation]);
  const messages = {
    empty: {
      title: t(emptyListMessages.precalculation_rows.empty_title),
      message: t(emptyListMessages.precalculation_rows.empty_message)
    },
    error: {
      message: ''
    }
  };

  return {
    fetchPrecalculation,
    PreCalculationRowsComponentRender: (
      <React.Fragment>
        <div id={scrollToId}>
          <PaginationLabel currentPage={currentPage} totalPages={totalPages} />
        </div>

        <div className={styles['precalculations-container']} slot="content">
          <div className={styles['precalculations-row-container']}>
            {precalcalculationRows.length > 0
              ? (
                precalcalculationRows.map((selected, i) => (
                  <div
                    key={i}
                    {...(selected.description !== null && { style: { borderBottom: '1px solid var(--ion-color-light-shade)' } })}
                  >
                    {((selected.secured_cost) || (!selected.secured_cost)) &&
                      (
                        <IonGrid>
                          <IonRow className={`ion-text-left ion-padding-top ${i % 2 === 0 ? styles.evenRow : ''}`}>
                            <IonCol
                              size={'4'}
                              className={`ion-text-left`}
                              {...(selectedPrecalculation?.is_draft && {
                                onClick: () => props.onRowClick(selected)
                              })}
                            >
                              <ActivityCodeTile activityCode={selected.activity_code} name={selected.name} />
                            </IonCol>

                            <IonCol className={isDesktop ? 'ion-text-center' : 'ion-text-center ion-no-padding'}>
                              <Easy2Use.Label.Regular label={t('Quantity')} style={{ fontSize: '12px' }} />
                              <p>{Math.round(selected.quantity)}</p>
                            </IonCol>

                            <IonCol className={isDesktop ? 'ion-text-center' : 'ion-text-center ion-no-padding'}>
                              <Easy2Use.Label.Regular label={t('Cost/unit')} style={{ fontSize: '12px' }} />
                              <p>{formatNumber(selected.cost_per_unit)}</p>
                            </IonCol>

                            <IonCol {
                              ...isDesktop
                                ? { className: 'ion-text-center ion-no-padding' }
                                : { className: 'ion-text-center ion-no-padding' }}>

                              <Easy2Use.Label.Regular label={t('Total')} style={{ fontSize: '12px' }} />
                              <p>{formatNumber(selected.total)}</p>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      )}

                    {selected.description && (
                      <div className={`ion-text-left ion-padding-bottom ${i % 2 === 0 ? styles.evenRow : ''}`} style={{ paddingLeft: 5 }}>
                        <IonGrid className={classNames(styles['precalculations-comment-section'])}>
                          <IonRow className='ion-margin-left' >
                            <IonLabel >{`${t('Comment')}`} </IonLabel>
                          </IonRow>
                          {isDesktop
                            ? (
                              <IonRow className='ion-align-items-center ion-margin-left'>
                                <IonCol size='10' className='ion-margin-left'>
                                  <span>
                                    {selected.description}
                                  </span>
                                </IonCol>
                              </IonRow>
                            )
                            : (
                              <IonRow className='ion-align-items-flex-start' onClick={() => toggleDescription(i)} role='button'>
                                <IonCol size='10' className='ion-margin-left'>
                                  <span>
                                    {showFullDescription === i
                                      ? <FadeInContainer isVisible={showFullDescription === i || !null}>{selected.description}</FadeInContainer>
                                      : selected.description && selected.description.length < 40 ? `${selected.description}` : `${selected.description.slice(0, 60)}...`}
                                  </span>
                                </IonCol>
                                <IonCol size='2' className='ion-text-center'>
                                  {selected.description.length > 40
                                    ? showFullDescription === i
                                      ? <IonIcon icon={chevronUp}></IonIcon>
                                      : <IonIcon icon={chevronDown}></IonIcon>
                                    : null}

                                </IonCol>
                              </IonRow>
                            )}
                        </IonGrid>
                      </div>
                    )}
                  </div>
                ))
              )
              : isLoadingPrecalculation
                ? (
                  <section style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                    <SkeletonTextThreeLines amount={4} />
                  </section>
                )
                : (
                  <IonGrid>
                    <IonRow className='ion-justify-content-center'>
                      {handleEmptyListStates({ isEmpty: precalcalculationRows.length === 0, error, messages, search: props.search })}
                    </IonRow>
                  </IonGrid>
                )}
          </div>
        </div>
        {precalcalculationRows.length > 0 && (
          <PaginateData
            currentPage={currentPage}
            totalPages={totalPages}
            pageStepper={pageStepper}
          />
        )}
      </React.Fragment>
    )

  };
};

export default PrecalculationRows;
