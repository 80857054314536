import type { Photo } from '@capacitor/camera';
import { IonCol, IonGrid, IonItem, IonLabel, IonRow, IonToggle } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import React, { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { networking } from '../../../../../api/networking';
import FileList from '../../../../../components/FileList/FileList';
import RelatedSelect from '../../../../../components/Search/RelatedSelect/RelatedSelect';
import type { Result } from '../../../../../components/Search/RelatedSelect/RelatedSelectInterface';
import toasters from '../../../../../components/Toasts/Toasts';
import Easy2Use from '../../../../../components/UI';
import { useAppSelector } from '../../../../../hooks';
import useCameraUpload from '../../../../../hooks/useCameraUpload';
import useFileUpload from '../../../../../hooks/useFileUpload';
import useFocusNextInput from '../../../../../hooks/useFocusNextInput';
import FileSelectionButton from '../AddFilesModal/FileSelectionButton';
import TakePhotoButton from '../AddFilesModal/TakePhotoButton';
import styles from '../ControlOfExecution.module.scss';

interface ButtonInterface {
  toggleBack: () => void
  onDelete: () => void;
  fetchOnSubmit: (recordId?: string) => void;
  record: Partial<E2U.V1.Models.ControlOfExecution>;
}

const EditModal: React.FC<ButtonInterface> = ({ fetchOnSubmit, record, toggleBack }) => {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [filesToRemove, setFilesToRemove] = useState<E2U.V1.Models.File[]>([]);
  const { focusNextInput, formRef, handleNextInput } = useFocusNextInput(); // Use the custom hook
  /*   const handleNextInput = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
      if (e.key === 'Enter') {
        focusNextInput(e.target as HTMLInputElement);
      }
    }; */
  const { getUploadedPhotos, handleTakenPhoto, removePhoto, uploadSelectedPhotos } = useCameraUpload();
  const { getUploadedFiles, handleFileSelection, removeFile, uploadSelectedFiles } = useFileUpload();
  const { t } = useTranslate();

  const project = useAppSelector(state => state.project.selectedProject);
  const user = useAppSelector(state => state.authentication.user);
  const form = useForm<E2U.V1.Models.ControlOfExecution>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      activity_code_id: undefined,
      assigned_user_id: user?.id,
      assigned_user: user,
      name: '',
      description: '',
      project_id: project?.id,
      confirmed: 0,
      assignees: [],
      requires_supervisor: false || undefined,
      files: [],
    },
  });

  const { control, formState, watch } = form;
  const { description, name, project_id } = watch();
  const files = getUploadedFiles() ?? [];
  const photos = getUploadedPhotos() ?? [];

  const handlePost: SubmitHandler<FieldValues> = (data: FieldValues) => {
    const isUpdating = Boolean(record && record.id);
    const request = isUpdating
      ? networking.put(`/api/v1/control_of_executions/${record.id}`, data)
      : networking.post(`/api/v1/control_of_executions`, data);

    toasters.promise(new Promise<any>((resolve, reject) => {
      request.then((saveRequest: E2U.V1.Response.Success<
        E2U.V1.Models.ControlOfExecution>
      ) => {
        const finishedPromises: Promise<any>[] = [];
        Promise.all(finishedPromises)
          .finally(() => {
            resolve(undefined);
          });
        Promise.allSettled([
          uploadSelectedFiles(`/api/v1/control_of_executions`, saveRequest.data.data.id ?? ''),
          uploadSelectedPhotos(`/api/v1/control_of_executions`, saveRequest.data.data.id ?? '')
        ]);

        if (isUpdating && record.assignees && record.assignees.length) {
          const existingAssigneeIds = data.assignees.map((r: E2U.V1.Models.User) => r.id);
          const assigneesToRemove: E2U.V1.Models.User[] = record.assignees.filter(
            (assignee: E2U.V1.Models.User) => !existingAssigneeIds.includes(assignee.id)
          );
          finishedPromises.push(
            Promise.all(
              assigneesToRemove.map((user: E2U.V1.Models.User) => networking.delete(
                `/api/v1/control_of_executions/${saveRequest.data.data.id}/assignees/${user.id}`
              )
                .catch((error) => {
                  Sentry.captureException(error);
                  toast(t(`Failed to remove assignee ${user.name} from control of execution`), {
                    type: 'error'
                  });
                })
              )
            )
          );
        }
        if (data.assignees.length) {
          finishedPromises.push(networking.post(
            `/api/v1/control_of_executions/${saveRequest.data.data.id}/assignees/${user?.id}`
          )
            .catch((error) => {
              Sentry.captureException(error);
              toast(t(`Failed to add assignee ${user?.name} to control of execution`), {
                type: 'error'
              });
            }));
        }
        if (filesToRemove.length) {
          finishedPromises.push(
            Promise.all(
              filesToRemove.map(
                (file: E2U.V1.Models.File) => networking.delete(
                  `/api/v1/control_of_executions/${saveRequest.data.data.id}/files/${file.id}`
                )
                  .catch((error) => {
                    Sentry.captureException(error);
                    toast(t(`Failed to remove file ${file.name} from control of execution`), {
                      type: 'error'
                    });
                  })
              )
            )
          );
        }
        Promise.all(finishedPromises)
          .finally(() => {
            fetchOnSubmit(isUpdating ? undefined : saveRequest.data.data.id);
            resolve(undefined);
          });
      })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    }), {
      pending: t('Saving Control of Execution'),
      success: t('Saved Control of Execution'),
      error: t('Error while saving Control of Execution'),
    });
  };

  const deleteFile = (e: React.MouseEvent<HTMLIonButtonElement>, file: E2U.V1.Models.File) => {
    e.stopPropagation();

    if (typeof file.id === 'undefined' && files) {
      const newFiles = files.filter((doc) => doc.name !== file.name);
      form.setValue('files', newFiles);

      const newFilesToUpload = filesToUpload.filter((file) => file.name);
      setFilesToUpload(newFilesToUpload);
    } else {
      setFilesToRemove(
        [...filesToRemove, file]
      );
      form.setValue(
        'files',
        files?.filter((doc) => doc.id !== file.id)
      );
    }
  };

  useEffect(() => {
    form.setValue('requires_supervisor', record?.requires_supervisor || false);
  }, [record]);

  useEffect(() => {
    if (record) {
      if (record.files) {
        form.setValue('files', record.files, { shouldDirty: true });
      }
      if (record.assignees) {
        form.setValue('assignees', record.assignees, { shouldDirty: true });
      }
      if (record.activity_code_id) {
        form.setValue('activity_code_id', record.activity_code_id, { shouldDirty: true });
      }
      if (record.name) {
        form.setValue('name', record.name, { shouldDirty: true });
      }
      if (record.description) {
        form.setValue('description', record.description, { shouldDirty: true });
      }
      if (record.project_id) {
        form.setValue('project_id', record.project_id, { shouldDirty: true });
      }
    }
  }, [record]);

  return (
    <FormProvider {...form}>
      <IonGrid>
        <form ref={formRef} onSubmit={form.handleSubmit(handlePost)} className={styles['record-view-edit-form']}>
          <IonRow className='ion-margin-vertical ion-justify-content-end'>
            <IonCol size='12'>
              <Controller
                control={form.control}
                name='activity_code_id'
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <RelatedSelect
                    infiniteScroll={true}
                    value={value}
                    model="activity_codes"
                    onSelect={(data: Result) => onChange(data.id)}
                    title={t('Select activity code')}
                    displayFields={['code', 'name']}
                    fetchFields={['activityCategory', 'code_suffix']}
                  />
                )}
              />
            </IonCol>
          </IonRow>
          <input value={project_id} type='text' hidden />
          <Easy2Use.Input
            id='title'
            onKeyUp={handleNextInput}
            name='title'
            itemProps={{ className: 'ion-no-padding', }}
            inputMode='text'
            autoCapitalize='sentences'
            label={t('Title')}
            labelPlacement='stacked'
            className='ion-margin-vertical'
            placeholder={t('Title of the completed task')}
            register={'name'}
            onIonInput={(e: any) => form.setValue('name', e.detail.value)}
            validation={{
              required: t('Title is required'),
              min: { value: 3, message: t('Title must be at least 3 characters long') },
              max: { value: 42, message: t('Title must be at most 42 characters long') }
            }}
          />
          <Easy2Use.Textarea
            id='description'
            name='description'
            itemProps={{ className: 'ion-no-padding', }}
            inputMode='text'
            enterkeyhint='done'
            autoCapitalize='sentences'
            label={t('Comment')}
            labelPlacement='stacked'
            placeholder={t('Enter a description')}
            register={'description'}
            className='ion-margin-vertical'
            validation={{
              required: t('Description is required'),
              min: { value: 3, message: t('Description must be at least 3 characters long') },
              max: { value: 255, message: t('Description must be at most 255 characters long') }
            }}
          />

          <IonItem className='ion-no-padding'>
            <IonLabel className='ion-no-margin'>{t(' Images & files')}</IonLabel>
          </IonItem>

          <IonRow className='ion-margin-vertical'>
            <IonCol size='6' className='ion-text-center ion-margin-bottom'>
              <FileSelectionButton expand='block' onFilesSelect={(files: File[]) => handleFileSelection(files)} />
            </IonCol>
            <IonCol size='6' className='ion-text-center ion-margin-bottom'>
              <TakePhotoButton expand='block' onTakePhotoClick={(photos: Photo[]) => handleTakenPhoto(photos)} />
            </IonCol>
          </IonRow>

          <FileList files={files} photos={photos} removeFile={removeFile} removePhoto={removePhoto} />

          <IonItem lines='none' className='ion-no-padding ion-margin-vertical'>
            <Controller
              control={control}
              name='requires_supervisor'
              render={({ field: { onChange, value } }) => (
                <IonToggle
                  checked={value === true}
                  onIonChange={({ detail: { checked } }) => onChange(checked)}
                >
                  {t('Does this require a supervisor?')}
                </IonToggle>
              )}
            />
          </IonItem>
          <IonItem lines='none' className='ion-no-padding ion-margin-vertical ion-padding-bottom'>
            <Controller
              control={control}
              name='confirmed'
              rules={{ validate: (value) => value === 1 }}
              render={({ field: { onChange, value } }) => (
                <IonToggle
                  checked={value === 1}
                  onIonChange={({ detail: { checked } }) => onChange(checked ? 1 : 0)}
                >
                  {t('Confirm the information above')}
                </IonToggle>
              )}
            />
          </IonItem>
          {formState.errors.confirmed && (
            <IonRow>
              <IonCol size={'12'}>
                <IonLabel color={'danger'}>
                  {t('You must confirm the information above')}
                </IonLabel>
              </IonCol>
            </IonRow>
          )}
          <IonRow className='ion-margin-top ion-padding-top ion-justify-content-evenly'>
            <IonCol size='4'>
              <Easy2Use.Buttons.Regular
                title='Cancel'
                onClick={toggleBack}
                color={'light'}
                expand='block'
              />
            </IonCol>
            <IonCol size='4'>
              <Easy2Use.Buttons.Regular
                title='Save'
                type='submit'
                disabled={formState.isSubmitting || !formState.isValid}
                color={'secondary'}
                expand='block'
              />
            </IonCol>
          </IonRow>
        </form>
      </IonGrid>
    </FormProvider>
  );
};

export default EditModal;
