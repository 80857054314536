import type { SourceResult, Sources } from './interface';
import i18n from '../../../i18n';

const handleSource = (type: Sources, uuid: string | undefined): SourceResult => {
  switch (type) {
    case 'deviations':
      return {
        name: i18n.t('Deviations'),
        routes: {
          create: `/economy-tools/${uuid}/deviations/create`,
          base: `/economy-tools/${uuid}/deviations`,
        }
      };
    case 'changes':
      return {
        name: i18n.t('Changes'),
        routes: {
          create: `/economy-tools/${uuid}/changes/create`,
          base: `/economy-tools/${uuid}/changes`,
        }
      };
    case 'monitoring':
      return {
        name: i18n.t('Reports'),
        routes: {
          base: `/economy-tools/${uuid}/monitoring`,
        }
      };
    default:
      return type;
  }
};

const buttonTextMap: Record<string, string> = {
  deviations: i18n.t('Add deviation'),
  changes: i18n.t('Add change'),
};

const types: Sources[] = ['deviations', 'changes'];

export { handleSource, buttonTextMap, types };
