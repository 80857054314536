import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { E2U } from '@techlove/easy2use-typings';
import type { AxiosResponse } from 'axios';
import mime from 'mime';

import { networking } from '../../../../api/networking';
import ButtonRound from '../Round/ButtonRound';

interface ShareButtonInterface {
  icon: string;
  backgroundColour: string;
  buttonSizePixels: string;
  textColour: string;
  shareTitle: string;
  shareText: string;
  shareUrl: string;
  shareDialogTitle: string;
  disabled?: boolean;
}

const ShareButton: React.FC<ShareButtonInterface> = ({
  backgroundColour,
  buttonSizePixels,
  disabled,
  icon,
  shareDialogTitle,
  shareText,
  shareTitle,
  shareUrl,
  textColour
}) => {
  // TODO: GET the export URL / patch with a one-time link from the backend

  function newFile(data: any) {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'octet/stream' });
    const url = window.URL.createObjectURL(blob);
    window.location.assign(url);
  }

  const shareAndExport = (name: string, fileData: any) => {
    if (typeof Filesystem !== 'undefined' && typeof Share !== 'undefined') {
      Filesystem.writeFile({
        path: name,
        data: fileData,
        directory: Directory.Cache
      })
        .then(() => {
          return Filesystem.getUri({
            directory: Directory.Cache,
            path: name
          });
        })
        .then((uriResult) => {
          return Share.share({
            title: name,
            text: name,
            url: uriResult.uri,
            dialogTitle: shareDialogTitle,
          })
            .catch((e) => {
              newFile(fileData || e);
            });
        }).catch(() => {
          newFile(fileData);
        });
    } else {
      newFile(fileData);
    }
  };

  const handleClick = () => {
    networking.get(shareUrl, { responseType: 'text' })
      .then((response: AxiosResponse<string>) => {
        const contentType = response.headers['content-type'];
        const extension = mime.extension(contentType);
        const fileName = `${shareTitle}.${extension}`;
        shareAndExport(fileName, response.data);
      });
  };

  return (
    <ButtonRound
      icon={icon}
      backgroundColour={backgroundColour}
      clickHandler={handleClick}
      textColour={textColour}
      disabled={disabled}
    />
  );
};

export default ShareButton;
