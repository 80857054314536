import { IonContent, IonPage } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PageMenuHeader from '../../components/PageMenuHeader/PageMenuHeader';
import { ionicColours } from '../../components/UI/variables';

const PageNotFound = () => {
  const redirectTimer = 20000;

  const [timeLeft, setTimeLeft] = useState(redirectTimer / 1000);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout|undefined>(undefined);

  const { t } = useTranslate();
  const history = useHistory();

  const handleSupportRedirect = () => {
    history.push('/support/report-error');
  };

  useEffect(() => {
    setTimeoutId(setTimeout(() => {
      history.goBack();
    }, redirectTimer));
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (!timeLeft) return;

    setTimeoutId(setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000));

    return () => clearInterval(timeoutId);
  }, [timeLeft]);

  return (
    <IonPage>
      <PageMenuHeader />
      <IonContent className="ion-padding">
        <div className="ion-text-center">
          <h1>{t('Page not found')}</h1>
          <p>
            {t('The page you are looking for does not exist.')}
            <br />
            {t('Please check the URL or go to the home page.')}
            <br />
          </p>

          <h3 color='secondary'>
            {t('If the problem persists feel free to contact our ')}
            <i
              style={{
                borderBottom: '1px solid #000000',
                cursor: 'pointer',
                color: ionicColours.secondary
              }}
              onClick={() => {
                handleSupportRedirect();
              }}>
              {t('support')}
            </i>
          </h3>

          <p>{t('You will be redirected back in')}: {timeLeft} </p>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default PageNotFound;
