import { isPlatform } from '@ionic/react';

import type { ColorPickerProps } from './colorpicker.interface';
import styles from './ColorPicker.module.scss';

const ColorPicker: React.FC<ColorPickerProps> = (props) => {
  return (
    <input
      disabled={props.disabled}
      {...isPlatform('ios') && {
        style: {
          backgroundColor: props.defaultValue as any || props.defaultColor,
          width: props.width || '70px',
        },
      }}
      style={{ width: props.width || '100%' }}
      ref={props.pickerRef || 'color'}
      id={props.pickerId}
      className={props.className || isPlatform('ios') ? styles.iosColourInput : styles.androidColourInput}
      onChange={props.onChange}
      type="color"
      value={props.value}
      color={props.defaultColor}
    />
  );
};

export default ColorPicker;
