import { IonCardSubtitle, IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { t } from 'i18next';
import { add } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';

import { BigUp } from '../../../components/UI';
import ColourDescription from '../../../components/UI/ColourDescription';
import { ionicColours } from '../../../components/UI/variables';
import { useAppSelector } from '../../../hooks';
import ProjectList from '../ProjectList';
import styles from '../ProjectList/ProjectList.module.scss';

interface ListPageProps {
  title?: string | undefined;
  description: string;
}

const legendList = [
  { colour: ionicColours.danger, label: t('Checked out') },
  { colour: ionicColours.success, label: t('Checked in') },
  { colour: ionicColours.medium, label: t('No access') },
];

const ProjectListPage: React.FC<ListPageProps> = ({ description }) => {
  const projects: E2U.V1.Models.Project[] | undefined = useAppSelector((state) => state.project.projects);
  const isLoading: boolean = useAppSelector((state) => state.loading.isLoading.projects);
  const history = useHistory();
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);

  const shared_button_props = {
    title: t('Add Project'),
    icon: { slot: 'start', icon: add },
    color: 'secondary',
    onClick: () => history.push('/project-tools/new'),
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%' }}>
      <div className={classNames('ion-padding', styles['project-list-page-check-information-wrapper'])} >
        <IonGrid className='ion-no-margin'>
          <IonRow className='ion-justify-content-space-between ion-align-items-center ion-margin-bottom'>
            <IonCol className='ion-text-left' size='9'>
              <IonCardSubtitle>{description}</IonCardSubtitle>
            </IonCol>
            <IonCol size='3' className={`ion-padding-end ion-text-right`}>
              {isLoading
                ? <IonSpinner name="lines" />
                : isDesktop
                  ? (
                    <BigUp.Buttons.Regular
                      title={shared_button_props.title}
                      color={shared_button_props.color}
                      icon={shared_button_props.icon}
                      onClick={shared_button_props.onClick}
                    />
                  )
                  : (
                    <BigUp.Buttons.Icon
                      title={shared_button_props.title}
                      color={'none'}
                      icon={{ icon: shared_button_props.icon.icon, color: shared_button_props.color }}
                      onClick={shared_button_props.onClick}
                    />
                  )
              }
            </IonCol>
          </IonRow>
        </IonGrid>

        <div className={styles.rowContainer} >
          <ColourDescription data={legendList} />
          <ProjectList projects={projects} />
        </div>
      </div>
    </div>
  );
};

export default ProjectListPage;
