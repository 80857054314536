import { IonCol, IonGrid, IonRow, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { type FieldValues, FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

import CategoryForm from './form/CategoryForm';
import { networking } from '../../../api/networking';
import DesktopWrapper from '../../../components/DesktopWrapper';
import SkeletonItem from '../../../components/SkeletonComponents/SkeletonItem';
import BigUp from '../../../components/UI';
import DeleteRecord from '../../../components/UI/DeleteRecord';
import toasters from '../../../components/UI/Toasts';
import TagForm from '../Tags/TagForm';

const EditDocumentTypePage: React.FC = () => {
  const history = useHistory();
  const [alert] = useIonAlert();
  const { category_id, uuid } = useParams<{ uuid: string | undefined, category_id: string | undefined }>();
  const [selectedDocumentType, setSelectedDocumentType] = useState<E2U.V1.Models.Type | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const methods = useForm<{
    name: string,
    color: string,
    tags: E2U.V1.Models.TagCategory[]
  }>({
    mode: 'onTouched',
    defaultValues: {
      ...selectedDocumentType,
      tags: [],
    }
  });

  const getSelectedCategory = () => {
    setIsLoading(true);
    networking.get(`/api/v1/document_types/${category_id}?with=tags`)
      .then((response) => {
        setSelectedDocumentType(response.data.data);
      })
      .finally(() => setIsLoading(false));
  };

  const handleTagSelection: SubmitHandler<FieldValues> = async (data) => {
    const existingTags = selectedDocumentType?.tags ?? [];
    const selectedTags = data.tags.flatMap(
      (tagCategory: E2U.V1.Models.TagCategory) => tagCategory.tags?.map((tag: E2U.V1.Models.Tag) => ({
        ...tag,
        category_id: tagCategory.id,
      })) ?? []
    );

    const tagsToRemove = existingTags.filter(
      (tag: E2U.V1.Models.Tag) => !selectedTags.some((st: E2U.V1.Models.Tag) => st.id === tag.id)
    );
    const tagsToAdd = selectedTags.filter(
      (tag: E2U.V1.Models.Tag) => tag.id && !existingTags.some((et) => et.id === tag.id)
    );
    const tagsToCreate = selectedTags.filter(
      (tag: E2U.V1.Models.Tag) => !tag.id
    );

    const createTagActions = tagsToCreate.map((tag: E2U.V1.Models.Tag) =>
      networking.post('/api/v1/tags', {
        name: tag.name,
        category_id: tag.category_id,
        color: tag.color,
      })
        .then((response) => {
          networking.post(`/api/v1/document_types/${selectedDocumentType?.id}/tags/${response.data.data.id}`);
        })
    );

    const removeTagActions = tagsToRemove.map((tag: E2U.V1.Models.Tag) =>
      networking.delete(`/api/v1/document_types/${selectedDocumentType?.id}/tags/${tag.id}`)
    );

    const addTagActions = tagsToAdd.map((tag: E2U.V1.Models.Tag) =>
      networking.post(`/api/v1/document_types/${selectedDocumentType?.id}/tags/${tag.id}`)
    );

    await Promise.all([
      ...createTagActions,
      ...removeTagActions,
      ...addTagActions,
    ])
      .then(() => {
        getSelectedCategory();
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const handleCategoryEdit: SubmitHandler<FieldValues> = async (data) => {
    try {
      const response = await networking.put(`/api/v1/document_types/${selectedDocumentType?.id}`, data);
      toasters.createToast({
        message: t('Updated category: {{name}}', { name: response.data.data.name }),
        background: 'var(--ion-color-light)'
      }, 'success');
      await handleTagSelection(data);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleCategoryDelete = async () => {
    try {
      await networking.delete(`/api/v1/document_types/${selectedDocumentType?.id}`);
      history.replace(`/project-tools/${uuid}/document-categories`);
      toasters.createToast({
        message: t('Deleted category: {{name}}', { name: selectedDocumentType?.name }),
        background: 'var(--ion-color-light)'
      }, 'success');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const deleteConfirmation = () => {
    alert({
      header: t('Delete category'),
      message: t('Are you sure you want to delete category {{name}}?', {
        name: selectedDocumentType?.name
      }),
      buttons: [
        { text: t('Cancel'), role: 'cancel', cssClass: 'secondary' },
        { text: t('Yes'), handler: handleCategoryDelete }
      ]
    });
  };

  useEffect(() => {
    if (category_id) {
      getSelectedCategory();
    }
  }, [category_id]);

  useEffect(() => {
    if (selectedDocumentType) {
      const mappedTags = selectedDocumentType?.tags?.reduce((acc: any, tag: E2U.V1.Models.Tag) => {
        const categoryIndex = acc.findIndex((cat: any) => cat.id === tag.category_id);
        if (categoryIndex > -1) {
          acc[categoryIndex].tags.push(tag);
        } else {
          acc.push({ id: tag.category_id, tags: [tag] });
        }
        return acc;
      }, []);

      methods.reset({
        name: selectedDocumentType.name,
        color: selectedDocumentType.color,
        tags: mappedTags,
      });
    }
  }, [selectedDocumentType, methods]);

  return (
    <>
      {isLoading
        ? <SkeletonItem amount={5} />
        : (
          <DesktopWrapper>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleCategoryEdit)}>
                <IonGrid className='ion-padding'>
                  <BigUp.Title label={t('Edit category')} style={{ paddingTop: '35px', marginBottom: '16px', fontWeight: 800 }} />
                  <CategoryForm />
                  <TagForm model='tags' />
                </IonGrid>
                <IonGrid className='ion-padding'>
                  <IonRow>
                    <DeleteRecord
                      record=''
                      hasPadding={false}
                      colSize='10'
                      label={t('Do you want to delete category {{name}}?', { name: selectedDocumentType?.name })}
                      onDelete={() => deleteConfirmation()}
                    />
                    <IonCol className="ion-text-end ion-margin-top" size='3'>
                      <BigUp.Buttons.Regular
                        type="submit"
                        color='secondary'
                        disabled={!methods.formState.isValid || methods.formState.isSubmitting}
                        title={t('Save')}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </form>
            </FormProvider>
          </DesktopWrapper>
        )
      }
    </>
  );
};

export default EditDocumentTypePage;
