
import React from 'react';

import PersonalOverviewContent from './PersonalOverviewContent';
import PersonalOverviewToolbar from './PersonalOverviewToolbar';
import type { OnboardingInterface } from '../../interfaces/Onboarding.interface';

const PersonalOverview: React.FC<OnboardingInterface> = (props) => {
  return (
    <React.Fragment>
      <PersonalOverviewToolbar togglePersonalOverview={props.togglePersonalOverview} />
      <PersonalOverviewContent toggle={props.togglePersonalOverview} />
    </React.Fragment>
  );
};

export default PersonalOverview;
