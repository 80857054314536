import styles from './SectionLoader.module.scss';

interface LoaderMessageInterface {
  message?: string;
}

const SectionLoader: React.FC<LoaderMessageInterface> = (props) => {
  return (
    <>
      <div className={styles.loaderz}>
        <div className={styles.boxes}>
          <div className={styles.box}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className={styles.box}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className={styles.box}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className={styles.box}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionLoader;
