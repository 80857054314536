import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';

import { useAppSelector } from '../../../../hooks';
import formatNumber from '../../../../tools/formatNumber';
import ActivityCodeTile from '../ActivityCodeTile';

interface ProductionBudgetRowProps {
  row: E2U.V1.Models.PrecalculationRow
  key: number
}

const ProductionBudgetRow: React.FC<ProductionBudgetRowProps> = (props, key) => {
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  return (
    <div key={key}>
      <IonGrid>
        <IonRow className={'ion-text-left ion-padding-top'}>
          <IonCol size={'4'} className={`ion-text-left`}>
            <ActivityCodeTile activityCode={props.row.activity_code} name={props.row.name} />
          </IonCol>

          <IonCol className={isDesktop ? 'ion-text-center' : 'ion-text-center ion-no-padding'}>
            <IonLabel>{t('Quantity')}</IonLabel>
            <p>{Math.round(props.row.quantity)}</p>
          </IonCol>

          <IonCol className={isDesktop ? 'ion-text-center' : 'ion-text-center ion-no-padding'}>
            <IonLabel>{t('Cost/unit')}</IonLabel>
            <p>{formatNumber(props.row.cost_per_unit)}</p>
          </IonCol>

          <IonCol {...isDesktop
            ? { className: 'ion-text-center ion-no-padding' }
            : { className: 'ion-text-center ion-no-padding' }}
          >
            <IonLabel>{t('Total')}</IonLabel>
            <p>{formatNumber(props.row.total)}</p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ProductionBudgetRow;
