import type { Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface WorkingEnvironmentEntryReducer {
  workingEnvironmentEntry: E2U.V1.Models.WorkingEnvironmentEntry | undefined;
}
export const workingEnvironmentEntrySlice = createSlice<WorkingEnvironmentEntryReducer, SliceCaseReducers<WorkingEnvironmentEntryReducer>, string>({
  name: 'workingEnvironment',
  initialState: {
    workingEnvironmentEntry: undefined,
  },
  reducers: {
    setWorkingEnvironmentEntry: (
      state:Draft<WorkingEnvironmentEntryReducer>,
      action: PayloadAction<E2U.V1.Models.WorkingEnvironmentEntry>
    ) => {
      state.workingEnvironmentEntry = action.payload;
    }
  }
});
export const { setWorkingEnvironmentEntry } = workingEnvironmentEntrySlice.actions;
export default workingEnvironmentEntrySlice.reducer;
