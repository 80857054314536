import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import AddTagCategory from './TagCategories/AddTagCategory';
import TagCategoryList from './TagCategories/TagCategoryList';
import { networking } from '../../api/networking';
import DesktopWrapper from '../../components/DesktopWrapper';
import BigUp from '../../components/UI';
import { setIsLoading } from '../../reducers/loading';
import store from '../../store';

const TagsPage: React.FC = () => {
  const [tagCategories, setTagCategories] = useState([]);
  const { uuid } = useParams<{ uuid: string }>();

  const getTagCategories = () => {
    store.dispatch(setIsLoading({ name: 'documentTypes', value: true }));
    networking.get(`/api/v1/projects/${uuid}/tag_categories?per_page=9999`)
      .then((response) => {
        const data = response.data.data.records;
        if (data) {
          setTagCategories(data);
        }
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'documentTypes', value: false }));
      });
  };

  useEffect(() => {
    getTagCategories();
  }, []);

  return (
    <DesktopWrapper>
      <IonGrid className='ion-padding'>
        <IonRow>
          <IonCol className='ion-text-start'>
            <AddTagCategory getTagCategories={getTagCategories} />
            {tagCategories.length ? <BigUp.Label.Thick label={t('Last updated')} /> : null}
          </IonCol>
        </IonRow>
      </IonGrid>
      <TagCategoryList tagCategories={tagCategories} />
    </DesktopWrapper>
  );
};

export default TagsPage;
