import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';
import { close } from 'ionicons/icons';
import React from 'react';

interface FileInterface {
  onReadFile: (file: E2U.V1.Models.ProjectAccessRequirement) => void;
  file: E2U.V1.Models.ProjectAccessRequirement | undefined;
  onDismiss: () => void;
  onDecline: (file: E2U.V1.Models.ProjectAccessRequirement) => void;
}
const File: React.FC<FileInterface> = ({ file, onDecline, onDismiss, onReadFile }) => {
  return (
    <React.Fragment>
      {file
        ? (
          <div>
            <IonHeader class="ion-no-border">
              <IonToolbar>
                <IonTitle>{file?.name}</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={onDismiss}>
                    <IonIcon icon={close} color='primary' />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding" style={{ height: '80vh' }}>
              <div>{file?.name}</div>
            </IonContent>

            <IonFooter class="ion-no-border ion-padding">
              <IonGrid>
                <IonRow className="ion-justify-content-center ion-text-center">
                  <IonCol size="6">
                    <IonButton onClick={() => onDecline(file)}>
                      {t('decline')}
                    </IonButton>
                  </IonCol>
                  <IonCol size="6">
                    <IonButton onClick={() => onReadFile(file)}>
                      {t('accept')}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonFooter>
          </div>
        )
        : <React.Fragment />
      }

    </React.Fragment>
  );
};
export default File;
