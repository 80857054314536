import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';

import appStyles from '../App.module.scss';

interface MenuLessAppLayoutProps {
  title?: string;
}

const MenuLessAppLayout: React.FC<MenuLessAppLayoutProps> = ({ children, title }) => {
  const history = useHistory();
  return (
    <IonPage id='main-content'>
      <IonHeader translucent={true} className='ion-no-border' >
        <IonToolbar>
          <IonButtons slot="start" onClick={history.goBack}>
            <IonButton><IonIcon icon={chevronBack} color='dark' /></IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={appStyles['remove-scroll']} fullscreen>
        {children}
      </IonContent>
    </IonPage>
  );
};

export default MenuLessAppLayout;
