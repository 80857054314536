import { IonChip, IonIcon } from '@ionic/react';
import fontColorContrast from 'font-color-contrast';
import { closeCircle } from 'ionicons/icons';

import BigUp from '..';

interface FilterChipsProps<T> {
  data: T[];
  renderChipLabel: (item: T) => React.ReactNode;
  chipIcon?: string;
  onChipClick?: (item: T) => void;
}

function FilterChips<T>(props: FilterChipsProps<T>) {
  const {
    chipIcon,
    data,
    onChipClick,
    renderChipLabel
  } = props;

  const handleChipClick = (item: T) => {
    if (onChipClick) {
      onChipClick(item);
    }
  };

  return (
    <>
      {data.map((chip: T | any, i) => {
        const checkDefaultColours = (chip.color === '#5d7292' || chip.color === '#000000')
          ? 'var(--ion-color-light)'
          : '';
        return (
          <IonChip key={i} style={{ background: chip.color || 'var(--ion-color-medium)' }}>
            <BigUp.Label.Regular
              label={renderChipLabel(chip) as string}
              className='ion-no-margin'
              style={{
                color: fontColorContrast(chip.color),
              }}
            />
            {chipIcon && (
              <IonIcon
                icon={chipIcon || closeCircle}
                onClick={() => handleChipClick(chip)}
                style={{
                  color: fontColorContrast(chip.color)
                }}
              />
            )}
          </IonChip>
        );
      })}
    </>
  );
}

export default FilterChips;
