import { IonCol, IonGrid, IonLoading, IonRow } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import styles from './LoginForm.module.scss';
import { useAppSelector } from '../../hooks';
import useFocusNextInput from '../../hooks/useFocusNextInput';
import useLogin from '../../hooks/useLogin';
import { regex } from '../../regex/regex';
import { BigUp } from '../UI';

interface LoginCredentials {
  username: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const { t } = useTranslate();
  const methods = useForm<LoginCredentials>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      username: undefined,
      password: undefined
    }
  });

  const { focusNextInput, formRef } = useFocusNextInput(); // Use the custom hook
  const handleNextInput = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (e.key === 'Enter') {
      focusNextInput(e.target as HTMLInputElement);
    }
  };

  const isLoggingIn: boolean = useAppSelector(state => state.loading.isLoading.loggingIn);
  const login = useLogin();

  const setServerErrors = () => {
    methods.setError('password', {
      type: 'server',
      message: t(login.result)
    });
  };

  useEffect(() => {
    if (login.result) {
      setServerErrors();
    }
  }, [login.result]);

  useEffect(() => {
    login.clearStorage();
  }, []);

  return (
    <section className={styles['form-container']}>
      {isLoggingIn
        ? <IonLoading isOpen={true} />
        : (
          <FormProvider {...methods}>
            <form className='ion-padding' onSubmit={methods.handleSubmit(login.handleLogin)} ref={formRef}>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <BigUp.Input
                      label={t('Email')}
                      register='username'
                      autocomplete='email'
                      autofocus
                      enterkeyhint='next'
                      id='email'
                      onKeyUp={handleNextInput}
                      name='email'
                      autoCorrect='on'
                      autocorrect='on'
                      inputMode='email'
                      placeholder={t('Enter email')}
                      validation={{
                        required: t('Email is required'),
                        pattern: {
                          value: regex.regex_email,
                          message: t('Invalid email address')
                        }
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <BigUp.Input
                      id='password'
                      enterkeyhint='done'
                      label={t('Password')}
                      name='password'
                      onKeyUp={handleNextInput}
                      register='password'
                      type='password'
                      placeholder={t('Enter password')}
                      validation={{
                        required: t('Password is required'),
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonGrid className='ion-no-margin ion-margin-top ion-no-padding'>
                <IonRow className='ion-justify-content-space-between ion-align-items-center'>
                  <IonCol size='12' className='ion-text-right'>
                    <BigUp.Buttons.Regular
                      title={t('Sign in')}
                      type='submit'
                      disabled={!methods.formState.isValid || methods.formState.isSubmitting}
                      color={'secondary'}
                    />
                  </IonCol>
                  <IonGrid>
                    <IonRow className='ion-justify-content-space-between ion-align-items-center ion-margin-top'>
                      <IonCol className='ion-text-right' size='12'>
                        <Link style={{ fontSize: 'var(--ion-font-size-xs)', color: 'var(--ion-color-primary)' }} to='/reset'>{t('Forgot your password?')}</Link>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className='ion-text-right' size='12'>
                        <Link style={{ fontSize: 'var(--ion-font-size-xs)', color: 'var(--ion-color-primary)' }} to='/registration'>{t('Create an account')}</Link>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>
              </IonGrid>
            </form>
          </FormProvider>
        )
      }
    </section >
  );
};

export default LoginForm;
