import { IonIcon, IonItem, IonLabel, IonList, IonNote } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { pricetag } from 'ionicons/icons';
import { useHistory, useParams } from 'react-router';

import SkeletonTextThreeLines from '../../../components/SkeletonComponents/SkeletonTextThreeLines';
import EmptyList from '../../../components/UI/EmptyList';
import { useAppSelector } from '../../../hooks';
import { formatToYYYYMMDD } from '../../../tools/formatDates';
import type { TagCategoryListProps } from '../interfaces';

const TagCategoryList: React.FC<TagCategoryListProps> = ({ tagCategories }) => {
  const { t } = useTranslate();
  const loading = useAppSelector((state) => state.loading.isLoading.documentTypes);
  const history = useHistory();
  const { uuid } = useParams<{ uuid: string }>();

  const redirectToTag = (tag: E2U.V1.Models.Tag) => {
    history.push({
      pathname: `/project-tools/${uuid}/tags/${tag.id}`,
      state: {
        tag
      }
    });
  };

  return (
    <>
      <IonList inset={false}>
        {loading && <SkeletonTextThreeLines amount={5} />}
        {tagCategories.map((tag: E2U.V1.Models.Tag) => {
          return (
            <IonItem key={tag.id} button={true} onClick={() => redirectToTag(tag)}>
              <IonIcon color={'primary'} slot="start" icon={pricetag} size="large"></IonIcon>
              <IonLabel className='ion-no-margin ion-margin-vertical'>{tag.name}</IonLabel>
              <IonNote slot="end">{formatToYYYYMMDD(tag.updated_at)}</IonNote>
            </IonItem>
          );
        })}
      </IonList>
      {(tagCategories.length === 0 && !loading) && (
        <EmptyList
          title={t('No tags found')}
          message={t('You can add tags to your project to categorize your documents.')}
        />
      )}
    </>
  );
};

export default TagCategoryList;
