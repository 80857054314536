import type { Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface ActivityCodesReducer {
  activityCodes: E2U.V1.Models.ActivityCode[];
  activityCodesById: { [id: string]: E2U.V1.Models.ActivityCode };
}

export const activityCodesSlice = createSlice<ActivityCodesReducer,
  SliceCaseReducers<ActivityCodesReducer>,
  string>({
    name: 'activity_codes',
    initialState: {
      activityCodes: [],
      activityCodesById: {}
    },
    reducers: {
      setActivityCodes: (
        state: Draft<ActivityCodesReducer>,
        action: PayloadAction<E2U.V1.Models.ActivityCode[]>
      ) => {
        state.activityCodes = action.payload;

        const mapActivityCodes: { [key: string]: E2U.V1.Models.ActivityCode } = {};

        action.payload.forEach(code => { mapActivityCodes[code.id!] = code; });
        state.activityCodesById = mapActivityCodes;
      }
    }
  });

export const {
  setActivityCodes
} = activityCodesSlice.actions;

export default activityCodesSlice.reducer;
