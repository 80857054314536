import type { Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export const deviationsSlice = createSlice<E2U.V1.Models.EconomyDeviation, SliceCaseReducers<E2U.V1.Models.EconomyDeviation>, string>({
  name: 'deviations',
  initialState: {
    id: '',
    number: 0,
    accepted: false,
    name: '',
    project_id: '',
    comment: '',
    rows: [] as E2U.V1.Models.EconomyDeviationRow[],
  },
  reducers: {
    setDeviations: (
      state: Draft<E2U.V1.Models.EconomyDeviation>,
      action: PayloadAction<E2U.V1.Models.EconomyDeviation>
    ) => {
      const { comment, project_id, rows } = action.payload;
      state.project_id = project_id;
      state.comment = comment;
      state.rows = rows;
    },
  }
});

export const { setActivityCodes, setDeviations } = deviationsSlice.actions;
export default deviationsSlice.reducer;
