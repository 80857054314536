import { IonCol, IonGrid, IonIcon, IonLabel, IonModal, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import AllControls from './AllControls/AllControls';
import mappings from './config';
import styles from './ControlOfExecution.module.scss';
import RecordView from './RecordView/RecordView';
import { networking } from '../../../../api/networking';
import DesktopWrapper from '../../../../components/DesktopWrapper';
import SkeletonItem from '../../../../components/SkeletonComponents/SkeletonItem';
import toasters from '../../../../components/Toasts/Toasts';
import ButtonCTA from '../../../../components/UI/Buttons/CTA/ButtonCTA';
import { handleEmptyListStates } from '../../../../components/UI/EmptyList';
import ItemContentHeader from '../../../../components/UI/Items/components/ItemContentHeader';
import ItemShadow from '../../../../components/UI/Items/components/ItemShadow';
import { ionicColours } from '../../../../components/UI/variables';
import { emptyListMessages } from '../../../../constants/messages';
import { useAppSelector } from '../../../../hooks';
import { setIsLoading } from '../../../../reducers/loading';
import store from '../../../../store';
import modalStyles from '../../../Onboarding/Components/containers/styles/ModalContainers.module.scss';

const ControlOfExecution: React.FC = () => {
  const [controlInformationModal, setControlInformationModal] = useState<boolean>(false);
  const [recordViewOpen, setRecordViewOpen] = useState<boolean>(false);
  const [defaultEdit, setDefaultEdit] = useState<boolean>(false);
  const [selectedRecordId, setSelectedRecordId] = useState<string | undefined>();
  const [records, setRecords] = useState<E2U.V1.Models.ControlOfExecution[]>([]);
  const [allControlsOpen, setAllControlsOpen] = useState<boolean>(false);
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { t } = useTranslate();
  const isLoading = useAppSelector(state => state.loading.isLoading.controlOfExecution);
  const [error, setError] = useState<string | undefined>();

  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const skeletonAmount = isDesktop ? 15 : 4;

  const messages = {
    empty: {
      title: t(emptyListMessages.control_of_execution.empty_title),
      message: t(emptyListMessages.control_of_execution.empty_message),
    },
    error: {
      message: error || '',
    },
  };

  const fetchRecords = () => {
    const searchParams = new URLSearchParams();
    searchParams.append('with[]', 'assignees');
    searchParams.append('per_page', '9999');

    store.dispatch(setIsLoading({ name: 'controlOfExecution', value: true }));
    toasters
      .promise(networking.get(`/api/v1/projects/${uuid}/control_of_executions?${searchParams.toString()}`),
        {
          error: t('Could not get controls')
        }
      )
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<
        E2U.V1.Models.ControlOfExecution>>
      ) => {
        setRecords(response.data.data.records);
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
        setError(error.message);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'controlOfExecution', value: false }));
      });
  };

  const toggleAddNewControl = () => {
    setControlInformationModal(!controlInformationModal);
  };

  const toggleRecordView = (recordId: string | undefined = undefined) => {
    setSelectedRecordId(recordId);
    setRecordViewOpen(!recordViewOpen);

    if (recordViewOpen) {
      setDefaultEdit(false);
    }
  };

  const toggleAllControls = () => {
    setAllControlsOpen(!allControlsOpen);
  };

  const addControl = () => {
    setDefaultEdit(true);
    toggleRecordView();
    toggleAddNewControl();
  };

  useEffect(() => {
    fetchRecords();
    return () => {
      store.dispatch(setIsLoading({ name: 'controlOfExecution', value: false }));
    };
  }, []);

  return (
    <React.Fragment>
      <DesktopWrapper>
        <IonGrid className={'ion-padding'}>
          <IonRow>
            <IonCol
              size='12'
              className='ion-text-right ion-margin-bottom ion-margin-right ion-padding-end'
            >
              <ButtonCTA
                content={t('Add new control')}
                backgroundColour={ionicColours.secondary}
                clickHandler={addControl}
              />
            </IonCol>
          </IonRow>
          {isLoading && (
            <SkeletonItem amount={skeletonAmount} />
          )}
          <IonRow className='ion-justify-content-start ion-align-items-center' style={{ display: 'flex', gap: 20 }}>
            {records.map((record: E2U.V1.Models.ControlOfExecution) => {
              const recordCreatedAt = moment(record.created_at).format('YYYY-MM-DD');
              return (
                <IonCol size='12' sizeXs='12' sizeSm='12' sizeMd='3' sizeLg='3' style={{ cursor: 'pointer', minWidth: 300 }} key={record.id}>
                  <Link to={`/project-tools/${uuid}/control-of-execution/${record.id}`}>
                  </Link>
                  <ItemShadow
                    clickHandler={() => toggleRecordView(record?.id)}
                    hasBorderLeft={true}
                    borderLeftColour={mappings(record).borderLeftColour.get(true) || ''}>
                    <IonGrid>
                      <IonRow>
                        <IonCol size='12'>
                          <ItemContentHeader
                            fileCount={record.file_file_count}
                            photoCount={record.file_image_count}
                            childLabel={`${record.assigned_user?.first_name} ${record.assigned_user?.last_name.substring(0, 1)}. | ${t('Control')} | ${t('Act. code')} ${record.stamped_activity_code}`}
                            childText={record.name}
                            childSubLabel={recordCreatedAt || record.created_at}
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow className='ion-align-items-center ion-justify-content-start'>
                        <IonCol className='ion-text-left'>
                          <IonLabel
                            id={styles['control-of-execution-label-small']}
                            className='ion-no-margin ion-text-wrap'
                            color={mappings(record).statusColour.get(true) || ''}
                          >
                            {mappings(record).handleIconList.get(true) && (
                              <>
                                <IonIcon className="ion-no-margin" icon={mappings(record).handleIconList.get(true)?.icon} />
                                &nbsp;{mappings(record).handleIconList.get(true)?.message}
                              </>
                            )}
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </ItemShadow>
                </IonCol>
              );
            })}
            {handleEmptyListStates({ isEmpty: records.length === 0, error, messages })}
          </IonRow>
        </IonGrid>
      </DesktopWrapper>
      <IonModal
        className={modalStyles['app-default-modal']}
        isOpen={recordViewOpen}
        onIonModalDidDismiss={() => setRecordViewOpen(false)}>
        <RecordView
          fetchRecords={fetchRecords}
          onClose={toggleRecordView}
          recordId={selectedRecordId}
          defaultEdit={defaultEdit}
          onCreation={(recordId: string) => setSelectedRecordId(recordId)}
        />
      </IonModal>

      <IonModal
        isOpen={allControlsOpen}
        onIonModalDidDismiss={() => setAllControlsOpen(false)}
      >
        <AllControls toggleControls={toggleAllControls} records={records} />
      </IonModal>
    </React.Fragment >
  );
};

export default ControlOfExecution;
