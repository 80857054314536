import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import ItemShadowNoIcons from '../../../components/UI/Items/components/ItemShadowNoIcons';
import { ionicColours } from '../../../components/UI/variables';
import { useAppSelector } from '../../../hooks';
import usePermissionHandler from '../../../hooks/usePermissions';

interface ProjectCardProps {
  project: E2U.V1.Models.Project;
  onClick?: (project: E2U.V1.Models.Project) => void;
}

interface ProjectListProps {
  projects: E2U.V1.Models.Project[] | undefined;
  onClick?: (project: E2U.V1.Models.Project) => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ onClick, project }: ProjectCardProps) => {
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const location = useLocation();
  const routes = new Map([
    ['notice-board', ''],
    ['project-tools', 'control-of-execution'],
    ['economy-tools', 'project-information'],
  ]);

  const baseRoute = location.pathname.split('/')[1];
  const desktopUrl = `/${baseRoute}/${project.id}/${routes.get(baseRoute)}`;
  const mobileUrl = `/${baseRoute}/${project.id}`;
  const handleUrl = isDesktop ? desktopUrl : mobileUrl;

  return (
    <React.Fragment>
      {project.has_site_access === true
        ? <Link to={handleUrl} style={{ textDecoration: 'none', width: '100%', maxWidth: 400 }}>
          <ItemShadowNoIcons
            hasBorder={true}
            borderColour={
              `5px solid ${project.checked_in && project.has_site_access
                ? ionicColours.success
                : ionicColours.danger
              }`
            }
            label={project.description} description={project.name} subLabel={''} />
        </Link>
        : (
          <Link to={handleUrl} style={{ textDecoration: 'none', width: '100%', maxWidth: 400 }}>
            <ItemShadowNoIcons
              onItemClick={() => onClick && onClick(project)}
              hasBorder={true}
              borderColour={`5px solid ${ionicColours.medium}`}
              label={project.description}
              description={project.name}
              subLabel={''} />
          </Link>
        )
      }
    </React.Fragment>
  );
};

const ProjectList: React.FC<ProjectListProps> = ({ onClick, projects }: ProjectListProps) => {
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const history = useHistory();
  const { checkPermissionsForProjectAndTools } = usePermissionHandler();
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', width: '100%', maxWidth: 'var(--ion-desktop-max-width)', ...isDesktop && { gap: 20 }, }}>
      {checkPermissionsForProjectAndTools('read', 'read')?.map((project) => {
        return (
          <ProjectCard key={project.id} project={project} onClick={() => history.push(`/project-tools/${project.id}/control-of-execution`)} />
        );
      })}
    </div>
  );
};
export default ProjectList;
