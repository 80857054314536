import * as Sentry from '@sentry/capacitor';
import * as SentrySibling from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './i18n';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './scss/basics.scss';
import './scss/variables.scss';

defineCustomElements(window);

Sentry.init(
  {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    enabled: process.env.NODE_ENV !== 'development',
    release: 'easy2use@' + process.env.npm_package_version,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [process.env.REACT_APP_API_BASE_URL as string]
      })
    ],
    tracesSampleRate: 1.0
  },
  SentrySibling.init
);

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .init({
    language: 'en',
    apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
    apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
  });

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<TolgeeProvider
  tolgee={tolgee}
  fallback="Loading..." // loading fallback
>
  <App />
</TolgeeProvider>);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
