import { useEffect, useMemo } from 'react';

const useDarkMode = () => {
  const storedTheme = localStorage.getItem('theme');
  const toggleTheme = useMemo(() => {
    return () => {
      const body = document.body;
      const isDarkMode = body.classList.toggle('dark');
      localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    };
  }, []);

  useEffect(() => {
    const setThemeFromStorage = () => {
      const body = document.body;
      const storedTheme = localStorage.getItem('theme');

      if (storedTheme === 'light' || (storedTheme === null && !body.classList.contains('dark'))) {
        body.classList.remove('dark');
      } else {
        body.classList.add('dark');
      }
    };
    setThemeFromStorage();
  }, []);

  return { toggleTheme, storedTheme };
};

export default useDarkMode;
