import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';

import styles from './ReportsCard.module.scss';
import ReportsCardHeader from '../ReportsCardHeader';
import type { typeHeader } from '../ReportsCardHeader/ReportsCardHeader';

const ReportsCard: React.FC<typeHeader> = ({
  children,
  iconLeft,
  iconRight,
  title
}) => {
  return (
    <>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol sizeSm="12" sizeMd="10" sizeLg="10">
            <IonCard color="light" className={styles['reports-card']}>
              <ReportsCardHeader
                title={title}
                iconRight={iconRight}
                iconLeft={iconLeft}
              ></ReportsCardHeader>
              <IonCardContent className={styles['ion-report-card-content']}>
                {children}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};
export default ReportsCard;
