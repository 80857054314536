import type { E2U } from '@techlove/easy2use-typings';

import type { InputProps } from '../../../components/UI/Inputs/Input.interface';
import i18n from '../../../i18n';
import { regex } from '../../../regex/regex';

export const INVITATION_INPUTS:InputProps<E2U.V1.Models.User>[] = [
  {
    label: i18n.t('First name'),
    type: 'text',
    inputMode: 'text',
    placeholder: i18n.t('First name'),
    register: 'first_name',
    validation: {
      required: i18n.t('A name is required') as keyof E2U.V1.Models.User,
      maxLength: { value: 50, message: i18n.t('Name is too long'), },
      minLength: { value: 1, message: i18n.t('Name is too short'), },
      pattern: { value: regex.regex_name, message: i18n.t('First name must be alphabetical') },
    }
  },
  {
    label: i18n.t('Last name'),
    type: 'text',
    inputMode: 'text',
    placeholder: i18n.t('Last name'),
    register: 'last_name',
    validation: {
      required: i18n.t('A last name is required') as keyof E2U.V1.Models.User,
      maxLength: { value: 50, message: i18n.t('Name is too long'), },
      minLength: { value: 1, message: i18n.t('Name is too short'), },
      pattern: { value: regex.regex_name, message: i18n.t('Last name must be alphabetical') },
    }
  },
  {
    label: i18n.t('E-mail'),
    type: 'email',
    inputMode: 'email',
    placeholder: i18n.t('example@example.com'),
    register: 'email',
    validation: {
      required: i18n.t('A valid email is required') as keyof E2U.V1.Models.User,
      pattern: { value: regex.regex_email, message: i18n.t('Please enter a valid email') },
    }
  }
];
