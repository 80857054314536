import type { URLOpenListenerEvent } from '@capacitor/app';
import { App } from '@capacitor/app';
import type React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { useAppSelector } from '../../hooks';

interface AppUrlListenerProps {
}

const AppUrlListener: React.FC<AppUrlListenerProps> = () => {
  const history = useHistory();

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split('.se').pop();
      if (slug) {
        history.push(slug);
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;
