import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonModal, IonRow, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { t } from 'i18next';
import { arrowForwardCircleOutline, closeSharp, logOutOutline, peopleOutline, ribbon } from 'ionicons/icons';
import type { ComponentProps } from 'react';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import styles from './HamburgerMenu.module.scss';
import { networking } from '../../api/networking';
import e2cWhiteLogo from '../../assets/images/e2c_vit.png';
import { useBankIdContext } from '../../bankid/bankid.context';
import { useAppSelector } from '../../hooks';
import useLogout from '../../hooks/useLogout';
import useModal from '../../hooks/useModal';
import usePermissionHandler from '../../hooks/usePermissions';
import modalStyle from '../../pages/Onboarding/Components/containers/styles/ModalContainers.module.scss';
import PersonalOverview from '../../pages/Onboarding/Components/PersonalOverview/PersonalOverview';
import { setUser } from '../../reducers/authentication';
import storage from '../../storage';
import store from '../../store';
import { truncate } from '../../tools/truncateString';
import AppVersion from '../AppVersion';
import Avatar from '../Avatar';
import { MENU_LIST } from '../PageMenuHeader/navLinks';
import BigUp from '../UI';
import BankID from '../UI/BankID/BankID';
import HeaderBorderLeft from '../UI/modals/HeaderBorderLeft';
import toasters from '../UI/Toasts';

interface HamburgerMenuProps {
  menuID: string;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ menuID }) => {
  const [teams, setTeams] = useState<E2U.V1.Models.Team[]>();
  const [bankdIdError, setBankIdError] = useState<string>();
  const bankIdModal = useModal();
  const bankid = useBankIdContext();

  const clearUserDetails = useLogout();
  const toggleOnboardingModal = useModal();
  const { uuid } = useParams<{ uuid: string }>();
  const user = useAppSelector(state => state.authentication.user);
  const permission = usePermissionHandler();

  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const fetchMyTeams = async () => {
    try {
      const response = await networking.get(`/api/v1/users/${user?.id}/teams`);
      setTeams(response.data.data.records);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (!user?.id) return;
    if (!teams) {
      fetchMyTeams();
    }
  }, [user?.id, uuid]);

  const signBankId = async () => {
    bankIdModal.openModal();
    bankid?.sign.initiateBankIdSign(t('Connect my account {{email}} with my personal number', { email: user?.email }));
  };

  useEffect(() => {
    if (!user?.id) return;
    if (!teams) {
      fetchMyTeams();
    }
  }, [user?.id, uuid]);

  const handleBankIdCompleted = (user_details: any, device_details: any) => {
    storage.get('access_token')
      .then((accessToken: string) => {
        bankid?.associate.associateBankId(user_details.personal_number, accessToken)
          .then(() => {
            bankid.abortBankIdAuthentication();
            bankIdModal.closeModal();
            toasters.createToast({
              message: t('BankID successfully connected'),
              background: 'var(--ion-color-light)',
              textColour: 'var(--ion-color-dark)',
              iconColour: 'success',
              icon: ribbon,
            }, 'success');
            store.dispatch(setUser({ ...user, ssn: user_details.personal_number }));
          })
          .catch((error) => {
            Sentry.captureException(error);
            setBankIdError(t('Could not connect BankID to account {{email}}', { email: user?.email }));
            bankid.abortBankIdAuthentication();
            toasters.createToast({
              message: t('Could not connect BankID to account {{email}}', { email: user?.email }),
              background: 'var(--ion-color-light)',
            }, 'error');
          })
          .finally(() => {
            bankid.abortBankIdAuthentication();
          });
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const bankdIdModalClose = () => {
    bankid?.abortBankIdAuthentication();
    bankIdModal.closeModal();
    setBankIdError('');
  };

  return (
    <>
      <IonMenu
        {...isPlatform('ios' || 'android' || 'mobile') && { type: 'push' }}
        menuId='header'
        contentId={menuID}
        side={'end'}
        style={{
          '--max-width': '600px',
          ...!isDesktop
            ? { '--width': '90%' }
            : { '--width': '30%' }
        }}>
        <IonHeader className='ion-no-border'>
          <IonToolbar className='ion-margin-top'>
            <IonButtons slot='end'>
              <IonMenuToggle >
                <IonButton>
                  <IonIcon slot='start' icon={closeSharp}></IonIcon>
                </IonButton>
              </IonMenuToggle>
            </IonButtons>
            <IonTitle size='large' style={{ fontWeight: 600 }}>
              {t('Profile')}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          <IonGrid>

            <IonRow className='ion-justify-content-center ion-align-items-center'>
              <IonCol size='4'>
                <Avatar.AvatarImage
                  onClick={toggleOnboardingModal.openModal}
                  avatarSource='userImage'
                  style={{
                    width: 100,
                    height: 100,
                  }}
                />
              </IonCol>
              <IonCol size='8'>
                <IonRow className='ion-justify-content-center ion-align-items-center'>
                  <IonCol>
                    <IonItem className='ion-no-padding' lines='none'>
                      <IonLabel className='ion-no-margin'>
                        <h2 style={{ fontWeight: 600 }}>{t('Name')}:</h2>
                        <p style={{ fontSize: 16 }}>{`${user?.first_name} ${user?.last_name}`}</p>
                      </IonLabel>
                    </IonItem>
                    <IonItem className='ion-no-padding' lines='none'>
                      <IonLabel className='ion-no-margin'>
                        <h2 style={{ fontWeight: 600 }}>{t('Email')}:</h2>
                        <p style={{ fontSize: 16 }}>{`${user?.email}`}</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonList>
            {MENU_LIST.map(item => (
              <IonItem key={item.linkText} lines='full'>
                <IonIcon icon={item.itemIcon} className='ion-margin-end'></IonIcon>
                <IonLabel className='ion-no-margin'>
                  <Link to={`${item.directTo}`} style={{ textDecoration: 'none', color: 'var(--ion-color-dark)' }}>
                    {item.linkText}
                  </Link>
                </IonLabel>
              </IonItem>
            ))}
            {permission.checkPermission('project_tools_access', 'admin') && uuid && (
              <HamburgerAccordionItem data={teams} route={`/project-tools/${uuid}/team`} icon={peopleOutline} title={t('Teams')} />
            )}
            <IonItem type='button' onClick={clearUserDetails}>
              <IonIcon icon={logOutOutline} className='ion-margin-end' />
              <IonLabel className='ion-no-margin' color={'dark'}>{t('Logout')}</IonLabel>
            </IonItem>
          </IonList>

          {!user?.ssn && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '50px 0' }}>
              <BankID.BankIdButton onClick={() => signBankId()} title={undefined}>
                {t('Authenticate')} <BankID.BankIdIcon color='white' width={40} />
              </BankID.BankIdButton>
            </div>
          )}

          {user?.ssn && (
            <IonGrid>
              <IonRow className='ion-align-items-center ion-justify-content-center'>
                <BigUp.Label.Regular label={t('Authenticated with ')} style={{ fontSize: 18 }} /><BankID.BankIdIcon color='black' width={60} />
              </IonRow>
            </IonGrid>
          )}
          <div className={styles['app-version-container']}>
            <IonImg src={e2cWhiteLogo} className={styles['e2u-logo-black']} />
            <AppVersion />
          </div>
        </IonContent >
      </IonMenu >

      <IonModal
        style={{ '--height': `${isDesktop ? '95%' : '100%'}` }}
        isOpen={bankIdModal.isModalOpen}
        onIonModalDidDismiss={() => bankdIdModalClose()}>
        <HeaderBorderLeft
          title={t('Authenticate')}
          clickHandler={bankdIdModalClose}
        />
        <IonContent className='ion-padding'>
          <BankID.BankIdSignForm
            handleBankIdCompleted={(user, device) => handleBankIdCompleted(user, device)}
            type='sign'
            error={bankdIdError ?? ''}
          />
        </IonContent>
      </IonModal>
      <IonModal
        isOpen={toggleOnboardingModal.isModalOpen}
        className={modalStyle['personaloverview-modal']}
        onIonModalDidDismiss={toggleOnboardingModal.closeModal}
      >
        <PersonalOverview togglePersonalOverview={toggleOnboardingModal.toggleModal} />
      </IonModal >
    </>
  );
};

interface HamburgerAccordionItemProps {
  data?: E2U.V1.Models.Team[] | E2U.V1.Models.Project[];
  route: string;
  icon: ComponentProps<typeof IonIcon>['icon'];
  title: string;
}

const HamburgerAccordionItem: React.FC<HamburgerAccordionItemProps> = ({
  data, icon, route, title
}) => {
  const history = useHistory();
  return (
    <IonItem className='ion-no-padding ion-no-margin'>
      <IonAccordionGroup className='ion-no-padding' style={{ width: '100%' }}>
        <IonAccordion value='teams' className='ion-no-padding'>
          <IonItem slot="header" lines='none' className={classNames([styles.accordionHeader, 'ion-no-padding'])}>
            <IonIcon icon={icon} className='ion-margin-end' />
            <IonLabel className='ion-no-margin' color={'dark'}>{title}</IonLabel>
          </IonItem>
          {data?.map((item, index) => {
            return (
              <div
                onClick={() => history.push(`${route}/${item.id}`)}
                key={item.id}
                className="ion-no-padding ion-margin-top"
                slot="content"
                style={{
                  background: index % 2 === 0
                    ? 'var(--ion-color-light)'
                    : 'var(--ion-color-light-shade)',
                  padding: '5px',
                  paddingLeft: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <p className='ion-no-margin'>{truncate(item.name, 30)}</p>
                <IonIcon icon={arrowForwardCircleOutline} size={'large'} color='medium' />
              </div>
            );
          })}
        </IonAccordion>
      </IonAccordionGroup>
    </IonItem>
  );
};

export default HamburgerMenu;
