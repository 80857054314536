import type { Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface CurrentStepReducer {
    active: number ;
}

export const stepSlice = createSlice<CurrentStepReducer,
SliceCaseReducers<CurrentStepReducer>, string
>({
  name: 'step',
  initialState: {
    active: 0
  },
  reducers: {
    setActive: (
      state: Draft<CurrentStepReducer>,
      action: PayloadAction<number>
    ) => {
      state.active = action.payload;
    }
  }
});

export const { setActive } = stepSlice.actions;

export default stepSlice.reducer;
