import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface InviteReducer {
  invite: E2U.V1.Models.Invite | undefined;
}

export const inviteSlice = createSlice<InviteReducer,
  SliceCaseReducers<InviteReducer>,
  string>({
    name: 'invite',
    initialState: {
      invite: undefined
    },
    reducers: {
      setInvite: (
        state: Draft<InviteReducer>,
        action: PayloadAction<E2U.V1.Models.Invite>
      ) => {
        state.invite = action.payload;
      }
    }
  });

export const { setInvite } = inviteSlice.actions;

export default inviteSlice.reducer;
