import { IonButton, IonButtons, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import classNames from 'classnames';
import { close } from 'ionicons/icons';
import React from 'react';

import id06Styles from './ID06/Id06Modals.module.scss';
import type { ModalController } from '../../interfaces/Onboarding.interface';

const Modal: React.FC<ModalController> = (props) => {
  const { children } = props;

  return (
    <IonModal isOpen={props.state} className={classNames(id06Styles[`${props.modalContainerClassName}`])}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>{props.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => props.toggleOnClick()}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {children}
    </IonModal>
  );
};
export default Modal;
