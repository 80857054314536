import type { AxiosResponse } from 'axios';
import axios from 'axios';

import type { CollectAuthResponse, CollectSignResponse, Token } from './typings/collectResponse';
import type { InitiateAuthRequest, InitiateSignRequest } from './typings/initiateRequest';
import type { InitiateAuthResponse, InitiateSignResponse } from './typings/initiateResponse';

const initiateBankIdAuthentication = async (
  baseURL: string,
  authenticationData: InitiateAuthRequest = {}
): Promise<AxiosResponse<InitiateAuthResponse>> => {
  return axios.post(`${baseURL}api/bankid/authenticate/initiate`, authenticationData);
};

const checkBankIdAuthenticationStatus = async (
  baseURL:string,
  id: string
): Promise<AxiosResponse<CollectAuthResponse>> => {
  return axios.get(`${baseURL}api/bankid/authenticate/status/${id}`);
};

const initiateBankIdSign = async (
  baseURL:string,
  data: InitiateSignRequest
): Promise<AxiosResponse<InitiateSignResponse | null>> => {
  return axios.post(`${baseURL}api/bankid/sign/initiate`, data);
};

const checkBankIdSignStatus = async (
  baseURL:string,
  id: string
):Promise<AxiosResponse<CollectSignResponse | null>> => {
  return axios.get(`${baseURL}api/bankid/sign/status/${id}`);
};

const associateBankId = async (
  baseURL:string,
  SSN: string,
  token: Token
): Promise<AxiosResponse<InitiateSignResponse | null>> => {
  return axios.post(`${baseURL}api/bankid/associate/${SSN}`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const bankIdNetworking = {
  authenticate: {
    initiateBankIdAuthentication,
    checkBankIdAuthenticationStatus,
  },
  sign: {
    initiateBankIdSign,
    checkBankIdSignStatus,
  },
  associate: {
    associateBankId,
  }
};

export default bankIdNetworking;
