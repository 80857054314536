import { IonCol, IonGrid, IonItem, IonLabel, IonRow, IonText } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { pencil, shareSocialSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import styles from './styles.module.scss';
import { networking } from '../../../../api/networking';
import FileButtons from '../../../../components/FileList/FileButtons';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import DeleteRecord from '../../../../components/UI/DeleteRecord';
import InputLabel from '../../../../components/UI/InputLabel';
import { useAppSelector } from '../../../../hooks';
import useAlert from '../../../../hooks/useAlert';
import formatNumber from '../../../../tools/formatNumber';
import { downloadAndShareFile } from '../../../../tools/shareAndDownloadFile';
import shareUrl from '../../../../tools/shareUrl';
import ActivityCodeTile from '../ActivityCodeTile';

const Deviation: React.FC = () => {
  const [deviation, setDeviation] = useState<E2U.V1.Models.EconomyDeviation>();
  const { deviation_uuid, uuid } = useParams<{ uuid: string | undefined, deviation_uuid: string | undefined }>();
  const history = useHistory();
  const { t } = useTranslate();
  const { alert } = useAlert();
  const activityCodes = useAppSelector((state) => state.activityCodes.activityCodes);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const editDeviation = (id: string | undefined) => {
    history.push(`/economy-tools/${uuid}/deviations/${id}/edit`);
  };

  const fetchDeviation = () => {
    const searchParameters = new URLSearchParams();
    searchParameters.append('with[]', 'rows');
    searchParameters.append('with[]', 'files');
    networking.get(`/api/v1/economy_deviations/${deviation_uuid}?${searchParameters.toString()}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.EconomyDeviation>) => {
        setDeviation(response.data.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const shareDeviation = () => {
    shareUrl(
      undefined,
      `${t('Here is the link to deviation')} ${deviation?.name}: `,
      `${process.env.REACT_APP_SHARE_BASE_URL}/economy-tools/${uuid}/deviation/${deviation_uuid}`,
      '',
      'share'
    );
  };

  const deleteDeviation = (id: string | undefined) => {
    toasters.promise(networking.delete(`/api/v1/economy_deviations/${id}`), {
      success: t('Deviation deleted'),
      error: t('Could not delete deviation'),
    })
      .finally(() => {
        fetchDeviation();
        history.goBack();
      });
  };

  const confirmDeleteDeviation = () => {
    alert(
      t(`Deleting change: ${deviation?.name}`),
      t(`Are you sure you want to delete change: ${deviation?.name}?`),
      () => deleteDeviation(deviation?.id),
      t('Cancel'),
      t('Delete')
    );
  };
  const handleDownloadFile = (file: E2U.V1.Models.File) => {
    networking.get(`/api/v1/files/${file.id}/share`)
      .then((response) => {
        window.location.assign(response.data.data.url);
      });
  };

  useEffect(() => {
    fetchDeviation();
  }, []);

  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'stretch', marginBottom: 20 }}>
        <div className={styles.deviationPageContainer}
          style={{
            ...isDesktop && {
              display: 'flex',
              flexDirection: 'row'
            }
          }}
        >
          <div style={{ ...isDesktop && { width: '70%' } }}>
            <IonGrid>

              <IonRow
                className={classNames([
                  styles.deviationPageHeaderContainer,
                  'ion-padding ion-no-margin ion-justify-content-center ion-align-items-center'
                ])}
              >
                <IonCol size='6' className={styles.nameContainer}>
                  {(typeof deviation?.name !== 'undefined') &&
                    <h3 className="ion-no-margin">{deviation.name}</h3>
                  }
                </IonCol>
                <IonCol size='3' className='ion-text-right'>
                  <BigUp.Buttons.Responsive
                    padding={20}
                    expand={'block'}
                    isDesktop={isDesktop}
                    color={isDesktop ? 'secondary' : 'none'}
                    onClick={() => shareDeviation()}
                    icon={{ icon: shareSocialSharp, color: 'secondary' }}
                    title={t('Share')}
                  />
                </IonCol>
                <IonCol size={'3'} className='ion-text-left'>
                  <BigUp.Buttons.Responsive
                    padding={20}
                    expand={'block'}
                    isDesktop={isDesktop}
                    color={isDesktop ? 'primary' : 'none'}
                    onClick={() => editDeviation(deviation?.id)}
                    icon={{ icon: pencil, color: 'primary' }}
                    title={t('Edit')}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid className={classNames([styles.deviationRowsContainer, 'ion-padding-start'])}>
              {deviation?.rows.map((row: E2U.V1.Models.EconomyDeviationRow, i) => {
                const activityCode = activityCodes.find(code => code.id === row.activity_code_id);
                console.log(row);
                return (
                  <React.Fragment key={i}>
                    <IonRow className="ion-justify-content-center ion-align-items-center">
                      <IonCol size={'5'}>
                        <ActivityCodeTile
                          activityCode={activityCode}
                          name={''}
                          summary={row.name}
                          description={''}
                        />
                      </IonCol>
                      <IonCol size="3" className="ion-text-center">
                        <IonLabel style={{ fontSize: '12px' }} className="ion-no-margin" color={'medium'}>{t('Cost/unit')}</IonLabel>
                        <p className='ion-no-margin'>
                          {row.cost_per_unit > 1000
                            ? formatNumber(row.cost_per_unit)
                            : row.cost_per_unit
                          }
                        </p>
                      </IonCol>
                      <IonCol size="2"

                        className="ion-text-center">
                        <IonLabel style={{ fontSize: '12px' }} className="ion-no-margin" color={'medium'}>{t('Quantity')}</IonLabel>
                        <p className='ion-no-margin'>
                          {row.quantity > 1000
                            ? formatNumber(row.quantity)
                            : row.quantity
                          }
                        </p>
                      </IonCol>
                      <IonCol size="2" className="ion-text-center">
                        <IonLabel style={{ fontSize: '12px' }} className="ion-no-margin" color={'medium'}>{t('Unit')}</IonLabel>
                        <p className='ion-no-margin'>{row.unit}</p>
                      </IonCol>
                    </IonRow>

                    <React.Fragment>
                      {row.description && (
                        <React.Fragment>
                          <InputLabel margin={'ion-no-margin'}>{t('Description')}</InputLabel>
                          <IonRow>
                            <IonCol>
                              <IonText
                                style={{
                                  backgroundColor: '#f2f2f2',
                                  minHeight: '40px',
                                  paddingTop: '7px',
                                  paddingLeft: '5px'
                                }}
                              >
                                <span style={{ fontSize: '16px' }}>
                                  {row.description}
                                </span>
                              </IonText>
                            </IonCol>
                          </IonRow>
                        </React.Fragment>
                      )}
                    </React.Fragment>

                  </React.Fragment>
                );
              })}
            </IonGrid>
          </div>
          <div style={{
            ...isDesktop && { width: '30%' }
          }}>
            <IonGrid className='ion-padding'>
              <IonRow className={styles.commentContainer}>
                <IonCol size="12" >
                  <BigUp.Label.Thick label={t('Comment')} className='ion-no-margin' />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <span>{deviation?.comment}</span>
                </IonCol>
              </IonRow>

            </IonGrid>
            <IonGrid className={'ion-padding-horizontal'}>
              <IonRow>
                <IonCol size='12'>
                  <BigUp.Label.Thick label={t('Files')} className='ion-no-margin' />
                </IonCol>
              </IonRow>
              {deviation?.files?.map((file) => {
                return (
                  <IonRow key={file.file_hash}>
                    <IonCol>
                      <IonItem className={'ion-no-padding'}>
                        <IonLabel className={'ion-no-margin ion-margin-vertical'}>
                          {file.name}
                        </IonLabel>
                        <FileButtons
                          file={file}
                          edit={false}
                          downloadFile={() => downloadAndShareFile(file, 'download', uuid)}
                          shareFile={() => downloadAndShareFile(file, 'share', uuid)}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                );
              })}
            </IonGrid>
            <DeleteRecord
              label={t('Want to delete this deviation?')}
              record={deviation?.id as string}
              onDelete={() => {
                confirmDeleteDeviation();
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Deviation;
