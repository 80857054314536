import { IonIcon, IonLabel } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { peopleCircleOutline } from 'ionicons/icons';
import { useHistory, useParams } from 'react-router';

import { useAppSelector } from '../../../hooks';
import ButtonResponsive from '../../Onboarding/Components/buttons/ButtonResponsive';

const CreateTeam: React.FC = () => {
  const history = useHistory();
  const { uuid } = useParams<{ uuid: string }>();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const { t } = useTranslate();
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1 }}>
        {!isDesktop && <IonLabel className='ion-no-margin '>{t('Create team')}</IonLabel>}
        <ButtonResponsive
          type='button'
          onClick={() => history.push(`/project-tools/${uuid}/team/create`)}
          mobileButton={{
            children: (<IonIcon color={'primary'} icon={peopleCircleOutline} size='large' />),
            ionicButton: { color: 'none', shape: 'round' },
          }}
          desktopButton={{
            children: (<><IonIcon color={'medium'} icon={peopleCircleOutline} /><IonLabel color='medium'>{t('Create team')}</IonLabel></>),
            ionicButton: { color: 'none' },
          }}
        />
      </div>
    </>
  );
};

export default CreateTeam;
