import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonRow,
  IonText
} from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { camera, documentText, trashBin } from 'ionicons/icons';
import React from 'react';

import type { DetailProps } from './DetailTypes';
import { networking } from '../../../../../../api/networking';
import { useAppSelector } from '../../../../../../hooks';

const Detail: React.FC<DetailProps> = (props) => {
  const incident: E2U.V1.Models.IncidentReport | undefined = useAppSelector(
    (state) => state.reports.selectedIncident
  );

  const incidentTime: Date | undefined = incident?.incident_time
    ? new Date(incident?.incident_time)
    : undefined;
  const { t } = useTranslate();
  const unlinkFile = (file: E2U.V1.Models.File) => {
    networking
      .delete(
        `/api/v1/incident_reports/${incident?.id}/files/${file.id}`
      )
      .then(() => {
        if (props.onUpdateIncident) {
          props.onUpdateIncident();
        }
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureMessage('Error while unlinking file from incident', {
          extra: {
            error
          }
        });
      });
  };

  return (
    <>
      <IonGrid fixed={true}>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" sizeXl="6" sizeLg="6" className="ion-no-margin">
            <IonList color="none" className="ion-no-padding">
              <IonListHeader color="none">{t('Name')}:</IonListHeader>
              <IonItem color="none">{incident?.name}</IonItem>
            </IonList>
          </IonCol>

          <IonCol size="12" sizeXl="6" sizeLg="6" className="ion-no-margin">
            <IonList color="none" className="ion-no-padding">
              <IonListHeader color="none">{t('Description')}:</IonListHeader>
              <IonItem color="none">{incident?.description}</IonItem>
            </IonList>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <IonCard>
              <IonCardHeader color="medium">
                <IonLabel>{t('Report')}</IonLabel>
              </IonCardHeader>
              <IonCardContent>
                <IonText color="dark">{incident?.report}</IonText>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" sizeXl="6" sizeLg="6">
            <IonCard>
              <IonCardHeader color="medium">
                <IonLabel>{t('Report Time')}</IonLabel>
              </IonCardHeader>
              <IonCardContent>
                {incidentTime
                  ? (
                    <IonItem lines="none">
                      <IonDatetimeButton
                        slot="start"
                        datetime="report_time"
                        disabled={true}
                      />
                      <IonModal keepContentsMounted={true}>
                        <IonDatetime
                          id="report_time"
                          preferWheel={true}
                          value={incidentTime?.toISOString()}
                        ></IonDatetime>
                      </IonModal>
                    </IonItem>
                  )
                  : (
                    <IonText>{t('No time given')}</IonText>
                  )}
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size="12" sizeXl="6" sizeLg="6">
            <IonCard>
              <IonCardHeader color="medium">
                <IonLabel>{t('Was anyone injured?')}</IonLabel>
              </IonCardHeader>
              <IonCardContent color="primary">
                <IonText color="dark">
                  {incident?.is_injury ? t('Yes') : t('No')}
                </IonText>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonCard>
              <IonCardHeader color="medium">
                <IonLabel>{t('Files')}</IonLabel>
              </IonCardHeader>
              <IonCardContent>
                <IonList lines="full">
                  {incident
                    ? (
                      incident?.files?.map((file, index) => {
                        return (
                          <IonItemSliding key={index}>
                            <IonItem lines="full">
                              <IonIcon
                                {...(file.type === 'image'
                                  ? { icon: camera }
                                  : { icon: documentText })}
                                slot="start"
                              ></IonIcon>
                              <IonText>{file.name}</IonText>
                            </IonItem>
                            <IonItemOptions>
                              <IonItemOption
                                color="danger"
                                onClick={() => {
                                  unlinkFile(file);
                                }}
                              >
                                <IonIcon icon={trashBin} slot="start"></IonIcon>
                                {t('Delete')}
                              </IonItemOption>
                            </IonItemOptions>
                          </IonItemSliding>
                        );
                      })
                    )
                    : (
                      <></>
                    )}
                </IonList>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default Detail;
