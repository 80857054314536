import { IonCard, IonFab, IonFabButton, IonIcon, IonLabel } from '@ionic/react';
import { add, trashSharp } from 'ionicons/icons';
import { useState } from 'react';

import styles from './CardListComponent.module.scss';
import type { DataType, TitleType } from './CardListTypes';
import IconComponent from '../IconComponent/index';

const fakeData = [
  {
    id: 1,
    title: 'John Doe 1'
  },
  {
    id: 2,
    title: 'John Doe 2'
  }
];

const CardListComponent: React.FC<TitleType> = ({ title }) => {
  const [isActive, setIsActive] = useState<boolean>(true);

  return (
    <div className={styles['card-ion-list']}>
      <IonLabel>{title}</IonLabel>
      {fakeData.map((item: DataType) => (
        <IonCard key={item.id} className={styles['ion-padding']}>
          <IonLabel className={styles['item-label']}>{item.title}</IonLabel>
          <IconComponent
            link={''}
            icon={trashSharp}
            width={'20px'}
            isActive={isActive}
          />
        </IonCard>
      ))}
      <IonFab
        className={styles['plus-button']}
        vertical="bottom"
        horizontal="start"
        slot="fixed"
      >
        <IonFabButton size="small">
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
    </div>
  );
};

export default CardListComponent;
