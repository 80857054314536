import { IonGrid, IonLabel, IonRow, IonSegment, IonSegmentButton } from '@ionic/react';

import type { SegmentProps } from './Button';
import styles from './ButtonStyles.module.scss';
import { useAppSelector } from '../../../hooks';

export const SegmentButtons = (props: SegmentProps) => {
  const segmentProperties = {
    scrollable: true,
    value: props.value,
    onIonChange: (e: any) => props.onChange(e.detail.value!),
    className: props.className ? props.className : styles.segmentButtons,
    style: props.style,
    ...props.ionSegmentProps ? props.ionSegmentProps : {},
    locked: props.locked,
  };

  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const renderButtons = () => {
    return props.buttons.map((item, index) => (
      <IonSegmentButton key={index} value={item.value} color={item.color} style={{ ...isDesktop && { width: 230 } }} {...item.ionSegmentButtonProps}>
        <IonGrid>
          <IonRow className='ion-align-items-center ion-justify-content-evenly'>
            <IonLabel>{item.text}</IonLabel>
          </IonRow>
        </IonGrid>
      </IonSegmentButton>
    ));
  };

  return (
    <IonSegment {...segmentProperties} >
      {renderButtons()}
    </IonSegment>
  );
};
