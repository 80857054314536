import { IonContent, IonHeader, IonIcon, IonItem, IonList, IonSplitPane, IonTitle, IonToolbar } from '@ionic/react';
import { t } from 'i18next';
import { chevronBack } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';

import styles from './splitpane.module.scss';
import SplitPaneOptions from './SplitPaneCategoryList';
import BigUp from '..';
import { useAppSelector } from '../../../hooks';
import type { SubpanelItem } from '../../../pages/DesktopCards';
import { ionicColours } from '../variables';

interface DynamicContentProps {
  uuid: string;
  menuItems: SubpanelItem[];
  baseURL: 'economy-tools' | 'project-tools' | 'notice-board';
  children: React.ReactNode;
  returnRoute?: string;
}

const SplitpaneContent: React.FC<DynamicContentProps> = ({ baseURL, children, menuItems, returnRoute, uuid }) => {
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const history = useHistory();
  if (!isDesktop) {
    return (<>
      <IonItem style={{ marginTop: 10 }} lines={'none'} type={'button'} onClick={() => {
        returnRoute
          ? history.push(returnRoute)
          : history.go(-1);
      }
      }>
        <IonIcon className={'ion-no-margin'} aria-hidden="true" icon={chevronBack} slot="start"></IonIcon>
        <BigUp.Label.Regular className={'ion-no-margin'} label={t('Back')}></BigUp.Label.Regular>
      </IonItem>
      {children}
    </>);
  }
  return (
    <div style={{ maxWidth: 'var(--ion-desktop-max-width)', minHeight: '80vh', marginBottom: 100 }}>
      <IonSplitPane contentId="main" style={{ marginTop: 200 }}>
        <SplitPaneOptions
          toolColour={ionicColours.primary}
          baseUrl={baseURL}
          uuid={uuid}
          label={t('Project tools')}
          menuItems={menuItems}
        />
        <div id="main" className={styles['split-pane-wrapper']}>
          <IonHeader >
            <IonToolbar color={'light'}>
              <IonTitle>
                {menuItems.find(item => location.pathname.includes(item.value))?.subpanelTitle}
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color={'light'} style={{ '--overflow': 'scroll' }}>
            <IonList>
              {children}
            </IonList>
          </IonContent>
        </div >
      </IonSplitPane>
    </div>
  );
};

export default SplitpaneContent;
