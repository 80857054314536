import { IonCol, IonRow } from '@ionic/react';
import { useFormContext } from 'react-hook-form';

import worksite_inputs_fields from './inputFields';
import BigUp from '../../../components/UI';

const WorksiteInputs: React.FC = () => {
  const methods = useFormContext();
  return (
    <>
      {worksite_inputs_fields.map((input, index) => {
        return (
          <IonRow key={index}>
            <IonCol>
              <BigUp.Input {...input} disabled={methods.formState.isSubmitting} />
            </IonCol>
          </IonRow>
        );
      })}
    </>
  );
};

export default WorksiteInputs;
