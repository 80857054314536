import { IonCol, IonGrid, IonItem, IonRow, IonSkeletonText } from '@ionic/react';
import type { CSSProperties } from 'react';
import React from 'react';

type Sizes = {
  sizeXs: string;
  sizeSm: string;
  sizeMd: string;
  sizeLg: string;
};

type SkeletonItemProps = {
  amount: number;
  sizes?: Sizes;
  styles?: CSSProperties;

};
const defaultStyles: CSSProperties = {
  width: '100%',
  height: 80,
  marginTop: 10
};

const SkeletonItem = (
  { amount, sizes, styles = defaultStyles }: SkeletonItemProps,
) => {
  const colSizes = {
    sizeXs: sizes?.sizeXs || '12',
    sizeSm: sizes?.sizeSm || '6',
    sizeMd: sizes?.sizeMd || '4',
    sizeLg: sizes?.sizeLg || '3',
  };

  const skeletonItems = Array.from({ length: amount }, (_, index) => (
    <IonRow className='ion-margin-bottom ion-margin-top' key={index}>
      <IonCol {...colSizes} className='ion-text-center ion-margin-bottom'>
        <IonItem lines='none' className='ion-no-padding' >
          <IonSkeletonText animated style={styles} />
        </IonItem>
      </IonCol>
    </IonRow>
  ));

  return (
    <>
      <IonGrid className={'ion-no-padding'}>
        {skeletonItems}
      </IonGrid>
    </>
  );
};

export default SkeletonItem;
