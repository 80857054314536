import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface CashFlowReducer {
  suggestedCashFlow: E2U.V1.Models.CashFlow | undefined;
  manualCashFlow: E2U.V1.Models.CashFlow | undefined;
  selectedSuggestedCashFlowRow: E2U.V1.Models.CashFlowRow | undefined;
  selectedManualCashFlowRow: E2U.V1.Models.CashFlowRow | undefined;
  selectedIndex: number;
  projectLiquidity: E2U.V1.Objects.ProjectLiquidity | undefined;
}

export const cashFlowSlice = createSlice<
  CashFlowReducer,
  SliceCaseReducers<CashFlowReducer>,
  string
>({
  name: 'cashFlow',
  initialState: {
    suggestedCashFlow: undefined,
    manualCashFlow: undefined,
    selectedSuggestedCashFlowRow: undefined,
    selectedManualCashFlowRow: undefined,
    selectedIndex: 0,
    projectLiquidity: undefined
  },

  reducers: {
    setSuggestedCashFlow: (
      state: CashFlowReducer,
      action: PayloadAction<E2U.V1.Models.CashFlow | undefined>
    ) => {
      state.suggestedCashFlow = action.payload;
    },

    setManualCashFlow: (
      state: CashFlowReducer,
      action: PayloadAction<E2U.V1.Models.CashFlow | undefined>
    ) => {
      state.manualCashFlow = action.payload;
    },

    setSelectedSuggestedCashFlowRow: (
      state: CashFlowReducer,
      action: PayloadAction<E2U.V1.Models.CashFlowRow | undefined>
    ) => {
      state.selectedSuggestedCashFlowRow = action.payload;
    },
    setSelectedManualCashFlowRow: (
      state: CashFlowReducer,
      action: PayloadAction<E2U.V1.Models.CashFlowRow | undefined>
    ) => {
      state.selectedManualCashFlowRow = action.payload;
    },

    setSelectedIndex: (
      state: CashFlowReducer,
      action: PayloadAction<number>
    ) => {
      state.selectedIndex = action.payload;
    },
    setProjectLiquidity: (
      state: CashFlowReducer,
      action: PayloadAction<E2U.V1.Objects.ProjectLiquidity | undefined>
    ) => {
      state.projectLiquidity = action.payload;
    }
  }
});

export const {
  setManualCashFlow,
  setProjectLiquidity,
  setSelectedIndex,
  setSelectedManualCashFlowRow,
  setSelectedSuggestedCashFlowRow,
  setSuggestedCashFlow
} = cashFlowSlice.actions;

export default cashFlowSlice.reducer;
