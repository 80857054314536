import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, isPlatform } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { lockClosed, pencilSharp } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { useAppSelector } from '../../../../hooks';

interface CategoriesProps {
  categories: E2U.V1.Models.Type[];
}

const EditCategory: React.FC<CategoriesProps> = ({ categories }) => {
  const { t } = useTranslate();
  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState<E2U.V1.Models.Type | undefined>();
  const selectedProject = useAppSelector(state => state.project.selectedProject);

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: { ...selectedCategory }
  });

  const systemRecords = categories.filter(category => category.is_system_record);
  const nonSystemRecords = categories.filter(category => !category.is_system_record);

  const getSelectedCategory = (id: E2U.V1.Models.Type['id'], systemRecord: boolean) => {
    if (!systemRecord) {
      const category = categories.find((category) => category.id === id);
      if (category) {
        setSelectedCategory(category);
        history.push(`/project-tools/${selectedProject?.id}/document-categories/${category.id}/`);
      }
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      methods.reset(selectedCategory);
    }
  }, [selectedCategory]);

  return (
    <>
      <h2>{t('Records')}</h2>
      {nonSystemRecords.length === 0 && <p>{t('No custom records found')}</p>}
      {nonSystemRecords.map((category, index) => (
        <IonGrid key={index}>
          <IonRow className='ion-align-items-center'>
            <IonCol size='12'>
              <IonItem className='ion-no-padding'>
                <div
                  style={{
                    background: category.color,
                    width: '30px',
                    height: '30px',
                    borderRadius: isPlatform('ios') ? '50%' : '0'
                  }}
                />
                <IonLabel>{category.name}</IonLabel>
                <IonIcon
                  style={{ cursor: 'pointer' }}
                  icon={pencilSharp}
                  size={'large'}
                  onClick={() => getSelectedCategory(category.id, category.is_system_record)}
                  color='medium'
                  slot='end'
                />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      ))}

      <h2>{t('System Records')}</h2>
      {systemRecords.map((category, index) => (
        <IonGrid key={index}>
          <IonRow className='ion-align-items-center'>
            <IonCol size='12'>
              <IonItem className='ion-no-padding'>
                <div
                  style={{
                    background: category.color,
                    width: '30px',
                    height: '30px',
                    borderRadius: isPlatform('ios') ? '50%' : '0'
                  }}
                />
                <IonLabel>{category.name}</IonLabel>
                <IonIcon
                  icon={lockClosed}
                  size={'large'}
                  color='medium'
                  slot='end'
                />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      ))}
    </>
  );
};
export default EditCategory;
