import { matchUuid } from './matchUuid';

export const onboardingStages = [
  'information',
  'certificates',
  'project-files',
  'summary'
];

export const onboardingPath = `/onboarding/:onboardingUuid(${matchUuid})`;

export const matchOnboarding = `/onboarding(/${matchUuid}|)(/(${onboardingStages.join('|')})|)$`;

export const optionalOnboardingRoute = `/onboarding/:onboardingUuid(${matchUuid})?/:path(${onboardingStages.join('|')})?`;
export const superOptionalOnboardingRoute = `/:onboarding(onboarding)?/:onboardingUuid(${matchUuid})?/:path(${onboardingStages.join('|')})?`;
