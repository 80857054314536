import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface PrecalculationsReducer {
  selectedPrecalculation: E2U.V1.Models.Precalculation | undefined;
  selectedRow: E2U.V1.Models.PrecalculationRow | undefined;
  precalculations: E2U.V1.Models.Precalculation[] | undefined;
}

export const precalculationsSlice = createSlice<
  PrecalculationsReducer,
  SliceCaseReducers<PrecalculationsReducer>,
  string
>({
  name: 'precalculations',
  initialState: {
    selectedPrecalculation: undefined,
    selectedRow: undefined,
    precalculations: undefined
  },

  reducers: {
    setSelectedPrecalculation: (
      state: Draft<PrecalculationsReducer>,
      action: PayloadAction<E2U.V1.Models.Precalculation | undefined>
    ) => {
      state.selectedPrecalculation = action.payload;
    },

    setSelectedRow: (
      state: Draft<PrecalculationsReducer>,
      action: PayloadAction<E2U.V1.Models.PrecalculationRow | undefined>
    ) => {
      state.selectedRow = action.payload;
    },

    setPrecalculations: (
      state: Draft<PrecalculationsReducer>,
      action: PayloadAction<E2U.V1.Models.Precalculation[] | undefined>
    ) => {
      state.precalculations = action.payload;
    }
  }
});

export const { setPrecalculations, setSelectedPrecalculation, setSelectedRow } =
  precalculationsSlice.actions;

export default precalculationsSlice.reducer;
