import type { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface UtilitiesReducer {
    isDesktop: boolean;
}

export const utilitiesSplice = createSlice<UtilitiesReducer, SliceCaseReducers<UtilitiesReducer>>({
  name: 'desktop',
  initialState: {
    isDesktop: false
  },
  reducers: {
    setIsDesktop: (
      state: UtilitiesReducer,
      action: PayloadAction<boolean>
    ) => {
      state.isDesktop = action.payload;
    }
  }
}
);

export const {
  setIsDesktop
} = utilitiesSplice.actions;

export default utilitiesSplice.reducer;
