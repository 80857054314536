import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';
import { chevronForward } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './RelatedSelect.module.scss';
import type { Result } from './RelatedSelectInterface';
import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import useToggle from '../../../hooks/useToggle';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import SectionLoader from '../../SectionLoader/SectionLoader';
import Toast from '../../Toasts/Toast';

interface RelatedSelectProps {
  baseModel?: string;
  model: string;
  onSelect: (data: Result) => void;
  title: string;
  value: any;
  infiniteScroll?: boolean;
  displayFields?: string[];
  fetchFields?: string[];
  color?: string;
  showSelected?: boolean;
}

const resultsHeight = 650;

const RelatedSelect: React.FC<RelatedSelectProps> = ({ infiniteScroll = true, showSelected = true, ...props }) => {
  const [results, setResults] = useState<Result[]>([]);
  const [page, setPage] = useState(1);
  const [selectedRecord, setSelectedRecord] = useState<Result | undefined>();
  const [searchValue, setSearchValue] = useState<string>();
  const [totalPages, setTotalPages] = useState(0);
  const dataRef = useRef<string>();
  const ionContentRef = useRef<HTMLIonContentElement | null>(null);
  const searchBarRef = useRef<HTMLIonSearchbarElement | null>(null);
  const isLoading = useAppSelector((state) => state.loading.isLoading.relatedSelect);

  const fetchRecords = (appendResult = false, pageNum: number | null = null) => {
    store.dispatch(setIsLoading({ name: 'relatedSelect', value: true }));

    const url = buildUrl(pageNum);

    networking.get(url)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<Result>>) => {
        if (appendResult) {
          const oldResults = structuredClone(results);
          setResults(oldResults.concat(response.data.data.records));
        } else {
          setResults(response.data.data.records);
        }
        setTotalPages(response.data.data.total_pages);
      })

      .catch((error: E2U.V1.Response.Error) => {
        Toast(t('Could not fetch records'), 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'relatedSelect', value: false }));
      });
  };

  const fetchSelectedRecord = () => {
    networking
      .get(`api/v1/${props.model}/${props.value}`)
      .then((response: E2U.V1.Response.Success<Result>) => {
        setSelectedRecord(response.data.data);
      })
      .catch((error) => {
        Toast(t('Failed to fetch related record.'), 'error');
        console.warn('Failed to load' + props.model, error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'records', value: false }));
      });
  };

  const handleChange = (ev: Event) => {
    const target = ev.target as HTMLIonSearchbarElement;
    setPage(1);

    if (target && typeof target.value === 'string') {
      setSearchValue(target.value);
    } else {
      setSearchValue('');
    }
  };
  const modalClose = useToggle('modalClose');
  const resetListOnModalClose = () => {
    setResults([]);
    modalClose.toggleFalse();
    setPage(1);
  };

  const handleSelect = (data: Result) => {
    setSelectedRecord(data);
    props.onSelect(data);
    resetListOnModalClose();
  };

  const nextPage = () => {
    if (page !== totalPages) {
      const nextPage = page + 1;
      fetchRecords(true, nextPage);
      setPage(nextPage);
    } else {
      console.log('stopped');
    }
  };

  const buildUrl = (pageNum: number | null = null): string => {
    const nextPage = pageNum ?? page;
    const fieldsToFetch: string[] = [
      ...props.displayFields ? props.displayFields : [],
      ...props.fetchFields ? props.fetchFields : []
    ];

    const modelUrl = props.baseModel ? props.baseModel + '/' + props.model : props.model;

    let addedParam = false;
    let url = `api/v1/${modelUrl}?`;

    if (searchValue) {
      addedParam = true;
      url += `search=${searchValue}`;
    }

    if (addedParam) {
      url += '&';
    }

    url += `fields=id,${fieldsToFetch.length ? fieldsToFetch.join(',') : 'name'}&page=${nextPage}`;

    return url;
  };

  useEffect(() => {
    if (modalClose.isToggled) {
      setPage(1);
      fetchRecords();
    }

    return () => {
      setPage(1);
      setResults([]);
    };
  }, [modalClose.isToggled]);

  useEffect(() => {
    dataRef.current = (Math.random() + 1).toString(36).substring(5);
    if (props.value) {
      fetchSelectedRecord();
    }
  }, []);

  useEffect(() => {
    if (props.value) {
      fetchSelectedRecord();
    }
  }, [props.value]);

  useEffect(() => {
    fetchRecords();
  }, [searchValue]);

  const renderRows = () => {
    return results.map((result, i) => {
      return (
        <div key={i}>
          <IonItem onClick={() => handleSelect(result)}>
            {
              props.displayFields
                ? props.displayFields.map((field) => { return result[field] ?? ''; }).join(' | ')
                : result.name
            }
          </IonItem>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <IonButton expand="block" onClick={() => modalClose.toggleTrue()} color={props.color || 'secondary'}>
        {selectedRecord && showSelected
          ? props.displayFields
            ? props.displayFields.map((field) => { return selectedRecord[field] ?? ''; }).join(' | ')
            : selectedRecord.name
          : props.title}
        <IonIcon slot='end' icon={chevronForward}></IonIcon>
      </IonButton>

      <IonModal
        isOpen={modalClose.isToggled}
        onDidDismiss={() => modalClose.toggleFalse()}
        className={styles['related-search-modal']}
      >
        <IonHeader className='ion-no-border'>
          <IonToolbar>
            <IonTitle>{props.title}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={resetListOnModalClose}>
                {t('Close')}
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar className='ion-margin-bottom'>
            <IonSearchbar
              className={styles.searchbar}
              debounce={500}
              onIonChange={(ev) => handleChange(ev)}
              ref={searchBarRef}
            />
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" scrollY={false} ref={ionContentRef}>

          <IonList
            style={{ maxHeight: resultsHeight }}
            className={styles['related-search-list']}
            id={dataRef.current}
          >
            {infiniteScroll
              ? (
                <InfiniteScroll
                  dataLength={results.length}
                  hasMore={page !== totalPages}
                  next={nextPage}
                  loader={
                    <IonGrid>
                      <IonRow className='ion-justify-content-center ion-align-items-center ion-margin-top'>
                        <IonCol className='ion-text-center ion-margin-top'>
                          <IonSpinner />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  }
                  scrollableTarget={dataRef.current}
                  scrollThreshold={0.95}
                  height={resultsHeight}
                  endMessage={
                    <IonItem>
                      <IonLabel color={'medium'} className={styles['no-more-results']}>
                        {t('No More Results')}
                      </IonLabel>
                    </IonItem>
                  }
                >
                  {renderRows()}
                </InfiniteScroll>
              )
              : renderRows()
            }
          </IonList>
          {infiniteScroll
            ? null
            : (page === totalPages
              ? <IonButton expand="block" disabled={true}>{t('No more results...')}</IonButton>
              : (
                <IonButton expand="block" onClick={() => nextPage()} disabled={isLoading}>
                  {isLoading
                    ? (<SectionLoader />)
                    : (t('Show more'))
                  }
                </IonButton>
              )
            )

          }
        </IonContent>
      </IonModal>
    </React.Fragment>
  );
};

export default RelatedSelect;
