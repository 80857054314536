import {
  IonButton,
  IonCard,
  IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonItem, IonLabel,
  IonLoading
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useStore } from 'react-redux';

import styles from './JoinTeamForm.module.scss';
import { networking } from '../../../api/networking';
import Toast from '../../../components/Toasts/Toast';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';

const JoinTeamForm: React.FC = () => {
  const store = useStore();
  const { t } = useTranslate();
  const invite: E2U.V1.Models.Invite | undefined = useAppSelector((state) => state.invite.invite);
  const isAcceptingInvite: boolean = useAppSelector((state) => state.loading.isLoading.acceptingInvite);
  const team: E2U.V1.Models.Team | undefined = useAppSelector((state) => state.team.selectedTeam);
  const isLoadingTeam: boolean = useAppSelector((state) => state.loading.isLoading.team);
  const [validationErrors, setValidationErrors] = useState<{ [field: string]: string[] }>({});
  const { formState: { errors }, handleSubmit, register, setValue } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange'
  });

  const handleFormSubmit: SubmitHandler<FieldValues> = (data) => {
    store.dispatch(setIsLoading({
      name: 'acceptingInvite',
      value: true
    }));
    networking.post(`/api/v1/invite/${invite?.token}/team`, data)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Invite>) => {
        Toast(t('Invitation accepted.'), 'success');
      })
      .catch((error: E2U.V1.Response.Error) => {
        if (error && error.response && error.response.data && error.response.data.message && error.response.data.message === 'Validation failed') {
          setValidationErrors(error.response.data.data);
        }
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'acceptingInvite',
          value: false
        }));
      });
  };

  return (
    <section className={styles['join-team-form']}>
      {(isLoadingTeam || isAcceptingInvite)
        ? <IonLoading isOpen={true} />
        : <>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <IonCard>
              <IonCardContent>
                <IonCardHeader>
                  <IonCardTitle>{t('Accept invitation?')}</IonCardTitle>
                </IonCardHeader>
                <IonItem>
                  <IonLabel>{t('Team')}: {invite?.team?.name}</IonLabel>
                </IonItem>
                <IonButton
                  type={'submit'}
                >
                  {t('Accept')}
                </IonButton>
              </IonCardContent>
            </IonCard>
          </form>
        </>}
    </section>
  );
};

export default JoinTeamForm;
