import { IonTitle } from '@ionic/react';

const ImagePicker: React.FC = () => {
  // const openScanner = async () => {
  //   const data = await BarcodeScanner.scan();
  //   console.log(`Barcode data: ${data.text}`);
  // };

  return (
    <div>
      {/* <IonButton onClick={openScanner}>Scan barcode</IonButton> */}
      <IonTitle>Camera Capture</IonTitle>
    </div>
  );
};

export default ImagePicker;
