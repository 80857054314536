import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import type { ComponentProps } from 'react';

type HTMLGenericProps<T> = React.DetailedHTMLProps<
  React.HTMLAttributes<T>, T
>
type FabProps = ComponentProps<typeof IonFab> &
  HTMLGenericProps<HTMLIonFabElement>;

export interface FabButtonProps extends FabProps {
  icon: ComponentProps<typeof IonIcon>;
  button?: ComponentProps<typeof IonFabButton>;
}
const FabButton: React.FC<FabButtonProps> = (props) => {
  return (
    <>
      <IonFab {...props}>
        <IonFabButton disabled={props.button?.disabled} {...props.button} id={props.id} color={props.color}>
          <IonIcon {...props.icon}></IonIcon>
        </IonFabButton>
      </IonFab>
    </>
  );
};

export default FabButton;
