import { IonItem, IonLabel, IonList, IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { label_styles } from '../../pages/Projects/ProjectForm';

interface SelectDropdownProps<T> extends React.HTMLAttributes<HTMLIonSelectElement> {
  data: T[];
  placeholder: string;
  label: string;
  onValueChange?: (value: string) => void;
  otherCategory?: any;
  setOtherCategory?: (val: any) => void;
}

interface SelectDataProps {
  label: string;
  name: string;
  id: string;
}

const SelectDropdown: React.FC<SelectDropdownProps<SelectDataProps>> = ({ data, label, onValueChange, placeholder }) => {
  const { register } = useFormContext();

  const handleSelectChange = (e: CustomEvent) => {
    const value = e.detail.value;
    if (onValueChange) onValueChange(value);
  };

  return (
    <React.Fragment>
      <IonLabel className='ion-margin-left ion-margin-top' color={'medium'} style={{ ...label_styles }}>
        {label}:
      </IonLabel>
      <IonList>
        <IonItem>
          <IonSelect
            {...register('project_category_id')}
            placeholder={placeholder}
            onIonChange={handleSelectChange}
          >
            {data.map((option: SelectDataProps, i) => {
              return (
                <IonSelectOption key={option.id || i} value={option.id}>
                  {option.name}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonItem>
      </IonList>
    </React.Fragment>
  );
};

export default SelectDropdown;
