import { IonSearchbar } from '@ionic/react';
import type { StyleReactProps } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';

interface SearchBarInterface extends StyleReactProps {
  debounceInterval?: number;
  placeholder?: string;
  onSearch: (search: string) => void
}

const SearchBar: React.FC<SearchBarInterface> = (
  {
    debounceInterval = 500,
    onSearch,
    placeholder,
    style
  }
) => {
  const { t } = useTranslate();
  const placeholderText = placeholder ?? t('Search');
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    onSearch(search);
  }, [search]);

  return (
    <IonSearchbar
      placeholder={placeholderText}
      onIonInput={(event) => setSearch(event.detail.value ?? '')}
      style={style}
      debounce={debounceInterval}
    />
  );
};

export default SearchBar;
