import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRow,
  IonText,
  IonTextarea,
  IonToggle
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { add, camera, documentText, trashBin } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useStore } from 'react-redux';

import { networking } from '../../../../../../api/networking';
import Toast from '../../../../../../components/Toasts/Toast';
import { useAppSelector } from '../../../../../../hooks';
import { setIsLoading } from '../../../../../../reducers/loading';
import DocumentForm from '../../../../../Document/DocumentForm';

interface EditProps {
  onSave: () => void;
}

const Edit: React.FC<EditProps> = (props) => {
  const store = useStore();
  const incident: E2U.V1.Models.IncidentReport | undefined = useAppSelector(
    (state) => state.reports.selectedIncident
  );
  const incidentTime: Date | undefined = incident?.incident_time
    ? new Date(incident?.incident_time)
    : undefined;
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [files, setFiles] = useState<E2U.V1.Models.File[]>(
    structuredClone(incident ? incident.files : [])
  );
  const isLoading = useAppSelector(
    (state) => state.loading.isLoading.incidents
  );

  const [removeFiles, setRemoveFiles] = useState<string[]>([]);
  const [addFiles, setAddFiles] = useState<string[]>([]);

  const {
    control,
    handleSubmit,
    register,
    setValue
  } = useForm<FieldValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange'
  });

  const handleSave: SubmitHandler<FieldValues> = (data: FieldValues) => {
    store.dispatch(setIsLoading({ name: 'incident', value: true }));
    const isUpdating = !!(incident && incident.id);
    delete data.incident_time;
    const saveRequest = isUpdating
      ? networking.put(`/api/v1/incident_reports/${incident?.id}`, data)
      : networking.post('/api/v1/incident_reports', data);

    saveRequest
      .then(
        (
          newIncidentData: E2U.V1.Response.Success<E2U.V1.Models.IncidentReport>
        ) => {
          const newIncident = newIncidentData.data.data;
          const requests = addFiles.map((addFile) => {
            return networking.post(
              `/api/v1/incident_reports/${newIncident?.id}/files/${addFile}`
            );
          });

          requests.push(
            ...removeFiles.map((removeFile) => {
              return networking.delete(
                `/api/v1/incident_reports/${newIncident?.id}/files/${removeFile}`
              );
            })
          );

          networking
            .all(requests)
            .then(() => {
              Toast(
                isUpdating
                  ? t('Successfully saved incident report')
                  : t('Successfully created incident report'),
                'success'
              );
            })
            .catch((errors) => {
              console.warn(errors);
              Toast(
                isUpdating
                  ? t('Failed saving incident report')
                  : t('Failed creating incident report'),
                'error'
              );
            });
        }
      )
      .catch((error: E2U.V1.Response.Error<E2U.V1.Models.IncidentReport>) => {
        console.warn(error);
        Toast(
          isUpdating
            ? t('Failed saving incident report')
            : t('Failed creating incident report'),
          'error'
        );
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'incident', value: false }));
        props.onSave();
      });
  };

  useEffect(() => {
    if (typeof incident !== 'undefined') {
      Object.entries(incident).forEach(([key, value]) => {
        console.log(key, value);
        switch (key) {
          case 'effects_schedule':
            setValue(key, !!value);
            break;
          case 'incident_time':
            setValue(key, new Date(String(value)).toISOString());
            break;
          default:
            setValue(key, value);
            break;
        }
      });
    }
  }, []);

  const addFile = (file: E2U.V1.Models.File) => {
    if (file.id) {
      if (files.findIndex((doc) => doc.id === file.id) !== -1) {
        return;
      }

      const newFiles = structuredClone(files);
      newFiles.push(file);

      setFiles(newFiles);
      addFiles.push(file.id);
      setAddFiles(addFiles);
      console.log('Add Files: ', addFiles);
    }

    setUploadingFile(false);
  };

  const deleteFile = (fileId: string) => {
    const index = files.findIndex((file) => file.id === fileId);

    if (index !== -1) {
      removeFiles.push(fileId);
      const newFiles = structuredClone(files);
      delete newFiles[index];
      setFiles(newFiles);
      setRemoveFiles(removeFiles);
    }
  };

  const { t } = useTranslate();
  return (
    <React.Fragment>
      {isLoading
        ? (
          <IonLoading isOpen />
        )
        : (
          <React.Fragment>
            <IonGrid fixed={true}>
              <form onSubmit={handleSubmit(handleSave)}>
                <IonRow>
                  <IonCol size="12" sizeXl="6" sizeLg="6">
                    <IonCard>
                      <IonCardHeader color="medium">
                        <IonLabel>{t('Name')}</IonLabel>
                      </IonCardHeader>
                      <IonCardContent color="primary">
                        <IonInput
                          {...register('name', {
                            required: true,
                            maxLength: 100
                          })}
                          color="dark"
                          placeholder={t('name')}
                        ></IonInput>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol size="12" sizeXl="6" sizeLg="6">
                    <IonCard>
                      <IonCardHeader color="medium">
                        <IonLabel>{t('Description')}</IonLabel>
                      </IonCardHeader>
                      <IonCardContent color="primary">
                        <IonTextarea
                          {...register('description', {
                            required: true,
                            maxLength: 500
                          })}
                          color="dark"
                        />
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <IonCard>
                      <IonCardHeader color="medium">
                        <IonLabel>{t('Report')}</IonLabel>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonTextarea
                          {...register('report', {
                            required: true,
                            maxLength: 3000
                          })}
                          color="dark"
                        >
                          {incident?.report}
                        </IonTextarea>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeXl="6" sizeLg="6">
                    <IonCard>
                      <IonCardHeader color="medium">
                        <IonLabel>{t('Report Time')}</IonLabel>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonItem lines="none">
                          <Controller
                            control={control}
                            name="incident_time"
                            render={({
                              field: { onBlur, onChange, ref, value }
                            }) => (
                              <>
                                <IonDatetimeButton
                                  slot="start"
                                  datetime="report_time"
                                  disabled={false}
                                />
                                <IonModal keepContentsMounted={true}>
                                  <IonDatetime
                                    preferWheel={true}
                                    id="report_time"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                  />
                                </IonModal>
                              </>
                            )}
                          />
                        </IonItem>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol size="12" sizeXl="6" sizeLg="6">
                    <IonCard>
                      <IonCardHeader color="medium">
                        <IonLabel>{t('Does this effect the schedule?')}</IonLabel>
                      </IonCardHeader>
                      <IonCardContent color="white">
                        <IonItem color="white" lines="none">
                          <IonToggle {...register('effects_schedule')} />
                          <IonLabel slot="end">Yes / No</IonLabel>
                        </IonItem>
                        {/* <IonList>
                                        <IonRadioGroup {...register('effects_schedule')}>
                                            <IonItem>
                                                <IonLabel>{t('Yes')}</IonLabel>
                                                <IonRadio value={true} slot="end" />
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel>{t('No')}</IonLabel>
                                                <IonRadio value={false} slot="end" />
                                            </IonItem>
                                        </IonRadioGroup>
                                    </IonList> */}
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader color="medium">
                        <IonLabel>{t('Files & Images')}</IonLabel>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonList lines="full">
                          {files.map((file, index) => {
                            return (
                              <IonItemSliding key={index}>
                                <IonItem lines="full">
                                  <IonIcon
                                    {...(file.type === 'image'
                                      ? { icon: camera }
                                      : { icon: documentText })}
                                    slot="start"
                                  ></IonIcon>
                                  <IonText>{file.name}</IonText>
                                </IonItem>
                                <IonItemOptions>
                                  <IonItemOption
                                    color="danger"
                                    onClick={() => {
                                      return file.id
                                        ? deleteFile(file.id)
                                        : null;
                                    }}
                                  >
                                    <IonIcon
                                      icon={trashBin}
                                      slot="start"
                                    ></IonIcon>
                                    Delete
                                  </IonItemOption>
                                </IonItemOptions>
                              </IonItemSliding>
                            );
                          })}
                        </IonList>
                        <IonFab
                          onClick={() => {
                            setUploadingFile(true);
                          }}
                          vertical="bottom"
                          horizontal="end"
                        >
                          <IonFabButton>
                            <IonIcon icon={add} />
                          </IonFabButton>
                        </IonFab>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton type="submit">{t('Submit')}</IonButton>
                  </IonCol>
                </IonRow>
              </form>
            </IonGrid>
            <IonModal
              isOpen={uploadingFile}
              onDidDismiss={() => {
                setUploadingFile(false);
              }}
            >
              <DocumentForm handleUploadedFile={addFile} />
            </IonModal>
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

export default Edit;
