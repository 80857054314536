import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { useBankIdContext } from '../../bankid/bankid.context';
import BankID from '../../components/UI/BankID/BankID';

type LoginMethod = 'email' | 'bankID';

interface LoginSegmentProps {
  loginMethod: LoginMethod;
  setLoginMethod: (method: LoginMethod) => void;
}

const LoginSegment: React.FC<LoginSegmentProps> = ({ loginMethod, setLoginMethod }) => {
  const { t } = useTranslate();
  const bankid = useBankIdContext();
  const location = useLocation();

  const handleRegistrationRedirected = () => {
    const locationState = (location.state as { bankid?: boolean })?.bankid;
    if (locationState && locationState === true) {
      setLoginMethod('bankID');
    }
  };

  useEffect(() => {
    handleRegistrationRedirected();
  }, [location]);

  useEffect(() => {
    if (loginMethod === 'bankID') {
      bankid?.authenticate.initiateBankIdAuthentication();
    } else {
      bankid?.abortBankIdAuthentication();
    }
  }, [loginMethod]);

  return (
    <IonSegment
      style={{ width: '100%' }}
      value={loginMethod}
      onIonChange={(e) => setLoginMethod(e.detail.value as LoginMethod)}
    >
      <IonSegmentButton disabled={loginMethod === 'email'} value={'email'}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <IonLabel className='ion-no-margin'>{t('Log in with email')}</IonLabel>
        </div>
      </IonSegmentButton>

      <IonSegmentButton disabled={loginMethod === 'bankID'} value="bankID">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <IonLabel>{t('BankID')}</IonLabel>
          <BankID.BankIdIcon color='standard' width={50} />
        </div>
      </IonSegmentButton>
    </IonSegment>
  );
};

export default LoginSegment;
