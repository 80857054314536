import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import type { SelectOption } from './interface';
import BigUp from '..';
import type { CountryList } from '../../../tools/countryList';
import countries from '../../../tools/countryList';

interface CountrySelectProps {
  default: CountryList['name'] | undefined;
}

const CountrySelect: React.FC<CountrySelectProps> = (props) => {
  const [selectCountry, setSelectCountry] = useState<CountryList['name']>(props.default || 'Sweden');
  const { t } = useTranslate();
  const methods = useFormContext();
  const getCountries = () => {
    return countries.map((country) => {
      return {
        value: country.name,
        id: country.name
      };
    });
  };

  const handleCountrySelection = (value: SelectOption | string) => {
    setSelectCountry(value as CountryList['name']);
  };

  const countrySelect = {
    data: getCountries(),
    selected: selectCountry,
    handleSelection: handleCountrySelection,
    label: t('Country'),
    register: 'country',
    validation: { required: t('Country is required') },
    itemProps: { className: 'ion-no-padding ion-margin-bottom' },
  };

  return <BigUp.Select {...countrySelect} />;
};

export default CountrySelect;
