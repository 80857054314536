import { IonBreadcrumb } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';

import BreadcrumbsWrapper from './BreadcrumbsWrapper';
import { truncate } from '../../../tools/truncateString';

export type BreadcrumbData = {
  label: string;
  path: string;
  disabled?: boolean;
}

interface BreadcrumbProps {
  data: BreadcrumbData[];
}

const Breadcrumbs: React.FC<BreadcrumbProps> = ({ data }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <BreadcrumbsWrapper>
      {data.map((item, index) => {
        return (
          <IonBreadcrumb
            key={index}
            style={{ cursor: 'pointer' }}
            disabled={location.pathname === item.path || item.disabled}
            onClick={() => history.push(item.path)}
          >
            {truncate(item.label, 20)}
          </IonBreadcrumb>
        );
      })}
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
