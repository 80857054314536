import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import AmendmentForm from '../../../../components/Page/Economy/AmendmentForm';
import ProgressBar from '../../../../components/UI/Loaders/ProgressBar';
import useFetchData from '../../../../hooks/useFetch';

const EditChange = () => {
  const { change_uuid } = useParams<{ change_uuid: string | undefined }>();
  const [change, setChange] = useState<E2U.V1.Models.EconomyChange>();

  const { fetchData, isLoading } = useFetchData<E2U.V1.Models.EconomyChange, any | undefined>({
    initialData: null
  });

  const params = new URLSearchParams();
  params.append('with[]', 'rows');
  params.append('with[]', 'files');

  const getRoute = `/api/v1/economy_changes/${change_uuid}?${params.toString()}`;

  const onThen = (response: E2U.V1.Response.Success<E2U.V1.Models.EconomyChange>) => {
    setChange(response.data.data);
  };

  const onCatch = (error: E2U.V1.Response.Error<E2U.V1.Models.EconomyChange>) => {
    Sentry.captureException(error);
  };

  useEffect(() => {
    if (change_uuid) {
      fetchData(getRoute, onThen, onCatch);
    }
  }, [change_uuid]);

  return (
    <React.Fragment>
      {isLoading
        ? <ProgressBar />
        : <AmendmentForm type="changes" amendment={change} />
      }
    </React.Fragment>
  );
};

export default EditChange;
