import { IonAvatar, IonImg } from '@ionic/react';
import React from 'react';

import placeholder from '../assets/images/placeholder-user.png';
import useProfilePicture from '../hooks/useProfilePicture';

type AvatarSource = 'userImage' | 'placeholder';

interface AvatarProps extends React.HTMLAttributes<HTMLIonImgElement & HTMLIonAvatarElement> {
  avatarSource: AvatarSource;
}
const defaultStyles = {
  size: '50px'
};

const CustomAvatar: React.FC<AvatarProps> = ({ avatarSource, ...props }) => {
  const sizeProps = {
    width: props.style?.width || defaultStyles.size,
    height: props.style?.height || defaultStyles.size
  };

  const avatarSources: Record<AvatarProps['avatarSource'], string> = {
    userImage: useProfilePicture(),
    placeholder,
  };

  const imageAlt: Record<AvatarProps['avatarSource'], string> = {
    userImage: 'employee profile picture',
    placeholder: 'user placeholder image',
  };

  const avatarSrc = avatarSources[avatarSource];
  const alt = imageAlt[avatarSource];
  return (
    <IonAvatar style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', width: '100%' }}>
      <IonImg {...props}
        style={{
          width: sizeProps.width,
          height: sizeProps.height,
        }}
        src={avatarSrc}
        alt={alt} />
    </IonAvatar>
  );
};

const AvatarImage = (props: AvatarProps) => {
  return <CustomAvatar {...props} />;
};

const Avatar = {
  AvatarImage
};
export default Avatar;
