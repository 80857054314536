import { IonSelectOption } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { networking } from '../../api/networking';
import { useAppSelector } from '../../hooks';
import usePermissionHandler from '../../hooks/usePermissions';
import { setSelectedProject } from '../../reducers/project';
import SelectOptions from '../UI/Select/SelectOption';

interface ProjectSelectorProps {
  currentTab: string | undefined;
}

const ProjectSelector: React.FC<ProjectSelectorProps> = ({ currentTab }) => {
  const history = useHistory();
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { checkPermissionsForProjectAndTools } = usePermissionHandler();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const location = useLocation();

  const selectProject = useCallback(async (projectId: string) => {
    if (!projectId) return;

    try {
      const response = await networking.get(`/api/v1/projects/${projectId}`);
      dispatch(setSelectedProject(response.data.data));
      console.log('redirect');
      history.push(`/${location.pathname.split('/')[1]}/${projectId}`);
    } catch (error) {
      console.error('Error selecting project:', error);
    }
  }, [dispatch, history, currentTab]);

  return (
    <SelectOptions
      placeholder={t('Select project')}
      selectedColour='white'
      selectedTextSize='18px'
      itemPadding='ion-no-padding'
      options={checkPermissionsForProjectAndTools('read', 'read') || []}
      getId={(option) => option.id}
      getName={(option) => option.name}
      selectedOption={selectedProject}
      setSelectedOption={(option) => selectProject(option?.id || '')}
      onRedirect={() => history.push('/project-tools/new')}
      childrenPosition='top'
    >
      <IonSelectOption value="redirect">
        {`+ ${t('Create new project')}`}
      </IonSelectOption>
    </SelectOptions>
  );
};

export default ProjectSelector;
