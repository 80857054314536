import { IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

import BankID from './BankID';
import type { BankIdHandlerProps } from './bankid.interfaces';
import styles from './bankid.module.scss';
import BigUp from '..';
import { useBankIdContext } from '../../../bankid/bankid.context';
import { handleHintcodes } from '../../../bankid/hintCodes';
import { useAppSelector } from '../../../hooks';

const BankIdHandler: React.FC<BankIdHandlerProps> = ({ error, handleBankIdCompleted, type = 'authenticate' }) => {
  const { t } = useTranslate();
  const bankid = useBankIdContext();
  const handleAuthenticationType = type === 'sign' ? bankid?.sign : bankid?.authenticate;
  const { data, isCancelled, status } = handleAuthenticationType || {};
  const isOngoingTransaction = !isCancelled && (status && status === 'ongoing');
  const isFailedOrCancelled = isCancelled || (status && ['failed', 'expired'].includes(status));
  const user = useAppSelector(state => state.authentication.user);
  const history = useHistory();

  const handleClick = () => {
    if (isOngoingTransaction) {
      bankid?.openBankIdApp(type);
    } else if (type === 'sign') {
      bankid?.sign.initiateBankIdSign(t('Connect my account {{email}} with my personal number', { email: user?.email }));
    } else {
      bankid?.authenticate.initiateBankIdAuthentication();
    }
  };

  const handleCompletion = () => {
    if (handleBankIdCompleted) {
      handleBankIdCompleted(data?.user, data?.device);
    } else {
      const token = bankid?.authenticate?.token;
      if (token) {
        history.push('/');
      } else {
        history.push({
          pathname: '/registration',
          state: {
            user: {
              first_name: data?.user?.given_name,
              last_name: data?.user?.surname,
              ssn: data?.user?.personal_number,
              birth_date: data?.user?.birth_date,
              bankid_authentication_id: bankid?.authenticate.auth_request?.id
            }
          }
        });
        bankid?.abortBankIdAuthentication();
      }
    }
  };
  const buttonText = isFailedOrCancelled ? t('Retry') : (!status ? <IonSpinner /> : t('Open BankID'));

  useEffect(() => {
    if (status === 'completed') {
      handleCompletion();
    }
  }, [status]);

  return (
    <div className={`ion-padding ${styles.bankIdContainer}`}>
      <div className={`ion-margin-top ${styles.bankIdQrContainer}`}>
        {bankid &&
          ((handleAuthenticationType?.auth_request) && typeof handleAuthenticationType?.auth_request?.qr_code_token !== 'undefined') &&
          ((handleAuthenticationType.status) === 'ongoing' && !handleAuthenticationType.isCancelled)
          ? (<BankID.BankIdQrCode value={handleAuthenticationType?.auth_request?.qr_code_token || ''} />)
          : (
            <div className={styles.bankIdPlaceHolderContainer}>
              <BankID.BankIdIcon color='standard' width={300} />
              {(!status && !isFailedOrCancelled) && <IonSpinner />}
              <BankID.BankIdMessages
                messages={handleAuthenticationType?.isCancelled
                  ? t('Canceled')
                  : handleHintcodes(handleAuthenticationType?.data?.hint_code)}
              />
            </div>
          )}
      </div>
      {(status && (status === 'completed' || 'ongoing')) &&
        <BankID.BankIdMessages
          messages={handleAuthenticationType?.isCancelled
            ? t('Canceled')
            : handleHintcodes(handleAuthenticationType?.data?.hint_code)}
        />
      }
      {error && <BankID.BankIdMessages messages={error} />}
      <IonGrid className='ion-no-margin'>
        <IonRow className='ion-margin-vertical'>
          <IonCol >
            <BankID.BankIdButton expand='block' title={undefined} onClick={handleClick}>
              {buttonText} <BankID.BankIdIcon color='white' width={35} />
            </BankID.BankIdButton>
          </IonCol>
        </IonRow>
        {!handleAuthenticationType?.isCancelled &&
          <IonRow className='ion-margin-vertical'>
            <IonCol>
              <BigUp.Buttons.Regular
                expand={'block'}
                color={'light'}
                title={t('Cancel')}
                onClick={bankid?.abortBankIdAuthentication}
              />
            </IonCol>
          </IonRow>
        }
      </IonGrid>
    </div>
  );
};

export default BankIdHandler;
