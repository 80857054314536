import { IonIcon, IonItem } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { openOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import TermsAndConditionsModal from './TermsAndConditionsModal';
import useModal from '../../hooks/useModal';
import BigUp from '../UI';

const TermsAndConditions: React.FC = () => {
  const [agreed, setAgreed] = useState(false);
  const methods = useFormContext();
  const termsAndConditionsModal = useModal();
  const { t } = useTranslate();

  const openTermsAndConditions = () => {
    termsAndConditionsModal.toggleModal();
  };

  const handleModal = (handling: 'accept-terms' | 'decline-terms') => {
    if (handling === 'accept-terms') {
      setAgreed(true);
    }
    if (handling === 'decline-terms') {
      setAgreed(false);
    }
    termsAndConditionsModal.closeModal();
  };

  return (
    <>
      <IonItem lines='full' type='button' onClick={openTermsAndConditions} className='ion-no-padding ion-no-margin'>
        <BigUp.Checkbox
          labelPlacement='start'
          value={agreed}
          handleCheckbox={(checked) => setAgreed(checked)}
        >
          {t('Terms and conditions')} <IonIcon icon={openOutline} />
        </BigUp.Checkbox>

      </IonItem>

      <TermsAndConditionsModal
        handleModal={handleModal}
        modalStateHandlers={{
          open: termsAndConditionsModal.openModal,
          close: termsAndConditionsModal.closeModal,
          isOpen: termsAndConditionsModal.isModalOpen
        }}
      />
    </>
  );
};

export default TermsAndConditions;
