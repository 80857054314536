import { IonProgressBar } from '@ionic/react';

const ProgressBar: React.FC = () => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IonProgressBar
        style={{
          width: '80%',
          height: '6px',
          borderRadius: '50%',
          opacity: 0.6
        }}
        type="indeterminate"
      />
    </div>
  );
};
export default ProgressBar;
