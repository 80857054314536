import { t } from 'i18next';
import React from 'react';
import type { FieldValues } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import type { CurrencyInputProps } from './Input.interface';
import { ValidationBadge } from './ValidationBadge';
import Easy2Use from '..';
import formatNumber from '../../../tools/formatNumber';

const CurrencyInput: React.FC<CurrencyInputProps<string>> = (input) => {
  const { formState: { errors }, register, setValue } = useFormContext<FieldValues>();
  return (
    <React.Fragment>
      <Easy2Use.Input
        validation={{
          required: input.required,
          maxLength: input.maxLength,
          minLength: input.minLength,
          pattern: input.pattern,
          setValueAs: (value) => {
            if (input.inputMode === 'numeric') {
              return value.toString().replace(/[ ]/g, '');
            }
            return value;
          }
        }}
        register={input.register}
        label={t(input.label)}
        labelPlacement='stacked'
        className='ion-no-padding'
        disabled={input.disabled}
        autoCapitalize='sentences'
        aria-required
        clearInput
        {...input.ionicProps}
        type={input.inputType}
        inputmode={input.inputMode}
        placeholder={input.placeholder}
        onIonInput={(e) => {
          const currentTarget = e.currentTarget as HTMLInputElement;
          if (currentTarget) {
            const value = currentTarget.value.replace(/[ ]/g, '');
            if (isNaN(parseFloat(value))) {
              setValue(input.register, (currentTarget.value && currentTarget.value[0] === '0') ? '0' : '');
              return;
            }

            if (value === '' || value === '0') {
              setValue(input.register, (currentTarget.value && currentTarget.value[0] === '0') ? '0' : value);
            } else {
              setValue(input.register, formatNumber(parseFloat(value)));
            }
          }
        }}
      />
      <div className='ion-no-margin' style={{ height: 20, paddingTop: 5 }}>
        {errors[input.register] && (
          <ValidationBadge>
            {errors[input.register]?.message}
          </ValidationBadge>
        )}
      </div>

    </React.Fragment>
  );
};

export default CurrencyInput;
