import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote
} from '@ionic/react';
import {
  bookmarkOutline,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp
} from 'ionicons/icons';
import { useLocation } from 'react-router-dom';

import './Menu.scss';
import type { AppPage } from './AppPageTypes';

import { useTranslate } from '@tolgee/react';
import { t } from 'i18next';
import { useEffect } from 'react';

import fetchActivityCodes from '../../services/fetchActivityCodes.service';

const appPages: AppPage[] = [
  {
    title: t('Home'),
    url: '/',
    iosIcon: mailOutline,
    mdIcon: mailSharp
  },
  {
    title: t('Profile'),
    url: '#',
    iosIcon: paperPlaneOutline,
    mdIcon: paperPlaneSharp
  },
  {
    title: t('Favorites'),
    url: '#',
    iosIcon: heartOutline,
    mdIcon: heartSharp
  }
];

const labels = [t('News'), t('Reminders'), t('Logout')];

const Menu: React.FC = () => {
  const { t } = useTranslate();

  const location = useLocation();

  useEffect(() => {
    fetchActivityCodes();
  }, []);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>{t('Menu')}</IonListHeader>
          <IonNote>{ }</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? 'selected' : ''
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="labels-list">
          <IonListHeader>{t('Labels')}</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
