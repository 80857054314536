import { IonItem, IonLabel } from '@ionic/react';
import React from 'react';

interface SummaryRowProps {
  personalContactInfo?: string;
  value?: string
  confirmationFiles?: string;
}

const OnboardingFormSummaryRows: React.FC<SummaryRowProps> = (props) => {
  return (

    <IonItem lines='none'
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <IonLabel style={{ fontWeight: 600 }} className='ion-no-margin'>{props.personalContactInfo}:</IonLabel>
      <IonLabel className={'ion-no-margin'}>{props.value}</IonLabel>
    </IonItem>

  );
};

export default OnboardingFormSummaryRows;
