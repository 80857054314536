import { FormatIcu } from '@tolgee/format-icu';
import { I18nextPlugin, TolgeeInstance, withTolgee } from '@tolgee/i18next';
import { DevTools, Tolgee } from '@tolgee/react';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import sv_se from './lang/sv_se.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en_gb: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next'
    }
  },
  sv_se: {
    translation: sv_se
  }
};

const tolgee = Tolgee().use(DevTools()).use(I18nextPlugin()).init({
  apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
  apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
});

tolgee.addPlugin(FormatIcu());

withTolgee(i18n, tolgee)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    resources,
    lng: 'sv_se',
    supportedLngs: ['sv_se', 'en_gb'],
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
