export const buildQuery = (search: string, sort_by: string, direction: string, page: number, per_page: number, extra:Record<string, string | number> = {}): string => {
  const params = new URLSearchParams({
    search,
    sort_by,
    direction,
    page: page.toString(),
    per_page: per_page.toString(),
    ...extra
  });

  return params.toString();
};

export const buildOptionalQuery = (
  search?: string,
  sort_by?: string,
  page?: number,
  per_page?: number,
  extra: Record<string, string | number> = {}
): string => {
  const params = new URLSearchParams();

  const optionalParams: Array<[string, string | number | undefined | null]> = [
    ['search', search],
    ['sort_by', sort_by],
    ['page', page],
    ['per_page', per_page],
    ...Object.entries(extra).filter(([_key, value]) => value !== undefined),
  ];

  optionalParams.forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      params.set(key, value.toString());
    }
  });

  return params.toString();
};
