
import sha256 from 'js-sha256';
import { useMemo } from 'react';

import placeholderImg from '../assets/images/placeholder-user.png';
import { useAppSelector } from '../hooks';

export const getGravatarPicture = (email: string) => {
  const hash = sha256.sha256(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}.jpg`;
};
const useProfilePicture = () => {
  const profilePictureSrc = useAppSelector((state) => state.authentication.profile_picture);
  const user = useAppSelector((state) => state.authentication.user);
  const profilePicture = useMemo(() => {
    if (profilePictureSrc) {
      return profilePictureSrc;
    } else if (user?.email) {
      return getGravatarPicture(user.email);
    } else if (user && user.email) {
      return getGravatarPicture(user.email);
    }
    return placeholderImg;
  }, [profilePictureSrc]);

  return profilePicture;
};

export default useProfilePicture;
