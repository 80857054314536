import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';

export interface LoadingReducer {
  isLoading: {
    [name: string]: boolean
  };
}

export const loadingSlice = createSlice<LoadingReducer,
  SliceCaseReducers<LoadingReducer>,
  string>({
    name: 'loading',
    initialState: {
      isLoading: {}
    },
    reducers: {
      setIsLoading: (
        state: Draft<LoadingReducer>,
        action: PayloadAction<{name: string, value: boolean}>
      ) => {
        state.isLoading[action.payload.name] = action.payload.value;
      }
    }
  });

export const { setIsLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
