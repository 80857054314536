import { IonCol, IonGrid } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import React from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useFormContext } from 'react-hook-form';

import styles from './UserSettingsStyle.module.scss';
import { networking } from '../../../../api/networking';
import toasters from '../../../../components/Toasts/Toasts';
import StepButtons from '../../../../components/Toolbar/StepButtons';
import ListTitle from '../../../../components/UI/Titles/ListTitle';
import PasswordInputs from '../../../Registration/PasswordInputs';

const PasswordChange: React.FC = () => {
  const { t } = useTranslate();
  const methods = useFormContext();

  const toastMessages = {
    pending: t('Saving changes'),
    success: t('Your information has been updated.'),
    error: t('Something went wrong, try again later.')
  };

  const updateUserSettings: SubmitHandler<FieldValues> = (data: FieldValues) => {
    toasters.promise(networking.post(`/api/v1/users/reset_password/change`, data), toastMessages);
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(updateUserSettings)} className={styles['user-setting-form-container']}>
          <IonGrid className='ion-margin-start ion-margin-end'>
            <IonCol size='9' className='ion-margin-bottom'>
              <ListTitle label={t('Change password')} />
            </IonCol>
            <PasswordInputs />
            <StepButtons
              rightTitle={t('save')}
              rightIonColor={'secondary'}
              rightSubmit={'submit'}
              rightOnClick={() => { }}
              rightDisabled={(!methods.formState.isDirty && !methods.formState.isValid) || methods.formState.isSubmitting}
            />
          </IonGrid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default PasswordChange;
