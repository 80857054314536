import { useTranslate } from '@tolgee/react';

import TeamInviteForm from './TeamInviteForm';
import ListTitle from '../../../components/UI/Titles/ListTitle';
import type { TeamsPromises } from '../interfaces';

const TeamInvite: React.FC<TeamsPromises> = ({ fetchTeam }) => {
  const { t } = useTranslate();
  return (
    <section className='ion-no-padding'>
      <ListTitle label={t('Invite user')} />
      <TeamInviteForm fetchTeam={fetchTeam} />
    </section>
  );
};

export default TeamInvite;
