import { useTranslate } from '@tolgee/react';
import { Controller, useFormContext } from 'react-hook-form';

import { notice_default } from './defaults';
import type { NoticeLevelProps } from './form.interface';
import { BigUp } from '../../../components/UI';

const NoticeLevel: React.FC<NoticeLevelProps> = ({ defaultValue }) => {
  const { t } = useTranslate();
  const methods = useFormContext();

  const notice_filter_access = {
    data: notice_default.default_levels,
    label: t('Notice level'),
    register: 'level',
    validation: { required: t('This field is required') },
    itemProps: { className: 'ion-no-padding ion-margin-bottom' },
  };

  return (
    <Controller
      name='level'
      control={methods.control}
      defaultValue={defaultValue || 'info'}
      render={({ field }) => (
        <BigUp.Select
          {...notice_filter_access}
          selected={field.value}
          handleSelection={field.onChange}
        />
      )}
    />
  );
};

export default NoticeLevel;
