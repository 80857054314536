
import useNoticeBoardList from '../NoticeBoard/useNoticeBoardList';

const FlashNotice: React.FC = () => {
  const { notices } = useNoticeBoardList();
  // TODO: Get notice from the useFlashNotice hook
  /* const findFlashNotice = () => {
    return notices.find((item) => item.flash);
  }; */

  return (
    <>
      {/*  {findFlashNotice() && (
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem color={'danger'} >
                <IonLabel>
                  <h2 style={{ fontWeight: 600 }}>{findFlashNotice()?.subject}</h2>
                  <p style={{ fontSize: 16 }}>{findFlashNotice()?.message}</p>
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      )} */}
    </>
  );
};

export default FlashNotice;
