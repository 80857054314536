import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import {
  createSlice
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface ProductionBudgetReducer {
  productionBudget: E2U.V1.Models.ProductionBudget | undefined;
  selectedIndex: number;
}

export const productionBudgetSlice = createSlice<
  ProductionBudgetReducer,
  SliceCaseReducers<ProductionBudgetReducer>,
  string
>({
  name: 'productionBudget',
  initialState: {
    productionBudget: undefined,
    selectedIndex: 0
  },

  reducers: {
    setProductionBudget: (
      state: Draft<ProductionBudgetReducer>,
      action: PayloadAction<E2U.V1.Models.ProductionBudget | undefined>
    ) => {
      state.productionBudget = action.payload;
    }
  }
});

export const { setProductionBudget } = productionBudgetSlice.actions;

export default productionBudgetSlice.reducer;
