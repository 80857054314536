import type { E2U } from '@techlove/easy2use-typings';

import shareUrl from './shareUrl';
import { networking } from '../api/networking';
import toasters from '../components/Toasts/Toasts';
import i18n from '../i18n';

export const downloadAndShareFile = (file: E2U.V1.Models.File, action: 'share' | 'download', uuid: string | undefined) => {
  if (action === 'download' && file) {
    if (file.id === undefined) {
      const downloadLink = document.createElement('a');
      downloadLink.download = file.name;
      downloadLink.click();
      return;
    }

    networking.get(`/api/v1/files/${file.id}/share`)
      .then((response) => {
        window.location.assign(response.data.data.url);
      });
  } else if (action === 'share' && file) {
    shareUrl(
      undefined,
      `${i18n.t('Here is the link to the file, ')} ${file.name}: `,
      `${process.env.REACT_APP_SHARE_BASE_URL}/economy-tools/${uuid}/file/${file.id}`,
      '',
      'share'
    );
  } else {
    toasters.error(i18n.t('Could not perform action'));
  }
};
