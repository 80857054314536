import { IonLabel } from '@ionic/react';
import React from 'react';

import type { LabelProps } from './interface';
import styles from './Label.module.scss';

const Regular: React.FC<LabelProps> = ({ children, label, ...props }) => {
  return (
    <IonLabel {...props} style={{ fontSize: props.style?.fontSize }}>
      {label}
      {children}
    </IonLabel>
  );
};

const Thick: React.FC<LabelProps> = ({ children, label, ...props }) => {
  return (
    <IonLabel {...props}
      className={`${styles.thickLabel} ${props.className || 'ion-no-margin'}`}
      color={props.color || 'medium'}
    >
      {label}
      {children}
    </IonLabel>
  );
};
const Label = {
  Regular,
  Thick
};

export default Label;
