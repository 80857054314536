import type { E2U } from '@techlove/easy2use-typings';
import i18n from 'i18next';

import type { IonicInputProps } from '../../../../../../components/UI/Inputs/Input.interface';

export interface EditProps {
  onModalDismiss(): void;
  refreshCashFlow: (updateIndex?: boolean) => void;
  row: E2U.V1.Models.CashFlowRow | undefined;
}

interface InputProps {
  ionicProps?: IonicInputProps;
  label: string;
  disabled?: boolean;
  inputType: any;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  placeholder: string;
  register: keyof E2U.V1.Models.CashFlowRow;
  required: string | false;
  maxLength?: {
    value: number;
    message: string;
  };
  minLength?: {
    value: number;
    message: string;
  };
  min?: {
    value: number;
    message: string;
  };
  max?: {
    value: number;
    message: string;
  };
  pattern?: {
    value: RegExp;
    message: string;
  };
  validate?: (value: string) => string | boolean | undefined;
}

const CASH_FLOW_EDIT_LIST: InputProps[] = [
  {
    label: i18n.t('Proj. management'),
    placeholder: i18n.t('Enter proj. management'),
    register: 'project_management_amount',
    inputType: 'text',
    inputMode: 'numeric',
    required: 'Proj. management is required',
    min: { value: 0, message: i18n.t('Value is too small'), },
  },
  {
    label: i18n.t('Net cost'),
    inputType: 'text',
    inputMode: 'numeric',
    placeholder: i18n.t('Enter net cost'),
    register: 'net_cost_amount',
    required: 'Living area is required',
    min: { value: 0, message: i18n.t('Value is too small'), },
  },
  {
    label: i18n.t('Fee'),
    placeholder: i18n.t('Enter fee'),
    register: 'fee_amount',
    inputType: 'text',
    inputMode: 'numeric',
    required: 'Fee is required',
    min: { value: 0, message: i18n.t('Value is too small'), },
  },
];

export default CASH_FLOW_EDIT_LIST;
