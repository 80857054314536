import type { E2U } from '@techlove/easy2use-typings';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import type { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import moment from 'moment';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { networking, setAxiosAccessToken } from '../../api/networking';
import storage from '../../storage';

export const NetworkInterceptor = () => {
  const history = useHistory();
  const location = useLocation();

  const refreshAuthLogic: (failedRequest: any) => Promise<void> = async (failedRequest) => {
    return storage.get('refresh_token').then(
      async (refreshToken: string) => {
        if (refreshToken === null) return Promise.reject(new Error());

        try {
          const result = await networking.post(
            '/oauth/token', {
              grant_type: 'refresh_token',
              refresh_token: refreshToken,
              client_id: process.env.REACT_APP_API_CLIENT_ID,
              client_secret: process.env.REACT_APP_API_CLIENT_SECRET
            } as E2U.V1.Requests.Oauth2.RefreshToken, { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig
          );
          const promises: Promise<any>[] = [];
          promises.push(storage.set('access_token', result.data.access_token));
          promises.push(storage.set('refresh_token', result.data.refresh_token));
          promises.push(storage.set('access_token_expires_at', moment().add(result.data.expires_in, 'seconds').toISOString()));
          setAxiosAccessToken(result.data.access_token);

          return await (Promise.resolve());
        } catch (err) {
          return await Promise.reject(new Error());
        }
      }
    ).catch((err) => {
      return Promise.reject(err);
    });
  };
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response && error.response.status === 401) {
        try {
          if (error.response.config.url === '/oauth/token') {
            return Promise.reject(error);
          }
          await refreshAuthLogic(error.response.config);
          error.response.config.headers.Authorization = 'Bearer ' + storage.get('access_token');
          return await axios(error.response.config);
        } catch (err) {
          console.error('Cant get refresh token', location.pathname);
          return await Promise.reject(err);
        }
      } else {
        return Promise.reject(error);
      }
    }
  );

  axios.interceptors.request.use((request) => {
    return new Promise<AxiosRequestConfig>((resolve, reject) => {
      storage.get('access_token')
        .then(
          (accessToken: string) => {
            if (request.headers) {
              request.headers.Authorization = `Bearer ${accessToken}`;
            } else {
              request.headers = {
                Authorization: `Bearer ${accessToken}`
              };
            }
            resolve(request);
          }
        )
        .catch(
          (err) => {
            console.error('Cant get access token', location.pathname);
            return reject(err);
          }
        );
    });
  });

  createAuthRefreshInterceptor(axios, refreshAuthLogic);

  return <React.Fragment />;
};
