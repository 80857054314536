import { IonCol, IonGrid, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import type { FieldValues } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import type { InputProps } from '../../../components/UI/Inputs/Input.interface';
import type { AddTagProps } from '../interfaces';
import Toast from '../Toast';

const AddNewTag: React.FC<AddTagProps> = ({ getTags }) => {
  const { t } = useTranslate();
  const { tag_category_id } = useParams<{ tag_category_id: string }>();
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: ''
    }
  });

  const addNewTag = (data: FieldValues) => {
    networking.post(`/api/v1/tags`, { ...data, color: '#5d7292', category_id: tag_category_id })
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Tag>) => {
        networking.post(`/api/v1/tag_categories/${tag_category_id}/tags/${response.data.data.id}`, data)
          .then(() => {
            getTags();
            methods.reset();
          })
          .catch((error) => {
            Sentry.captureException(error);
            Toast(t('Could not add {{tag}}', {
              tag: data.name
            }), 'error');
          });
      });
  };

  const inputProps: InputProps<E2U.V1.Models.Tag> = {
    label: t('Tag name'),
    placeholder: t('Add new tag'),
    register: 'name',
    slot: 'start',
    validation: {
      required: t('Tag name is required'),
      min: { value: 3, message: t('Tag name must be at least 3 characters long') },
      max: { value: 20, message: t('Tag name must be at most 20 characters long') }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(addNewTag)}>
        <IonGrid className='ion-padding ion-margin-bottom'>
          <IonRow>
            <IonCol size='9' >
              <BigUp.Input {...inputProps} />
            </IonCol>
            <IonCol>
              <BigUp.Buttons.Regular
                title={t('Save')}
                type='submit'
                disabled={!methods.formState.isValid || methods.formState.isSubmitting}
                color={'secondary'}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    </FormProvider>
  );
};

export default AddNewTag;
