import {
  IonButton,
  IonCard,
  IonCol,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonRow
} from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { add, arrowBack, close, pencil, search } from 'ionicons/icons';
import React, { useState } from 'react';
import { useStore } from 'react-redux';

import IncidentReports from './IncidentReports';
import ObservationReports from './ObvervationReports';
import ButtonWide from '../../../components/ButtonWide';
import ReportsCard from '../../../components/ReportsCard';
import { useAppSelector } from '../../../hooks';
import { setReportsView } from '../../../reducers/project';
import styles from '../ReportsPage/ReportsPage.module.scss';

const ProjectReportsPage: React.FC = () => {
  const { t } = useTranslate();
  const store = useStore();
  const currentView: string = useAppSelector(
    (state) => state.project.reportsView
  );

  const [newObservation, setNewObservation] = useState(false);

  const changeReportView = (view: string) => {
    store.dispatch(setReportsView(view));
  };

  const navigationOptions = [
    { key: 'observations', title: t('Observations') },
    { key: 'incidents', title: t('Incidents Reports') },
    { key: 'inspections', title: t('Protection Round') }
  ];

  return (
    <React.Fragment>
      {newObservation
        ? (
          <IonFab vertical='bottom' horizontal='end' slot='fixed'>
            <IonFabButton
              onClick={() => {
                setNewObservation(false);
              }}
            >
              <IonIcon icon={close} />
            </IonFabButton>
          </IonFab>
        )
        : (
          <React.Fragment>
            <ReportsCard
              title={t('Reports')}
              iconRight={pencil}
              iconLeft={arrowBack}
            >
              <IonItemGroup className={styles['report-navigation']}>
                <IonGrid>
                  <IonRow>
                    {navigationOptions.map((item, i) => {
                      return (
                        <IonCol size='4' key={i}>
                          <IonItem
                            detail={false}
                            button
                            onClick={() => changeReportView(item.key)}
                            {...(currentView === item.key
                              ? {
                                lines: 'full',
                                className: styles.active
                              }
                              : {
                                lines: 'none',
                                className: styles['not-active']
                              })}
                          >
                            <IonLabel
                              className={classNames(
                                ['ion-text-center'],
                                ['ion-text-wrap'],
                                styles['navigation-text']
                              )}
                            >
                              {item.title}
                            </IonLabel>
                          </IonItem>
                        </IonCol>
                      );
                    })}
                  </IonRow>
                </IonGrid>
              </IonItemGroup>

              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonItem
                      detail={false}
                      lines='none'
                      className={styles['input-container']}
                      color='light'
                    >
                      <IonLabel slot='start'>{t('Tags')}</IonLabel>

                      <IonInput placeholder={t('Search list')} />

                      <IonIcon slot='end' icon={search}></IonIcon>
                    </IonItem>
                    <IonButton
                      className={styles['reports-button']}
                      fill='outline'
                    >
                      Construction site 2
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className={styles['sort-reports']}>
                <IonRow className='ion-justify-content-space-between'>
                  <IonCol>
                    <span>{t('Name')}</span>
                  </IonCol>
                  <IonCol
                    className={classNames(
                      styles['files-position'],

                      'ion-text-end'
                    )}
                  >
                    <span>{t('Images')}</span>
                    <span>{t('Docs')}</span>
                  </IonCol>
                </IonRow>
              </IonGrid>

              {currentView === 'observations'
                ? (
                  <ObservationReports />
                )
                : currentView === 'incidents'
                  ? (
                    <IncidentReports />
                  )
                  : (
                    <IonCard>{/* PROTECTION ROUND COMPONENT GOES HERE */}</IonCard>
                  )}
            </ReportsCard>

            {!newObservation && <ButtonWide title={t('Filter')}></ButtonWide>}
            <IonFab vertical='bottom' horizontal='end' slot='fixed'>
              <IonFabButton onClick={() => setNewObservation(true)}>
                <IonIcon icon={add} />
              </IonFabButton>
            </IonFab>
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

export default ProjectReportsPage;
