import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow
} from '@ionic/react';
import React from 'react';

import styles from './ButtonWide.module.scss';

interface typeButtonTitle {
  title: string;
}
const ButtonWide: React.FC<typeButtonTitle> = ({ title }) => {
  return (
    <>
      <IonButton expand="block" color="primary">
        {title}
      </IonButton>
    </>
  );
};
export default ButtonWide;
