import { createSlice } from '@reduxjs/toolkit';
import type { Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface ProjectReducer {
  projects: E2U.V1.Models.Project[] | undefined;
  selectedProject: E2U.V1.Models.Project | undefined;
  projectPaginationData: E2U.V1.Objects.PaginationProperties | undefined;
  reportsView: string;
  observationReports: E2U.V1.Models.ObservationReport[] | undefined;
  incidentReports: E2U.V1.Models.IncidentReport[] | undefined;
  reloadProjects: boolean;
  shouldHideMenu: boolean;
}

export const projectSlice = createSlice<ProjectReducer,
  SliceCaseReducers<ProjectReducer>,
  string>({
    name: 'project',
    initialState: {
      projects: undefined,
      selectedProject: undefined,
      projectPaginationData: undefined,
      reportsView: 'observations',
      observationReports: undefined,
      incidentReports: undefined,
      reloadProjects: false,
      shouldHideMenu: false,
    },
    reducers: {
      reloadProjects: (
        state: Draft<ProjectReducer>,
        action: PayloadAction<undefined>
      ) => {
        state.reloadProjects = !state.reloadProjects;
      },
      setProjects: (
        state: Draft<ProjectReducer>,
        action: PayloadAction<E2U.V1.Models.Project[] | undefined>
      ) => {
        state.projects = action.payload;
      },
      setSelectedProject: (
        state: Draft<ProjectReducer>,
        action: PayloadAction<E2U.V1.Models.Project | undefined>
      ) => {
        state.selectedProject = action.payload;
      },
      setProjectsPaginationData: (
        state: Draft<ProjectReducer>,
        action: PayloadAction<E2U.V1.Objects.PaginationProperties | undefined>
      ) => {
        state.projectPaginationData = action.payload;
      },
      setReportsView: (
        state: Draft<ProjectReducer>,
        action: PayloadAction<string>
      ) => {
        state.reportsView = action.payload;
      },
      setObservationReports: (
        state: Draft<ProjectReducer>,
        action: PayloadAction<E2U.V1.Models.ObservationReport[] | undefined>
      ) => {
        state.observationReports = action.payload;
      },
      setIncidentReports: (
        state: Draft<ProjectReducer>,
        action: PayloadAction<E2U.V1.Models.IncidentReport[] | undefined>
      ) => {
        state.incidentReports = action.payload;
      },
      setShouldHideMenu: (
        state: Draft<ProjectReducer>,
        action: PayloadAction<boolean>
      ) => {
        state.shouldHideMenu = action.payload;
      }
    }
  });

export const {
  reloadProjects,
  setIncidentReports,
  setObservationReports,
  setProjects,
  setProjectsPaginationData,
  setReportsView,
  setSelectedProject,
  setShouldHideMenu
} = projectSlice.actions;

export default projectSlice.reducer;
