import { IonList } from '@ionic/react';

import styles from './RegisterSteps.module.scss';

interface ActiveStep {
  registerActive: boolean;
  userPhotoActive: boolean;
  roleSelectActive: boolean;
  functionSelectActive: boolean;
}

const RegisterSteps: React.FC<ActiveStep> = (props) => {
  return (
    <section className={styles['register-steps']}>
      <IonList className={styles['steps-list']}>
        <span className={props.registerActive ? styles.active : ''}>1</span>
        <span className={props.userPhotoActive ? styles.active : ''}>2</span>
        <span className={props.roleSelectActive ? styles.active : ''}>3</span>
        <span className={props.functionSelectActive ? styles.active : ''}>
          4
        </span>
      </IonList>
    </section>
  );
};

export default RegisterSteps;
