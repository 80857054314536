import { IonButton, IonContent, IonHeader, IonImg, IonText } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import React from 'react';

import styles from './styles/OnboardingFinished.module.scss';
import image from '../../../../assets/images/gifs/Done.gif';
import Curve from '../containers/CurveTop';

interface OnboardingFinishProps {
  toggle: () => void
}

const OnboardingFinish: React.FC<OnboardingFinishProps> = (props) => {
  const { t } = useTranslate();

  return (
    <>
      <IonHeader className={classNames(styles['finished-header'], 'ion-no-border')} />
      <Curve>
        <IonContent className={classNames(styles['finish-content'])}>
          <div className={styles['finish-container']}>
            <IonText>
              <h3>{t('Finished!')}</h3>
              <h5>{t("You've completed tour onboarding and the form has now been sent to the administration for control")}</h5>
            </IonText>
            <div className={styles['finish-gif']}>
              <IonImg src={image} />
            </div>
            <div className={styles['finish-bottom-container']}>
              <div className={styles['bottom-btn-container']}>
                <IonButton color="onboarding" routerLink='/project-tools' onClick={() => props.toggle()}>
                  {t('finish')}
                </IonButton>
              </div>
            </div>
          </div>
        </IonContent>
      </Curve>
    </>
  );
};
export default OnboardingFinish;
