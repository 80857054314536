import type { E2U } from '@techlove/easy2use-typings';
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

import type { ProjectWithPermissions } from '../components/UI/Permissions/PermissionsModal';
import { useAppSelector } from '../hooks';
import type { Permissions } from '../pages/Teams/interfaces';

const usePermissionHandler = () => {
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const projects = useAppSelector((state) => state.project.projects);
  const history = useHistory();
  const location = useLocation();

  const mapPermissionLevel = (accessLevel: Permissions | 'none') => {
    switch (accessLevel) {
      case 'admin':
        return { admin: true, write: true, read: true };
      case 'write':
        return { admin: false, write: true, read: true };
      case 'read':
        return { admin: false, write: false, read: true };
      case 'none':
        return { admin: false, write: false, read: false };
      default:
        return { admin: false, write: false, read: false };
    }
  };

  const checkPermission = (
    accessType: 'economy_tools_access' | 'project_tools_access',
    permission: Permissions,
    project: E2U.V1.Models.Project | any | undefined = undefined
  ) => {
    const projectToCheck = project || selectedProject as keyof ProjectWithPermissions['project_tools_access' | 'economy_tools_access'];
    const accessLevel = typeof projectToCheck !== 'undefined' && (projectToCheck[accessType]);
    return mapPermissionLevel(accessLevel as Permissions)[permission];
  };

  const checkPermissionsForProjectAndTools = useMemo(() => {
    return (projectTools: Permissions, economyTools: Permissions) => {
      const isProjectTools = location.pathname.includes('project-tools');
      const isEconomyTools = location.pathname.includes('economy-tools');

      return projects?.filter((project) => {
        return isProjectTools
          ? checkPermission('project_tools_access', projectTools, project)
          : (
            isEconomyTools
              ? checkPermission('economy_tools_access', economyTools, project)
              : (
                checkPermission('project_tools_access', projectTools, project) ||
                checkPermission('economy_tools_access', economyTools, project)
              )
          );
      });
    };
  }, [location.pathname, projects]);

  useEffect(() => {
    const isProjectTools = location.pathname.includes('project-tools');
    const isEconomyTools = location.pathname.includes('economy-tools');

    if (isProjectTools && isEconomyTools) {
      const newPath = location.pathname.replace('/project-tools', '');
      history.push(newPath);
    }
  }, [location.pathname, history]);

  return { checkPermission, checkPermissionsForProjectAndTools };
};

export default usePermissionHandler;
