import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import moment from 'moment';
import { useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router';

import { networking, setAxiosAccessToken } from '../api/networking';
import { setUser } from '../reducers/authentication';
import storage from '../storage';
import store from '../store';

const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  const handleLogin: SubmitHandler<FieldValues> = (data, event) => {
    event?.preventDefault();
    event?.stopPropagation();
    setLoading(true);

    networking.post('/oauth/token', {
      grant_type: 'password',
      client_id: process.env.REACT_APP_API_CLIENT_ID,
      client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
      username: data.username,
      password: data.password
    } as E2U.V1.Requests.Oauth2.PasswordToken)
      .then((result: E2U.V1.Response.Oauth2.TokenSuccess) => {
        const promises: Promise<any>[] = [];
        promises.push(storage.set('access_token', result.data.access_token));
        promises.push(storage.set('refresh_token', result.data.refresh_token));
        promises.push(storage.set('access_token_expires_at', moment().add(result.data.expires_in, 'seconds').toISOString()));

        Promise.all(promises)
          .then(() => {
            setAxiosAccessToken(result.data.access_token);
            networking.post(`/api/v1/user`, {})
              .then((result: E2U.V1.Response.Success<E2U.V1.Models.User>) => {
                store.dispatch(setUser(result.data.data));
                history.push('/');
              })
              .catch((error) => {
                const errorData = error.response?.data.data;
                if (errorData) {
                  setResult(errorData.message);
                }
                Sentry.captureException(error);
              });
          });
      })
      .catch((error: E2U.V1.Response.Error) => {
        const errorData = error.response?.data.message;
        if (errorData) {
          setResult(errorData);
        }
        setError(error?.message);
        Sentry.captureException(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBankIdLogin = async (token: any) => {
    storage.set('access_token', token.access_token);
    storage.set('refresh_token', token.refresh_token);
    storage.set('access_token_expires_at', moment().add(token.expires_in, 'seconds').toISOString());
    setAxiosAccessToken(token.access_token);
  };

  const clearStorage = () => {
    storage.clear();
  };

  return {
    loading,
    error,
    handleLogin,
    result,
    handleBankIdLogin,
    clearStorage
  };
};

export default useLogin;
