import type { ItemReorderEventDetail } from '@ionic/react';
import { IonItem, IonLabel, IonList, IonReorder, IonReorderGroup } from '@ionic/react';
import type { ComponentProps } from 'react';
import React, { useEffect, useState } from 'react';

interface Item {
  [key: string]: any;
}

interface ReorderableListProps {
  reorderItems: Item[];
  labelKey: string;
  storageKey: string;
  ionItemProps?: ComponentProps<typeof IonItem>;
}
/*
  Example usage:
  const reorderItems = [
    { item: 'Item 1', id: 'TEST1' },
    { item: 'Item 2', id: 'TEST2' },
    { item: 'Item 3', id: 'TEST3' },
    { item: 'Item 4', id: 'TEST4' },
  ];
  <ReorderableList reorderItems={reorderItems} labelKey='id' storageKey='itemOrder' />
*/

const ReorderableList: React.FC<ReorderableListProps> = (props) => {
  const [items, setItems] = useState<Item[]>(() => {
    const storedItems = localStorage.getItem(props.storageKey);
    return storedItems ? JSON.parse(storedItems) : props.reorderItems;
  });

  const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    const reorderedItems = event.detail.complete(items);
    setItems(reorderedItems);
  };

  useEffect(() => {
    localStorage.setItem(props.storageKey, JSON.stringify(items));
  }, [items]);

  return (
    <IonList>
      <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
        {items.map((item, index) => (
          <IonItem key={index} {...props.ionItemProps}>
            <IonLabel>{item[props.labelKey]}</IonLabel>
            <IonReorder slot="end" />
          </IonItem>
        ))}
      </IonReorderGroup>
    </IonList>
  );
};

export default ReorderableList;
