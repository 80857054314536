import { IonCard, IonCol, IonGrid, IonIcon, IonRow, useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { close, fileTray } from 'ionicons/icons';
import React from 'react';

interface UploadFileCardInterface {
  deleteFile(file: Partial<E2U.V1.Models.File>): void;
  file: E2U.V1.Models.File;
  document_name: any;
}
const UploadFileCard: React.FC<UploadFileCardInterface> = (props) => {
  const [presentAlert] = useIonAlert();
  const { t } = useTranslate();

  const triggerDeleteFile = (file: Partial<E2U.V1.Models.File>) => {
    presentAlert({
      header: t('Are you sure you want to delete this file?'),
      message: `${file.filename}`,
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel'
        },
        {
          text: t('Confirm'),
          role: 'confirm',
          handler: () => {
            if (props.deleteFile) {
              props.deleteFile(file);
            }
          }
        }
      ]
    });
  };

  return (
    <IonCard color={'light'}>
      <IonGrid>
        <IonRow className='ion-align-items-center '>
          <IonCol size='2' className='ion-text-center'>
            <IonIcon icon={fileTray} size='large' color={'medium'} />
          </IonCol>
          <IonCol size='8' className='ion-text-left'>
            <p className='ion-no-margin'>
              {typeof props.document_name === 'string' ? props.document_name : props.file.filename}
            </p>
          </IonCol>
          <IonCol size='2' className='ion-text-center'>
            <IonIcon
              color={'tertiary'}
              size='large'
              icon={close}
              onClick={() => triggerDeleteFile(props.file)}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>

  );
};
export default UploadFileCard;
