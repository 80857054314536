export const emptyListMessages = {
  amendments: {
    empty_title: 'No {{type}} could be found',
    empty_message: 'No {{type}} have been added to this project. Select your areas to get started.'
  },
  cash_flow: {
    empty_title: 'No cash flow generated yet.',
    empty_message: 'Go to production budget and lock the project to calculate cash flow.'
  },
  economy_monitoring: {
    empty_title: 'No economy monitoring could be found',
    empty_message: `No economy monitoring have been added to this project. Generate one to get started.`
  },
  payment_schedule: {
    empty_title: 'No payment schedule',
    empty_message: 'No payment schedule has been added to this project. Create a cash flow to continue.'
  },
  precalculation_rows: {
    empty_title: '',
    empty_message: ''
  },
  control_of_execution: {
    empty_title: 'No controls',
    empty_message: 'No controls have been added to this project. Add a control to get started.'
  },
  site_access_requests: {
    empty_title: 'No {{type}} requests could be found',
    empty_message: 'No {{type}} requests have been submitted to this project.'
  },
  working_environment: {
    empty_title: 'No working environments found',
    empty_message: 'No working environments have been added to this project. Add one to get started.'
  },
  working_environment_list: {
    empty_title: 'No working environments found',
    empty_message: 'No working environments have been added to this project. Add one to get started.'
  }
};
