/**
  * A custom hook to focus the next input element or submit the form when the last input is focused.
  * @param formRef A reference to the form element.
  * @returns A function to focus the next input or submit the form.
  *
  * @example
  * ```typescript
  * // Example usage in an input element's event handler
  *
  * const focusNextInput = useFocusNextInput(formRef);
  *
  * const handleNextInput = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
  *  if (e.key === 'Enter') {
  *    focusNextInput(e.target as HTMLInputElement);
  *   }
  * };
  * <form className='ion-padding' onSubmit={methods.handleSubmit(login.handleLogin)} ref={formRef}>
  *   <BigUp.Input {...inputProps} onKeyUp={handleNextInput} />
  * </form>
  * ```
 */

import { useRef } from 'react';

const useFocusNextInput = () => {
  const formRef = useRef<HTMLFormElement | null>(null);

  const focusNextInput = (currentInput: HTMLInputElement) => {
    const form = formRef.current;
    if (form) {
      const inputs = Array.from(form.elements) as HTMLInputElement[];
      const currentInputIndex = inputs.indexOf(currentInput);
      const nextInput = inputs[currentInputIndex + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  const handleNextInput = (e: React.KeyboardEvent<HTMLIonInputElement | HTMLIonTextareaElement>) => {
    if (e.key === 'Enter') {
      focusNextInput(e.target as HTMLInputElement);
    }
  };
  return { formRef, focusNextInput, handleNextInput };
};

export default useFocusNextInput;
