import { IonCol, IonGrid, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import BigUp from '../../../components/UI';
import type { InputProps } from '../../../components/UI/Inputs/Input.interface';
import type { TagCategoryEditProps } from '../interfaces';

const TagCategoryEdit: React.FC<TagCategoryEditProps> = ({ category, isEditing, onCancel, onDelete, onSave, setIsEditing }) => {
  const { t } = useTranslate();
  const methods = useForm<E2U.V1.Models.TagCategory>({
    mode: 'onBlur',
    defaultValues: {
      name: category.name,
      tags: category.tags
    }
  });

  const inputProps: InputProps<E2U.V1.Models.TagCategory> = {
    label: t('Tag category'),
    register: 'name',
    validation: {
      required: t('Tag name is required'),
      min: {
        value: 3,
        message: t('Tag name must be at least 3 characters long'),
      },
      max: {
        value: 25,
        message: t('Tag name must be at most 25 characters long'),
      },
    }
  };

  const name = methods.watch('name');

  useEffect(() => {
    if (category.name !== name) {
      methods.setValue('name', category.name);
    }
  }, [category]);

  return (

    <IonGrid className='ion-padding'>
      <IonRow className='ion-align-items-center ion-margin-bottom'>
        {isEditing
          ? (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSave)} style={{ width: '100%' }}>
                <IonRow>
                  <IonCol size='9'>
                    <BigUp.Input {...inputProps} value={name} />
                  </IonCol>
                  <IonCol size='3' className='ion-text-center'>
                    <BigUp.Buttons.Regular color='secondary' title={t('Save')} type='submit' onClick={() => setIsEditing(false)} />
                  </IonCol>
                </IonRow>
              </form>
            </FormProvider>
          )
          : (
            <>
              <IonCol size='12'>
                <BigUp.Title label={t('Tags for {{category_name}}', { category_name: category.name })} style={{ paddingTop: '40px', fontWeight: 800 }} />
              </IonCol>
              <IonCol size='12' className='ion-text-end'>
                <BigUp.Buttons.Regular color='light' title={t('Edit')} onClick={() => setIsEditing(true)} />
              </IonCol>
            </>
          )}
      </IonRow>
      {isEditing && (
        <IonRow className='ion-justify-content-between'>
          <IonCol size='4' className='ion-text-start'>
            <BigUp.Buttons.Regular color='danger' title={t('Delete')} onClick={onDelete} />
          </IonCol>
          <IonCol size='4' className='ion-text-end'>
            <BigUp.Buttons.Regular color='light' title={t('Cancel')} onClick={onCancel} />
          </IonCol>
        </IonRow>
      )}
    </IonGrid>

  );
};

export default TagCategoryEdit;
