import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import type { EntitySelectProps } from './form.interface';
import { networking } from '../../../api/networking';
import RelatedMultiSelectEdit from '../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import type { SelectListEntities } from '../../../components/Search/RelatedMultiSelect/MultiSelectList';
import { useAppSelector } from '../../../hooks';

const EntitySelect: React.FC<EntitySelectProps> = ({ label, modalTitle, model }) => {
  const [selection, setSelection] = useState<E2U.V1.Models.Team[] | E2U.V1.Models.Project[] | SelectListEntities[]>([]);
  const [users, setUsers] = useState<E2U.V1.Models.User[]>([]);
  const [allTeams, setAllTeams] = useState<E2U.V1.Models.Team[] | undefined>(undefined);

  const entities = model === 'teams' ? allTeams : users;
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const methods = useFormContext();
  const project = useAppSelector(state => state.project.selectedProject);

  const handleSelection = (data: (E2U.V1.Models.Team | E2U.V1.Models.Project | SelectListEntities)[]) => {
    setSelection(data as SelectListEntities[]);
    methods.setValue(model, data.filter((d): d is E2U.V1.Models.Team | E2U.V1.Models.Project => 'id' in d).map(d => d.id));
  };

  const fetchProjectsTeams = () => {
    networking.get(`/api/v1/projects/${project?.id}/teams`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Team>>) => {
        setAllTeams(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const fetchAllUsers = () => {
    networking.get('/api/v1/users?per_page=9999')
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.User>>) => {
        setUsers(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    if (model === 'users') {
      fetchAllUsers();
    }
    if (model === 'teams' && project) {
      fetchProjectsTeams();
    }
  }, [model, project?.id]);

  return (
    <>
      <RelatedMultiSelectEdit
        {...isDesktop && { button: 'solid' }}
        records={entities}
        model={model}
        label={label}
        action='submit'
        value={entities}
        modalTitle={modalTitle}
        onChange={() => { }}
        postSelected={(data: SelectListEntities[]) => handleSelection(data)}
        displayFields={
          model === 'teams'
            ? ['name']
            : ['first_name', 'last_name']
        }
        hideSelected
      />
      <IonGrid style={{ maxHeight: 250, overflow: 'scroll' }} className='ion-margin-vertical'>
        {entities?.map((e: any) => {
          return (
            <IonRow key={e.id}>
              <IonCol size='12'>
                <IonItem>
                  <IonLabel className='ion-text-nowrap ion-no-margin'>
                    {e.name || e.first_name + ' ' + e.last_name}
                  </IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
          );
        })}
      </IonGrid>

    </>
  );
};

export default EntitySelect;
