import { IonButton, IonCol, IonGrid, IonItem, IonLabel, IonRow, IonText } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { documentAttach, fileTrayStackedSharp, image, personSharp } from 'ionicons/icons';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { networking } from '../../../../../api/networking';
import Easy2Use from '../../../../../components/UI';
import EmptyList from '../../../../../components/UI/EmptyList';
import ItemShadowNoIcons from '../../../../../components/UI/Items/components/ItemShadowNoIcons';
import { customBorder } from '../../../../../components/UI/variables';
import { useAppSelector } from '../../../../../hooks';
import type { Detail } from '../Record';

const DetailModal: React.FC<Detail> = ({ booleans, fetchRecords, onAttested, onClose, record }) => {
  const [attestRecord, setAttestRecord] = useState<boolean | undefined>(record?.has_been_attested);
  const { t } = useTranslate();
  const user = useAppSelector((state) => state.authentication.user);

  const {
    formState: { errors },
  } = useForm<E2U.V1.Models.ControlOfExecution>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: record
  });

  const submitAttest = (attest = false) => {
    setAttestRecord(!attestRecord);
    networking.post(`/api/v1/control_of_executions/${record?.id}/${attest ? 'unattest' : 'attest'}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.ControlOfExecution>) => {
        if (onAttested) {
          onAttested();
        }
      })
      .finally(() => {
        fetchRecords();
      });
  };

  const downloadFile = (file: E2U.V1.Models.File) => {
    networking.get(`/api/v1/files/${file.id}/share`)
      .then((response) => {
        window.location.assign(response.data.data.url);
      });
  };

  return (
    <React.Fragment>
      <IonGrid>
        <IonRow>
          <IonCol size='12' className='ion-no-padding'>
            <IonItem className='ion-no-padding'>
              <Easy2Use.Label.Thick label={record?.activity_code?.code}>
                <p>{record?.activity_code?.name}</p>
              </Easy2Use.Label.Thick>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            size='12'
            className='ion-text-left ion-no-margin ion-margin-top ion-no-padding'>
            <IonItem className='ion-no-padding'>
              <Easy2Use.Label.Thick className='ion-no-margin' label={t('Title')} />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            size='12'
            className='ion-text-left ion-no-padding'>
            <IonText>
              <span style={{ fontSize: '16px' }}>
                {record?.name}
              </span>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            size='12'
            className='ion-text-left ion-no-margin ion-margin-top ion-no-padding'>
            <IonItem className='ion-no-padding'>
              <Easy2Use.Label.Thick className='ion-no-margin' label={t('Comments')} />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            size='12'
            className='ion-text-left  ion-no-padding'>
            <IonText>
              <span style={{ fontSize: '16px' }}>
                {record?.description}
              </span>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='12' className='ion-margin-top'>
            {record?.requires_supervisor
              ? <IonCol size='12' className='ion-no-padding'>
                <IonItem className='ion-no-padding'>
                  <IonLabel className='ion-no-margin' color={'danger'} style={{ fontWeight: 800 }}>
                    * {t('Needs supervisor approval')}
                  </IonLabel>
                </IonItem>
              </IonCol>
              : <React.Fragment>
                <IonCol size='12' className='ion-no-padding ion-margin-top' >
                  <IonItem className='ion-no-padding'>
                    <Easy2Use.Label.Thick label={t('Assignees')} />
                  </IonItem>
                </IonCol>
                {record?.assigned_user_id === null &&
                  <EmptyList
                    message={<Easy2Use.Label.Thick label={t('No assignees')} />}
                    title={''}
                    icon={{
                      icon: personSharp,
                      size: 60
                    }}
                  />
                }
                {record?.assignees?.map((assignee) => {
                  const recordCreatedAt = moment(record.created_at).format('YYYY-MM-DD');
                  return (
                    <ItemShadowNoIcons
                      key={assignee.id}
                      label={assignee.name}
                      description={`${assignee?.first_name} ${assignee?.last_name.charAt(0)}.`}
                      subLabel={recordCreatedAt || record.created_at}
                      hasBorder={true}
                      borderColour={
                        assignee.pivot.status === 'attested' || !record.has_been_attested
                          ? customBorder.borderColourGreen
                          : customBorder.borderColourGray
                      }
                    />
                  );
                })}
              </React.Fragment>
            }
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='12' className='ion-no-padding ion-margin-top'>
            <IonItem className='ion-no-padding'>
              <Easy2Use.Label.Thick label={t('Images & files')} />
            </IonItem>
          </IonCol>
        </IonRow>
        {record?.files?.length !== undefined &&
          record.files.length > 0
          ? record?.files?.map((doc) => {
            return (
              <IonRow key={doc.id}>
                <IonCol size='12' >
                  <ItemShadowNoIcons
                    hasIconLeft={true}
                    iconLeft={
                      doc.type === 'image'
                        ? image
                        : documentAttach
                    }
                    label={
                      doc.type === 'image'
                        ? t('Image')
                        : t('File')
                    }
                    description={doc.name}
                    subLabel={''}
                    hasBorder={false}
                    onItemClick={() => downloadFile(doc)}
                    backgroundLeft={
                      doc.type === 'image'
                        ? `${doc.export_url}?base64=true`
                        : undefined
                    }
                  />
                </IonCol>
              </IonRow>
            );
          })
          : (
            <EmptyList
              message={<Easy2Use.Label.Thick label={t('No images or files uploaded')} />}
              title={''}
              icon={{ icon: fileTrayStackedSharp, size: 60 }}
            />
          )
        }
        {(user &&
          record &&
          !record.requires_supervisor &&
          typeof record.assignees !== 'undefined' && record.assignees?.find(a => a.id === user.id)) &&
          <IonRow key={record.id}>
            <IonCol size='12' className='ion-text-center ion-margin-bottom ion-margin-top'>
              <IonButton type='button'
                shape='round'
                size='large'
                color={
                  !record.assignees?.find((assignee: any) => assignee.id === user.id &&
                    assignee.pivot.status === 'attested')
                    ? 'danger'
                    : 'success'
                }
                style={{ height: '100px', width: '100px' }}
                onClick={() => submitAttest(record.has_been_attested === true)}>
                {!record.assignees?.find(
                  (assignee: any) => assignee.id === user.id &&
                    assignee.pivot.status === 'attested'
                )
                  ? t('Attest')
                  : t('Unattest')}
              </IonButton>
            </IonCol>
          </IonRow>
        }

      </IonGrid>
    </React.Fragment>
  );
};

export default DetailModal;
