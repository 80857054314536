import { IonCol, IonGrid, IonList, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import TeamActionRow from './Teams/TeamActionRow';
import TeamList from './Teams/TeamList';
import styles from './Teams/Teams.module.scss';
import { networking } from '../../api/networking';
import PaginateData from '../../components/Pagination/PaginationData';
import SkeletonItem from '../../components/SkeletonComponents/SkeletonItem';
import ListTitle from '../../components/UI/Titles/ListTitle';
import { useAppSelector } from '../../hooks';
import { setIsLoading } from '../../reducers/loading';
import { setSelectedTeam, setTeams, setTeamsPaginationData } from '../../reducers/team';
import store from '../../store';

const Teams: React.FC = () => {
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [allTeams, setAllTeams] = useState<E2U.V1.Models.Team[] | undefined>(undefined);
  const isLoadingTeams: boolean = useAppSelector((state) => state.loading.isLoading.teams);
  const { uuid } = useParams<{ uuid: string }>();

  const handlePagination = (step: number) => {
    setCurrentPage(currentPage + step);
  };

  const searchParameters = new URLSearchParams();
  searchParameters.append('page', currentPage.toString());
  searchParameters.append('total_pages', totalPages?.toString() || '');
  searchParameters.append('per_page', '10');

  const fetchTeams = async () => {
    try {
      if (uuid) {
        store.dispatch(setIsLoading({ name: 'teams', value: true }));
        const response = await networking.get(`/api/v1/projects/${uuid}/teams?${searchParameters.toString()}`);
        store.dispatch(setTeams(response.data.data.records));
        store.dispatch(setTeamsPaginationData(response.data.data));
        setTotalPages(response.data.data.total_pages);
        setCurrentPage(response.data.data.current_page);
        store.dispatch(setSelectedTeam(undefined));
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      store.dispatch(setIsLoading({ name: 'teams', value: false }));
    }
  };

  const getAllTeams = () => {
    networking.get(`/api/v1/teams`).then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Team>>) => {
      setAllTeams(response.data.data.records);
    });
  };

  useEffect(() => {
    getAllTeams();
    fetchTeams();
    return () => {
      store.dispatch(setTeams(undefined));
    };
  }, [currentPage, uuid]);

  return (
    <section className={styles['fade-in']}>
      <IonGrid className='ion-no-margin'>
        <TeamActionRow fetchAllTeams={getAllTeams} teams={allTeams} />
      </IonGrid>
      {isLoadingTeams
        ? (
          <IonGrid className='ion-padding'>
            <IonRow>
              <IonCol>
                <SkeletonItem amount={4} />
              </IonCol>
            </IonRow>
          </IonGrid>
        )
        : (
          <IonList className='ion-padding-start ion-padding-end'>
            <TeamList fetchAllTeams={getAllTeams} />
            <PaginateData currentPage={currentPage} totalPages={totalPages} pageStepper={handlePagination} />
          </IonList>
        )

      }
    </section>
  );
};

export default Teams;
