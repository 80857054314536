import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import React, { useEffect, useState } from 'react';

interface ListItemProps {
  label: string | JSX.Element;
  ionLabelClass?: string;
  labelClassName?: string;
  ionItemClass?: string;
  itemClassName?: string;
  ionItemLines?: any;
  iconStart?: string;
  iconEnd?: string;
  showIconStart?: boolean | undefined;
  showIconEnd?: boolean | undefined;
}

const ListItems: React.FC<ListItemProps> = (props) => {
  const [iconStart, setIconStart] = useState<boolean | undefined>(false);
  const [iconEnd, setIconEnd] = useState<boolean | undefined>(false);

  useEffect(() => {
    setIconStart(props.showIconStart);
    setIconEnd(props.showIconEnd);
  }, [iconStart, iconEnd]);

  return (
    <React.Fragment>
      <IonItem
        lines={props.ionItemLines}
        className={props.itemClassName}
      >
        {iconStart
          ? <IonIcon size='xs' icon={props.iconStart} />
          : <React.Fragment />
        }

        <IonLabel
          class={props.ionLabelClass}
          className={props.labelClassName}
        >
          {props.label}
        </IonLabel>

        {iconEnd
          ? <IonIcon size='xs' icon={props.iconEnd} />
          : <React.Fragment />
        }
      </IonItem>
    </React.Fragment>
  );
};
export default ListItems;
