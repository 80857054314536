import { useIonAlert } from '@ionic/react';

import i18n from '../i18n';

type CancelButton = {
  text: string;
  role: string;
  cssClass: string;
}

type ConfirmButton = {
  text: string;
  handler?: () => void;
}

interface DeleteConfirmationOptions {
  cancelButton?: CancelButton;
  confirmButton?: ConfirmButton;
}

export const useDeleteConfirmation = (props: DeleteConfirmationOptions) => {
  const [alert] = useIonAlert();

  const handleDeleteConfirmation = (
    deleteFunction: (id: string) => void,
    header: string = i18n.t('Delete confirmation'),
    message: string = i18n.t('Are you sure you want to delete this record?'),
  ) => {
    alert({
      header,
      message,
      buttons: [
        { ...props.cancelButton ?? { text: 'Cancel', role: 'cancel', cssClass: 'secondary' } },
        { ...props.confirmButton ?? { text: 'Yes', handler: deleteFunction } }
      ]
    });
  };
  return {
    handleDeleteConfirmation
  };
};
