import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import classNames from 'classnames';

import styles from '../Items.module.scss';

interface ItemWithTotalInterface {
  labelLeft: string;
  descriptionLeft: string;
  labelRight: string;
  total: string;
  hasLines?: any;
  amountValue?: string;
}
const ItemWithTotal: React.FC<ItemWithTotalInterface> = ({
  descriptionLeft,
  hasLines,
  labelLeft,
  labelRight,
  total
}) => {
  return (
    <IonItem lines={hasLines} className={classNames('ion-no-padding ion-margin-top', styles['item-with-total-container'])}>
      <IonGrid>
        <IonRow className='ion-align-items-center'>
          <IonCol size={'12'} className='ion-no-padding'>
            <IonLabel className={classNames('ion-no-margin', styles['item-with-total-label'])}>
              {labelLeft}
            </IonLabel>
            <span className={classNames('ion-no-margin', styles['item-with-total-description'])}>{descriptionLeft}</span>
          </IonCol>
          <IonCol size={'12'}>
            <IonLabel className={classNames('ion-no-margin ion-text-right', styles['item-with-total-label'])}>
              {labelRight}
            </IonLabel>
            <IonLabel className={classNames('ion-no-margin ion-text-right', styles['item-with-total-label'])}>
              {total}
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem >
  );
};

export default ItemWithTotal;
