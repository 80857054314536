import { IonBadge, IonChip, IonCol, IonGrid, IonIcon, IonList, IonRow, IonSelectOption, useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { addSharp, close, filterOutline } from 'ionicons/icons';
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { levelColour, statusMap, statusVariables } from './config';
import type { NoticeStatus } from './notice.models';
import useNoticeBoardList from './useNoticeBoardList';
import DesktopWrapper from '../../components/DesktopWrapper';
import { BigUp } from '../../components/UI';
import type { SegmentButtonProps } from '../../components/UI/Buttons/Button';
import EmptyList from '../../components/UI/EmptyList';
import SortByDropdown from '../../components/UI/SearchAndSort/Sort/SortByDropdown';
import { useAppSelector } from '../../hooks';
import useFlashNotice from '../../hooks/useFlashNotice';
import usePermissionHandler from '../../hooks/usePermissions';
import { formatToYYYYMMDD } from '../../tools/formatDates';
import FlashNotice from '../Notice/FlashNotice';

const NoticeBoard: React.FC = () => {
  const { dummy } = useFlashNotice();
  return (
    <>
      <DesktopWrapper alignment={'center'}>
        <NoticeBoardContent />
      </DesktopWrapper>
      {dummy && <FlashNotice />}
    </>
  );
};

const AddNewButton: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslate();
  const project = useAppSelector(state => state.project.selectedProject);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const shared_button_props = {
    icon: addSharp,
    onClick: () => history.push(`/notice-board/${project?.id}/add`),
  };

  return (isDesktop
    ? (
      <BigUp.Buttons.Regular
        title={t('Add new')}
        onClick={shared_button_props.onClick}
        icon={{ slot: 'end', icon: shared_button_props.icon }}
        color={'secondary'}
      />
    )
    : (
      <BigUp.Buttons.Icon
        color={'none'}
        icon={{ icon: shared_button_props.icon, color: 'secondary' }}
        onClick={shared_button_props.onClick}
      />
    )
  );
};

const NoticeBoardContent: React.FC = () => {
  const [segmentValue, setSegmentValue] = useState<NoticeStatus>(statusVariables.unseen as NoticeStatus);
  const history = useHistory();
  const { t } = useTranslate();
  const location = useLocation();
  const [presentAlert] = useIonAlert();
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { filterValues, findStatus, getEmptyListMessage, getProjectNotices, getUsersNotices, notices, projectNotices, setFilterValues, setSortNoticesBy } = useNoticeBoardList();
  const permissions = usePermissionHandler();

  useEffect(() => {
    getProjectNotices();
  }, [uuid]);

  // TODO: Implement once available from server
  const handleSegmentValue = (value: NoticeStatus) => {
    setSegmentValue(value);
    const searchParams = new URLSearchParams(location.search);
    if (value === statusVariables.unseen) {
      searchParams.delete('status');
    } else {
      searchParams.set('status', value);
    }
    history.push({ search: searchParams.toString() });
  };

  const getStatusButtons = (): SegmentButtonProps['buttons'] => {
    return Array.from(statusMap.entries()).map(([status, label]) => ({
      text: t(`{{label}} ({{amount}})`, {
        label,
        // amount: findStatus(status as NoticeStatus | boolean).length,
        amount: 0,
      }),
      value: status,
    }));
  };

  const SEGMENT_BUTTONS: SegmentButtonProps['buttons'] = getStatusButtons();

  const filterInputs = [
    {
      level: 'danger',
      type: 'checkbox',
      label: t('Danger'),
      value: 'danger',
      checked: filterValues.includes('danger'),
    },
    {
      level: 'warning',
      type: 'checkbox',
      label: t('Warning'),
      value: 'warning',
      checked: filterValues.includes('warning'),
    },
    {
      level: 'info',
      type: 'checkbox',
      label: t('Information'),
      value: 'info',
      checked: filterValues.includes('info'),
    },
  ];

  const presentFilterByLevelAlert = () => {
    presentAlert({
      header: t('Filter by level'),
      inputs: filterInputs as any,
      buttons: [{
        text: t('Cancel'), role: 'cancel'
      },
      {
        text: t('Ok'), handler: (value) => setFilterValues(value)
      }],
    });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const statusFromURL = queryParams.get('status');
    if (statusFromURL) {
      setSegmentValue(statusFromURL as NoticeStatus);
    }
  }, [location.search]);

  const project: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.project.selectedProject);

  return (
    <>

      {/* TODO: Implement once available from server */}
      {/*       <SegmentButtons
        buttons={SEGMENT_BUTTONS}
        value={segmentValue}
        onChange={(value) => handleSegmentValue(value as NoticeStatus)}
      /> */}

      <DesktopWrapper width='var(--ion-desktop-mid-width)' alignment={'center'}>
        <div>
          <IonGrid className='ion-padding'>
            <IonRow className='ion-align-items-center ion-no-padding'>
              <IonCol size='8'>
                <h3>{t('Notices for {{project}}', { project: project?.name })}</h3>
              </IonCol>
              {(
                permissions.checkPermission('project_tools_access', 'admin') &&
                permissions.checkPermission('economy_tools_access', 'admin')
              ) &&
                (
                  <IonCol className='ion-text-right'>
                    <AddNewButton />
                  </IonCol>
                )}
            </IonRow>
            <IonRow className='ion-align-items-center'>
              <IonCol size='9' className='ion-padding-start'>
                <IonList>
                  <SortByDropdown onSort={(value: 'publish_at' | 'expire_at' | string | any) => {
                    setSortNoticesBy(
                      {
                        date: value,
                        direction: value === 'publish_at' ? 'desc' : 'asc'
                      }
                    );
                    getProjectNotices();
                  }}>
                    <IonSelectOption value='publish_at'>{t('Latest')}</IonSelectOption>
                    <IonSelectOption value='expire_at'>{t('Oldest')}</IonSelectOption>
                  </SortByDropdown>
                </IonList>
              </IonCol>
              <IonCol size='3' className='ion-text-right'>
                <BigUp.Buttons.Icon
                  mode='ios'
                  color={'none'}
                  icon={{ icon: filterOutline, color: 'dark', style: { fontSize: '30px' } }}
                  onClick={presentFilterByLevelAlert}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <section className='ion-padding-start'>
            {filterValues.map((value, i) => {
              return (
                <IonChip
                  itemType='button'
                  key={i}
                  color={'dark'}
                  onClick={() => setFilterValues(filter(filterValues, (item) => item !== value))}
                >
                  {value} <IonIcon icon={close} />
                </IonChip>
              );
            })}
          </section>
          <IonList className='ion-padding-horizontal'>
            {projectNotices?.map((item, i) => {
              return (
                <BigUp.MetadataItem
                  key={i}
                  indicator={{
                    hasIndicator: false, // Should be used for seen/unseen
                    color: levelColour.get(item.level) // Should be used for seen/unseen
                  }}
                  ionTextProps={{
                    color: 'medium',
                    IonTextProps: { children: formatToYYYYMMDD(item.publish_at) ?? '' }
                  }}
                  onItemClick={() => history.push(`/notice-board/${project?.id}/${item.id}`)}
                  toprightContent={<IonBadge style={{ background: levelColour.get(item.level) }}>{item.level}</IonBadge>}
                  subject={item.subject}
                  message={item.message}
                />
              );
            })}
          </IonList>
          {projectNotices?.length === 0 &&
            <EmptyList
              title={t('No notices has been created for {{project}}', { project: project?.name })}
              message={
                <>
                  {t('Click')} <Link to={`/notice-board/${project?.id}/add`}>{t('here')}</Link> {t('to create one.')}
                </>
              }
            />
          }
        </div>
      </DesktopWrapper>
    </>
  );
};
export default NoticeBoard;
