import { IonCol, IonGrid, IonItem, IonLabel, IonRow, useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import TagList from './Tags/TagList';
import { networking } from '../../api/networking';
import DesktopWrapper from '../../components/DesktopWrapper';
import HistoricalFileList from '../../components/FileList/HistoricalFileList';
import SkeletonTextThreeLines from '../../components/SkeletonComponents/SkeletonTextThreeLines';
import Toast from '../../components/Toasts/Toast';
import BigUp from '../../components/UI';
import { useAppSelector } from '../../hooks';
import { setSelectedFile } from '../../reducers/file';
import { setIsLoading } from '../../reducers/loading';

const DocumentPage: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  const { documentUuid } = useParams<{ documentUuid: string | undefined }>();
  const { t } = useTranslate();
  const [uploadedPhotos, setUploadedPhotos] = useState<E2U.V1.Models.File[] | undefined>(undefined);
  const [uploadedFiles, setUploadedFiles] = useState<E2U.V1.Models.File[] | undefined>(undefined);
  const document: E2U.V1.Models.Document | undefined = useAppSelector((state) => state.file.selectedFile);
  const isLoadingDocument: boolean = useAppSelector((state) => state.loading.isLoading.document);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const [presentAlert] = useIonAlert();

  const fetchSelectedFile = () => {
    if (typeof documentUuid === 'undefined' || documentUuid === '') {
      if (history.length) {
        history.goBack();
      } else {
        history.push('/project-tools');
      }
      return;
    }
    store.dispatch(setIsLoading({ name: 'document', value: true }));
    const queryParams = new URLSearchParams();
    queryParams.append('with[]', 'files');
    queryParams.append('with[]', 'activityCode');
    queryParams.append('with[]', 'relatedTags');
    networking.get(`/api/v1/documents/${documentUuid}?${queryParams.toString()}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Document>) => {
        store.dispatch(setSelectedFile(response.data.data));
      })
      .catch((error: E2U.V1.Response.Error) => {
        console.warn('Error while loading file', error);
        Toast(t('Document not found'), 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'document', value: false }));
      });
  };

  const handleDeleteButtonClicked = () => {
    presentAlert({
      header: t('Are you sure you want to delete this document?'),
      message: document?.name,
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel'
        },
        {
          text: t('Delete'),
          role: 'confirm',
          handler: () => {
            deleteSelectedFile();
          }
        }
      ]
    });
  };

  const deleteSelectedFile = () => {
    store.dispatch(setIsLoading({ name: 'deletingFile', value: true }));
    networking.delete(`/api/v1/documents/${documentUuid}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.File>) => {
        store.dispatch(setSelectedFile(undefined));
        Toast(t('Document deleted!'), 'success');

        history.go(-1);
      }).catch((error: E2U.V1.Response.Error) => {
        console.warn('Error while deleting file', error);
        Toast(t('Could not delete document {{name}}', { name: document?.name }), 'error');
      }).finally(() => {
        store.dispatch(setIsLoading({ name: 'deletingFile', value: false }));
      });
  };

  const handleFilesUpdated = (files: E2U.V1.Models.File[]) => {
    const photos = files.filter((file) => file.type === 'image');
    const otherFiles = files.filter((file) => file.type !== 'image');
    setUploadedPhotos(photos);
    setUploadedFiles(otherFiles);
  };

  useEffect(() => {
    fetchSelectedFile();
    return () => {
      store.dispatch(setSelectedFile(undefined));
    };
  }, [documentUuid]);

  useEffect(() => {
    if (document?.files && document.files.length) {
      handleFilesUpdated(document.files);
    }
  }, [document]);

  return (
    <DesktopWrapper>
      {isLoadingDocument
        ? <SkeletonTextThreeLines />
        : <React.Fragment>
          <IonRow className={'ion-padding'}>
            <IonCol size={'12'}>
              <IonGrid>
                <IonRow className='ion-align-items-start ion-justify-content-start ion-margin-bottom'>
                  <IonCol size='12'>
                    <IonItem className='ion-no-padding' lines='none'>
                      <IonLabel className='ion-no-margin'>
                        <BigUp.Label.Thick color={'dark'} label={t('Document name')} />
                        <BigUp.Label.Regular label={document?.name} className='ion-no-margin' />
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-align-items-start ion-justify-content-start ion-margin-bottom'>
                  <IonCol size='12'>
                    <IonItem className='ion-no-padding' lines='none'>
                      <IonLabel className='ion-no-margin'>
                        <BigUp.Label.Thick color={'dark'} label={t('Description')} />
                        <BigUp.Label.Regular label={document?.description} className='ion-no-margin' />
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-align-items-start ion-justify-content-start ion-margin-bottom'>
                  <IonCol size='12'>
                    <IonItem className='ion-no-padding' lines='none'>
                      <IonLabel className='ion-no-margin'>
                        <BigUp.Label.Thick color={'dark'} label={t('Actvity code')} />
                        {(document && document.activity_code && document.activity_code.name)
                          ? <BigUp.Label.Regular label={`${document.activity_code.code} ${document.activity_code.name}`} />
                          : <p>{t('No activity code set')}</p>}
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-align-items-start ion-justify-content-start ion-margin-bottom'>
                  <IonCol size='12'>
                    <IonItem className='ion-no-padding' lines='none'>
                      <IonLabel className='ion-no-margin'>
                        <BigUp.Label.Thick color={'dark'} label={t('Tags')} />
                        {(document && document.related_tags && document.related_tags.length > 0)
                          ? <TagList tags={document.related_tags} />
                          : <p>{t('No tags set')}</p>}
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-align-items-start ion-justify-content-start ion-margin-bottom'>
                  <IonCol size='12'>
                    <IonLabel className='ion-no-margin'>
                      <h2 className='ion-margin-bottom'><b>{t('Files')}</b></h2>
                      {(document && document.files && document.files.length) &&
                        <HistoricalFileList
                          files={uploadedFiles || []}
                          photos={uploadedPhotos || []}
                        />
                      }
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid>
                <IonRow className='ion-justify-content-evenly'>
                  <IonCol size='6' className='ion-text-center'>
                    <BigUp.Buttons.Regular
                      fill='outline'
                      onClick={() => handleDeleteButtonClicked()}
                      color={'danger'}
                      title={t('Delete')}
                    />
                  </IonCol>
                  <IonCol size='6' className='ion-text-center'>
                    <BigUp.Buttons.Regular
                      fill='outline'
                      routerLink={`/project-tools/${selectedProject?.id}/documents/${document?.id}/edit`}
                      color={'medium'}
                      className='ion-text-center'
                      title={t('Edit')}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </React.Fragment>
      }
    </DesktopWrapper>
  );
};

export default DocumentPage;
