import { IonIcon, IonItem, IonLabel, IonToggle } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { contrastOutline } from 'ionicons/icons';

import useDarkMode from '../../hooks/useDarkMode';

const DarkmodeToggle: React.FC = () => {
  const toggleTheme = useDarkMode();
  const { t } = useTranslate();
  const checkEnvironment = process.env.NODE_ENV === 'development';

  return (
    <>
      {checkEnvironment && (
        <IonItem color={'none'} lines="none">
          <IonIcon icon={contrastOutline} className='ion-margin-end' />
          <IonLabel className='ion-no-margin' color={'dark'}>{t('Dark mode')}</IonLabel>
          <IonToggle
            color={'success'}
            checked={toggleTheme.storedTheme === 'dark'}
            onIonChange={toggleTheme.toggleTheme}
            className="space-between"
          />
        </IonItem>
      )
      }
    </>
  );
};

export default DarkmodeToggle;
