import type {
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import {
  createSlice
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface PaymentScheduleReducer {
  paymentSchedule: E2U.V1.Models.PaymentSchedule | undefined;
  workupGraphData: E2U.V1.Objects.LineGraphData | undefined;
  selectedIndex: number;
}

export const paymentScheduleSlice = createSlice<
  PaymentScheduleReducer,
  SliceCaseReducers<PaymentScheduleReducer>,
  string
>({
  name: 'paymentSchedule',
  initialState: {
    paymentSchedule: undefined,
    workupGraphData: undefined,
    selectedIndex: 0
  },

  reducers: {
    setPaymentSchedule: (
      state: PaymentScheduleReducer,
      action: PayloadAction<E2U.V1.Models.PaymentSchedule | undefined>
    ) => {
      state.paymentSchedule = action.payload;
    },
    setWorkupGraphData: (
      state: PaymentScheduleReducer,
      action: PayloadAction<E2U.V1.Objects.LineGraphData | undefined>
    ) => {
      state.workupGraphData = action.payload;
    }
  }
});

export const { setPaymentSchedule, setWorkupGraphData } = paymentScheduleSlice.actions;

export default paymentScheduleSlice.reducer;
