import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenu,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { add } from 'ionicons/icons';
import React, { useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import styles from './splitpane.module.scss';
import { useAppSelector } from '../../../hooks';
import usePermissionHandler from '../../../hooks/usePermissions';
import ButtonResponsive from '../../../pages/Onboarding/Components/buttons/ButtonResponsive';
import type { ColourVariables } from '../variables';

interface DynamicMenuProps {
  uuid: string;
  menuItems: any[];
  label: string;
  baseUrl: 'project-tools' | 'economy-tools' | 'notice-board';
  toolColour: ColourVariables;
}

const SplitPaneOptions: React.FC<DynamicMenuProps> = ({
  baseUrl,
  menuItems,
  toolColour,
}) => {
  const location = useLocation();
  const { checkPermissionsForProjectAndTools } = usePermissionHandler();
  const { t } = useTranslate();
  const history = useHistory();
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);

  const url = (value: string) => `/${baseUrl}/${selectedProjectId}/${value}`;

  const handleProjectClick = (projectId: string | undefined) => {
    history.replace(`/${baseUrl}/${projectId}/${menuItems[0].value}`);
  };

  return (
    <IonMenu className={styles['split-pane-wrapper']} contentId="main">
      <IonHeader>
        <IonToolbar color={'light'}>
          <IonTitle className='ion-text-right'>
            <ButtonResponsive
              type='button'
              onClick={() => history.push('/project-tools/new')}
              mobileButton={{
                label: t('Add project'),
                children: (<IonIcon color='secondary' icon={add} />),
                ionicButton: { color: 'none', shape: 'round' },
              }}
              desktopButton={{
                children: (<><IonIcon slot='start' icon={add} color={'medium'} size='large' />{t('Create new project')}</>),
                ionicButton: { color: 'none' },
              }}
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" color={'light'}>
        <div className="ion-padding">
          {checkPermissionsForProjectAndTools('read', 'read')?.map((project, i) => (
            <React.Fragment key={project.id}>
              <IonItem
                className={classNames(styles['project-item'], {
                  [styles['selected-project']]: project.id === selectedProjectId,
                })}
                onClick={() => handleProjectClick(project.id)}
              >
                <IonLabel style={{
                  color: project.id === selectedProjectId ? toolColour : 'var(--ion-color-medium)',
                  fontWeight: project.id === selectedProjectId ? 800 : 400,
                }}>
                  {project.name}
                </IonLabel>
              </IonItem>
              {project.id === selectedProjectId && (
                <>
                  <div style={{ padding: 20, background: 'var(--ion-color-light)' }}>
                    {menuItems.map((item) => (
                      <Link
                        key={item.value}
                        to={url(item.value)}
                        className={classNames(
                          'ion-padding-start',
                          styles['split-pane-category-list-item']
                        )}
                        style={{
                          color:
                            location.pathname.startsWith(url(item.value))
                              ? toolColour
                              : 'var(--ion-color-medium)',
                          fontWeight:
                            location.pathname.startsWith(url(item.value)) ? 800 : 400,
                        }}
                      >
                        <div className={styles['label-container']}>
                          <IonIcon icon={item.subpanelIcon} /> {item.subpanelTitle}
                        </div>
                      </Link>
                    ))}
                  </div>
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default SplitPaneOptions;
