import { IonButton, IonCol, IonContent, IonGrid, IonList, IonListHeader, IonRow, IonText } from '@ionic/react';
import classNames from 'classnames';
import { t } from 'i18next';
import { ellipse } from 'ionicons/icons';
import React from 'react';

import styles from './styles/PersonalOverview.module.scss';
import ListItems from '../../../../components/ListsComponents/ListItems';

interface CloseModal {
  onClose: () => void;
}
const NoAccessModal: React.FC<CloseModal> = (props) => {
  const listContent = [
    {
      text: t("You haven't finished the onboarding for the specific project."),
      icon: ellipse
    },
    {
      text: t('Distance to the construction site.'),
      icon: ellipse
    },
    {
      text: t('No or bad internet connection.'),
      icon: ellipse
    },
    {
      text: <React.Fragment>
        {t('Error in the system')}
        <a href={`${process.env.REACT_APP_CLICKUP_REPORT_FORM}` as string} target='_blank'>{t('(click here to report error)')}</a>
      </React.Fragment>,
      icon: ellipse
    }

  ];

  return (
    <IonContent className={classNames('ion-padding', styles['access-info-modal-content'])}>
      <IonGrid>
        <IonList className={styles['no-access-list-container']}>
          <IonListHeader>
            <IonText>
              <h1>{t("Can't check in?")}</h1>
              <h3>{t('It might be because:')}</h3>
            </IonText>
          </IonListHeader>

          {listContent.map((accessInfo, i) => {
            return (
              <ListItems
                key={i}
                label={accessInfo.text}
                iconStart={accessInfo.icon}
                showIconStart={true}
                ionLabelClass={'ion-text-wrap'}
                labelClassName={''}
                ionItemLines={'none'}
              />
            );
          })}
        </IonList>
      </IonGrid>

      <IonGrid>
        <IonRow className='ion-text-center'>
          <IonCol>
            <IonButton color='secondary' onClick={() => props.onClose()}>
              {t('close')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default NoAccessModal;
