import { IonFabButton, IonIcon, IonSpinner } from '@ionic/react';
import { refresh } from 'ionicons/icons';
import React from 'react';

interface RefreshButtonInterface {
  onClick: () => void;
  loadingState?: boolean;
}

const RefreshButton: React.FC<RefreshButtonInterface> = ({ loadingState, onClick }) => {
  return (
    <React.Fragment>
      {loadingState
        ? <IonSpinner style={{ margin: '0 10px 0 10px' }} color={'light'} />
        : (
          <IonFabButton
            onClick={onClick}
            type='button'
            disabled={loadingState}
            size='small'
            color='var(--ion-color-light)'
          >
            <IonIcon color='light' icon={refresh} />
          </IonFabButton>
        )
      }

    </React.Fragment>
  );
};

export default RefreshButton;
