import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

const setAxiosAccessToken = (access_token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
};

const networking = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  isAxiosError: axios.isAxiosError,
  all: axios.all
};

export {
  networking,
  setAxiosAccessToken
};
