import type { Draft, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { SliceCaseReducers } from '@reduxjs/toolkit/src';
import type { E2U } from '@techlove/easy2use-typings';

export interface WorkSiteReducer {
  worksites: E2U.V1.Models.WorkSite[] extends E2U.V1.Models.WorkSiteLayer[] ? E2U.V1.Models.WorkSiteLayer[] : E2U.V1.Models.WorkSite[] | undefined;
  selectedWorksite: Partial<E2U.V1.Models.WorkSite & { layers: E2U.V1.Models.WorkSiteLayer[] }> | undefined;
  worksitePaginationData: E2U.V1.Objects.PaginationProperties | undefined;
  reloadWorksites: boolean;
}

export const worksiteSlice = createSlice<WorkSiteReducer, SliceCaseReducers<WorkSiteReducer>>({
  name: 'worksite',
  initialState: {
    worksites: undefined,
    selectedWorksite: undefined,
    worksitePaginationData: undefined,
    reloadWorksites: false
  },
  reducers: {
    setWorksites: (
      state: Draft<WorkSiteReducer>,
      action: PayloadAction<E2U.V1.Models.WorkSite[] | undefined>
    ) => {
      state.worksites = action.payload;
    },
    setSelectedWorksite: (
      state: Draft<WorkSiteReducer>,
      action: PayloadAction<E2U.V1.Models.WorkSite | undefined>
    ) => {
      state.selectedWorksite = action.payload;
    },
    setWorksitesPaginationData: (
      state: Draft<WorkSiteReducer>,
      action: PayloadAction<E2U.V1.Objects.PaginationProperties | undefined>
    ) => {
      state.worksitePaginationData = action.payload;
    },
    reloadWorksites: (
      state: Draft<WorkSiteReducer>,
    ) => {
      state.reloadWorksites = !state.reloadWorksites;
    }
  }
});

export const { reloadWorksites, setSelectedWorksite, setWorksites, setWorksitesPaginationData } = worksiteSlice.actions;
export default worksiteSlice.reducer;
