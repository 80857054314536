import type { ToastOptions } from 'react-toastify';
import { toast } from 'react-toastify';

const Toast = (
  message: string,
  type: 'success' | 'error' | 'warning' | 'info' | 'default' | 'dark',
) => {
  const toastOptions: ToastOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  if (type === 'error') {
    toast.error(message, toastOptions);
  } else if (type === 'success') {
    toast.success(message, toastOptions);
  } else if (type === 'warning') {
    toast.warning(message, toastOptions);
  } else if (type === 'info') {
    toast.info(message, toastOptions);
  } else if (type === 'default') {
    toast(message, toastOptions); // No specific method for default type, use toast directly
  } else if (type === 'dark') {
    toast.dark(message, toastOptions);
  } else {
    console.error('Invalid toast type:', type);
  }
};

export default Toast;
