import { IonCol, IonGrid, IonList, IonRow, useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import { peopleCircleSharp } from 'ionicons/icons';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import { networking } from '../../../api/networking';
import EmptyList from '../../../components/UI/EmptyList';
import ItemShadowNoIcons from '../../../components/UI/Items/components/ItemShadowNoIcons';
import { customBorder } from '../../../components/UI/variables';
import { useAppSelector } from '../../../hooks';
import { setTeams } from '../../../reducers/team';
import store from '../../../store';
import { formatToYYYYMMDD } from '../../../tools/formatDates';
import type { TeamId, TeamsPromises } from '../interfaces';

const TeamsListTable: React.FC<TeamsPromises> = (props) => {
  const { uuid } = useParams<{ uuid: string }>();
  const history = useHistory();
  const { t } = useTranslate();
  const teams: E2U.V1.Models.Team[] | undefined = useAppSelector(state => state.team.teams);
  const [deleteAlert] = useIonAlert();

  const confirmDelete = (id: TeamId) => {
    deleteAlert({
      header: t('Delete team'),
      message: t('Are you sure you want to delete this team?'),
      buttons: [
        { text: t('Cancel'), role: 'cancel', },
        { text: t('Delete'), handler: () => deleteTeam(id) }
      ]
    });
  };

  const deleteTeam = (id: TeamId) => {
    networking.delete(`/api/v1/projects/${uuid}/teams/${id}`)
      .then(() => {
        store.dispatch(setTeams(teams?.filter((team) => team.id !== id) || []));
        props.fetchAllTeams && props.fetchAllTeams();
      });
  };

  useEffect(() => {
    props.fetchAllTeams && props.fetchAllTeams();
  }, []);

  return (
    <IonList className="ion-no-padding ion-no-margin">
      {(typeof teams === 'undefined' || teams === null)
        ? <EmptyList
          title={t('No teams found')}
          message={t('Create a team to get started')}
        />
        : (
          <IonGrid>
            {teams.map((team, index) => {
              return (
                <IonRow key={index} className='ion-align-items-center'>
                  <IonCol className={'ion-no-margin'} size='12' style={{ width: '60%', maxWidth: 900 }}>
                    <ItemShadowNoIcons
                      iconLeft={peopleCircleSharp}
                      hasIconLeft
                      key={index}
                      label={t('Team')}
                      subLabel={`${t('Created at')} ${team.created_at ? formatToYYYYMMDD(team.created_at) : '-'}`}
                      hasBorder
                      description={team.name}
                      borderColour={customBorder.borderColourGray}
                      onItemClick={() => history.push(`/project-tools/${uuid}/team/${team.id}`)}
                      deleteHandler={() => confirmDelete(team.id as TeamId)}
                    />
                  </IonCol>
                </IonRow>
              );
            })}
          </IonGrid>
        )
      }
    </IonList>);
};

export default TeamsListTable;
