import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonRow,
  IonToolbar
} from '@ionic/react';
import { t } from 'i18next';
import { closeOutline } from 'ionicons/icons';
import React from 'react';

import { useAppSelector } from '../../../../hooks';
import useProfilePicture from '../../../../hooks/useProfilePicture';
import type { OnboardingInterface } from '../../interfaces/Onboarding.interface';
import styles from '../modals/styles/PersonalOverview.module.scss';

const PersonalOverviewToolbar: React.FC<OnboardingInterface> = (props) => {
  const user = useAppSelector(state => state.authentication.user);
  const profilePicture = useProfilePicture();

  return (
    <React.Fragment>
      <IonToolbar className={styles['personaloverview-toolbar']}>
        <IonGrid>
          <IonRow class="ion-justify-content-between ion-text-center">
            <IonCol size="4">
              <IonButtons>
                <IonButton size="large" shape="round" fill="solid" onClick={() => props.togglePersonalOverview!()}>
                  <IonIcon icon={closeOutline} size="large" color="secondary" />
                </IonButton>
              </IonButtons>
            </IonCol>
            <IonCol size="4" className={styles['onboarding-avatar-container']}>
              <IonAvatar>
                <IonImg alt={t('Employee profile picture for {{name}}', { name: user?.first_name })} src={profilePicture} />
              </IonAvatar>
              <p>{`${user?.first_name} ${user?.last_name}`}</p>
            </IonCol>
            <IonCol size="4" />
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </React.Fragment>
  );
};

export default PersonalOverviewToolbar;
