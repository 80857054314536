import { IonCol, IonDatetime, IonGrid, IonIcon, IonLabel, IonList, IonModal, IonRow, IonSelectOption, IonSpinner } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import classnames from 'classnames';
import { arrowDownOutline, arrowUpOutline, checkmarkCircleSharp, lockClosed, lockOpen } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import styles from './ProductionBudget.module.scss';
import ProductionBudgetRow from './ProductionBudgetRow';
import { networking } from '../../../../api/networking';
import AlarmModalContent from '../../../../components/Alarms/AlarmModalContent';
import PaginateData from '../../../../components/Pagination/PaginationData';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import { handleEmptyListStates } from '../../../../components/UI/EmptyList';
import SearchAndSortRow from '../../../../components/UI/SearchAndSort/SearchAndSortRow';
import { useAppSelector } from '../../../../hooks';
import useModal from '../../../../hooks/useModal';
import { setIsLoading } from '../../../../reducers/loading';
import { setProductionBudget } from '../../../../reducers/production-budget';
import { setAll, softUnlock } from '../../../../reducers/subpanelLock';
import store from '../../../../store';
import { buildOptionalQuery } from '../../../../tools/queryBuilder';
import { scrollToSection } from '../../../../tools/scrollToSection';
import modalStyle from '../../../Onboarding/Components/containers/styles/ModalContainers.module.scss';
import { PaginationLabel } from '../Precalculations/PreCalculationRows';

interface Lock<T> {
  isLocked: boolean | T;
  fetchProject: () => void;
}

const scrollToId = 'production-budget-rows-top';
const ProductionBudget: React.FC<Lock<boolean>> = (props: Lock<boolean>) => {
  const { t } = useTranslate();

  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [productionBudgetRows, setProductionBudgetRows] = useState<E2U.V1.Models.ProductionBudgetRow[]>([]);
  const [error, setError] = useState<string | undefined>();
  const { closeModal, isModalOpen, openModal } = useModal();
  const { handleSubmit } = useForm<FieldValues>({ mode: 'onTouched', reValidateMode: 'onChange' });
  const { uuid } = useParams<{ uuid: string | undefined }>();

  const productionBudget: E2U.V1.Models.ProductionBudget | undefined | any =
    useAppSelector((state) => state.productionBudget.productionBudget);
  const isLoadingProductionBudget = useAppSelector((state) => state.loading.isLoading.productionBudget);
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);

  const fetchProductionBudget = async (): Promise<any> => {
    store.dispatch(setIsLoading({ name: 'productionBudget', value: true }));
    try {
      const response = await toasters.promise(
        networking.get(`/api/v1/projects/${uuid}/production_budget?with=lockedBy`),
        { error: t("Couldn't load production budget rows") }
      );
      store.dispatch(setProductionBudget(response.data.data.records[0]));
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      store.dispatch(setIsLoading({ name: 'productionBudget', value: false }));
    }
  };

  const fetchRows = () => {
    if (!productionBudget?.id) return;
    networking.get(`/api/v1/production_budgets/${productionBudget.id}/rows?${buildOptionalQuery(search, sortBy, currentPage, perPage)}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.ProductionBudgetRow>>) => {
        setProductionBudgetRows(response.data.data.records);
        setTotalPages(response.data.data.total_pages);
        setCurrentPage(response.data.data.current_page);
      })
      .catch((error) => {
        Sentry.captureException(error);
        setError(error.message);
      });
  };

  const toastersLockProductionBudget = {
    pending: t('Locking production budget...'),
    success: t('Production budget is locked'),
    error: t("Couldn't lock production budget")
  };

  const lockProductionBudget: SubmitHandler<FieldValues> = () => {
    toasters
      .promise(networking.post(`/api/v1/production_budgets/${productionBudget?.id}/lock`), { ...toastersLockProductionBudget, })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        onLockDate();
      });
  };

  const onLockDate = () => {
    props.fetchProject();
    closeModal();
    store.dispatch(softUnlock(false));
  };

  const handleLocked = (all: boolean, soft: boolean) => {
    store.dispatch(setAll(all));
    store.dispatch(softUnlock(soft));
  };

  const pageStepper = (step: number) => {
    setCurrentPage(currentPage + step);
    scrollToSection(scrollToId);
  };

  const numberOfResults = (resultPerPage: number, startPage: number) => {
    setPerPage(resultPerPage);
    setCurrentPage(startPage);
    setSearch('');
  };

  useEffect(() => {
    fetchProductionBudget();
    return () => {
      setProductionBudget(undefined);
    };
  }, []);

  useEffect(() => {
    if (productionBudget?.id) {
      fetchRows();
    }
  }, [search, sortBy, currentPage, perPage, productionBudget?.id]);

  const messages = {
    empty: {
      title: t('No production budget'),
      message: t('No production budget have been added to this project. Add a control to get started.'),
    },
    error: {
      message: error || '',
    },
  };

  return (
    <React.Fragment>
      <div className={'ion-padding'}>
        <div className={styles['production-budget-page-container']}>
          <div className={classnames(styles['production-budget-container'])} slot="content">
            <IonGrid className={styles['production-budget-datelock']}>
              <IonRow className='ion-align-items-center'>
                {productionBudget?.locked_by && (
                  <>
                    <IonCol sizeLg='4'>
                      <IonLabel style={{ fontSize: 'var(--ion-label-size-small)' }}>
                        {t('Lock date')}:
                      </IonLabel>
                      <p className='ion-no-margin'>{productionBudget?.lock_date}</p>
                    </IonCol>
                    <IonCol sizeLg='5'>
                      <IonLabel style={{ fontSize: 'var(--ion-label-size-medium)' }}>
                        {t('Locked by')}:
                      </IonLabel>
                      <p className='ion-no-margin'> {`${productionBudget?.locked_by?.first_name} ${productionBudget?.locked_by?.last_name}`}</p>
                    </IonCol>
                  </>
                )}
                <IonCol size='3' className='ion-text-center'>
                  {props.isLocked
                    ? (
                      <IonIcon
                        icon={lockClosed}
                        size="large"
                        className={styles['date-locked']}
                        onClick={() => {
                          handleLocked(false, true);
                        }}
                      />
                    )
                    : (
                      <IonIcon
                        icon={lockOpen}
                        size="large"
                        className={styles['date-unlocked']}
                        onClick={() => openModal()}
                      />
                    )}
                </IonCol>
              </IonRow>
            </IonGrid>

            <div className={styles['production-row-container']}>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <SearchAndSortRow onSearch={setSearch} onSort={setSortBy} value={search}>
                      <IonSelectOption value={'name'}>{t('Name')}</IonSelectOption>
                      <IonSelectOption value={'created_at'}>{t('Created at')}</IonSelectOption>
                      <IonSelectOption value={'updated_at'}>{t('Last modified')}</IonSelectOption>
                    </SearchAndSortRow>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-justify-content-end ion-align-items-center'>
                  <IonCol id={scrollToId}>
                    <PaginationLabel currentPage={currentPage} totalPages={totalPages} />
                  </IonCol>
                  <IonCol size='3' className='ion-text-right ion-margin-bottom'>
                    {
                      <BigUp.Buttons.Regular
                        title={perPage >= 9999 ? t('Show less') : t('Show all')}
                        icon={perPage >= 9999 ? { icon: arrowUpOutline } : { icon: arrowDownOutline }}
                        onClick={() => numberOfResults(perPage >= 9999 ? 10 : 9999, perPage >= 9999 ? 1 : 0)}
                        color={'medium'}
                        fill='outline'
                        expand='block'
                      />
                      /* perPage >= 9999 && (
                        <BigUp.Buttons.Regular />
                      ) */
                      /* ? <IonButton onClick={() => numberOfResults(10, 1)} color={'medium'} fill='outline' className='ion-no-margin ion-margin-bottom'>
                        {t('Show less')} <IonIcon icon={arrowUpOutline} />
                      </IonButton>
                      : <IonButton onClick={() => numberOfResults(9999, 0)} color={'medium'} fill='outline' className='ion-no-margin ion-margin-bottom'>
                        {t('Show all')}  <IonIcon icon={arrowDownOutline} />
                      </IonButton> */
                    }
                  </IonCol>
                </IonRow>
              </IonGrid>

              {isLoadingProductionBudget
                ? <IonSpinner />
                : (
                  <React.Fragment>
                    {handleEmptyListStates({ isEmpty: productionBudgetRows.length === 0, error, messages, search })}
                    {productionBudgetRows.length > 0 &&
                      <IonList {...isDesktop && {
                        className: 'ion-margin-end ion-padding-end'
                      }}>
                        {productionBudgetRows?.map((row, i) => {
                          return (
                            <ProductionBudgetRow key={i} row={row} />
                          );
                        })}
                        {productionBudgetRows.length > 0 && (
                          <PaginateData
                            currentPage={currentPage}
                            totalPages={totalPages}
                            pageStepper={pageStepper}
                          />
                        )}
                      </IonList>
                    }
                  </React.Fragment>
                )
              }
            </div>
          </div>

          <IonModal keepContentsMounted={true}>
            <IonDatetime
              showDefaultButtons={true}
              size="cover"
              id="datetime"
              className={styles.test}
            />
          </IonModal>

          <IonModal isOpen={isModalOpen} onIonModalDidDismiss={closeModal} className={modalStyle['app-default-modal']}>
            <form onSubmit={handleSubmit(lockProductionBudget)} className={styles.projectLockForm}>
              {selectedProject?.locked &&
                <AlarmModalContent
                  modal={{ modalDismiss: closeModal }}
                  colour={{
                    backgroundColour: 'var(--ion-color-danger)',
                    textColours: 'var(--ion-color-dark)'
                  }}
                  texts={{
                    alertTitle: t('Are you sure you want to re-lock the production budget?'),
                    alertSubtitle: `${selectedProject?.name}`,
                    contentText: t('Locking the production budget again will do the following:'),
                  }}
                  buttonLeft={{
                    leftTitle: t('Cancel'),
                    leftIonColour: 'medium',
                    leftOnClick: closeModal
                  }}
                  buttonRight={{
                    shouldSubmit: true,
                    rightTitle: t('Lock'),
                    rightIonColour: 'none',
                  }}
                />
              }
              {!selectedProject?.locked &&
                <AlarmModalContent
                  modal={{ modalDismiss: closeModal }}
                  alert={{ alertIcon: checkmarkCircleSharp }}
                  colour={{
                    backgroundColour: 'var(--ion-color-success)',
                    textColours: 'var(--ion-color-light)',
                    buttonRightColour: 'var(--ion-color-light)'
                  }}
                  texts={{
                    alertTitle: t('Are you sure you want to lock the production budget?'),
                    alertSubtitle: `${selectedProject?.name}`,
                    contentText: t('Locking the production budget will do the following:'),
                  }}
                  buttonLeft={{
                    leftTitle: t('Cancel'),
                    leftIonColour: 'none',
                    leftOnClick: closeModal
                  }}
                  buttonRight={{
                    shouldSubmit: true,
                    rightTitle: t('Unlock'),
                    rightIonColour: 'success',
                  }}
                />
              }
            </form>
          </IonModal>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ProductionBudget;
