import { Storage } from '@ionic/storage';

const store = new Storage();
let storage: Storage | undefined;
const storagePromise: Promise<Storage> = new Promise((resolve, reject) => {
  if (typeof storage === 'undefined') {
    store.create().then((s) => {
      storage = s;
      resolve(storage);
    }).catch(err => reject(err));
  } else {
    resolve(storage);
  }
});

const get: (name: string) => Promise<any> = (name: string) => new Promise((resolve, reject) => {
  storagePromise.then((store) => {
    store.get(name).then(
      (result) => resolve(result)
    ).catch(
      (err) => reject(err)
    );
  }).catch(
    (err) => reject(err)
  );
});

const set: (name: string, data: any) => Promise<any> = (name: string, data: any) => new Promise((resolve, reject) => {
  storagePromise.then((store) => {
    store.set(name, data).then(
      (result) => resolve(result)
    ).catch(
      (err) => reject(err)
    );
  }).catch(
    (err) => reject(err)
  );
});

const remove: (name: string) => Promise<any> = (name: string) => new Promise((resolve, reject) => {
  storagePromise.then((store) => {
    store.remove(name).then(
      (result) => resolve(result)
    ).catch(
      (err) => reject(err)
    );
  }).catch(
    (err) => reject(err)
  );
});

const clear: () => Promise<any> = () => new Promise((resolve, reject) => {
  storagePromise.then((store) => {
    store.clear().then(
      (result) => resolve(result)
    ).catch(
      (err) => reject(err)
    );
  }).catch(
    (err) => reject(err)
  );
});

const keys: () => Promise<any> = () => new Promise((resolve, reject) => {
  storagePromise.then((store) => {
    store.clear().then(
      (result) => resolve(result)
    ).catch(
      (err) => reject(err)
    );
  }).catch(
    (err) => reject(err)
  );
});

const length: () => Promise<any> = () => new Promise((resolve, reject) => {
  storagePromise.then((store) => {
    store.clear().then(
      (result) => resolve(result)
    ).catch(
      (err) => reject(err)
    );
  }).catch(
    (err) => reject(err)
  );
});

const exportData = {
  storage: storagePromise,
  get,
  set,
  remove,
  clear,
  keys,
  length
};
export default exportData;
