import { IonBadge } from '@ionic/react';
import type { HTMLAttributes } from 'react';

interface ValidationBadgeProps extends HTMLAttributes<HTMLIonBadgeElement> {
  children: React.ReactNode;
  marginLeft?: boolean;

}

export const ValidationBadge: React.FC<ValidationBadgeProps> = ({ children, marginLeft = false, ...rest }) => {
  const badgeColor = rest.color || 'secondary';

  return (
    <section style={{ marginLeft: marginLeft ? '5px' : '0px', minWidth: 70 }}>
      <IonBadge
        className='ion-no-margin'
        style={{
          textAlign: 'left',
          textWrap: 'wrap',
          maxWidth: 400
        }}
        {...rest}
        color={badgeColor}
      >
        {children}
      </IonBadge>
    </section >
  );
};
