import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export interface TeamReducer {
  teams: E2U.V1.Models.Team[] | undefined;
  selectedTeam: E2U.V1.Models.Team | undefined;
  teamsPaginationData: E2U.V1.Objects.PaginationProperties | undefined;
  teamRoles: E2U.V1.Models.TeamableRole[] | undefined;
}

export const teamsSlice = createSlice<TeamReducer,
  SliceCaseReducers<TeamReducer>,
  string>({
    name: 'team',
    initialState: {
      teams: undefined,
      selectedTeam: undefined,
      teamsPaginationData: undefined,
      teamRoles: undefined
    },
    reducers: {
      setTeams: (
        state: Draft<TeamReducer>,
        action: PayloadAction<E2U.V1.Models.Team[]>
      ) => {
        state.teams = action.payload;
      },
      setSelectedTeam: (
        state: Draft<TeamReducer>,
        action: PayloadAction<E2U.V1.Models.Team>
      ) => {
        state.selectedTeam = action.payload;
      },
      setTeamsPaginationData: (
        state: Draft<TeamReducer>,
        action: PayloadAction<E2U.V1.Objects.PaginationProperties | undefined>
      ) => {
        state.teamsPaginationData = action.payload;
      },
      setTeamRoles: (
        state: Draft<TeamReducer>,
        action: PayloadAction<E2U.V1.Models.TeamableRole[] | undefined>
      ) => {
        state.teamRoles = action.payload;
      }
    }
  });

export const { setSelectedTeam, setTeamRoles, setTeams, setTeamsPaginationData } = teamsSlice.actions;

export default teamsSlice.reducer;
