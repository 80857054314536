import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { t } from 'i18next';
import { capitalize } from 'lodash';

import type { PermissionsSelectionProps } from './config';

const PermissionSelection: React.FC<PermissionsSelectionProps<any>> = (props) => {
  return (
    <div className='ion-margin-bottom'>
      <IonItem className='ion-no-padding'>
        <IonSelect
          label={props.title}
          labelPlacement={'stacked'}
          placeholder={props.placeholder || t('Select Permissions')}
          value={props.selected}
          onIonChange={(e) => props.handleSelection(e.detail.value, props.title)}
        >
          {props.data.map((section, index) => {
            const value = section.label === null ? t('None') : section.label;
            return (
              <IonSelectOption key={index} value={section.value}>
                {capitalize(value)}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>
    </div>
  );
};

export default PermissionSelection;
