import { IonList } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { constructOutline, headsetOutline, newspaperOutline, settingsOutline, statsChartOutline } from 'ionicons/icons';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import DesktopWrapper from '../../components/DesktopWrapper';
import Panel from '../../components/Panels/Panel';
import { setSelectedProject } from '../../reducers/project';
import store from '../../store';

interface HomepageLinks {
  icon: string;
  directTo: string;
  title: string;
}

const HomePage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslate();

  const HOMEPAGE_LINKS: HomepageLinks[] = [
    {
      icon: newspaperOutline,
      directTo: '/notice-board/',
      title: t('Notice board'),
    },
    {
      icon: statsChartOutline,
      directTo: '/economy-tools/',
      title: t('Economy tools'),
    },
    {
      icon: constructOutline,
      directTo: '/project-tools/',
      title: t('Project tools'),
    },
    {
      icon: headsetOutline,
      directTo: '/support/',
      title: t('Support'),
    },
    {
      icon: settingsOutline,
      directTo: '/settings/',
      title: t('User settings'),
    }
  ];

  useEffect(() => {
    store.dispatch(setSelectedProject(undefined));
  }, []);

  return (
    <DesktopWrapper width='600px' alignment={'center'}>
      <IonList className='ion-padding-end ion-margin-top ion-padding-top' >
        {HOMEPAGE_LINKS.map((item, index) => {
          return (
            <Panel key={index}
              icon={item.icon}
              onClick={() => history.push(item.directTo)}
              panelTitle={item.title}
              iconColour={'var(--ion-color-primary-shade)'}
              hasInnerIcon={true}
            />
          );
        })}
      </IonList>
    </DesktopWrapper>
  );
};

export default HomePage;
