import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';

import { BankIdContext } from './bankid.context';
import bankIdNetworking from './bankid.networking';
import { handleHintcodes } from './hintCodes';
import type { BankID } from './typings/bankIdContext';
import type { CollectAuthResponse, CollectSignResponse, CompletedAuthResponse, Token } from './typings/collectResponse';
import type { InitiateAuthResponse, InitiateSignResponse } from './typings/initiateResponse';
import type { HintCode, ResponsePoll } from './typings/pendingLog';

export const BankIdProvider: React.FC<
  BankID.BankIdProviderProps & {
    handleToken: (token: CollectAuthResponse['token']) => void
  }> =
  ({ baseURL, children, handleToken }) => {
    const [bankdIdAuthResponse, setBankIdAuthResponse] = useState<CollectAuthResponse | CompletedAuthResponse | null>(null);
    const [bankdIdSignResponse, setBankIdSignResponse] = useState<CollectSignResponse | null>(null);
    const [bankIdAuthInitiation, setBankIdAuthInitiation] = useState<InitiateAuthResponse | null>(null);
    const [bankIdSignInitiation, setBankIdSignInitiation] = useState<InitiateSignResponse | null>(null);
    const [pollHandling, setPollHandling] = useState<ResponsePoll>({
      authPoll: null,
      signPoll: null,
      shouldPoll: true,
      isCancelled: false,
    });
    const bankIdHintCodes = handleHintcodes(bankdIdAuthResponse?.data.hint_code as HintCode);

    const initiateAuthentication = async () => {
      try {
        const response = await bankIdNetworking.authenticate.initiateBankIdAuthentication(baseURL);
        if (response) {
          setBankIdAuthInitiation(response.data);
          setPollHandling({
            ...pollHandling,
            shouldPoll: true,
            isCancelled: false,
          });
        }
      } catch (error: AxiosError<string> | any) {
        throw new Error('COULD NOT INITIATE BANKID AUTHENTICATION: ', error);
      }
    };

    const openBankIdApp = (type: 'authenticate' | 'sign') => {
      const urlSearchParams = new URLSearchParams();
      if (type === 'authenticate') {
        urlSearchParams.append('autostarttoken', bankIdAuthInitiation?.data?.auto_start_token ?? '');
      }
      if (type === 'sign') {
        urlSearchParams.append('autostarttoken', bankIdSignInitiation?.data?.auto_start_token ?? '');
      }
      urlSearchParams.append('redirect', process.env.REACT_APP_SHARE_BASE_URL ?? 'null');
      window.open('https://app.bankid.com?' + urlSearchParams.toString(), '_blank');
    };

    const checkAuthenticationStatus = async () => {
      if (bankIdAuthInitiation) {
        const response = await bankIdNetworking.authenticate.checkBankIdAuthenticationStatus(baseURL, bankIdAuthInitiation.data.id);
        if (response) {
          setBankIdAuthResponse(response.data);
          if (response.data.status === 'completed') {
            if (response.data.token) {
              handleToken(response.data.token);
            }
          }
          if (pollHandling.shouldPoll && (response.data.status === 'pending' || response.data.status === 'ongoing')) {
            setPollHandling({
              ...pollHandling,
              isCancelled: false,
              authPoll: setTimeout(checkAuthenticationStatus, 1000),
            });
          }
        }
      }
    };

    const initiateSign = async (message: string) => {
      const response = await bankIdNetworking.sign.initiateBankIdSign(baseURL, { user_visible_data: message });
      if (response) {
        setBankIdSignInitiation(response.data);
        setPollHandling({
          ...pollHandling,
          shouldPoll: true,
          isCancelled: false,
        });
      }
    };

    const checkSignStatus = async () => {
      if (bankIdSignInitiation) {
        const response = await bankIdNetworking.sign.checkBankIdSignStatus(baseURL, bankIdSignInitiation.data.id);
        if (response) {
          setBankIdSignResponse(response.data);
          if (pollHandling.shouldPoll && (response.data?.status === 'pending' || response.data?.status === 'ongoing')) {
            setPollHandling({
              ...pollHandling,
              isCancelled: false,
              signPoll: setTimeout(checkSignStatus, 1000),
            });
          }
        }
      }
    };

    const clearReducer = () => {
      setBankIdAuthResponse(null);
      setBankIdSignResponse(null);
      setBankIdAuthInitiation(null);
      setBankIdSignInitiation(null);
    };

    const abortAuthentication = () => {
      if (pollHandling.authPoll) {
        clearTimeout(pollHandling.authPoll);
        setPollHandling({
          ...pollHandling,
          shouldPoll: false,
          isCancelled: true,
        });
      }
      if (pollHandling.signPoll) {
        clearTimeout(pollHandling.signPoll);
        setPollHandling({
          ...pollHandling,
          shouldPoll: false,
          isCancelled: true,
        });
      }
      clearReducer();
    };

    useEffect(() => {
      if (bankIdAuthInitiation) {
        checkAuthenticationStatus();
      }
      if (bankdIdAuthResponse?.status === 'completed') {
        // Do something with the response
      }
      if (bankdIdAuthResponse?.status === 'failed') {
        setBankIdAuthInitiation(null);
        clearReducer();
      }
    }, [bankIdAuthInitiation]);

    useEffect(() => {
      if (bankIdSignInitiation) {
        checkSignStatus();
      }
      if (bankdIdSignResponse?.status === 'completed') {
        // Do something with the response ?
      }
      if (bankdIdSignResponse?.status === 'failed') {
        setBankIdSignInitiation(null);
        clearReducer();
      }
    }, [bankIdSignInitiation]);

    const value = {
      authenticate: {
        isCancelled: pollHandling.isCancelled,
        bankIdHintCodes,
        status: bankdIdAuthResponse?.status,
        data: bankdIdAuthResponse?.data,
        auth_request: bankdIdAuthResponse?.auth_request,
        initiateBankIdAuthentication: initiateAuthentication,
        token: bankdIdAuthResponse?.token ?? null,
      },
      sign: {
        initiateBankIdSign: initiateSign,
        isCancelled: pollHandling.isCancelled,
        bankIdHintCodes,
        status: bankdIdSignResponse?.status,
        data: bankdIdSignResponse?.data,
        auth_request: bankdIdSignResponse?.auth_request,
        token: bankdIdSignResponse?.token ?? null,
      },
      abortBankIdAuthentication: abortAuthentication,
      openBankIdApp,
      clearReducer,
      associate: {
        associateBankId: (
          ssn: string,
          token: Token
        ) => bankIdNetworking.associate.associateBankId(baseURL, ssn, token),
      }
    };

    return (
      <BankIdContext.Provider value={value as BankID.BankIDContext | any}>
        {children}
      </BankIdContext.Provider>
    );
  };
