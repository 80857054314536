import { IonAccordion } from '@ionic/react';
import React from 'react';

import type { AccordionItemInterface } from './Accordion';
import styles from './Accordion.module.scss';
import AccordionHeader from './AccordionHeader';
import PaddedContent from '../UI/Divs/PaddedContent';
import LockIcon from '../UI/Icons/LockIcon';

const AccordionItem: React.FC<AccordionItemInterface> = ({
  children,
  isLockable,
  locked,
  onToggle,
  subpanelActiveColour,
  subpanelClassName,
  subpanelDisabled,
  subpanelIcon,
  subpanelIconEnd,
  subpanelTitle,
  value
}) => {
  const endIcon = subpanelIconEnd ?? (isLockable
    ? <LockIcon isLocked={locked} onClick={onToggle} />
    : undefined
  );

  return (
    <div className={styles[subpanelClassName as any]}>
      <IonAccordion disabled={subpanelDisabled} value={value}>
        <AccordionHeader
          route={value}
          {...{ subpanelIcon, subpanelTitle, subpanelActiveColour, subpanelIconEnd: endIcon }}
        />
        <PaddedContent>
          {children}
        </PaddedContent>
      </IonAccordion>
    </div>
  );
};

export default AccordionItem;
