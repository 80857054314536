import { useEffect } from 'react';
import { useStore } from 'react-redux';

import { setIsDesktop } from '../../reducers/utilities/utilities.reducer';

const MediaChangeHandler: React.FC = () => {
  const store = useStore();
  const updateMedia = () => {
    store.dispatch(setIsDesktop(window.innerWidth > 1024 ?? false));
  };

  useEffect(() => {
    updateMedia();
    window.addEventListener('resize', updateMedia);
    return () => {
      window.removeEventListener('resize', updateMedia);
    };
  }, []);

  return (
    <></>
  );
};

export default MediaChangeHandler;
