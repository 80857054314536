import { IonItem } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import PasswordInputs from './PasswordInputs';
import type { PasswordHandlerProps } from './types';
import FadeInContainer from '../../components/Animated/FadeInContainer';
import BigUp from '../../components/UI';

const PasswordHandler: React.FC<PasswordHandlerProps> = (props) => {
  const { t } = useTranslate();
  const [handlePasswordCheckbox, setHandlePasswordCheckbox] = useState(false);

  const methods = useFormContext();
  const handleCheckbox = (checked: boolean) => {
    setHandlePasswordCheckbox(checked);
    if (!checked) {
      methods.setValue('password', undefined);
      methods.setValue('password_confirmation', undefined);
    }
  };

  return (
    <>
      {props.isBankId?.ssn && (
        <IonItem className='ion-no-padding' type='button' lines='full'>
          <BigUp.Checkbox
            labelPlacement='start'
            checked={handlePasswordCheckbox}
            justify='space-between'
            handleCheckbox={(checked) => handleCheckbox(checked)}
          >
            {t('I want to set a password (optional)')}
          </BigUp.Checkbox>
        </IonItem>
      )
      }

      {(!props.isBankId?.ssn || handlePasswordCheckbox) && (
        <FadeInContainer isVisible={!props.isBankId?.ssn || handlePasswordCheckbox}>
          <PasswordInputs onKeyUp={props.onKeyUp} required={!props.isBankId?.ssn || handlePasswordCheckbox} />
        </FadeInContainer>
      )}
    </>
  );
};

export default PasswordHandler;
