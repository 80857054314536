import {
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonLoading,
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import {
  appsOutline,
  arrowBack,
  pencil,
  trash,
  trashBin
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import Detail from './Detail';
import Edit from './Edit';
import { networking } from '../../../../../api/networking';
import ReportsCard from '../../../../../components/ReportsCard';
import Toast from '../../../../../components/Toasts/Toast';
import { useAppSelector } from '../../../../../hooks';
import { setIsLoading } from '../../../../../reducers/loading';
import { setSelectedIncident } from '../../../../../reducers/reports';

const IncidentReport: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslate();
  const { incident_uuid } = useParams<{ incident_uuid: string | undefined }>();
  const store = useStore();
  const isLoadingIncident: boolean = useAppSelector(
    (state) => state.loading.isLoading.incident
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const fetchIncident = () => {
    if (typeof incident_uuid === 'undefined' || incident_uuid === '') {
      Toast(t('Incident not found'), 'error');
      history.push('/projects');
      return;
    }
    store.dispatch(setIsLoading({ name: 'incident', value: true }));
    networking
      .get(`/api/v1/incident_reports/${incident_uuid}?with=files`)
      .then(
        (response: E2U.V1.Response.Success<E2U.V1.Models.IncidentReport>) => {
          store.dispatch(setSelectedIncident(response.data.data));
        }
      )
      .catch((error: E2U.V1.Response.Error) => {
        console.warn('Error while loading incident', error);
        Toast(t('Incident not found'), 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'incident', value: false }));
      });
  };

  const onSave = () => {
    setIsEditing(false);
    fetchIncident();
  };

  useEffect(() => {
    fetchIncident();
    return () => {
      store.dispatch(setSelectedIncident(null));
    };
  }, []);
  return (
    <>
      <ReportsCard
        title={t('Incident details')}
        iconRight={trash}
        iconLeft={arrowBack}
      >
        {isLoadingIncident
          ? (
            <IonLoading isOpen />
          )
          : isEditing
            ? (
              <Edit onSave={onSave}></Edit>
            )
            : (
              <Detail
                onUpdateIncident={fetchIncident}
                onOpenEdit={() => {
                  setIsEditing(true);
                }}
              />
            )}
      </ReportsCard>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton>
          <IonIcon icon={appsOutline}></IonIcon>
        </IonFabButton>
        <IonFabList side="start">
          <IonFabButton
            color="warning"
            onClick={() => {
              setIsEditing(true);
            }}
          >
            <IonIcon icon={pencil}></IonIcon>
          </IonFabButton>
          <IonFabButton color="danger">
            <IonIcon icon={trashBin}></IonIcon>
          </IonFabButton>
        </IonFabList>
      </IonFab>

    </>
  );
};

export default IncidentReport;
