import { IonItem, IonLabel, IonSkeletonText } from '@ionic/react';

type SkeletonItemProps = {
  amount?: number;
};
const SkeletonTextThreeLines: React.FC<SkeletonItemProps> = ({ amount = 3 }) => {
  const skeletonItems = Array.from({ length: amount }, (_, index) => (
    <IonItem key={index}>
      <IonLabel>
        <h3>
          <IonSkeletonText animated={true} style={{ width: '100' }}></IonSkeletonText>
        </h3>
        <p>
          <IonSkeletonText animated={true} style={{ width: '60%' }}></IonSkeletonText>
        </p>
        <p>
          <IonSkeletonText animated={true} style={{ width: '30%' }}></IonSkeletonText>
        </p>
      </IonLabel>
    </IonItem>
  ));
  return (
    <>
      {skeletonItems}
    </>
  );
};

export default SkeletonTextThreeLines;
