import { IonImg } from '@ionic/react';
import { useTranslate } from '@tolgee/react';

import type { BankIdLogoProps } from './bankid.interfaces';
import bankIdLogoStandard from '../../../assets/bankID/BankID_logo.png';
import bankIdLogoBlack from '../../../assets/bankID/BankID_logo_black.png';
import bankIdLogoNegative from '../../../assets/bankID/BankID_logo_neg.png';
import bankIdLogoWhite from '../../../assets/bankID/BankID_logo_white.png';

const defaultStyles = {
  width: '100px'
};

const logoSources: Record<BankIdLogoProps['color'], string> = {
  black: bankIdLogoBlack,
  white: bankIdLogoWhite,
  negative: bankIdLogoNegative,
  standard: bankIdLogoStandard,
};

const Logo: React.FC<BankIdLogoProps> = ({ color, ...props }) => {
  const { t } = useTranslate();
  const logoSrc = logoSources[color];
  return <IonImg {...props} style={{ width: props.width || defaultStyles.width }} src={logoSrc} alt={t('BankID logo')} />;
};

const BankIdIcon = (props: BankIdLogoProps) => {
  return <Logo {...props} />;
};

export default BankIdIcon;
