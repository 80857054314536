import { IonCol, IonGrid, IonItem, IonRow, IonText, IonToggle } from '@ionic/react';
import React from 'react';
import type { Control, FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { ionicColours } from '../../../../../../components/UI/variables';

interface CertificateToggleInterface {
  name: string;
  will_work_with: boolean;
  toggle: () => void;
  control: Control<FieldValues>;
  watchName: string;
}

const CertificateToggle: React.FC<Partial<CertificateToggleInterface>> = (props) => {
  return (
    <IonItem className='ion-no-padding'>
      <IonGrid>
        <IonRow className='ion-no-padding ion-padding-top ion-justify-content-between ion-align-items-center'>
          <IonCol size='9' sizeLg='3' className='ion-text-left'>
            <IonText style={{ fontWeight: 600, color: ionicColours.tertiary, opacity: '.8', textTransform: 'capitalize' }}>
              <p>{props.name?.replace(/_/g, ' ')}</p>
            </IonText>
          </IonCol>
          <IonCol offset='1' offsetLg='8'>
            <Controller
              name={props.watchName!}
              control={props.control}
              render={({ field }) => <IonToggle checked={field.value} onIonChange={() => props.toggle!()} />}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
export default CertificateToggle;
