import type { Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

export const changesSlice = createSlice<E2U.V1.Models.EconomyChange, SliceCaseReducers<E2U.V1.Models.EconomyChange>, string>({
  name: 'changes',
  initialState: {
    id: '',
    accepted: false,
    number: 0,
    name: '',
    project_id: '',
    comment: '',
    rows: [] as E2U.V1.Models.EconomyChangeRow[],
  },
  reducers: {
    setChanges: (
      state: Draft<E2U.V1.Models.EconomyChange>,
      action: PayloadAction<E2U.V1.Models.EconomyChange>
    ) => {
      const { comment, project_id, rows } = action.payload;
      state.project_id = project_id;
      state.comment = comment;
      state.rows = rows;
    },
  }
});

export const { setActivityCodes, setChanges } = changesSlice.actions;

export default changesSlice.reducer;
