import { IonContent, IonPage } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import React from 'react';

import HeaderBackground from '../../../components/UI/Header/HeaderBackground';
import PasswordResetForm from '../PasswordResetForm';

const PasswordResetPage: React.FC = () => {
  const { t } = useTranslate();
  return (
    <IonPage>
      <IonContent>
        <HeaderBackground
          title={t('Reset password')}
          subtitle={t('Enter your email address below to reset your password')}
        />
        <PasswordResetForm />
      </IonContent>
    </IonPage>
  );
};

export default PasswordResetPage;
