import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonCol,
  IonGrid,
  IonModal,
  IonRow,
  IonSelectOption, IonSpinner
} from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useTranslate } from '@tolgee/react';
import {
  chevronForward,
  documentAttach,
  filterOutline
} from 'ionicons/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import getPropertiesByType from './documentSwitch';
import type { DocumentNavigation, DocumentType } from './interfaces';
import { networking } from '../../../api/networking';
import AccordionHeader from '../../../components/Accordion/AccordionHeader';
import PaginateData from '../../../components/Pagination/PaginationData';
import RelatedMultiSelectEdit from '../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import BigUp from '../../../components/UI';
import PaddedContent from '../../../components/UI/Divs/PaddedContent';
import ItemShadowNoIcons from '../../../components/UI/Items/components/ItemShadowNoIcons';
import HeaderBorderLeft from '../../../components/UI/modals/HeaderBorderLeft';
import PaddedModalContent from '../../../components/UI/modals/PaddedModalContent';
import type { ReturnInterfaceExtraInfo } from '../../../components/UI/Return/ReturnChevronText';
import SearchAndSortRow from '../../../components/UI/SearchAndSort/SearchAndSortRow';
import toasters from '../../../components/UI/Toasts';
import { ionicColours } from '../../../components/UI/variables';
import { useAppSelector } from '../../../hooks';
import useFileUpload from '../../../hooks/useFileUpload';
import {
  setCurrentDocumentPage,
  setDocumentTypes,
  setLoadingTypes,
  setPaginatedDocuments,
} from '../../../reducers/document';
import { setFiles, setFilesPaginationData } from '../../../reducers/file';
import { setIsLoading } from '../../../reducers/loading';
import { scrollToSection } from '../../../tools/scrollToSection';
import modalStyles from '../../Onboarding/Components/containers/styles/ModalContainers.module.scss';
import FileSelectionButton from '../../Projects/ProjectPage/ControlOfExecution/AddFilesModal/FileSelectionButton';
import DocumentForm from '../DocumentForm';

const uncategorized = 'Uncategorized';

const DocumentsListTable: React.FC = () => {
  const [search, setSearch] = useState<string>('');
  const [sort, setSort] = useState<string>('created_at');
  const [uploadDocumentModalOpen, setUploadDocumentModalOpen] = useState<boolean>(false);
  const [documentIsOpen, setDocumentIsOpen] = useState<{ [type: string]: boolean }>({});
  const { getUploadedFiles, handleFileSelection, onlyUploadFiles, setUploadedFiles, uploadSelectedFiles } = useFileUpload();
  const files = getUploadedFiles() ?? [];
  const { t } = useTranslate();
  const store = useStore();
  const location = useLocation<ReturnInterfaceExtraInfo>();
  const history = useHistory();

  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const currentDocumentPage = useAppSelector(state => state.document.currentDocumentPage);
  const totalDocumentPages = useAppSelector(state => state.document.totalDocumentPages);
  const documentTypes = useAppSelector(state => state.document.documentTypes);
  const loadingTypes = useAppSelector(state => state.document.loadingTypes);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const projectId = useMemo(
    () => selectedProject && selectedProject.id,
    [selectedProject]
  );
  const prevProjectId = useRef(projectId).current;
  const lastReloadTs = useAppSelector(state => state.file.lastReloadTs);
  const [selectedActivityCodes, setSelectedActivityCodes] = useState<E2U.V1.Models.ActivityCode[]>([]);
  const [selectedFileTypes, setSelectedFileTypes] = useState<E2U.V1.Objects.FileType[]>([]);

  const paginatedDocuments: {
    [key: string]: {
      [key: number]: E2U.V1.Models.Document[]
    }
  } = useAppSelector(state => state.document.paginatedDocuments);

  const getDocumentTypes = () => {
    if (
      typeof selectedProject === 'undefined' ||
      selectedProject === null ||
      typeof selectedProject.id === 'undefined' ||
      selectedProject.id === null
    ) {
      return;
    }
    networking.get(`/api/v1/projects/${selectedProject.id}/documents/types`)
      .then((response: E2U.V1.Response.Success<{ [key: string]: DocumentType }>) => {
        const newDocumentTypes: { [key: string]: DocumentType } = { ...response.data.data };
        newDocumentTypes.none = {
          class: 'App\\Models\\Documents\\Document',
          count: 0,
          color: 'blue',
          name: t(uncategorized),
        };
        store.dispatch(setDocumentTypes(newDocumentTypes));
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      });
  };

  const paginate = (pageNumber = 1, perPage = 10) => {
    scrollToSection('file-top');
    if (
      typeof selectedProject === 'undefined' ||
      selectedProject === null ||
      typeof selectedProject.id === 'undefined' ||
      selectedProject.id === null
    ) {
      return;
    }

    store.dispatch(setIsLoading({ name: 'files', value: true }));

    const searchParams = new URLSearchParams();
    searchParams.append('page', pageNumber.toString());
    searchParams.append('per_page', perPage.toString());
    searchParams.append('project_id', selectedProject.id);
    if (search) {
      searchParams.append('search', search);
    }
    if (sort) {
      searchParams.append('sort_by', sort);
    }

    networking.get(`/api/v1/projects/${selectedProject.id}/documents?${searchParams.toString()}`)
      .then((response: E2U.V1.Response.Success<
        E2U.V1.Objects.PaginatedData<
          E2U.V1.Models.Document
        >
      >) => {
        store.dispatch(setFiles(response.data.data.records));
        store.dispatch(setFilesPaginationData(response.data.data));
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'files', value: false }));
      });
  };

  const refreshDocuments = () => {
    documentTypes && Object.keys(documentTypes).forEach((type) => {
      fetchTypeDocuments(type, currentDocumentPage[type] ? currentDocumentPage[type] : 1, 5);
    });
  };

  useEffect(() => {
    if (projectId && projectId !== prevProjectId) {
      refreshDocuments();
      getDocumentTypes();
    }
  }, [projectId]);

  const toggleUploadModal = () => {
    setUploadDocumentModalOpen(!uploadDocumentModalOpen);
  };

  const typesWithProperties = useMemo(() => {
    return Object.keys(documentTypes).map((type) => {
      const {
        categoryName,
        docColour,
        file,
        route,
        state
      } = getPropertiesByType(documentTypes[type].class);

      return {
        type: documentTypes[type],
        filterName: type,
        categoryName: documentTypes[type]?.name || categoryName,
        docColour: documentTypes[type]?.color || docColour,
        route,
        file,
        state
      };
    }).sort(
      (a, b) => {
        a.categoryName.localeCompare(b.categoryName);
        if (a.categoryName === t(uncategorized)) {
          return -1;
        }
        if (b.categoryName === t(uncategorized)) {
          return 1;
        }
        return a.categoryName.localeCompare(b.categoryName);
      }
    );
  }, [documentTypes]);

  const checkLocationState = () => {
    if (location && location.state && location.state.subpanelCategory) {
      const type = typesWithProperties.find(
        (type) => type.type.class === location.state.subpanelCategory
      );
      if (!type) {
        return;
      }
      setDocumentIsOpen({
        ...documentIsOpen,
        [type.filterName]: true
      });
    }
  };

  useEffect(() => {
    getDocumentTypes();
    checkLocationState();
  }, []);

  useEffect(() => {
    refreshDocuments();
  }, [search, sort, selectedActivityCodes, selectedFileTypes]);

  const fetchTypeDocuments = (type: string, page: number, perPage = 5, forced = false) => {
    if (
      typeof selectedProject === 'undefined' ||
      selectedProject === null ||
      typeof selectedProject.id === 'undefined' ||
      selectedProject.id === null
    ) {
      return;
    }

    const searchParams = new URLSearchParams();
    searchParams.append('page', page.toString());
    searchParams.append('per_page', perPage.toString());
    const filters = [{
      field: 'document_type',
      value: [type]
    }];
    if (selectedActivityCodes.length > 0) {
      filters.push({
        field: 'activity_code_id',
        value: selectedActivityCodes.map((activityCode) => activityCode?.id ?? '')
      });
    }
    if (selectedFileTypes.length > 0) {
      filters.push({
        field: 'document_has_file_types',
        value: selectedFileTypes.map((fileType) => fileType.value)
      });
    }
    searchParams.append('with[]', 'files');
    searchParams.append('filters', JSON.stringify(filters));
    searchParams.append('project_id', selectedProject.id);
    if (search) {
      searchParams.append('search', search);
    }
    if (sort) {
      searchParams.append('sort_by', sort);
    }
    store.dispatch(setLoadingTypes({
      type,
      value: true
    }));

    networking.get(`/api/v1/projects/${selectedProject?.id}/documents?${searchParams.toString()}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Document>>) => {
        if (currentDocumentPage[type] > response.data.data.total_pages && !forced) {
          handlePaginateDocuments(type, response.data.data.total_pages, true, true);
          return;
        }
        store.dispatch(setPaginatedDocuments({
          type,
          page: response.data.data.current_page,
          documents: response.data.data.records,
          total: response.data.data.total_pages
        }));
      })
      .finally(() => {
        store.dispatch(setLoadingTypes({
          type,
          value: false
        }));
      });
  };

  const handlePaginateDocuments = (type: string, step: number, isFixedPage = false, forceRefetch = false) => {
    const newPage = isFixedPage ? step : (currentDocumentPage[type] ? currentDocumentPage[type] + step : 1);
    if (
      forceRefetch ||
      typeof paginatedDocuments[type] === 'undefined' ||
      typeof paginatedDocuments[type][newPage] === 'undefined'
    ) {
      fetchTypeDocuments(type, newPage, 5, forceRefetch);
    }
    store.dispatch(setCurrentDocumentPage({
      type,
      page: newPage
    }));
  };

  const handleShowDocuments = (type: string, forceRefetch = false) => {
    if (documentIsOpen[type] && !forceRefetch) {
      setDocumentIsOpen({
        ...documentIsOpen,
        [type]: false
      });
      return;
    }
    const currentPage = currentDocumentPage[type] ? currentDocumentPage[type] : 1;
    if (
      typeof paginatedDocuments[type] === 'undefined' ||
      typeof paginatedDocuments[type][currentPage] === 'undefined' ||
      forceRefetch
    ) {
      fetchTypeDocuments(type, currentPage);
    }
    if (typeof currentPage === 'undefined') {
      store.dispatch(setCurrentDocumentPage({
        type,
        page: 1
      }));
    }
    setDocumentIsOpen({
      ...documentIsOpen,
      [type]: true
    });
  };

  useEffect(() => {
    documentIsOpen && Object.keys(documentIsOpen).forEach((type) => {
      if (documentIsOpen[type]) {
        handleShowDocuments(type, true);
      }
    });
  }, [search, sort, lastReloadTs]);

  useEffect(() => {
    checkLocationState();
  }, [location]);

  const navigateToDocument = (document: E2U.V1.Models.Document, documentType: DocumentNavigation) => {
    if (!selectedProject || !selectedProject.id || !document.id) {
      return;
    }
    let route = '/project-tools/{project_id}/documents/{document_id}';
    const state = documentType.state;
    if (documentType.route && document.resource_id) {
      route = documentType.route;
    }
    route = route.replace('{document_id}', document.id)
      .replace('{project_id}', selectedProject.id);
    if (document.resource_id) {
      route = route.replace('{resource_id}', document.resource_id);
      state.subpanelCategoryUuid = document.resource_id;
    }
    history.push(route, state);
  };

  const uploadFilesOnSelection = () => {
    if (files.length === 0) {
      return;
    }

    const handleToasters = (successful: boolean) => {
      if (successful) {
        setUploadedFiles([]);
        refreshDocuments();
      }
    };

    const uploadFiles = () => {
      const uploadPromises = onlyUploadFiles().map((filePromise) =>
        filePromise.then((fileResponse) =>
          networking.post(`/api/v1/documents`, {
            name: fileResponse.data.data.name,
            description: '',
            project_id: selectedProject?.id,
          })
            .then((response: E2U.V1.Response.Success<E2U.V1.Models.Document>) => {
              const documentId = response.data.data.id;
              const fileId = fileResponse.data.data.id;
              return networking.post(`/api/v1/documents/${documentId}/files/${fileId}`);
            })
        )
      );

      const allUploads = Promise.allSettled(uploadPromises);

      toasters.promiseToast(
        allUploads,
        {
          pending: {
            message: t('Uploading files'),
            background: 'var(--ion-color-light)',
          },
          success: {
            message: t('All files uploaded successfully'),
            background: 'var(--ion-color-light)',
            textColour: 'var(--ion-color-dark)',
          },
          error: {
            message: t('Some files could not be uploaded'),
            background: 'var(--ion-color-light)',
            textColour: 'var(--ion-color-dark)',
          },
        }
      )
        .then((results: PromiseSettledResult<E2U.V1.Models.Document>[]) => {
          const successful = results.every((result) => result.status === 'fulfilled');
          handleToasters(successful);
        })
        .catch((error) => {
          handleToasters(false);
          Sentry.captureException(error);
        });
    };

    uploadFiles();

    store.dispatch(setFiles([]));
  };

  useEffect(() => {
    if (files.length > 0) {
      uploadFilesOnSelection();
    }
  }, [files]);

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
      <div style={{
        width: '100%',
        maxWidth: 'var(--ion-desktop-max-width)',
        ...isDesktop && {
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'center',
          gap: 20,
          paddingTop: '20px'
        }
      }}>
        <div id={'file-top'} {...isDesktop && { style: { width: '40%' } }}>
          <IonGrid>
            {!isDesktop && (
              <IonRow className='ion-justify-content-end ion-align-items-center'>
                <IonCol size='4' className='ion-text-end ion-margin-bottom'>
                  <BigUp.Buttons.Icon
                    icon={{
                      icon: documentAttach,
                      size: 'large'
                    }}
                    size='small'
                    title={t('Create document')}
                    color={'secondary'}
                    onClick={toggleUploadModal}
                  />
                </IonCol>
                <IonCol size='3' className='ion-text-end ion-margin-bottom'>
                  <FileSelectionButton
                    expand='block'
                    custom
                    onFilesSelect={(files: File[]) => handleFileSelection(files)}
                    responsiveButton={true}
                    label={t('Upload files')}
                  />
                </IonCol>
              </IonRow>
            )}

            <BigUp.Label.Thick label={t('Filter by:')} />
            <IonRow>
              <IonCol>
                <RelatedMultiSelectEdit
                  expand='block'
                  button={'wide'}
                  model={'activity_codes'}
                  onChange={(results: E2U.V1.Models.ActivityCode[]) => setSelectedActivityCodes(results)}
                  label={
                    selectedActivityCodes.length
                      ? t('{{amount}} selected', {
                        amount: selectedActivityCodes.length
                      })
                      : t('Activity code')
                  }
                  records={selectedActivityCodes}
                  value={selectedActivityCodes}
                  displayFields={['code', 'name']}
                  modalTitle={t('Select activity codes')}
                  hideSelected={true}
                  ionButtonProps={{
                    color: 'light'
                  }}
                  leftIcon={filterOutline}
                />
              </IonCol>
              <IonCol >
                <RelatedMultiSelectEdit
                  expand='block'
                  button={'wide'}
                  model={'file_types'}
                  onChange={(results: E2U.V1.Objects.FileType[]) => setSelectedFileTypes(results)}
                  label={
                    selectedFileTypes.length
                      ? t('{{amount}} selected', {
                        amount: selectedFileTypes.length
                      })
                      : t('File type')
                  }
                  color='secondary'
                  value={selectedFileTypes}
                  displayFields={['label']}
                  modalTitle={t('Select file types')}
                  hideSelected={true}
                  /*      ionButtonProps={{
                         color: 'light'
                       }} */
                  leftIcon={filterOutline}
                  keyField={'value'}
                />
              </IonCol>
            </IonRow>
          </IonGrid>

          <SearchAndSortRow
            onSearch={setSearch}
            onSort={setSort}
            value={search}
          >
            <IonSelectOption value={'name'}>{t('Name')}</IonSelectOption>
            <IonSelectOption value={'created_at'}>{t('Created at')}</IonSelectOption>
            <IonSelectOption value={'updated_at'}>{t('Last modified')}</IonSelectOption>
          </SearchAndSortRow>

          {isDesktop && (
            <IonGrid>
              <IonRow>
                <IonCol size='6' sizeSm='12'>
                  <IonButton
                    expand='block'
                    onClick={toggleUploadModal}
                    className='ion-margin-bottom'
                    color={'secondary'}
                  >
                    {t('Create document')}
                  </IonButton>
                </IonCol>
                <IonCol
                  size='6'
                  sizeSm='12'
                >
                  <FileSelectionButton
                    expand='block'
                    onFilesSelect={(files: File[]) => handleFileSelection(files)}
                    label={t('Upload files')}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </div>
        <div {...isDesktop && {
          style: {
            width: '60%', marginBottom: 20, overflow: 'scroll', height: '100%'
          }
        }}>
          {typesWithProperties.map((documentType, i) => {
            const { categoryName, docColour, file, route } = documentType;

            return (
              <div key={i} style={{ borderLeft: `5px solid ${docColour}`, ...isDesktop && { width: '97%' } }}>
                <IonAccordionGroup
                  value={documentIsOpen[documentType.filterName] ? categoryName : undefined}
                  className='ion-no-padding ion-margin-bottom'
                  onIonChange={() => handleShowDocuments(documentType.filterName)}
                  style={{ borderBottom: `.5px solid var(--ion-color-medium)` }}
                >
                  <IonAccordion value={categoryName || ''} className='ion-margin-start ion-no-padding' >
                    <AccordionHeader {...{
                      subpanelIcon: '',
                      subpanelTitle: categoryName,
                      subpanelActiveColour: docColour,
                      subpanelClassName: 'ion-no-padding',
                      hasNoPadding: true,
                    }} />
                    <PaddedContent >
                      {(loadingTypes && loadingTypes[documentType.filterName])
                        ? <IonSpinner name={'lines'} />
                        : <React.Fragment>
                          {(
                            paginatedDocuments &&
                            paginatedDocuments[documentType.filterName] &&
                            typeof currentDocumentPage[documentType.filterName] !== 'undefined' &&
                            paginatedDocuments[documentType.filterName][currentDocumentPage[documentType.filterName]] &&
                            paginatedDocuments[documentType.filterName][currentDocumentPage[documentType.filterName]].length > 0
                          )
                            ? paginatedDocuments[documentType.filterName][currentDocumentPage[documentType.filterName]].map((document, index) => {
                              const files: any[] = document?.files || [];
                              const fileNames = files?.length ? files?.map((file) => file.name).join(', ') : t('No files attached');

                              return (
                                <div key={index} className='ion-no-margin'>
                                  <div
                                    onClick={() => navigateToDocument(document, documentType)}
                                    style={{
                                      textDecoration: 'none',
                                      cursor: 'pointer'
                                    }}
                                  >
                                    <ItemShadowNoIcons
                                      deleteHandler={() => navigateToDocument(document, documentType)}
                                      deleteIcon={chevronForward}
                                      borderColour={`5px solid ${docColour}`}
                                      label={t('Document: {{document}}', { document: document.name })}
                                      description={(files?.length > 1 ? t('Flera filer ({{count}})', { count: files.length }) : (files[0]?.name === document.name ? '' : fileNames))}
                                      subLabel={typeof document.description !== 'undefined'
                                        ? document.description
                                        : ''}
                                    />
                                  </div>
                                </div>
                              );
                            })
                            : <div>
                              <p className={'ion-text-center'}><i>{t('No documents found')}</i></p>
                            </div>
                          }
                        </React.Fragment>
                      }
                      <PaginateData
                        currentPage={currentDocumentPage[documentType.filterName] ? currentDocumentPage[documentType.filterName] : 1}
                        totalPages={totalDocumentPages[documentType.filterName] ? totalDocumentPages[documentType.filterName] : 1}
                        pageStepper={(step) => handlePaginateDocuments(documentType.filterName, step)}
                      />
                    </PaddedContent>
                  </IonAccordion>
                </IonAccordionGroup>
              </div>
            );
          })}

          <IonModal
            isOpen={uploadDocumentModalOpen}
            onIonModalDidDismiss={() => setUploadDocumentModalOpen(false)} className={modalStyles['app-default-modal']}
          >
            <HeaderBorderLeft
              borderColour={ionicColours.secondary}
              title={t('Create document')}
              clickHandler={() => toggleUploadModal()}
            />
            <PaddedModalContent>
              <DocumentForm handleCloseModal={toggleUploadModal} />
            </PaddedModalContent>
          </IonModal>
        </div>
      </div>
    </div>
  );
};

export default DocumentsListTable;
