import { isPlatform } from '@ionic/core';
import { IonAvatar, IonButton, IonButtons, IonCol, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonModal, IonRow, IonSegment, IonSegmentButton, IonToolbar } from '@ionic/react';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { arrowForward, menuOutline } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { matchPath, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { paths } from './navLinks';
import styles from './PageMenuHeader.module.scss';
import economyToolsHeaderImage from '../../assets/images/Economy_desktop.jpg';
import newsFeedHeaderImage from '../../assets/images/News_feed_desktop.jpg';
import projectToolsHeaderImage from '../../assets/images/Project_tools_desktop.jpg';
import { useAppSelector } from '../../hooks';
import useFetchProjects from '../../hooks/useFetchProjects';
import useModal from '../../hooks/useModal';
import usePermissionHandler from '../../hooks/usePermissions';
import useProfilePicture from '../../hooks/useProfilePicture';
import useRefresh from '../../hooks/useRefresh';
import modalStyle from '../../pages/Onboarding/Components/containers/styles/ModalContainers.module.scss';
import PersonalOverview from '../../pages/Onboarding/Components/PersonalOverview/PersonalOverview';
import { setCurrentTab } from '../../reducers/navigation';
import store from '../../store';
import ProjectSelector from '../ProjectSelector';
import RefreshButton from '../UI/Buttons/RefreshButton';

const PageMenuHeader: React.FC = () => {
  const { t } = useTranslate();
  const router = useHistory();
  const location = useLocation();
  const fetchProjects = useFetchProjects();
  const profilePicture = useProfilePicture();
  const { handleRefresh, isLoading } = useRefresh();
  const { checkPermission, checkPermissionsForProjectAndTools } = usePermissionHandler();
  const project = useAppSelector(store => store.project.selectedProject);
  const { closeModal, isModalOpen, openModal, toggleModal } = useModal();

  const ios = isPlatform('ios');
  const currentTab: string | undefined = useAppSelector(state => state.navigation.currentTab);
  const isDesktop: boolean = useAppSelector(state => state.desktopView.isDesktop);

  const handleChangeTab = (tab: string) => {
    if (tab === '') {
      router.push('/');
      return;
    }
    const tabData = paths.find((t) => t.tab === tab);
    if (typeof tabData === 'undefined') return;
    let tool;

    switch (tab) {
      case 'project-tools':
        tool = 'control-of-execution';
        break;
      case 'economy-tools':
        tool = 'project-information';
        break;
      default:
        tool = '';
    }
    if (!project?.id) {
      router.push(`/${tab}`);
    } else if (isDesktop) {
      router.push(`/${tab}/${project?.id}/${tool}`);
    } else {
      router.push(`/${tab}/${project?.id}`);
    }
  };

  const handleUrlChange = () => {
    const tabData = project?.id && paths.find((t) =>
      t.pattern.some((p) => matchPath(location.pathname, {
        path: p,
        exact: p === '/'
      }))
    );
    if (tabData) {
      store.dispatch(setCurrentTab(tabData.tab !== '' ? tabData.tab : ''));
    } else {
      store.dispatch(setCurrentTab(location.pathname.split('/')[1] || ''));
    }
  };

  const getImageURL = () => {
    if (matchPath(location.pathname, { path: '/economy-tools', exact: false })) {
      return economyToolsHeaderImage;
    } else if (matchPath(location.pathname, { path: '/project-tools', exact: false })) {
      return projectToolsHeaderImage;
    }
    return newsFeedHeaderImage;
  };
  const backgroundImage = getImageURL();
  const headerStyle = {
    objectFit: 'cover',
    paddingTop: ios ? '70px' : '30px',
    paddingBottom: isDesktop && '20px',
    backgroundImage: `var(--ion-color-primary-gradient), url(${backgroundImage})`
  };

  useEffect(() => {
    if (typeof location.pathname !== 'undefined') {
      handleUrlChange();
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <React.Fragment>
      <div style={{
        zIndex: 40,
        ...ios && { marginTop: 30 },
        background: 'transparent',
        height: '50px',
        width: '50px',
        position: 'absolute',
        right: 10,
        top: 30
      }}>
        <IonMenuButton className='ion-margin-end ion-margin-top' color={'light'}>
          <IonIcon icon={menuOutline} style={{ color: 'white' }} size='large' className='text-align-center'></IonIcon>
        </IonMenuButton>
      </div>

      <IonHeader className={classNames(styles['golden-header'],
        styles['page-menu-header-comp'], 'ion-no-border')}>
        <IonToolbar className={styles['header-toolbar-background']} style={headerStyle}>
          <div className={styles['header-nav']}>
            <div className={styles['header-user']} >
              <IonButtons className={styles['image-center']} >
                <IonItem color="none" lines="none" mode='md' type='button' onClick={() => openModal()}>
                  <IonAvatar className={styles['image-center']} >
                    <img src={profilePicture} sizes="small" alt="avatar" />
                  </IonAvatar>
                  <IonButton shape="round" color="primary">
                    <IonIcon className={styles['avatar-icon']} icon={arrowForward}></IonIcon>
                  </IonButton>
                </IonItem>
              </IonButtons>
            </div>
            <div className={styles['header-bar']}>
              <IonItem color="none" lines="none">
                <IonSegment value={currentTab} className={styles['golden-segment']}>
                  {paths.map((p, i) => {
                    if (
                      (!project?.id || !location.pathname.includes(`${project?.id}`)) ||
                      (p.tab === 'project-tools' && checkPermission('project_tools_access', 'read')) ||
                      (p.tab === 'economy-tools' && checkPermission('economy_tools_access', 'read')) ||
                      (p.tab === 'notice-board')
                    ) {
                      return (
                        <IonSegmentButton
                          class="ion-text-left"
                          onClick={() => handleChangeTab(p.tab)}
                          value={p.tab}
                          key={`tab-${i}`}
                        >
                          <IonLabel className='ion-text-wrap ion-text-center'>
                            {t(p.title)}
                          </IonLabel>
                        </IonSegmentButton>
                      );
                    } else {
                      return null;
                    }
                  }
                  )}
                </IonSegment>
              </IonItem>
            </div>
            {(project?.id && location.pathname.includes(`${project?.id}`)) && !isDesktop && (
              <IonGrid>
                <IonRow className='ion-align-items-center ion-justify-content-end'>
                  <IonCol size='9'>
                    <ProjectSelector currentTab={currentTab} />
                  </IonCol>
                  <IonCol size='2'>
                    <RefreshButton loadingState={isLoading} onClick={handleRefresh} />
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}
          </div>
        </IonToolbar>
      </IonHeader >

      <IonModal
        isOpen={isModalOpen}
        className={modalStyle['personaloverview-modal']}
        onIonModalDidDismiss={() => { closeModal(); }}
      >

        <PersonalOverview togglePersonalOverview={toggleModal} />
      </IonModal >
    </React.Fragment >
  );
};

export default PageMenuHeader;
