import { BigUp } from '../../../components/UI';
import type { TextareaProps } from '../../../components/UI/Textareas/interface';
import i18n from '../../../i18n';

const notice_message_validation: TextareaProps<any>['validation'] = {
  required: i18n.t('A message is required'),
  maxLength: { value: 500, message: i18n.t('Message is too long') },
  minLength: { value: 5, message: i18n.t('Message is too short') },
};
const notice_message_textarea: TextareaProps<any> = {
  label: i18n.t('Message'),
  inputMode: 'text',
  placeholder: i18n.t('Message'),
  register: 'message',
  validation: notice_message_validation,
  itemProps: { className: 'ion-no-padding' },
};

const NoticeMessage: React.FC = () => {
  return <BigUp.Textarea {...notice_message_textarea} />;
};

export default NoticeMessage;
