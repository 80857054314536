import type { PayloadAction, Slice } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import type { RootState } from '../store';

interface NotificationState {
    request_notifications: boolean
}

const initialState: NotificationState = {
  request_notifications: false
};

export const notificationSlice: Slice<NotificationState, SliceCaseReducers<NotificationState>> = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setRequestNotifications: (state, action: PayloadAction<boolean>) => {
      state.request_notifications = action.payload;
    },
  }
});

export const selectRequestNotifications = (state: RootState) => state.notification.request_notifications;

export const {
  setRequestNotifications
} = notificationSlice.actions;

export default notificationSlice.reducer;
