import * as Sentry from '@sentry/capacitor';
import { useTranslate } from '@tolgee/react';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import type { NoticeFormValues } from './Form/form.interface';
import type { NoticeStatus } from './notice.models';
import { networking } from '../../api/networking';
import { setIsLoading } from '../../reducers/loading';
import store from '../../store';

const useNoticeBoardList = () => {
  const [filterValues, setFilterValues] = useState<NoticeFormValues['level'][]>(['danger', 'warning', 'info']);
  const [notices, setNotices] = useState<NoticeFormValues[]>([]);
  const [sortNoticesBy, setSortNoticesBy] = useState<{
    date: 'publish_at' | 'expire_at',
    direction: 'asc' | 'desc'
  }>({
    date: 'publish_at',
    direction: 'desc'
  });
  const [projectNotices, setProjectNotices] = useState<NoticeFormValues[]>([]); // TODO: Implement once available from server
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { t } = useTranslate();

  const getUsersNotices = () => {
    store.dispatch(setIsLoading({ name: 'notices', value: true }));
    networking.get('/api/v1/notices?with=teams')
      .then((response) => {
        setNotices(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        return store.dispatch(setIsLoading({ name: 'notices', value: false }));
      });
  };
  const getProjectNotices = () => {
    networking.get(`/api/v1/projects/${uuid}/notices?direction=${sortNoticesBy.direction}&sort_by=${sortNoticesBy.date}`)
      .then(response => {
        setProjectNotices(response.data.data.records);
      });
  };

  /*   useEffect(() => {
      getNotices();
    }, []);
    useEffect(() => {
      getNoticeBoardItems();
    }, []); */

  const memoizedNotices = useMemo(() => {
    return notices;
  }, [notices]);

  const memoizedProjectNotices = useMemo(() => {
    return projectNotices;
  }, [projectNotices]);

  const findStatus = (status: NoticeStatus | boolean, noticeType: 'by-project' | 'by-user') => {
    const selectedLevels = filterValues.length > 0 ? filterValues : ['danger', 'warning', 'info'];
    if (filterValues.length === 0) {
      return memoizedNotices; // Return all items if no filters applied
    } else {
      /*
      TODO: Implement once available from server
      const meetsStatusCondition =
      (status === statusVariables.unseen && !item.has_seen) ||
      (status === statusVariables.seen && item.has_seen) ||
      (status === statusVariables.archived && item.archived);
      */
      /*  return (() => {
         if(noticeType === 'by-project')
         (memoizedProjectNotices && memoizedProjectNotices.filter((item) => selectedLevels.includes(item.level))) ||
         (memoizedNotices.filter((item) => selectedLevels.includes(item.level))))

       }  */

      if (noticeType === 'by-project') {
        return memoizedProjectNotices.filter((item) => selectedLevels.includes(item.level));
      }
      if (noticeType === 'by-user') {
        return memoizedNotices.filter((item) => selectedLevels.includes(item.level));
      }
    }
  };

  const getEmptyListMessage = (): string => {
    if (filterValues.length > 0) {
      return t('No notices found with the selected filters');
    }
    return t('No notices found');
  };

  return {
    notices: findStatus(true, 'by-user'),
    getEmptyListMessage,
    findStatus,
    setSortNoticesBy,
    filterValues,
    setFilterValues,
    getProjectNotices,
    projectNotices: findStatus(true, 'by-project'),
    getUsersNotices
  };
};

export default useNoticeBoardList;
