import { IonCol, IonGrid, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import DetailModal from './DetailModal/DetailModal';
import { networking } from '../../../../api/networking';
import FadeInContainer from '../../../../components/Animated/FadeInContainer';
import toasters from '../../../../components/Toasts/Toasts';
import ReturnChevronText from '../../../../components/UI/Return/ReturnChevronText';

const ControlOfExecutionDetailed: React.FC = () => {
  const { coe_id } = useParams<{ coe_id: string }>();
  const [selectedControlOfExecution, setSelectedControlOfExecution] = useState<E2U.V1.Models.ControlOfExecution>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchSelectedRecord = (record: string, preventToggleEdit = true) => {
    const endpoint = `/api/v1/control_of_executions/${record}`;
    const searchParams = new URLSearchParams();
    searchParams.append('with[]', 'assignees');
    searchParams.append('with[]', 'files');
    searchParams.append('with[]', 'activityCode');
    setIsLoading(true);
    toasters
      .promise(networking.get(`${endpoint}?${searchParams.toString()}`),
        { error: t('Could not get controls') }
      )
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.ControlOfExecution>) => {
        setSelectedControlOfExecution(response.data.data);
      })
      .catch((error: E2U.V1.Response.Error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchSelectedRecord(coe_id);
  }, []);

  return (
    <IonGrid>
      <IonRow className='ion-justify-content-center'>
        <IonCol>
          <ReturnChevronText text={t('Return')} color={'tertiary'} fill={'clear'} />
          <section className='ion-padding'>
            <FadeInContainer isVisible={!isLoading}>
              <DetailModal
                booleans={{ details: true }}
                fetchRecords={() => fetchSelectedRecord(coe_id)}
                record={selectedControlOfExecution}
              />
            </FadeInContainer>
          </section>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ControlOfExecutionDetailed;
